import type { Hour } from "../genericTypes";
import type { WorkingStatus, WorkingStatusMacedonian } from "../modules/EmployeeWeekly/types";

export function isHour(value: any): value is Hour {
  return (
    value === "09:00" ||
    value === "10:00" ||
    value === "11:00" ||
    value === "12:00" ||
    value === "13:00" ||
    value === "14:00" ||
    value === "15:00" ||
    value === "16:00" ||
    value === "17:00" ||
    value === "18:00" ||
    value === "19:00" ||
    value === "20:00" ||
    value === "21:00" ||
    value === "22:00" ||
    value === "23:00"
  );
}
export function isValidTime(timeString) {
  // Regular expression to match the time format "XX:XX"
  var regex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
  return regex.test(timeString);
}

export function isWorkingStatus(value: any): value is WorkingStatus | WorkingStatusMacedonian {
  return (
    value === "True" ||
    value === "Free" ||
    value === "Sick" ||
    value === "Vacation" ||
    value === "Болен" ||
    value === "Работен" ||
    value === "Одмор" ||
    value === "Слободен"
  );
}

