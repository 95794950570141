import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { AlarmOn, DriveEta, Refresh } from "@material-ui/icons";
import DialogTitle from "@shared/components/Dialog/DialogTitle";
import TextWithIcon from "@shared/components/TextWithIcon/TextWithIcon";
import { getDrivers } from "@shared/services/drivers/drivers.service";
import { IDriverRouteResponseDto } from "@shared/services/orders/dtos/orders/driverRouteResponseDto";
import {
  getActiveOrders,
  getDriversEstimatedRoutesAsync,
} from "@shared/services/orders/orders.service";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { FC, useState } from "react";
import DriverRouteComponent from "../Orders/DriverRouteComponent";

const useStyles = makeStyles({
  root: {
    width: "100%",
    marginTop: 10,
  },
  container: {
    height: "75vh",
  },
  dialog: {
    height: "100%",
  },
  dialogContent: {
    padding: "18px 24px 8px 24px",
  },
});

const loadAllData = async () => {
  return Promise.all([getDrivers(), getActiveOrders(), getDriversEstimatedRoutesAsync()]).then(
    ([driversResponse, ordersResponse, driverRoutesResponse]) => {
      const drivers = driversResponse.map((x) => ({
        ...x,
        geolocation: { longitude: x.longitude, latitude: x.latitude },
      }));

      const orders = ordersResponse
        .sort((a, b) =>
          dayjs.utc(b.pickUpTime).local().diff(dayjs.utc(a.pickUpTime).local(), "milliseconds") > 0
            ? -1
            : 1
        )
        .map((x) => ({
          ...x,
          driver: x.employeeFk ? drivers.find((r) => r.employeeId === x.employeeFk) : null,
        }));

      return {
        drivers,
        orders,
        driversRoutes: driverRoutesResponse,
      };
    }
  );
};

interface IFilterState {
  displayOrderNumber: string;
}

export interface FullRoutesDialogProps {
  onClose?: () => void;
}

const FullRoutesDialog: FC<FullRoutesDialogProps> = ({ onClose }) => {
  const classes = useStyles();

  const { data, isLoading, isFetching, error, refetch } = useQuery(["plannedRoutes"], loadAllData, {
    refetchInterval: 15000,
  });

  const [filter, setFilterState] = useState<IFilterState>({
    displayOrderNumber: "",
  });

  const handleFilterChange = (filterKey: keyof IFilterState, value: string) => {
    const cleanValue = value.trim();
    setFilterState((state) => ({ ...state, [filterKey]: cleanValue }));
  };

  
  const filterBydisplayOrderNumber = (
    driversRoutes: IDriverRouteResponseDto[],
    displayOrderNumber: string
  ) => {
    if (!displayOrderNumber) return driversRoutes;

    const ordersIds = data?.orders
      .filter((order) => order.displayOrderNumber.includes(displayOrderNumber))
      .map((order) => order.id);

    return driversRoutes?.filter((driversRoute) =>
      driversRoute.routePoints.find((routePoint) => ordersIds.includes(routePoint.orderId))
    );
  };

  const filteredDriversRoutes = filterBydisplayOrderNumber(
    data?.driversRoutes,
    filter.displayOrderNumber
  );

  return (
    <Dialog
      open
      fullWidth
      maxWidth="xl"
      classes={{ paper: classes.dialog, container: classes.dialog }}
      onClose={onClose}
    >
      <DialogTitle onClose={onClose}>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          style={{ gap: "0.5em", width: "100%" }}
        >
          <TextField
            size="small"
            type="search"
            id="displayOrderNumber"
            label="Број на нарачка"
            value={filter.displayOrderNumber}
            onChange={(e) => handleFilterChange("displayOrderNumber", e.target.value)}
            variant="outlined"
            autoComplete="off"
          />

          <IconButton onClick={() => refetch()}>
            {isFetching || isLoading ? <CircularProgress size={25} /> : <Refresh />}
          </IconButton>
        </Box>
      </DialogTitle>

      <Divider />

      <DialogContent className={classes.dialogContent}>
        {isLoading ? (
          <Typography variant="h5" align="center">
            Се вчитува
          </Typography>
        ) : error ? (
          <Typography variant="h5" align="center" color="error">
            {(error as Error)?.message}
          </Typography>
        ) : data?.driversRoutes.length === 0 ? (
          <Typography variant="h5" align="center">
            Нема активни рути
          </Typography>
        ) : (
          filteredDriversRoutes?.map((driversRoute, index) => (
            <>
              <Grid container>
                {driversRoute?.totalDistanceInKilometers != null && (
                  <Grid sm={3} md={2} lg={1} item>
                    <TextWithIcon icon={<DriveEta color="primary" />}>
                      {driversRoute.totalDistanceInKilometers} км.
                    </TextWithIcon>
                  </Grid>
                )}

                {driversRoute?.totalTimeInMinutes != null && (
                  <Grid sm={3} md={2} lg={1} item>
                    <TextWithIcon icon={<AlarmOn color="primary" />}>
                      {Math.round(driversRoute.totalTimeInMinutes)} мин.
                    </TextWithIcon>
                  </Grid>
                )}
              </Grid>

              <DriverRouteComponent
                key={index}
                orders={data?.orders}
                drivers={data?.drivers}
                data={driversRoute}
              />

              {index < filteredDriversRoutes.length - 1 ? (
                <Divider style={{ marginTop: "4px", marginBottom: "16px" }} />
              ) : null}
            </>
          ))
        )}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose} color="primary">
          Затвори
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FullRoutesDialog;

function filterBydisplayOrderNumber(driversRoutes: IDriverRouteResponseDto[], displayOrderNumber: string) {
  throw new Error("Function not implemented.");
}
