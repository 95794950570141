import httpService from "./http.service";
import { IChangeOrderStatusRequestDto } from "./orders/dtos/orders/changeOrderStatusRequestDto";
import { IDriverOrderStatusResponseDto } from "./orders/dtos/orders/driverOrderStatusResponseDto";
import { IDriverRejectionReasonRequestDto } from "./orders/dtos/orders/driverRejectionReasonRequestDto";
import { IOrderRequestDto } from "./orders/dtos/orders/orderRequestDto";
import { IRoutePointDto } from "./orders/dtos/orders/routePointDto";
import { IUpdateOrderRequestDto } from "./orders/dtos/orders/updateOrderRequestDto";

export enum OrderStatus {
  WaitingForProcessing = 1,
  Processing = 2,
  WaitingForAccept = 3,
  Assigned = 4,
  PickedUp = 5,
  Delivered = 6,
  HandleByDispatch = 7,
  RejectByDriver = 8,
  ResetByDispatch = 9,
  Paused = 10,
  PreAssigned = 11,
  Removed = 12,
  RejectByCustomer = 13,
  WaitingForAcceptByCustomer = 14,
  AutoRejectedForCustomer = 15,
  UnsuccessfulPayment = 16,
}

export enum OrderChangeType {
  None = 1,
  Algorithm = 2,
  Dispatch = 3,
  Driver = 4,
  Customer = 5,
}

export interface IOrderDto {
  id: number;
  externalOrderNumber: string;
  pickUpLatitude: number;
  pickUpLongitude: number;
  pickUpTime: string;
  pickUpAddress: string;
  dropOffLatitude: number;
  dropOffLongitude: number;
  dropOffTime: string;
  dropOffAddress: string;
  driverComment: string; // @deprecated
  paymentTypeFK: number;
  deliveryTypeFK: number;
  isPriority: boolean;
  clientID: string;
  customerNumber: string;
  customerName: string;
  orderStatus: OrderStatus;
  employeeFk: number;
  estimatedPickupTime: string;
  estimatedDropOffTime: string;
  estimatedTime: number;
  estimatedKm: number;
  partnerID: string;
  partnerName: string;
  partnerImageSrc: string;
  packageGuid?: string;
  mergeGuid?: null;
  displayOrderNumber: string;
}

export interface IFullOrderDto extends IOrderDto {
  driverComment: string;
  orderDetails?: IOrderDetails;
  dispatcher?: IDispatcherInfo;
}

export interface IDispatcherInfo {
  id: string;
  firstName: string;
  lastName: string;
}

export interface INewSystemRestaurant {
  id: string;
  address: string;
  latitude: number;
  longitude: number;
  time: string;
  logo: string;
  name: string;
  phoneNumbers: string[];
}

export interface INewSystemCustomerAddress {
  id: number;
  street: string;
  streetNumber: string;
  apartment: string;
  entranceCode: string;
}

export interface INewSystemCustomer {
  id: string;
  address: INewSystemCustomerAddress;
  formattedAddress: string;
  latitude: number;
  longitude: number;
  time: string;
  name: string;
  phoneNumber: string;
}

export interface INewSystemOrderDetails {
  orderItems: INewSystemOrderItem[];
  originalPriceWithDiscount: number;
  priceWithDiscount: number;
  deliveryPrice: number;
  priorityPrice: number;
  isContactless: boolean;
  driverComment: string;
}

export interface INewSystemOrderItem {
  foodName: string;
  foodAdditions: string;
  price: number;
  originalPrice: number;
  quantity: number;
}

export interface IOrderRequest {
  clientId: string;
  externalOrderNumber: string;
  restaurant: INewSystemRestaurant;
  customer: INewSystemCustomer;
  orderDetails: INewSystemOrderDetails;
  paymentTypeId: number;
  deliveryTypeId: number;
  isPriority: boolean;
}

export interface IDispatchUpdateOrderRequest {
  orderRejectReasonFK: number;
  driverId: number;
}

export interface IDriverRejectionReasonRequest {
  orderRejectReasonFK: number;
}

export interface IDriverOrderStatusResponse {
  status: OrderStatus;
}

export interface IOrderTrackChangesResponse {
  id: number;
  createdOn: string;
  orderChangeType: OrderChangeType;
  orderUpdatedFromEmployeeFK: number;
  orderUpdatedFromFirstName: string;
  orderUpdatedFromLastName: string;
  orderAssignToEmployeeFK: number;
  orderAssignToFirstName: string;
  orderAssignToLastName: string;
  orderStatus: OrderStatus;
  description: string;
}

export interface IOrderTardiness {
  orderId: string;
  orderNumber: string;
  vehicleTypeId: number;
  pickupTime: string;
  estimatedPickupTime: string;
  dropOffTime: string;
  estimatedDropOffTime: string;
  tardinessInMinutes: number;
}

export interface IDriverRouteResponse {
  id: number;
  employeeId: number;
  firstName: string;
  lastName: string;
  mergeGuid: string;
  isActive: boolean;
  routePoints: IRoutePoint[];
  totalDistanceInKilometers: number;
  totalTimeInMinutes: number;
  ordersTardiness?: { [orderId: string]: IOrderTardiness };
  pickUpTime: Date;
}

export interface IEstimatedDriverRouteResponse extends IDriverRouteResponse {}

export interface IRoutePoint {
  id: number;
  orderId: number;
  address: string;
  orderTime: string;
  estimatedTime: string;
  estimatedTimeWindow?: {
    from: string;
    till: string;
  };
  onLocationAt: string;
  latitude: number;
  longitude: number;
  isPickup: boolean;
  isDispatched: boolean;
  orderStatus: OrderStatus;
  imgSrc: string;
  eta: string;
  onAddress: string;
  differenceTime: number;
}

export interface IPartnerDto {
  externalNumber: string;
  name: string;
  address: string;
  logo: string;
  phoneNumbers: string;
}

export interface ICustomerDto {
  externalNumber: string;
  name: string;
  phoneNumber: string;
  street: string;
  streetNumber: string;
  apartment: string;
  entranceCode: string;
  formattedAddress: string;
}

export interface IUpdateOrderRequest {
  paymentTypeId: number;
  deliveryTypeId: number;
  isPriority: boolean;
  driverComment: string;
  restaurant: IRestaurant;
  customer: ICustomer;
}

export interface ICustomer {
  formattedAddress: string;
  latitude: number;
  longitude: number;
  time: string;
}

export interface ICustomerAddress {
  street: string;
  streetNumber: string;
  apartment: string;
  entranceCode: string;
}

export interface IRestaurant {
  address: string;
  latitude: number;
  longitude: number;
  time: string;
}

export interface IAddress {
  formatted: string;

  street?: string;
  streetNumber?: string;
  apartment?: string;
  entranceCode?: string;
}

export interface IOrderItem {
  foodName: string;
  foodAdditions: string;
  price: number;
  quantity: number;
}

export interface IDriverRoutePoint {
  orderId: number;
  externalOrderNumber: number;
  address: IAddress;
  time?: Date;
  estimatedTime?: Date;
  latitude: number;
  longitude: number;
  phoneNumber: string;
  name?: string;
  photo?: string;
  isPriority: boolean;
  isContactless: boolean;
  orderStatus: OrderStatus;
  onLocationAt?: Date;
  paymentTypeFK?: number;
  deliveryTypeFK?: number;
  isPickup: boolean;
  mergeGuid: string;
  driverComment: string;
  orderDetails: IOrderDetails;
  packageGuid: string;
  displayOrderNumber: string;
}

export interface IOrderDetails {
  price: number;
  deliveryPrice: number;
  priorityPrice: number;
  orderItems: IOrderItem[];
}

export interface IPendingOrder {
  orderId: number;
  restaurantPhoto?: string;
  restaurantName: string;
  restaurantAddress: string;
  pickUpTime: Date;
  pickUpLatitude: number;
  pickUpLongitude: number;
  customerAddress: string;
  dropOffTime: Date;
  dropOffLatitude: number;
  dropOffLongitude: number;
  paymentTypeFK: number;
  deliveryTypeFK: number;
  isPriority: boolean;
  isContactless: boolean;
  mergeGuid: string;
  packageGuid: string;
}

export interface IActiveOrdersResponse {
  isProcessing: boolean;
  pendingOrders: IPendingOrder[];
  routePoints: IDriverRoutePoint[];
}

interface IChangeOrderStatusRequest {
  status: OrderStatus;
}

export type AlgorithmMode = "Disabled" | "Basic" | "Advanced";

export interface IAlgorithmStatusResponse {
  algorithmExecutionMode: AlgorithmMode;
}

export interface IPackageOrdersRequest {
  PackageGuid?: string;
  orders: { orderId: number }[];
  routePoints: ShortRoutePoint[];
}
export interface IGroupOrdersRequest {
  PackageGuid: string;
  orders: PackageOrderGuid[];
  routePoints: ShortRoutePoint[];
}
export interface IPackageOrdersResponse {
  packageGuid: string;
  orders: PackageOrder[];
  routePoints: ShortRoutePoint[];
}
export interface ShortRoutePoint {
  orderId: number;
  isPickup: boolean;
  order?: IOrderDto;
}
export interface PackageOrder {
  orderId: number;
  externalOrderNumber: string;
}
export interface PackageOrderGuid {
  packageGuid: string;
  orderId: number;
  externalOrderNumber: string;
}

export const getOrders = (): Promise<IOrderDto[]> => {
  return httpService.get<IOrderDto[]>("/api/orders");
};

export const getOrderLog = (orderId: number): Promise<IOrderTrackChangesResponse[]> => {
  return httpService.get<IOrderTrackChangesResponse[]>(`/api/orders/${orderId}/logs`);
};

export const getActiveOrders = (): Promise<IFullOrderDto[]> => {
  // return httpService.get<IFullOrderDto[]>("/api/orders");
  return httpService.get<IFullOrderDto[]>("/api/orders/active");
};

export const getOrderDetailsAsync = (orderId: string): Promise<IOrderDto> => {
  return httpService.get<IOrderDto>(`/api/orders/${orderId}`);
};

export const getOrderCustomerDetailsAsync = (orderId: number): Promise<ICustomerDto> => {
  return httpService.get<ICustomerDto>(`/api/orders/${orderId}/customer-info`);
};

export const getOrderPartnerDetailsAsync = (orderId: number): Promise<IPartnerDto> => {
  return httpService.get<IPartnerDto>(`/api/orders/${orderId}/partner-info`);
};

export const driverAcceptsOrderAsync = (driverId: number, orderId: number) => {
  return httpService.post<IDriverOrderStatusResponseDto>(
    `/api/drivers/${driverId}/orders/${orderId}/accept`
  );
};

export const driverRejectsOrderAsync = (
  driverId: number,
  orderId: number,
  request: IDriverRejectionReasonRequestDto
) => {
  return httpService.post<IDriverOrderStatusResponseDto, IDriverRejectionReasonRequestDto>(
    `/api/drivers/${driverId}/orders/${orderId}/reject`,
    request
  );
};

export const driverConfirmsOrderPickUpAsync = (driverId: number, orderId: number) => {
  return httpService.put<IDriverOrderStatusResponseDto>(
    `/api/drivers/${driverId}/orders/${orderId}/pick-up`
  );
};

export const driverConfirmsOrderDropOffAsync = (driverId: number, orderId: number) => {
  return httpService.put<IDriverOrderStatusResponseDto>(
    `/api/drivers/${driverId}/orders/${orderId}/drop-off`
  );
};

export const driverAcceptsMergedOrdersAsync = (driverId: number, mergeGuid: string) => {
  return httpService.post<IDriverOrderStatusResponseDto>(
    `/api/drivers/${driverId}/orders/${mergeGuid}/accept`
  );
};
export const driverAcceptsGroupedOrdersAsync = (driverId: number, packageGuid: string) => {
  return httpService.post<IDriverOrderStatusResponseDto>(
    `/api/drivers/${driverId}/package-orders/${packageGuid}/accept`
  );
};
export const driverRejectsMergedOrdersAsync = (
  driverId: number,
  mergeGuid: string,
  request: IDriverRejectionReasonRequestDto
) => {
  return httpService.post<IDriverOrderStatusResponseDto, IDriverRejectionReasonRequestDto>(
    `/api/drivers/${driverId}/orders/${mergeGuid}/reject`,
    request
  );
};
export const driverConfirmsMergedOrdersPickUpAsync = (driverId: number, mergeGuid: string) => {
  return httpService.put<IDriverOrderStatusResponseDto>(
    `/api/drivers/${driverId}/orders/${mergeGuid}/pick-up`
  );
};

export const driverConfirmsMergedOrdersDropOffAsync = (driverId: number, mergeGuid: string) => {
  return httpService.put<IDriverOrderStatusResponseDto>(
    `/api/drivers/${driverId}/orders/${mergeGuid}/drop-off`
  );
};

export const resetOrderManuallyAsync = (orderId: number, request: IDispatchUpdateOrderRequest) => {
  return httpService.put<IDispatchUpdateOrderRequest, IDispatchUpdateOrderRequest>(
    `/api/orders/${orderId}/reset`,
    request
  );
};

export const assignOrderManuallyAsync = (orderId: number, driverId: number) => {
  return httpService.post(`/api/orders/${orderId}/assign/${driverId}`, {});
};

export const rescheduleOrderAsync = (orderId: number) => {
  return httpService.post(`/api/orders/${orderId}/reschedule`, {});
};

export const createOrderAsync = (request: IOrderRequestDto) => {
  return httpService.post<void, IOrderRequestDto>(`/api/orders`, request);
};

export const updateOrderAsync = (orderId: number, request: IUpdateOrderRequestDto) => {
  return httpService.put<void, IUpdateOrderRequestDto>(`/api/orders/${orderId}`, request);
};

export const getAllDriverOrders = (driverId: number) => {
  return httpService.get<IOrderDto[]>(`/api/drivers/${driverId}/orders`);
};

export const getDriversRoutesAsync = (): Promise<IDriverRouteResponse[]> => {
  return httpService.get<IDriverRouteResponse[]>(`/api/drivers/routes`);
};

export const getDriversEstimatedRoutesAsync = (): Promise<IEstimatedDriverRouteResponse[]> => {
  return httpService.get<IEstimatedDriverRouteResponse[]>(`/api/drivers/estimated-routes`);
};

export const getDriverOrder = (driverId: number, orderId: string) => {
  return httpService.get<IOrderDto>(`/api/drivers/${driverId}/orders/${orderId}`, {});
};

export const pauseOrderManuallyAsync = (orderId: number) => {
  return httpService.put<void>(`/api/orders/${orderId}/pause`);
};

export const getDriverPendingAndActiveOrders = (
  driverId: number
): Promise<IActiveOrdersResponse> => {
  return httpService.get<IActiveOrdersResponse>(
    `/api/drivers/${driverId}/orders/pending-and-active-routes`
  );
};

export const deleteOrderManuallyAsync = (orderId: number) => {
  return httpService.delete(`/api/orders/${orderId}`);
};

export const getRecommendedPickUpDelay = () => {
  return httpService.get<number>(`/api/orders/recommended-pickup-delay`);
};

export const executeAlgorithmManually = () => {
  return httpService.post(`/api/orders/schedule-processing`, {});
};

export const deleteRoutePoint = (driverId: number, routePointId: number) => {
  return httpService.delete(`/api/drivers/${driverId}/routes/routePoints/${routePointId}`, {});
};

export const changeOrderStatus = (orderId: number, request: IChangeOrderStatusRequestDto) => {
  return httpService.put<void, IChangeOrderStatusRequestDto>(
    `/api/orders/${orderId}/status`,
    request
  );
};

export const getDriverAndOrderRoutePoints = (driverId: number, orderId?: number) => {
  return httpService.get<IDriverRouteResponse>(
    `/api/drivers/${driverId}/routes/generate?orderId=${orderId}`
  );
};

export const modifyDriverRoutePoints = (
  driverId: number,
  routeId: number,
  routePoints: IRoutePointDto[]
) => {
  return httpService.put<void, IRoutePointDto[]>(
    `/api/drivers/${driverId}/routes/${routeId}/modify`,
    routePoints
  );
};

export const manuallyChargeTaximeter = (orderId: number, paymentType: string) => {
  return httpService.post(`/api/orders/${orderId}/charge?paymentType=${paymentType}`, {});
};

export const getAlgorithmMode = () => {
  return httpService.get<IAlgorithmStatusResponse>(`/api/orders/algorithm-execution-mode`);
};

export const setAlgorithmMode = (mode: AlgorithmMode) => {
  return httpService.put(`/api/orders/algorithm-execution-mode`, { algorithmExecutionMode: mode });
};
export const getGroupOrdersAsync = (orderGroupId: string) => {
  return httpService.get<IPackageOrdersResponse>(`/api/package-orders/${orderGroupId}`, {});
};
export const generateGroupOrdersAsync = (orderId1: number, orderId2: number) => {
  return httpService.get<IPackageOrdersResponse>(
    `/api/package-orders/${orderId1}/generate?appendOrderId=${orderId2}`,
    {}
  );
};
export const groupOrdersAsync = (request: IPackageOrdersRequest) => {
  return httpService.post<{}, IPackageOrdersRequest>(`/api/package-orders`, request);
};
export const updateGroupOrdersAsync = (groupId: string, request: IPackageOrdersRequest) => {
  return httpService.put<{}, IPackageOrdersRequest>(`/api/package-orders/${groupId}`, request);
};
export const assignOrderGroupManuallyAsync = (orderGroupId: string, driverId: number) => {
  return httpService.post(`/api/orders/${orderGroupId}/assign/${driverId}`, {});
};

export const ungroupGroupedOrders = (packageGuid: string) => {
  return httpService.delete(`/api/package-orders/${packageGuid}`);
};

export const translateOrderStatus = (status: OrderStatus): string => {
  const statusLabelMap = new Map([
    [OrderStatus.WaitingForProcessing, "Чека на процесирање"],
    [OrderStatus.Processing, "Се процесира"],
    [OrderStatus.WaitingForAccept, "Чека на одобрување"],
    [OrderStatus.Assigned, "Доделена"],
    [OrderStatus.PickedUp, "Подигната"],
    [OrderStatus.Delivered, "Доставена"],
    [OrderStatus.HandleByDispatch, "Потребна акција од диспечер"],
    [OrderStatus.RejectByDriver, "Одбиена од возач"],
    [OrderStatus.ResetByDispatch, "Ресетирана од диспечер"],
    [OrderStatus.Paused, "Паузирана"],
    [OrderStatus.PreAssigned, "Закажана"],
    [OrderStatus.RejectByCustomer, "Одбиена од клиент"],
    [OrderStatus.WaitingForAcceptByCustomer, "Чека на одобрување од клиент"],
    [OrderStatus.AutoRejectedForCustomer, "Автоматски одбиена"],
    [OrderStatus.UnsuccessfulPayment, "Неуспешно плаќање"],
  ]);

  if (statusLabelMap.has(status)) return statusLabelMap.get(status);
  else if (status in OrderStatus) return OrderStatus[status];
  else return status.toString();
};

export const translateOrderChangeType = (changeType: OrderChangeType): string => {
  const statusLabelMap = new Map([
    [OrderChangeType.None, "Нова нарачка"],
    [OrderChangeType.Algorithm, "Алгоритам"],
    [OrderChangeType.Dispatch, "Диспечер"],
    [OrderChangeType.Driver, "Возач"],
    [OrderChangeType.Customer, "Клиент"],
  ]);

  if (statusLabelMap.has(changeType)) return statusLabelMap.get(changeType);
  else if (changeType in OrderChangeType) return OrderChangeType[changeType];
  else if (changeType.toString() !== "0") return changeType.toString();
  else return null;
};
