import { Typography, createStyles, makeStyles } from "@material-ui/core";
import CustomDateField from "@shared/components/CustomFormControls/CustomDateField";
import dayjs, { Dayjs } from "dayjs";
import { FC, useEffect, useState } from "react";
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
export interface IPeriodDateFilter {
  from?: Date;
  to?: Date;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      margin: theme.spacing(2),
    },
  })
);

interface PeriodToDateFilterProps {
  minDate?: Date;
  onChange: (to: Date) => void;
}

const PeriodToDateFilter: FC<PeriodToDateFilterProps> = ({ minDate, onChange }) => {
  const classes = useStyles();


  const [toDate, setToDate] = useState<Dayjs>(null);

  useEffect(() => {
    setToDate(dayjs().utcOffset(0));
  }
  , []);

  useEffect(() => {
    if (toDate) {
      onChange(toDate.utc().endOf("day").toDate());
    }
  }
  , [toDate]);

  return (
    <div style= {{display:"flex", flexDirection:"column"}} className={classes.root}>
      <Typography variant="h6">Период</Typography>
      <div style= {{display:"flex", alignItems:"end", height:"100%"}}>
        <CustomDateField
          variant="inline"
          label="До"
          value={toDate}
          onChange={(value) => {
            setToDate(value);
          }}
          minDate={minDate}
        />
      </div>
    </div>
  );
};

export default PeriodToDateFilter;
