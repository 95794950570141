import RoutePointMarkerPopup from "./RoutePointMarkerPopup";
import TimeTooltip from "./TimeTooltip";
import { makeStyles } from "@material-ui/core";
import { IMapOrder } from "@shared/components/Map/models/models";
import { IRoutePointDto } from "@shared/services/orders/dtos/orders/routePointDto";
import { blueMarkerIcon, redMarkerIcon } from "@shared/utils/defaultMarkerIcons";
import { Icon } from "leaflet";
import { FC, memo, useMemo } from "react";
import { Marker, Popup } from "react-leaflet";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "50%",
  },
}));

export interface RoutePointMarkerProps {
  routePoint: IRoutePointDto;
  order: IMapOrder;
  onClick: () => void;
}

const RoutePointMarker: FC<RoutePointMarkerProps> = ({ routePoint, order, onClick }) => {
  const classes = useStyles();

  const partnerLogoMarkerIcon = useMemo(
    () =>
      new Icon({
        iconUrl: routePoint?.imgSrc,
        className: classes.root,
        iconSize: [32, 32],
        iconAnchor: [16, 16],
      }),
    [routePoint?.imgSrc]
  );

  return (
    <Marker
      position={{
        lat: routePoint.latitude,
        lng: routePoint.longitude,
      }}
      icon={
        routePoint?.imgSrc
          ? partnerLogoMarkerIcon
          : routePoint?.isPickup
          ? redMarkerIcon
          : blueMarkerIcon
      }
      riseOnHover
      eventHandlers={{
        click: onClick,
      }}
    >
      <TimeTooltip routePoint={routePoint} />

      {/* <Popup>
        <RoutePointMarkerPopup routePoint={routePoint} order={order} />
      </Popup> */}
    </Marker>
  );
};

export default memo(RoutePointMarker);
