import { FilterType, IFilterState } from "../Map";
import OrdersList from "./Orders/OrdersList";
import UnassignedOrdersList from "./Orders/UnassignedOrdersList";
import { selectAllDriversWithoutGeolocation } from "@App/modules/Logistics/store/features/drivers/driverSelectors";
import { makeStyles } from "@material-ui/core";
import { Button, Grid, InputAdornment, TextField } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { IMapOrder } from "@shared/components/Map/models/models";
import { useMemoSelector } from "@shared/hooks";
import { IDriverRouteResponseDto } from "@shared/services/orders/dtos/orders/driverRouteResponseDto";
import {
  isOrderCompletelyLate,
  isOrderDelivered,
  isOrderDispatched,
  isOrderDropOffLate,
  isOrderNormal,
  isOrderPickUpLate,
} from "@shared/utils/orders/orderStatus";
import { ChangeEvent, FC, memo } from "react";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    padding: "0.5rem",
  },
  searchContainer: {
    display: "flex",
    alignContent: "center",
  },
  searchInput: {
    flexBasis: "60%",
    margin: "5px",
  },
  button: {
    flexBasis: "35%",
    margin: "5px",
  },
}));

export interface OrdersPanelProps {
  orders: IMapOrder[];
  driversRoutes: IDriverRouteResponseDto[];
  searchValue?: string;
  filters: Pick<IFilterState, "orders" | "driversRoutes">;
  isMapPanelHidden?: boolean;
  onFilterChange: (filterType: FilterType, checked: boolean, orderId?: number) => void;
  onSearchChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onOrderDeleted?: (orderId: number) => void;
  onNewOrderClick: () => void;
}

const OrdersPanel: FC<OrdersPanelProps> = ({
  orders,
  driversRoutes,
  searchValue,
  filters,
  isMapPanelHidden,
  onFilterChange,
  onSearchChange,
  onNewOrderClick,
  onOrderDeleted,
}) => {
  const classes = useStyles();

  const drivers = useMemoSelector((state) => selectAllDriversWithoutGeolocation(state, false));

  const unassignedOrders = orders.filter((x) => !isOrderDispatched(x));
  const completelyLateOrders = driversRoutes.filter((x) => isOrderCompletelyLate(x));
  const latePickUpOrders = driversRoutes.filter(
    (x) => !isOrderCompletelyLate(x) && isOrderPickUpLate(x)
  );
  const lateDropOffOrders = driversRoutes.filter(
    (x) => !isOrderCompletelyLate(x) && isOrderDropOffLate(x)
  );
  const normalOrders = driversRoutes.filter((x) => isOrderNormal(x));
  const deliveredOrders = driversRoutes.filter((x) => isOrderDelivered(x));

  return (
    <Grid item xs={12} md={isMapPanelHidden ? true : 4} className={classes.container}>
      <Grid item xs={12} md={isMapPanelHidden ? 7 : 12} className={classes.searchContainer}>
        <TextField
          variant="outlined"
          margin="dense"
          placeholder="Барај..."
          className={classes.searchInput}
          value={searchValue}
          onChange={onSearchChange}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />

        <Button
          onClick={onNewOrderClick}
          className={classes.button}
          variant="contained"
          color="primary"
        >
          Креирај
        </Button>
      </Grid>

      {unassignedOrders.length > 0 && (
        <Grid item xs={12} md={isMapPanelHidden ? 7 : 12}>
          <UnassignedOrdersList
            onOrderDeleted={onOrderDeleted}
            title="Недоделени нарачки"
            emptyStateText="Нема недоделени нарачки"
            orders={unassignedOrders}
            drivers={drivers}
            selectedOrderIds={filters.orders.selectedIds}
            onOrderSelect={(checked, orderId) =>
              onFilterChange("selectedOrderId", checked, orderId)
            }
            isGroupSelected={filters.orders.groups.unassignedOrders}
            onGroupSelect={(checked) => onFilterChange("unassignedOrders", checked, undefined)}
            expanded
          />
        </Grid>
      )}

      {completelyLateOrders.length > 0 && (
        <OrdersList
          title="Доцнат со подигање и испорака"
          emptyStateText="Нема нарачки кои доцнат со подигање и испорака"
          data={completelyLateOrders}
          orders={orders}
          drivers={drivers}
          isGroupSelected={filters.driversRoutes.groups.completelyLateOrders}
          onGroupSelect={(checked) => onFilterChange("completelyLateOrders", checked)}
          expanded
          forceExpanded
        />
      )}

      {latePickUpOrders.length > 0 && (
        <OrdersList
          title="Доцнат со подигање"
          emptyStateText="Нема нарачки кои доцнат со подигање"
          data={latePickUpOrders}
          orders={orders}
          drivers={drivers}
          isGroupSelected={filters.driversRoutes.groups.latePickUpOrders}
          onGroupSelect={(checked) => onFilterChange("latePickUpOrders", checked)}
          expanded
        />
      )}

      {lateDropOffOrders.length > 0 && (
        <OrdersList
          title="Доцнат со испорака"
          emptyStateText="Нема нарачки кои доцнат со испорака"
          data={lateDropOffOrders}
          orders={orders}
          drivers={drivers}
          isGroupSelected={filters.driversRoutes.groups.lateDropOffOrders}
          onGroupSelect={(checked) => onFilterChange("lateDropOffOrders", checked)}
          expanded
        />
      )}

      {normalOrders.length > 0 && (
        <OrdersList
          title="Нарачки"
          emptyStateText="Нема нарачки"
          data={normalOrders}
          orders={orders}
          drivers={drivers}
          isGroupSelected={filters.driversRoutes.groups.normalOrders}
          onGroupSelect={(checked) => onFilterChange("normalOrders", checked)}
          expanded
        />
      )}

      {deliveredOrders.length > 0 && (
        <OrdersList
          title="Завршени нарачки"
          emptyStateText="Нема завршени нарачки"
          data={deliveredOrders}
          orders={orders}
          drivers={drivers}
          expanded={false}
        />
      )}
    </Grid>
  );
};

export default memo(OrdersPanel);
