import { Active, Over, useDroppable } from "@dnd-kit/core";
import { CSSProperties, Children, FC, cloneElement, isValidElement } from "react";

export type DraggableDroppables = "Driver" | "Order" | "DriversRoute" | "OrderGroup";
export type Category = DraggableDroppables;

export const isDropAllowed = (active: Active | null, over: Over | null, isOver: boolean = true) => {
  if (!isOver || !active || !over) {
    return false;
  }

  const source = active.id.toString().split("-");
  const target = over.id.toString().split("-");

  const sourceCategory = source[0] as Category;
  const targetCategory = target[0] as Category;

  if (
    (sourceCategory === "Driver" && targetCategory === "Order") ||
    (sourceCategory === "Order" && targetCategory === "Driver")
  ) {
    return true;
  }

  if (
    (sourceCategory === "DriversRoute" && targetCategory === "Order") ||
    (sourceCategory === "Order" && targetCategory === "DriversRoute")
  ) {
    return true;
  }

  if (
    (sourceCategory === "Order" && targetCategory === "Order") ||
    (sourceCategory === "Order" && targetCategory === "Order")
  ) {
    return true;
  }

  return true;
};

interface DroppableProps {
  id: string;
  style?: CSSProperties;
  styleDragOver?: CSSProperties;
}

const Droppable: FC<DroppableProps> = (props) => {
  const { active, over, isOver, setNodeRef } = useDroppable({
    id: props.id,
  });

  const style = isDropAllowed(active, over, isOver) ? props.styleDragOver : props.style;

  return (
    <div ref={setNodeRef} style={style}>
      {Children.map(props.children, (child) => {
        if (isValidElement(child)) {
          return cloneElement<any>(child, { style });
        }
        return null;
      })}
    </div>
  );
};

export default Droppable;
