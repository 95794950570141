import { FC, Key, useEffect, useRef } from "react";
import { Subscription } from "rxjs";
import { useSnackbar } from "notistack";
import { connectionUpdate } from "@shared/services/signalR.client";

const SignalRConnectionChangeWatcher: FC = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const keys = useRef<Key[]>([]);
  const subscription = useRef<Subscription>(null);

  const unsubscribe = () => {
    if (subscription.current) {
      subscription.current.unsubscribe();
      subscription.current = null;
    }
  }

  useEffect(() => {
    unsubscribe();

    subscription.current = connectionUpdate.subscribe(({ type, msg }) => {
      if (keys.current.length >= 2) {
        keys.current.forEach((x) => {
          closeSnackbar(x);
        });

        keys.current.length = 0;
      }

      const notificationKey = enqueueSnackbar(msg, {
        variant: type === "error" ? "error" : "success",
        autoHideDuration: 6000,
        persist: type === "error",
        anchorOrigin: { horizontal: "center", vertical: "bottom" },
      });

      keys.current.push(notificationKey);
    });

    return () => {
      unsubscribe();
    }
  }, [enqueueSnackbar, closeSnackbar]);

  return null;
};

export default SignalRConnectionChangeWatcher;
