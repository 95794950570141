import React from "react";
import { Typography } from "@material-ui/core";

const FixedHeader = () => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        backgroundColor: "white",
        zIndex: 999,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "6px 40px",
      }}
    >
      <Typography>Датум</Typography>
      <div style={{ display: "flex", alignItems: "center", padding: "3px 100px" }}>
        <Typography>Смена</Typography>
      </div>
    </div>
  );
};

export default FixedHeader;
