import useFormStyles from "../hooks/useFormStyles";
import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { IRoleSalaryConfigurationDto } from "@shared/services/salary-configuration/dtos/role-salary-configurations/roleSalaryConfigurationDto";
import { getAllCompanies } from "@shared/services/settings/companies.service";
import { useQuery } from "@tanstack/react-query";
import { useFormik } from "formik";
import { FC } from "react";

interface FixedEarningsFormProps {
  formik: ReturnType<typeof useFormik<IRoleSalaryConfigurationDto>>;
}

const FixedEarningsForm: FC<FixedEarningsFormProps> = ({ formik }) => {
  const classes = useFormStyles();
  const { data: companies = [] } = useQuery(["getAllCompanies"], getAllCompanies);

  return (
    <div style={{display: "flex", flexDirection: "column"}} className={classes.form}>
      <div>
        <TextField
          variant="outlined"
          className={classes.formControl}
          id="fixedSalaryBrutto"
          name="fixedSalaryBrutto"
          label="Фиксна нето плата"
          InputProps={{
            endAdornment: <InputAdornment position="start">мкд</InputAdornment>,
          }}
          type="number"
          value={formik.values.fixedSalaryBrutto}
          onChange={formik.handleChange}
          error={formik.touched.fixedSalaryBrutto && Boolean(formik.errors.fixedSalaryBrutto)}
          helperText={formik.touched.fixedSalaryBrutto && formik.errors.fixedSalaryBrutto}
        />

        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="fixedSalaryBruttoCompanyFKLabel">
            Компанија (фиксна нето плата)
          </InputLabel>
          <Select
            id="fixedSalaryBruttoCompanyFK"
            name="fixedSalaryBruttoCompanyFK"
            value={formik.values.fixedSalaryBruttoCompanyFK}
            labelId="fixedSalaryBruttoCompanyFKLabel"
            label="Компанија (фиксна нето плата)"
            onChange={formik.handleChange}
            error={
              formik.touched.fixedSalaryBruttoCompanyFK &&
              Boolean(formik.errors.fixedSalaryBruttoCompanyFK)
            }
          >
            {!companies.length && (
              <MenuItem value={-1}>
                <em>Нема компанија</em>
              </MenuItem>
            )}
            {companies.map((company) => (
              <MenuItem key={company.id} value={company.id}>
                {company.abbreviation} - {company.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <div>
        <TextField
          variant="outlined"
          className={classes.formControl}
          id="fixedContract"
          name="fixedContract"
          label="Фиксен договор на дело"
          InputProps={{
            endAdornment: <InputAdornment position="start">мкд</InputAdornment>,
          }}
          type="number"
          value={formik.values.fixedContract}
          onChange={formik.handleChange}
          error={formik.touched.fixedContract && Boolean(formik.errors.fixedContract)}
          helperText={formik.touched.fixedContract && formik.errors.fixedContract}
        />

        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="fixedContractCompanyFKLabel">
            Компанија (фиксен договор на дело)
          </InputLabel>
          <Select
            id="fixedContractCompanyFK"
            name="fixedContractCompanyFK"
            labelId="fixedContractCompanyFKLabel"
            label="Компанија (фиксен договор на дело)"
            value={formik.values.fixedContractCompanyFK}
            onChange={formik.handleChange}
            error={
              formik.touched.fixedContractCompanyFK && Boolean(formik.errors.fixedContractCompanyFK)
            }
          >
            {!companies.length && (
              <MenuItem value={-1}>
                <em>Нема компанија</em>
              </MenuItem>
            )}
            {companies.map((company) => (
              <MenuItem key={company.id} value={company.id}>
                {company.abbreviation} - {company.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <div>
        <TextField
          variant="outlined"
          className={classes.formControl}
          id="verna"
          name="verna"
          label="Верна"
          InputProps={{
            endAdornment: <InputAdornment position="start">мкд</InputAdornment>,
          }}
          type="number"
          value={formik.values.verna}
          onChange={formik.handleChange}
          error={formik.touched.verna && Boolean(formik.errors.verna)}
          helperText={formik.touched.verna && formik.errors.verna}
        />

        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="vernaCompanyFKLabel">Компанија (верна)</InputLabel>
          <Select
            id="vernaCompanyFK"
            name="vernaCompanyFK"
            labelId="vernaCompanyFKLabel"
            label="Компанија (верна)"
            value={formik.values.vernaCompanyFK}
            onChange={formik.handleChange}
            error={formik.touched.vernaCompanyFK && Boolean(formik.errors.vernaCompanyFK)}
          >
            {!companies.length && (
              <MenuItem value={-1}>
                <em>Нема компанија</em>
              </MenuItem>
            )}
            {companies.map((company) => (
              <MenuItem key={company.id} value={company.id}>
                {company.abbreviation} - {company.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <div>
        <TextField
          variant="outlined"
          className={classes.formControl}
          id="carRent"
          name="carRent"
          label="Закуп на возило"
          InputProps={{
            endAdornment: <InputAdornment position="start">мкд</InputAdornment>,
          }}
          type="number"
          value={formik.values.carRent}
          onChange={formik.handleChange}
          error={formik.touched.carRent && Boolean(formik.errors.carRent)}
          helperText={formik.touched.carRent && formik.errors.carRent}
        />

        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="carRentCompanyFKLabel">Компанија (Закуп на возило)</InputLabel>
          <Select
            id="carRentCompanyFK"
            name="carRentCompanyFK"
            labelId="carRentCompanyFKLabel"
            label="Компанија (Закуп на возило)"
            value={formik.values.carRentCompanyFK}
            onChange={formik.handleChange}
            error={formik.touched.carRentCompanyFK && Boolean(formik.errors.carRentCompanyFK)}
          >
            {!companies.length && (
              <MenuItem value={-1}>
                <em>Нема компанија</em>
              </MenuItem>
            )}
            {companies.map((company) => (
              <MenuItem key={company.id} value={company.id}>
                {company.abbreviation} - {company.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <div>
        <TextField
          variant="outlined"
          className={classes.formControl}
          id="contract"
          name="contract"
          label="Договор на дело"
          InputProps={{
            endAdornment: <InputAdornment position="start">мкд</InputAdornment>,
          }}
          type="number"
          value={formik.values.contract}
          onChange={formik.handleChange}
          error={formik.touched.contract && Boolean(formik.errors.contract)}
          helperText={formik.touched.contract && formik.errors.contract}
        />

        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="contractCompanyFKLabel">Компанија (договор на дело)</InputLabel>
          <Select
            id="contractCompanyFK"
            name="contractCompanyFK"
            labelId="contractCompanyFKLabel"
            label="Компанија (договор на дело)"
            value={formik.values.contractCompanyFK}
            onChange={formik.handleChange}
            error={formik.touched.contractCompanyFK && Boolean(formik.errors.contractCompanyFK)}
          >
            {!companies.length && (
              <MenuItem value={-1}>
                <em>Нема компанија</em>
              </MenuItem>
            )}
            {companies.map((company) => (
              <MenuItem key={company.id} value={company.id}>
                {company.abbreviation} - {company.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      {!companies.length && (
        <Alert severity="warning">Прво мора да додадете барем една компанија во системот</Alert>
      )}
    </div>
  );
};

export default FixedEarningsForm;
