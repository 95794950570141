import NewCityForm from "./components/NewCityForm";
import { Button, IconButton, TableCell } from "@material-ui/core";
import { Delete, Edit, Warning } from "@material-ui/icons";
import TableView from "@shared/components/Table/TableView";
import { ITableHeaderCell } from "@shared/components/Table/types/ITableHeaderCell";
import { useConfirmationDialog } from "@shared/context/ConfirmationDialogContext";
import { getCities, removeCity } from "@shared/services/settings/cities.service";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import { FC, useCallback, useEffect, useState } from "react";

export interface ICity {
  id: number;
  name: string;
  longitude?: number;
  latitude?: number;
  zoom?: number;
  createdOn?: string;
  onEditClick?: (id: number) => void;
  onDeleteClick?: (id: number) => void;
}

const createCity = (
  id: number,
  name: string,
  latitude?: number,
  longitude?: number,
  zoom?: number,
  createdOn?: string,
  onEditClick?: (id: number) => void,
  onDeleteClick?: (id: number) => void
): ICity => ({
  id,
  name,
  latitude,
  longitude,
  zoom,
  createdOn,
  onEditClick,
  onDeleteClick,
});

const headerCells: ITableHeaderCell<ICity>[] = [
  { id: "id", label: "ID" },
  { id: "name", label: "Име" },
  {
    id: "createdOn",
    label: "Датум и време на додавање",
  },
  { id: "editButton", label: "", disableSorting: true },
  { id: "deleteButton", label: "", disableSorting: true },
];

const createRow = (row: ICity) => (
  <>
    <TableCell>{row.id}</TableCell>
    <TableCell>{row.name}</TableCell>
    <TableCell>{dayjs.utc(row.createdOn).local().format("LLL")}</TableCell>
    <TableCell padding="checkbox">
      <IconButton size="small" onClick={() => (row.onEditClick ? row.onEditClick(row.id) : null)}>
        <Edit />
      </IconButton>
    </TableCell>
    <TableCell padding="checkbox">
      <IconButton
        size="small"
        onClick={() => (row.onDeleteClick ? row.onDeleteClick(row.id) : null)}
      >
        <Delete />
      </IconButton>
    </TableCell>
  </>
);

const getAllCities = () => getCities();

const CitiesViewPage: FC = () => {
  const {
    data: cities,
    isLoading,
    isFetching,
    error,
    refetch,
  } = useQuery(["cities"], getAllCities, {
    refetchInterval: 15000,
  });

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  useEffect(() => {
    if (!error || !(error instanceof Error)) return;

    enqueueSnackbar(`Се случи грешка, обидете се повторно. ${error.message}`, {
      variant: "error",
      persist: true,
      preventDuplicate: true,
      anchorOrigin: { horizontal: "center", vertical: "bottom" },
      action: function onDismiss(key) {
        return <Button onClick={() => closeSnackbar(key)}>OK</Button>;
      },
    });
  }, [error, enqueueSnackbar, closeSnackbar]);

  const confirmationDialog = useConfirmationDialog();

  const handleDeleteClick = (id: number) => {
    const city = cities.find((x: ICity) => x.id === id);

    if (city) {
      confirmationDialog.open({
        icon: <Warning fontSize="large" style={{ color: "orange" }} />,
        title: "Избриши град",
        body: `Дали сте сигурни дека сакате да го избришете градот ${city.name} со ID ${city.id}?`,
        onConfirm: () => handleDelete(city.id),
        onDeny: () => confirmationDialog.close(),
      });
    }
  };

  const handleDelete = useCallback(
    (id: number) => {
      confirmationDialog.setLoading(true);

      removeCity(id)
        .then(() => {
          confirmationDialog.close();
          enqueueSnackbar("Успешно го избришавте градот", {
            variant: "success",
            autoHideDuration: 6000,
          });

          refetch();
        })
        .catch((error: AxiosError) => {
          confirmationDialog.close();
          enqueueSnackbar(`Не успеа да се отстрани градот: ${error.message}`, {
            variant: "error",
            persist: true,
            preventDuplicate: true,
            anchorOrigin: { horizontal: "center", vertical: "bottom" },
            action: function onDismiss(key) {
              return <Button onClick={() => closeSnackbar(key)}>OK</Button>;
            },
          });
        });
    },
    [closeSnackbar, enqueueSnackbar, refetch]
  );

  const handleEditClick = (id: number) => {
    const city = cities.find((x: ICity) => x.id === id);

    if (city) setSelectedRow(city);
  };

  const [selectedRow, setSelectedRow] = useState<ICity | null>(null);
  const handleAddTypeClick = () => {
    setSelectedRow(createCity(0, ""));
  };

  const handleAddTypeDialogClose = () => {
    setSelectedRow(null);
  };

  // Map data
  const rows: ICity[] = [];
  if (cities)
    cities.forEach((x: ICity) => {
      rows.push(
        createCity(
          x.id,
          x.name,
          x.latitude,
          x.longitude,
          x.zoom,
          x.createdOn,
          handleEditClick,
          handleDeleteClick
        )
      );
    });

  return (
    <TableView
      title="Градови"
      addButtonText="Додади град"
      onAddButtonClick={handleAddTypeClick}
      onRefreshClick={() => refetch()}
      headers={headerCells}
      rows={rows}
      render={createRow}
      isLoading={isLoading || isFetching}
      addDialog={
        <NewCityForm
          title={selectedRow?.id ? "Промени град" : "Додади град"}
          addButtonText={selectedRow?.id ? "Зачувај" : "Додади"}
          open={selectedRow ? true : false}
          row={selectedRow?.id ? selectedRow : null}
          onClose={handleAddTypeDialogClose}
          onSuccess={() => refetch()}
        />
      }
    />
  );
};

export default CitiesViewPage;
