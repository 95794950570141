import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import "leaflet/dist/leaflet.css";
import { FC } from "react";
import { MapContainer, MapContainerProps, TileLayer } from "react-leaflet";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  tileLayerDarkMode: {
    "-webkit-filter": "invert(1) hue-rotate(180deg) grayscale(0.5)",
    filter: "invert(1) hue-rotate(180deg) grayscale(0.5)",
  },
});

export interface IGeolocation {
  latitude: number;
  longitude: number;
}

export interface LeafletMapProps extends MapContainerProps {
  defaultLocation?: IGeolocation;
  defaultZoom?: number;
  width?: string | number;
  height?: string | number;
}

const LeafletMap: FC<LeafletMapProps> = ({
  defaultLocation,
  defaultZoom,
  width,
  height,
  children,
  attributionControl = false,
  ...rest
}) => {
  const classes = useStyles();
  const isDarkMode = useSelector(
    ({ appState }: IGlobalState) => appState.preferences.theme.isDarkMode
  );

  return (
    <MapContainer
      {...rest}
      center={{
        lat: defaultLocation?.latitude ?? 41.6137143,
        lng: defaultLocation?.longitude ?? 21.743258,
      }}
      zoom={defaultZoom ?? 9}
      style={{
        ...rest.style,
        width: width || "100%",
        height: height || "100%",
      }}
      attributionControl={attributionControl}
    >
      <TileLayer
        key={isDarkMode.toString()} // Force rerender when the theme changes
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        minZoom={1}
        className={clsx({
          [classes.tileLayerDarkMode]: isDarkMode,
        })}
      />

      {children}
    </MapContainer>
  );
};

export default LeafletMap;
