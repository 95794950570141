import UnassignedOrderCard from "./UnassignedOrderCard";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ExpandMore } from "@material-ui/icons";
import { IMapOrder } from "@shared/components/Map/models/models";
import { IDriverDto } from "@shared/services/drivers/dtos/driverDto";
import dayjs from "dayjs";
import * as React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));

interface Props {
  title: string;
  orders: IMapOrder[];
  drivers: IDriverDto[];
  emptyStateText: string;
  expanded: boolean;
  forceExpanded?: boolean;
  selectedOrderIds: number[];
  onOrderSelect: (checked: boolean, orderId: number) => void;
  isGroupSelected: boolean;
  onGroupSelect: (isSelected: boolean) => void;
  onOrderDeleted: (orderId: number) => void;
}

const UnassignedOrdersList: React.FC<Props> = ({
  title,
  orders,
  drivers,
  emptyStateText,
  onOrderDeleted,
  selectedOrderIds,
  onOrderSelect,
  isGroupSelected,
  onGroupSelect,
  expanded = false,
  forceExpanded = false,
}) => {
  const classes = useStyles();
  const [expandedState, setExpandedState] = React.useState(false);

  React.useEffect(() => {
    setExpandedState(expanded);
  }, [expanded]);

  return (
    <>
      <Accordion elevation={0} expanded={forceExpanded || expandedState} style={{ margin: 0 }}>
        <AccordionSummary
          style={{ padding: 0 }}
          IconButtonProps={{
            style: {
              marginRight: 2,
            },
            onClick: () => {
              setExpandedState(!expandedState);
            },
          }}
          expandIcon={<ExpandMore />}
          aria-label="Expand"
          aria-controls="additional-actions1-content"
          id="additional-actions1-header"
        >
          <Checkbox
            checked={isGroupSelected}
            onChange={(event) => onGroupSelect(event.currentTarget.checked)}
            style={{ padding: "0px 8px" }}
          />

          <Typography style={{ paddingRight: "10px" }} variant="body1" component="div">
            {title} {` [${orders && orders.length}]`}
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: 0, width: "100%" }}>
          {orders && orders.length > 0 ? (
            <TableContainer>
              <Table stickyHeader={true} size="small" padding="none" aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left" key="checkbox">
                      &nbsp;
                    </TableCell>
                    <TableCell align="left" key="action">
                      &nbsp;
                    </TableCell>
                    <TableCell align="left" key="externalOrderNumber" padding="checkbox">
                      &nbsp;
                    </TableCell>
                    <TableCell align="left" key="status" padding="checkbox">
                      Статус
                    </TableCell>
                    <TableCell align="left" key="pickUp">
                      Подигање
                    </TableCell>
                    <TableCell align="left" key="featureBadges">
                      &nbsp;
                    </TableCell>
                    <TableCell align="left" key="partner">
                      &nbsp;
                    </TableCell>
                    <TableCell align="left" key="info" padding="checkbox">
                      &nbsp;
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orders
                    .sort((a, b) => (dayjs(a.pickUpTime).isBefore(dayjs(b.pickUpTime)) ? -1 : 1))
                    .map((order: IMapOrder) => (
                      <UnassignedOrderCard
                        key={order.id}
                        drivers={drivers}
                        order={order}
                        isSelected={selectedOrderIds.includes(order.id)}
                        onSelect={(event) => onOrderSelect(event.currentTarget.checked, order.id)}
                        onOrderDeleted={onOrderDeleted}
                      />
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography variant="body2" align="center">
              {emptyStateText}
            </Typography>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
};
export default UnassignedOrdersList;
