import { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  chipContainer: {
    display: "flex",
    flexWrap: "wrap",
    listStyle: "none",
    padding: 0,
    margin: 0,
    gap: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  horizontalPadding: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  verticalPadding: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

interface OrderCardProps {
  disableVerticalPadding?: boolean;
  disableHorizontalPadding?: boolean;
}

const ChipContainer: FC<OrderCardProps> = ({
  disableVerticalPadding,
  disableHorizontalPadding,
  children,
}) => {
  const classes = useStyles();

  return (
    <ul
      className={clsx(classes.chipContainer, {
        [classes.verticalPadding]: !disableVerticalPadding,
        [classes.horizontalPadding]: !disableHorizontalPadding,
      })}
    >
      {children}
    </ul>
  );
};

export default ChipContainer;
