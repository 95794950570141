import { getCities } from "../services/settings/cities.service";
import { getAllDeliveryTypes } from "../services/settings/delivery-types.service";
import { getEmployeePositions } from "../services/settings/employee-positions.service";
import { getAllPaymentTypes } from "../services/settings/payment-types.service";
import { getAllVehicleTypes } from "../services/settings/vehicle-types.service";
import { createAsyncThunk } from "@reduxjs/toolkit";
// import { getEmployees } from "../services/employees.service";
import { getAllOrderRejectReasonTypes } from "@shared/services/orders/order-reject-reason.service";

export const getCitiesThunkAsync = createAsyncThunk("getCitiesThunkAsync", async () => {
  return await getCities();
});

export const getPaymentTypesAsyncThunk = createAsyncThunk("getPaymentTypesAsyncThunk", async () => {
  return await getAllPaymentTypes();
});

export const getVehicleTypesAsyncThunk = createAsyncThunk("getVehicleTypesAsyncThunk", async () => {
  return await getAllVehicleTypes();
});

export const getDeliveryTypesAsyncThunk = createAsyncThunk(
  "getDeliveryTypesAsyncThunk",
  async () => {
    return await getAllDeliveryTypes();
  }
);

export const getEmployeePositionTypesAsyncThunk = createAsyncThunk(
  "getEmployeePositionTypesAsyncThunk",
  async () => {
    return await getEmployeePositions();
  }
);

// export const getEmployeesAsyncThunk = createAsyncThunk(
//     'getEmployeesAsyncThunk',
//     async () => {
//         return await getEmployees()
//     }
// )

export const getAllOrderRejectReasonTypesAsyncThunk = createAsyncThunk(
  "getAllOrderRejectReasonTypesAsyncThunk",
  async () => {
    return await getAllOrderRejectReasonTypes();
  }
);
