import { FC, memo } from "react";
import { IMapOrder } from "@shared/components/Map/models/models";
import UnassignedOrderMarker from "./UnassignedOrderMarker";

interface UnassignedOrderMarkersProps {
  orders: IMapOrder[];
}

const UnassignedOrderMarkers: FC<UnassignedOrderMarkersProps> = ({ orders }) => (
  <>
    {orders.map((order) => (
      <UnassignedOrderMarker key={order.id} order={order} />
    ))}
  </>
);

export default memo(UnassignedOrderMarkers);
