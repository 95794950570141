import useFormStyles from "../hooks/useFormStyles";
import { InputAdornment, TextField } from "@material-ui/core";
import { IRoleSalaryConfigurationDto } from "@shared/services/salary-configuration/dtos/role-salary-configurations/roleSalaryConfigurationDto";
import { useFormik } from "formik";
import { FC } from "react";

interface BreaksFormProps {
  formik: ReturnType<typeof useFormik<IRoleSalaryConfigurationDto>>;
}

const BreaksForm: FC<BreaksFormProps> = ({ formik }) => {
  const classes = useFormStyles();

  return (
    <div style={{ display: "flex", flexDirection: "column" }} className={classes.form}>
      <div>
        <TextField
          variant="outlined"
          className={classes.formControl}
          id="breakDuration"
          name="breakDuration"
          label="Времетраење на пауза"
          InputProps={{
            endAdornment: <InputAdornment position="start">мин</InputAdornment>,
          }}
          type="number"
          value={formik.values.breakDuration}
          onChange={formik.handleChange}
          error={formik.touched.breakDuration && Boolean(formik.errors.breakDuration)}
          helperText={formik.touched.breakDuration && formik.errors.breakDuration}
        />
      </div>

      <div>
        <TextField
          variant="outlined"
          className={classes.formControl}
          id="breakPay"
          name="breakPay"
          label="Исплата за пауза"
          InputProps={{
            endAdornment: <InputAdornment position="start">мкд/ч</InputAdornment>,
          }}
          type="number"
          value={formik.values.breakPay}
          onChange={formik.handleChange}
          error={formik.touched.breakPay && Boolean(formik.errors.breakPay)}
          helperText={formik.touched.breakPay && formik.errors.breakPay}
        />
      </div>
    </div>
  );
};

export default BreaksForm;
