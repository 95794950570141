import { CSSProperties, FC, memo } from "react";
import { Typography, Icon, makeStyles } from "@material-ui/core";
import { useMemoSelector } from "@shared/hooks";
import { Call as CallIcon } from "@material-ui/icons";
import { IMapDriver } from "@shared/components/Map/models/models";
import { getVehicleIcon } from "@shared/utils/getVehicleIcon";
import { getDisplayValue } from "@shared/utils/getDisplayValue";
import ColoredIconButton from "@shared/components/ColoredIconButton/ColoredIconButton";
import { isDriverPaused } from "@shared/utils/drivers/driverStatus";
import Card from "../Card/Card";

import DriverTooltipContent from "@App/modules/Map/components/Drivers/DriverTooltipContent";
import ClickableTooltip from "@shared/components/Tooltip/ClickableTooltip";

const useStyles = makeStyles((theme) => ({}));

interface CompactDriverCardProps {
  driver: IMapDriver;
  style?: CSSProperties;
}

const CompactDriverCard: FC<CompactDriverCardProps> = ({ driver, style }) => {
  const classes = useStyles();

  const vehicleTypes = useMemoSelector((state) => state.sharedState?.vehicleTypes?.data);

  const isPaused = isDriverPaused(driver);

  return (
    <ClickableTooltip
      arrow={true}
      type="driver"
      placement="right"
      content={<DriverTooltipContent driver={driver} />}
    >
      <Card
        background={isPaused ? "danger" : driver.orders.length > 0 ? "warning" : "normal"}
        style={style}
        onClick={(event) => event.stopPropagation()}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Icon fontSize="small">
            {getVehicleIcon(getDisplayValue(vehicleTypes, driver.vehicleTypeFk), "inherit")}
          </Icon>
          <Typography component="span">
            {driver.firstName} {driver.lastName?.[0] || ""}.
          </Typography>
        </div>

        <div>
          {/* Call button */}
          <ColoredIconButton
            size="small"
            color="success"
            onClick={(event) => {
              event.stopPropagation();

              // TODO: There has to be a better way to do this
              document.location.href = `tel:${driver.phoneNumber}`;
            }}
          >
            <CallIcon fontSize="inherit" />
          </ColoredIconButton>
        </div>
      </Card>
    </ClickableTooltip>
  );
};

export default memo(CompactDriverCard);
