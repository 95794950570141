import DialogActions from "../../../../../../shared/components/Dialog/DialogActions";
import DialogContent from "../../../../../../shared/components/Dialog/DialogContent";
import DialogTitle from "../../../../../../shared/components/Dialog/DialogTitle";
import ErrorMessage from "../../../../../../shared/components/ErrorMessage/ErrorMessage";
import {
  addNewPaymentType,
  updatePaymentType,
} from "../../../../../../shared/services/settings/payment-types.service";
import { IPaymentType } from "../Payment";
import { NewPaymentTypeSchema } from "../schemas/NewPaymentTypeSchema";
import { Button, CircularProgress, Dialog, TextField } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { IApiErrorResponseData } from "@shared/services/http.client";
import { AxiosError } from "axios";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { FC, SyntheticEvent, useEffect, useState } from "react";

const useStyles = makeStyles(() =>
  createStyles({
    dialogContent: {
      minWidth: "290px",
    },
    form: {
      width: "100%",
    },
  })
);

const initialValues = {
  name: "",
};

interface NewPaymentTypeFormProps {
  title: string;
  addButtonText: string;
  open: boolean;
  row: IPaymentType | null;
  onClose: () => void;
  onSuccess: () => void;
}

const NewPaymentTypeForm: FC<NewPaymentTypeFormProps> = (props) => {
  const classes = useStyles();
  const { title, addButtonText, open, row, onClose, onSuccess } = props;

  const { enqueueSnackbar } = useSnackbar();

  const [error, setError] = useState("");
  const handleSubmit = (values: typeof initialValues) => {
    const APICall =
      row && row.id ? updatePaymentType(row.id, { ...values }) : addNewPaymentType(values);

    APICall.then((response) => {
      // Reset formik
      formik.setSubmitting(false);
      formik.resetForm();

      // Show notification
      enqueueSnackbar("Успешно додадено", { variant: "success" });

      // TODO: Send back the data from the response here

      // Refetch data
      onSuccess();

      // Close the dialog
      onClose();
    }).catch((error: AxiosError<IApiErrorResponseData>) => {
      formik.setSubmitting(false);
      setError(error.response?.data?.message);
    });
  };

  const {
    setValues,
    handleChange: handleFormikChange,
    ...formik
  } = useFormik({
    initialValues,
    validationSchema: NewPaymentTypeSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const handleClose = () => {
    formik.resetForm();
    setError("");

    if (onClose) onClose();
  };

  const handleChange = (event: SyntheticEvent) => {
    // Reset errors on input change
    if (error) setError("");

    handleFormikChange(event);
  };

  // Set initial values
  useEffect(() => {
    if (!row) return;

    const newInitialValues = {} as typeof initialValues;
    Object.keys(initialValues).forEach((key: string) => {
      if (key in row) newInitialValues[key] = row[key];
    });

    setValues(newInitialValues);
  }, [row, setValues]);

  return (
    <Dialog
      maxWidth={false}
      aria-labelledby="new-payment-type-dialog"
      open={open}
      onClose={handleClose}
    >
      <form className={classes.form} onSubmit={formik.handleSubmit}>
        <DialogTitle onClose={handleClose}>{title}</DialogTitle>

        <DialogContent className={classes.dialogContent} dividers>
          <TextField
            name="name"
            label="Име"
            variant="outlined"
            value={formik.values.name}
            onChange={handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            autoFocus
            fullWidth
            required
          />

          <ErrorMessage error={error} />
        </DialogContent>

        <DialogActions>
          {formik.isSubmitting ? (
            <CircularProgress size={25} />
          ) : (
            <Button type="submit" color="primary">
              {addButtonText}
            </Button>
          )}

          <Button onClick={handleClose}>Откажи</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default NewPaymentTypeForm;
