import { calculateDistanceAndBearing } from "../../utils/calculateDistanceAndBearing";
import Card from "../Card/Card";
import DriverTooltipContent from "@App/modules/Map/components/Drivers/DriverTooltipContent";
import { skopjeGeolocation } from "@Logistics/constants";
import { selectedDriverId } from "@Logistics/store/features/filters/filtersSlice";
import { Icon, IconButton, Typography, makeStyles } from "@material-ui/core";
import { ClickAwayListener } from "@material-ui/core";
import {
  ArrowUpward as ArrowUpwardIcon,
  BatteryAlert as BatteryAlertIcon,
  Call as CallIcon,
  InfoOutlined as InfoOutlinedIcon,
  LocationDisabled,
  PermDeviceInformation,
} from "@material-ui/icons";
import ColoredIconButton from "@shared/components/ColoredIconButton/ColoredIconButton";
import { IMapDriver } from "@shared/components/Map/models/models"; // Ensure this imports IMapDriver correctly
import ClickableTooltip from "@shared/components/Tooltip/ClickableTooltip";
import { useMemoSelector } from "@shared/hooks";
import { OrderStatus } from "@shared/services/order.service";
import { IRoutePointDto } from "@shared/services/orders/dtos/orders/routePointDto";
import { isDriverLost, isDriverPaused, isLowBattery } from "@shared/utils/drivers/driverStatus";
import { getDisplayValue } from "@shared/utils/getDisplayValue";
import { getVehicleIcon } from "@shared/utils/getVehicleIcon";
import clsx from "clsx";
import { CSSProperties, FC, memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { timer } from "rxjs";
import {
  selectAllRoutes,
  selectDriversDifferenceTime,
} from "../../store/features/drivers/driverSelectors";

const useStyles = makeStyles((theme) => ({
  selectedCard: {
    background: "linear-gradient(to right, #1e88e5, #2196f3)",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1), 0px 8px 16px rgba(0, 0, 0, 0.1)",
    borderRadius: "15px",
    transform: "scale(1.02)",
    transition: "transform 0.2s",
    "& *": {
      color: "white",
    },
  },
  directionContainer: {
    "& *": {
      color: "black",
    },
  },
}));

interface DriverCardProps {
  driver: IMapDriver;
  routePoint?: IRoutePointDto;
    style?: CSSProperties;
}

const t = timer(0, 10000);

const DriverCard: FC<DriverCardProps> = ({ driver, routePoint, style }) => {
  const classes = useStyles();
  const [isLocationLost, setIsLocationLost] = useState(false);

  const vehicleTypes = useMemoSelector((state) => state.sharedState?.vehicleTypes?.data);
  const routes = useMemoSelector(selectAllRoutes);
  const differenceTime = useMemoSelector((state) =>
    selectDriversDifferenceTime(state, driver.employeeId)
  );

  const highestDifferenceTime = useMemoSelector((state) =>
    selectDriversDifferenceTime(state, driver.employeeId)
  );

  const isPaused = isDriverPaused(driver);

  
  const recoverLocation = () => {
    console.log(`Recovering location for driver ${driver.employeeId}`);
    
    dispatch({
      type: "RECOVER_DRIVER_LOCATION",
      payload: { driverId: driver.employeeId },
    });
  };

  
  useEffect(() => {
    const subscription = t.subscribe(() => {
      const lost = isDriverLost(driver);
      console.log(`Driver Lost Status for ${driver.employeeId}: ${lost}`);
      setIsLocationLost(lost);

      if (lost) {
        recoverLocation();
      }
    });

    return () => subscription.unsubscribe();
  }, [driver]);

  const { bearing, distance } = calculateDistanceAndBearing(skopjeGeolocation, {
    latitude: driver.geolocation.latitude,
    longitude: driver.geolocation.longitude,
  });

  console.log("Calculated Bearing:", bearing, "Distance:", distance);

  const [isHovered, setIsHovered] = useState(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const dispatch = useDispatch();
  const isSelected = useSelector(({ appState }: IGlobalState) =>
    appState.logistics.filters.drivers.selectedIds?.includes(driver.employeeId)
  );

  let color = "black";
  let textColor = "black";
  let backgroundColor = "white";
  if (highestDifferenceTime > 30) {
    color = "red";
    textColor = "white";
    backgroundColor = "red";
  } else if (highestDifferenceTime > 15) {
    color = "yellow";
    textColor = "white";
    backgroundColor = "yellow";
  } else {
    backgroundColor = "white";
  }

  return (
    <ClickAwayListener onClickAway={() => setIsTooltipOpen(false)}>
    <Card
     background={
      driver.orders.some((order) => order.orderStatus === OrderStatus.PickedUp)
        ? "success"
        : isPaused
          ? "danger"
          : driver.orders.length > 0
            ? "warning"
            : "normal"
    }
      style={{ ...style, width: "100%", gap: 0, borderRadius: "15px", cursor: "pointer" }}
      className={clsx({ [classes.selectedCard]: isSelected })}
      onClick={() => {
        dispatch(selectedDriverId({ selectedId: driver.employeeId, isEnabled: !isSelected }));
      }}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          flexShrink: 1,
          minWidth: "50px",
        }}
      >
        <Icon fontSize="medium">
          {getVehicleIcon(getDisplayValue(vehicleTypes, driver.vehicleTypeFk), "inherit")}
        </Icon>
        {driver.hasFiscalPrinter && (
          <ColoredIconButton
            size="small"
            color="inherit"
            title="Има фискален принтер"
            disableRipple
            style={{ marginLeft: "-10px" }}
          >
            <PermDeviceInformation fontSize="inherit" />
          </ColoredIconButton>
        )}

        <Typography
          component="span"
          noWrap
          title={`${driver.firstName.slice(0, 5)}${driver.firstName.length > 5 ? "." : ""} ${
            driver.lastName
          }`}
        >
          {`${driver.firstName.slice(0, 5)}${driver.firstName.length > 5 ? "." : ""}`}{" "}
          {driver.lastName?.[0] || ""}.{" "}
          {driver.orders.length > 0 && <>({driver.orders.length})</>}
        </Typography>
      </div>

      <div style={{ display: "flex", flexShrink: 0 }}>
        {isLowBattery(driver) && (
          <ColoredIconButton size="small" color="error" disableRipple>
            <BatteryAlertIcon fontSize="inherit" />
          </ColoredIconButton>
        )}

        {isLocationLost && (
          <ColoredIconButton size="small" color="error" title="Изгубена локација" disableRipple>
            <LocationDisabled fontSize="inherit" />
            </ColoredIconButton>
          )}

          <div style={{ display: "flex", flexShrink: 0 }} onMouseOver={() => setIsHovered(true)}>
            {isHovered || isTooltipOpen ? (
              <>
                <ColoredIconButton
                  size="small"
                  color="success"
                  onClick={(event) => {
                    event.stopPropagation();
                    document.location.href = `tel:${driver.phoneNumber}`;
                  }}
                >
                  <CallIcon fontSize="inherit" />
                </ColoredIconButton>

          <ClickableTooltip
            arrow={true}
            placement="right"
            type="driver"
            content={<DriverTooltipContent driver={driver} />}
          >
            <IconButton id="Location-btn" size="small" onClick={() => setIsTooltipOpen(true)}>
              <InfoOutlinedIcon fontSize="inherit" />
            </IconButton>
          </ClickableTooltip>
        </>
            ) : (
              <div
                className={classes.directionContainer}
                style={{
                  display: "flex",
                  gap: "0.2rem",
                  border: "1px solid black",
                  borderRadius: "25px",
                  padding: "1px 8px 1px 5px",
                  backgroundColor,
                  color,
                }}
              >
                <ArrowUpwardIcon style={{ transform: `rotate(${bearing}deg)` }} />
                <Typography>{Math.round(distance)}</Typography>
              </div>
            )}
          </div>
        </div>
      </Card>
    </ClickAwayListener>
  );
};

export default memo(DriverCard);

