import { FC, useState } from "react";
import { Collapse, makeStyles } from "@material-ui/core";
import { Alert, AlertProps, AlertTitle } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  alertMessage: {
    width: "100%",
  },
  alertAction: {
    alignItems: "flex-start",
  },
}));

interface CollapsableAlertProps extends AlertProps {
  title?: string;
}

const CollapsableAlert: FC<CollapsableAlertProps> = ({ title, children, ...rest }) => {
  const classes = useStyles();

  const [isShown, setIsShown] = useState(true);

  return (
    <Collapse in={isShown}>
      <Alert
        {...rest}
        className={rest.className}
        classes={{ ...rest.classes, message: classes.alertMessage, action: classes.alertAction }}
        onClose={() => setIsShown((state) => !state)}
      >
        {title && <AlertTitle>{title}</AlertTitle>}

        {children}
      </Alert>
    </Collapse>
  );
};

export default CollapsableAlert;
