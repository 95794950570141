import { TaximeterRatesValidationSchema } from "../schemas/TaximeterRatesValidationSchema";
import { Button, Grid, TextField, Typography } from "@material-ui/core";
import { ITaximeterRatesDto } from "@shared/services/maksi/dtos/taximeterRatesDto";
import {
  getTaximeterRates,
  updateTaximeterRates,
} from "@shared/services/maksi/taximeter-rates-settings.service";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { FC, useEffect, useState } from "react";

const initialValues: ITaximeterRatesDto = {
  startPrice: 0,
  pricePerKm: 0,
  pricePerMinute: 0,
};

interface TaximeterRatesConfigProps {
  city: { text: string; value: number };
  deliveryType: { text: string; value: number };
}

const TaximeterRatesConfig: FC<TaximeterRatesConfigProps> = ({ city, deliveryType }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [loadingState, setLoadingState] = useState<boolean>(false);

  const handleSubmit = () => {
    setLoadingState(true);
    updateTaximeterRates({
      ...formik.values,
      cityId: city.value,
      deliveryTypeId: deliveryType.value,
    })
      .then(() => {
        enqueueSnackbar("Успешно ги сочувавте информациите", {
          variant: "success",
          autoHideDuration: 6000,
        });
      })
      .catch((e) => {
        enqueueSnackbar(`Се случи грешка: ${e.message}`, {
          variant: "error",
          autoHideDuration: 6000,
        });
      })
      .finally(() => {
        setLoadingState(false);
      });
  };

  const {
    setValues,
    handleChange: handleFormikChange,
    ...formik
  } = useFormik({
    initialValues,
    validationSchema: TaximeterRatesValidationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    setLoadingState(true);
    getTaximeterRates(city.value, deliveryType.value)
      .then((res) => {
        setValues(res);
      })
      .catch((error) => {
        enqueueSnackbar(`Се случи грешка: ${error.message}.`, {
          variant: "error",
          autoHideDuration: 6000,
        });
        setValues(initialValues);
      })
      .finally(() => {
        setLoadingState(false);
      });
  }, [city.value, deliveryType.value, enqueueSnackbar, setValues]);

  if (loadingState === true) {
    return <Typography align="center">Се вчитува</Typography>;
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5">
            Таксиметар цени за {deliveryType.text} во {city.text}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <TextField
            name="startPrice"
            label="Стартна цена"
            variant="outlined"
            size="small"
            value={formik.values.startPrice}
            onChange={handleFormikChange}
            error={formik.touched.startPrice && Boolean(formik.errors.startPrice)}
            helperText={formik.touched.startPrice && formik.errors.startPrice}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="pricePerKm"
            label="Цена по километар"
            variant="outlined"
            size="small"
            value={formik.values.pricePerKm}
            onChange={handleFormikChange}
            error={formik.touched.pricePerKm && Boolean(formik.errors.pricePerKm)}
            helperText={formik.touched.pricePerKm && formik.errors.pricePerKm}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="pricePerMinute"
            label="Цена по минута"
            variant="outlined"
            size="small"
            value={formik.values.pricePerMinute}
            onChange={handleFormikChange}
            error={formik.touched.pricePerMinute && Boolean(formik.errors.pricePerMinute)}
            helperText={formik.touched.pricePerMinute && formik.errors.pricePerMinute}
            fullWidth
          />
        </Grid>
        <Grid item style={{ display: "flex", justifyContent: "flex-end" }} xs={12}>
          <Button
            style={{ float: "right" }}
            type="submit"
            disabled={loadingState}
            variant="contained"
            color="primary"
          >
            Зачувај
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default TaximeterRatesConfig;
