import httpService from "../http.service";
import { IDriverRouteResponseDto } from "../orders/dtos/orders/driverRouteResponseDto";
import { IDriverRevenueDetails } from "./dtos/driverRevenueDetailsDto";
import { IDriverDto } from "./dtos/driverDto";

export const getDrivers = () => {
  return httpService.get<IDriverDto[]>("/api/drivers");
};

export const requestPingDriverLocation = (userId: string) => {
  return httpService.post<void>(`/api/users/${userId}/notifications/ping-location`);
};

export const requestStartGPSService = (userId: string) => {
  return httpService.post<void>(`/api/users/${userId}/notifications/start-gps-service`);
};

export const requestStopGPSService = (userId: string) => {
  return httpService.post<void>(`/api/users/${userId}/notifications/stop-gps-service`);
};

export const executeAlgorithmManually = (driverId: number) => {
  return httpService.post<void>(`/api/drivers/${driverId}/orders/schedule-processing`);
};

export const forceCloseActiveDriverRoute = (driverId: number) => {
  return httpService.post<void>(`/api/drivers/${driverId}/routes/force-close-active-route`);
};

export const getDriverActiveRoute = (driverId: number) => {
  return httpService.get<IDriverRouteResponseDto>(`/api/drivers/${driverId}/routes/active`);
};

export const updateDriverRoute = (driverId: number, route: IDriverRouteResponseDto) => {
  return httpService.put<void>(`/api/drivers/${driverId}/routes/${route.id}`, route);
};

export const getDriverTurnover = (driverId: number, date: string) => {
  return httpService.get<IDriverRevenueDetails>(`/api/drivers/${driverId}/turnover/${date}`);
};
