import Navigation from "./Navigation/Navigation";
import { Container } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import NetworkChangeListener from "@shared/components/NetworkChangeListener/NetworkChangeListener";
import { FC } from "react";
import { Outlet } from "react-router-dom";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: "flex",
    },
    toolbar: {
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    contentContainer: {
      height: "100vh",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      overflowX: "hidden",
    },
    content: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
    },
  })
);

const Layout: FC = ({ children }) => {
  const classes = useStyles();

  return (
    <Container disableGutters className={classes.container}>
      <NetworkChangeListener />

      <main className={classes.contentContainer}>
        <div className={classes.content}>
          <Outlet />
        </div>
        <div className={classes.toolbar} />
      <Navigation/>
      </main>

    </Container>
  );
};

export default Layout;
