import ErrorFallback from "../../../../../error";
import AddRoleSalaryConfigCard from "./components/AddRoleSalaryConfigCard";
import RoleSalaryConfigCard from "./components/RoleSalaryConfigCard";
import { Grid, Typography, createStyles, makeStyles } from "@material-ui/core";
import Loading from "@shared/components/Loading/Loading";
import { getAllRoleSalaries } from "@shared/services/salary-configuration/role-salary-configuration.service";
import { useQuery } from "@tanstack/react-query";
import { FC, useState } from "react";
import CitiesFilter from "../../shared/components/CitiesFilter";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  })
);

const RoleSalaryConfig: FC = () => {
  const classes = useStyles();
  const [citiesFilter, setCitiesFilter] = useState<number[]>([]);

  const {
    data: roleSalaryConfigs,
    refetch: refetchRoleSalaryConfigs,
    isLoading,
    isError,
    error,
  } = useQuery(["getAllRoleSalaries"], getAllRoleSalaries);

  if (isLoading) return <Loading />;
  if (isError) return <ErrorFallback error={error} />;

  return (
    <Grid container>
      <Grid container>
        <Grid item>
          <Typography variant="h5">Конфигурација на плати</Typography>
      <CitiesFilter onChange={(cities) => setCitiesFilter(cities)} />

        </Grid>
      </Grid>
      <Grid container className={classes.root}>
        </Grid>

      <Grid container className={classes.root}>
        {roleSalaryConfigs?.map((roleSalaryConfig) => (
          <Grid key={roleSalaryConfig.id} item>
            <RoleSalaryConfigCard
              roleSalaryConfig={roleSalaryConfig}
              onAddSubRole={refetchRoleSalaryConfigs}
            />
          </Grid>
        ))}

        <Grid item>
          <AddRoleSalaryConfigCard onAddRole={refetchRoleSalaryConfigs} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RoleSalaryConfig;
