import ErrorFallback from "../../../../error";
import EmployeeSalaryConfig from "./EmployeeSalaryConfig/EmployeeSalaryConfig";
import EmployeesOverview from "./EmployeesOverview/EmployeesOverview";
import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

const Employees: FC = () => {
  return (
    <Routes>
      <Route errorElement={<ErrorFallback />}>
        <Route index element={<EmployeesOverview />} />
        <Route path=":employeeId/salary-config" element={<EmployeeSalaryConfig />} />

        <Route path="*" element={<EmployeesFallback />} />
      </Route>
    </Routes>
  );
};

const EmployeesFallback: FC = () => <Navigate to="" />;

export default Employees;
