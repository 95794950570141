import { FC, memo } from "react";
import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  withStyles,
} from "@material-ui/core";
import {
  deleteOrderManuallyAsync,
  IOrderDto,
  OrderStatus,
  pauseOrderManuallyAsync,
  rescheduleOrderAsync,
  ungroupGroupedOrders,
} from "@shared/services/order.service"
import { Delete, Edit, Pause, PlayArrow, LayersClear, Transform} from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { useModal } from "mui-modal-provider";
import { useConfirmationDialog } from "@shared/context/ConfirmationDialogContext";
import CreateOrderFormDialog from "@App/modules/Map/components/Orders/CreateOrderFormDialog";
import GeneratePackageDialog
 from "../Map/GeneratePackageDialog";
import UpdatePackageDialog from "../Map/UpdatePackageDialog";
import EditPackageDialog from "../Map/EditPackageDialog";

const MenuIcon = withStyles((theme) => ({
  root: {
    minWidth: theme.spacing(4),
  },
}))(ListItemIcon);

interface OrderContextMenuProps {
  order: IOrderDto;
  contextMenuPosition: { mouseX: number; mouseY: number };
  onClose: () => void;
}

const OrderContextMenu: FC<OrderContextMenuProps> = ({ order, contextMenuPosition, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { showModal } = useModal({ disableAutoDestroy: true });
  const confirmationDialog = useConfirmationDialog();

  const handlePauseOrder = () => {
    onClose();
    confirmationDialog.setLoading(true);

    pauseOrderManuallyAsync(order.id)
      .then(() => {
        enqueueSnackbar(`Успешно пратена нотификација за доделена нарачка.`, {
          variant: "success",
          autoHideDuration: 6000,
        });
      })
      .catch((error) => {
        enqueueSnackbar(`Неуспешно испратена нотификација: ${error.response.data.message}`, {
          variant: "error",
          autoHideDuration: 6000,
        });
      })
      .finally(() => {
        confirmationDialog.close();
      });
  };

  const handleUnpauseOrder = () => {
    onClose();
    confirmationDialog.setLoading(true);

    rescheduleOrderAsync(order.id)
      .then(() => {
        enqueueSnackbar(`Успешно пратена нотификација за доделена нарачка.`, {
          variant: "success",
          autoHideDuration: 6000,
        });
      })
      .catch((error) => {
        enqueueSnackbar(`Неуспешно испратена нотификација: ${error.response.data.message}`, {
          variant: "error",
          autoHideDuration: 6000,
        });
      })
      .finally(() => {
        confirmationDialog.close();
      });
  };

  const handleDeleteOrder = () => {
    onClose();
    confirmationDialog.setLoading(true);

    deleteOrderManuallyAsync(order.id)
      .then(() => {
        enqueueSnackbar(`Успешно пратена нотификација за доделена нарачка.`, {
          variant: "success",
          autoHideDuration: 6000,
        });
      })
      .catch((error) => {
        enqueueSnackbar(`Неуспешно испратена нотификација: ${error.response.data.message}`, {
          variant: "error",
          autoHideDuration: 6000,
        });
      })
      .finally(() => {
        confirmationDialog.close();
      });
  };

  const ungroup = () => {
    onClose();
    confirmationDialog.setLoading(true);

    ungroupGroupedOrders(order.packageGuid)
      .then(() => {
        enqueueSnackbar(`Нарачките се одгрупирани.`, {
          variant: "success",
          autoHideDuration: 6000,
        });
      })
      .catch((error) => {
        enqueueSnackbar(`Се случи грешка: ${error.message}`, {
          variant: "error",
          autoHideDuration: 6000,
        });
      })
      .finally(() => {
        confirmationDialog.close();
      });
  };

  const handleEditOrder = () => {
    onClose();

    const editOrderModal = showModal(CreateOrderFormDialog, {
      order: { ...order, driver: null },
      onClose: () => editOrderModal.destroy(),
    });
  };

  const handleEditGroup = () => {
    onClose();

    const editGroupOrderModal = showModal(EditPackageDialog, {
  
     orderGroupId: order.packageGuid,
      onClose: () => editGroupOrderModal.destroy(),
    });
  };

  const isOrderPaused = order.orderStatus === OrderStatus.Paused;
  const isGrouped = order.packageGuid !== null;
  return (
    <Menu
      open={contextMenuPosition !== null}
      onClose={onClose}
      anchorReference="anchorPosition"
      anchorPosition={
        contextMenuPosition !== null
          ? { top: contextMenuPosition.mouseY, left: contextMenuPosition.mouseX }
          : undefined
      }
     
      //  PaperProps={{ style: { backgroundColor: isOrderPaused ? "#ff0000" : undefined}}}
  > 
      <MenuList disablePadding>
        <MenuItem onClick={() => (isOrderPaused ? handleUnpauseOrder() : handlePauseOrder())}>
          <MenuIcon>
            {isOrderPaused ? <PlayArrow fontSize="small" /> : <Pause fontSize="small" />}
          </MenuIcon>
          <ListItemText>{isOrderPaused ? "Врати на обработка" : "Паузирај"}</ListItemText>
        </MenuItem>

{isGrouped ?
        <MenuItem onClick={handleEditGroup}>
          <MenuIcon>
            <Transform fontSize="small" />
          </MenuIcon>
          <ListItemText>Измени група</ListItemText>
        </MenuItem>
: null}

{isOrderPaused ?
        <MenuItem  onClick={handleEditOrder}>
          <MenuIcon>
            <Edit fontSize="small" />
          </MenuIcon>
          <ListItemText>Измени нарачка</ListItemText>
        </MenuItem>
: null}
        
{isOrderPaused ?
        <MenuItem onClick={handleDeleteOrder}>
          <MenuIcon>
            <Delete fontSize="small" />
          </MenuIcon>
          <ListItemText>Избриши</ListItemText>
        </MenuItem>
        : null}
        
{isGrouped ? 
        <MenuItem onClick={ungroup}>
          <MenuIcon>
            <LayersClear fontSize="small" />
          </MenuIcon>
          <ListItemText>Одгрупирај</ListItemText>
        </MenuItem>
         :null}
      </MenuList>
      </Menu>
  );
};

export default memo(OrderContextMenu);
