import useFormStyles from "../hooks/useFormStyles";
import { Box, InputAdornment, TextField } from "@material-ui/core";
import { IEmployeeSalaryConfigurationDto } from "@shared/services/salary-configuration/dtos/employee-salary-configuration/employeeSalaryConfigurationDto";
import { useFormik } from "formik";
import { FC } from "react";

interface SickDaysProps {
  formik: ReturnType<typeof useFormik<IEmployeeSalaryConfigurationDto>>;
}

const SickDaysForm: FC<SickDaysProps> = ({ formik }) => {
  const classes = useFormStyles();

  return (
    <Box display="flex" flexDirection="column" className={classes.form}>
      <Box>
        <TextField
          variant="outlined"
          className={classes.formControl}
          id="rateSick"
          name="rateSick"
          label="Стапка на боледување (по ден)"
          InputProps={{
            endAdornment: <InputAdornment position="start">мкд</InputAdornment>,
          }}
          type="number"
          value={formik.values.rateSick}
          onChange={formik.handleChange}
          error={formik.touched.rateSick && Boolean(formik.errors.rateSick)}
          helperText={formik.touched.rateSick && formik.errors.rateSick}
        />
      </Box>
    </Box>
  );
};

export default SickDaysForm;
