import { Chip } from "@material-ui/core";
import { DriveEta, RoomService, Schedule } from "@material-ui/icons";
import ChipContainer from "@shared/components/ChipContainer/ChipContainer";
import { getRecommendedPickUpDelay } from "@shared/services/orders/orders.service";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { FC } from "react";

export interface StatsOverviewProps {
  numberOfOrders: number;
  numberOfDrivers: number;
}

const StatsOverview: FC<StatsOverviewProps> = ({ numberOfOrders, numberOfDrivers }) => {
  const { data: recommendedPickupDelay, isError } = useQuery(
    ["minPickUpTime"],
    getRecommendedPickUpDelay,
    {
      refetchInterval: 60 * 1000, // 1 minute
      retry: (count) => count < 2,
    }
  );

  return (
    <ChipContainer disableVerticalPadding>
      <Chip
        size="small"
        label={
          <>
            Нарачки: <strong>{numberOfOrders}</strong>
          </>
        }
        avatar={<RoomService />}
        variant="outlined"
        component="li"
        title="Број на нарачки"
      />

      <Chip
        size="small"
        label={
          <>
            Возачи: <strong>{numberOfDrivers}</strong>
          </>
        }
        avatar={<DriveEta />}
        variant="outlined"
        component="li"
        title="Број на возачи"
      />

      <Chip
        size="small"
        label={
          <>
            Мин. време:{" "}
            <strong>
              {recommendedPickupDelay
                ? dayjs.duration({ minutes: recommendedPickupDelay }).humanize()
                : isError
                ? "--:--"
                : "Се вчитува"}
            </strong>
          </>
        }
        avatar={<Schedule />}
        variant="outlined"
        component="li"
        title="Мин. време за подигнување"
      />
    </ChipContainer>
  );
};

export default StatsOverview;
