import useFormStyles from "../hooks/useFormStyles";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment, TextField } from "@material-ui/core";
import { IEmployeeSalaryConfigurationDto } from "@shared/services/salary-configuration/dtos/employee-salary-configuration/employeeSalaryConfigurationDto";
import { addEmployeeSalaryBonus, deleteEmpoyeeSalaryBonus, updateEmployeeSalaryBonus } from "@shared/services/salary-configuration/employee-salary-configuration.service";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { FC, useState } from "react";

interface BonusesFormProps {
  formik: ReturnType<typeof useFormik<IEmployeeSalaryConfigurationDto>>;
}

const BonusesForm: FC<BonusesFormProps> = ({ formik }) => {
  const classes = useFormStyles();
  const { enqueueSnackbar } = useSnackbar();

    // Function to add a new bonus
  const handleAddBonus = () => {
    const newBonus = { id: null, condition: '', amount: '', type: 1 }; // Adjust based on your needs
    const bonuses = [...formik.values.employeeSalaryBonuses, newBonus];
    formik.setFieldValue('employeeSalaryBonuses', bonuses);
  };

  const handleSaveBonus = (bonus) => () => {
    // Save the bonus
    if(bonus.id === null) {
      bonus.employeeSalaryConfigurationFk = formik.values.id;
      addEmployeeSalaryBonus(bonus);
      enqueueSnackbar('Бонусот е успешно додаден', { variant: 'success' });
    }
    else{
      updateEmployeeSalaryBonus(bonus);
      enqueueSnackbar('Бонусот е успешно ажуриран', { variant: 'success' });
    }
  }
  const handleDeleteBonus = (bonus) => () => {
    // Delete the bonus
    deleteEmpoyeeSalaryBonus(bonus.id);
    //remove from form
    formik.setFieldValue('employeeSalaryBonuses', formik.values.employeeSalaryBonuses.filter(b => b.id !== bonus.id));
    enqueueSnackbar('Бонусот е успешно избришан', { variant: 'success' });

  }

  return (
    <Box display="flex" flexDirection="column" className={classes.form}>
        <h1>Услови за бонус:</h1>
      <Box>
      <TextField
          variant="outlined"
          className={classes.formControl}
          id="scheduleBonus"
          name="scheduleBonus"
          label="Бонус за работни часа над: (h)"
          type="number"
          value={formik.values.scheduleBonus}
          onChange={formik.handleChange}
          error={formik.touched.scheduleBonus && Boolean(formik.errors.scheduleBonus)}
          helperText={formik.touched.scheduleBonus && formik.errors.scheduleBonus}
        />
        </Box><Box>
          <TextField
          variant="outlined"
          className={classes.formControl}
          id="orderDeclineBonus"
          name="orderDeclineBonus"
          label="Максимум одбиени нарачки:"
          type="number"
          value={formik.values.orderDeclineBonus}
          onChange={formik.handleChange}
          error={formik.touched.orderDeclineBonus && Boolean(formik.errors.orderDeclineBonus)}
          helperText={formik.touched.orderDeclineBonus && formik.errors.orderDeclineBonus}
        />
        </Box>
        <Box>
           <TextField
          variant="outlined"
          className={classes.formControl}
          id="penaltiesUnderBonus"
          name="penaltiesUnderBonus"
          label="Максимум пенали (ден):"
          type="number"
          value={formik.values.penaltiesUnderBonus}
          onChange={formik.handleChange}
          error={formik.touched.penaltiesUnderBonus && Boolean(formik.errors.penaltiesUnderBonus)}
          helperText={formik.touched.penaltiesUnderBonus && formik.errors.penaltiesUnderBonus}
        />
        
      </Box>
      {formik.values.employeeSalaryBonuses.map((bonus, index) => (
        <Box key={bonus.id}>
          <TextField
            variant="outlined"
            className={classes.formControl}
            id={`employeeSalaryBonuses.${index}.condition`}
            name={`employeeSalaryBonuses.${index}.condition`}
            label="Нарачки"
            type="number"
            value={bonus.condition}
            onChange={formik.handleChange}
            error={formik.touched.employeeSalaryBonuses && Boolean(formik.errors.employeeSalaryBonuses)}
            helperText={formik.touched.employeeSalaryBonuses && formik.errors.employeeSalaryBonuses}
          />
           <TextField
            variant="outlined"
            className={classes.formControl}
            id={`employeeSalaryBonuses.${index}.amount`}
            name={`employeeSalaryBonuses.${index}.amount`}
            label="Бонус"
            type="number"
            value={bonus.amount}
            onChange={formik.handleChange}
            error={formik.touched.employeeSalaryBonuses && Boolean(formik.errors.employeeSalaryBonuses)}
            helperText={formik.touched.employeeSalaryBonuses && formik.errors.employeeSalaryBonuses}
          />
          <Button variant="contained" color="primary" onClick={handleSaveBonus(bonus)} style={{
            marginRight: 10,
            marginBottom: 10,
          }}>
            Зачувај
          </Button>
          <Button variant="contained" color="secondary" onClick={handleDeleteBonus(bonus)} style={{
            marginRight: 10,
            marginBottom: 10,
          }}>
            Избриши
          </Button>
          </Box>


          
      ))}
          <Button variant="contained" color="primary" onClick={handleAddBonus}>
          Додај нов бонус за нарачки
      </Button>
    </Box>
  );
};

export default BonusesForm;
