import { FC } from "react";
import { useDraggable } from "@dnd-kit/core";

interface DraggableProps {
  id: string;
  disabled?: boolean;
}

const Draggable: FC<DraggableProps> = (props) => {
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: props.id,
    disabled: props.disabled,
  });

  return (
    <div ref={setNodeRef} {...listeners} {...attributes}>
      {props.children}
    </div>
  );
};

export default Draggable;
