import { ChangeEvent, FocusEvent, FC, memo } from "react";
import {
  FormControl,
  InputLabel,
  FormHelperText,
  TextField,
} from "@material-ui/core";

export interface Props {
  errorMessage: string;
  touched: boolean;
  value: number | string;
  onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  className?: string;
  margin?: "none" | "dense" | "normal";
  size?: "small" | "medium";
  variant?: "standard" | "outlined" | "filled";
  icon?: boolean;
  label?: string;
  name?: string;
  autoFocus?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  type?: HTMLInputElement["type"];
}

const CustomTextField: FC<Props> = ({
  value,
  errorMessage,
  touched,
  autoFocus,
  onChange,
  onFocus,
  onBlur,
  margin,
  className,
  size,
  variant,
  label,
  name,
  fullWidth,
  disabled,
  type
}) => {

  return (
    <FormControl
      fullWidth={fullWidth}
      variant={variant}
      size={size}
      className={className}
      error={touched && Boolean(errorMessage)}
      margin={margin}
    >
      {label ? <InputLabel>{label}</InputLabel> : null}
      <TextField
        type={type}
        name={name}
        label={label}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        autoFocus={autoFocus}
        disabled={disabled}
      />

      <FormHelperText>{touched && errorMessage}</FormHelperText>
    </FormControl>
  );
};

export default memo(CustomTextField);
