import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import ColoredButton from "@shared/components/ColoredButton/ColoredButton";
import { getCurrentUser } from "@shared/services/auth/auth.service";
import { executeAlgorithmManually } from "@shared/services/drivers/drivers.service";
import { useQueryClient } from "@tanstack/react-query";
import { throttle } from "lodash";
import { FC } from "react";

const useStyles = makeStyles((theme: Theme) => createStyles({}));

const recalculateNextDestinationClick = throttle(() => {
  const currentUser = getCurrentUser();
  return executeAlgorithmManually(currentUser.employeeId);
}, 10000);

const CalculateNextDestinationButton: FC = () => {
  const classes = useStyles();

  const queryClient = useQueryClient();

  const handleRecalculateNextDestinationClick = () => {
    recalculateNextDestinationClick().finally(() => {
      queryClient.refetchQueries(["pendingAndActiveDriverOrders"]);
    });
  };

  return (
    <ColoredButton
      variant="contained"
      color="warning"
      size="small"
      onClick={handleRecalculateNextDestinationClick}
      fullWidth
    >
      Доколку веќе чекате повеќе од една минута, притиснете тука за да добиете следна дестинација
    </ColoredButton>
  );
};

export default CalculateNextDestinationButton;
