import ErrorFallback from "../../../../../error";
import AddCompanyCard from "./components/AddCompanyCard";
import CompanyCard from "./components/CompanyCard";
import { Grid, Typography, createStyles, makeStyles } from "@material-ui/core";
import Loading from "@shared/components/Loading/Loading";
import { getAllCompanies } from "@shared/services/settings/companies.service";
import { useQuery } from "@tanstack/react-query";
import { FC } from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  })
);

const Company: FC = () => {
  const classes = useStyles();

  const {
    data: companies,
    refetch: refetchCompanies,
    isLoading,
    isError,
    error,
  } = useQuery(["getAllCompanies"], getAllCompanies);

  if (isLoading) return <Loading />;
  if (isError) return <ErrorFallback error={error} />;

  return (
    <Grid container>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5">Конфигурација за компании</Typography>
        </Grid>
      </Grid>

      <Grid container className={classes.root}>
        {companies?.map((company) => (
          <Grid key={company.id} item>
            <CompanyCard company={company} onEdit={() => refetchCompanies()} />
          </Grid>
        ))}

        <Grid item>
          <AddCompanyCard onAdd={() => refetchCompanies()} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Company;
