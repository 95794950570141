import httpClient from "./http.client";
import { AxiosRequestConfig, AxiosResponse } from "axios";

/**
 * Uses base http client to execute get requests
 * @param url Only the endpoint, base server url is set by the underlining client
 * @param config Any config needed for the request except for standard one
 */
function get<TResponse = unknown>(url: string, config?: AxiosRequestConfig) {
  return httpClient.get<TResponse>(url, config).then<TResponse>((response) => response.data);
}

/**
 * Uses base http client to execute get blob requests
 * @param url Only the endpoint, base server url is set by the underlining client
 * @param config Any config needed for the request except for standard one
 */
function getBlob(url: string, config?: AxiosRequestConfig) {
  return httpClient
    .get<Blob>(url, { responseType: "blob", ...config })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;

    // Parse the filename from the Content-Disposition header
    const contentDisposition = response.headers["content-disposition"];
    let filename = "default.xlsx"; // Default filename in case parsing fails
    if (contentDisposition) {
      const filenameMatch = contentDisposition.match(
        /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/i
      );
      if (filenameMatch && filenameMatch.length > 1) {
        filename = decodeURIComponent(filenameMatch[1]);
      }
    }

    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    });
}

/**
 * Uses base http client to execute get blob requests
 * @param url Only the endpoint, base server url is set by the underlining client
 * @param request The data being sent to the server
 * @param config Any config needed for the request except for standard one
 */
function getBlobPost<TRequest = any>(url: string, request: TRequest, config?: AxiosRequestConfig) {
  return httpClient.post(url, request, { responseType: "blob", ...config }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;

    // Parse the filename from the Content-Disposition header
    const contentDisposition = response.headers["content-disposition"];
    let filename = "default.xlsx"; // Default filename in case parsing fails
    if (contentDisposition) {
      const filenameMatch = contentDisposition.match(
        /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/i
      );
      if (filenameMatch && filenameMatch.length > 1) {
        filename = decodeURIComponent(filenameMatch[1]);
      }
    }

    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
  });
}

/**
 * Uses base http client to execute put requests
 * @param url Only the endpoint, base server url is set by the underlining client
 * @param request The data being sent to the server
 * @param config Any config needed for the request except for standard one
 */
function put<TResponse = unknown, TRequest = any>(
  url: string,
  request?: TRequest,
  config?: AxiosRequestConfig<TRequest>
) {
  return httpClient
    .put<TResponse, AxiosResponse<TResponse>, TRequest>(url, request, config)
    .then<TResponse>((response) => response.data);
}

/**
 * Uses base http client to execute post requests
 * @param url Only the endpoint, base server url is set by the underlining client
 * @param request The data being sent to the server
 * @param config Any config needed for the request except for standard one
 */
function post<TResponse = unknown, TRequest = any>(
  url: string,
  request?: TRequest,
  config?: AxiosRequestConfig<TRequest>
) {
  return httpClient
    .post<TResponse, AxiosResponse<TResponse>, TRequest>(url, request, config)
    .then<TResponse>((response) => response.data);
}

/**
 * Uses base http client to execute patch requests
 * @param url Only the endpoint, base server url is set by the underlining client
 * @param request The data being sent to the server
 * @param config Any config needed for the request except for standard one
 */
function patch<TResponse = unknown, TRequest = any>(
  url: string,
  request?: TRequest,
  config?: AxiosRequestConfig<TRequest>
): Promise<TResponse> {
  return httpClient
    .patch<TResponse, AxiosResponse<TResponse>, TRequest>(url, request, config)
    .then<TResponse>((response) => response.data);
}

//delete is reserved so _delete
/**
 * Uses base http client to execute delete requests
 * @param url Only the endpoint, base server url is set by the underlining client
 * @param config Any config needed for the request except for standard one
 */
function _delete<TResponse = unknown>(
  url: string,
  config?: AxiosRequestConfig
): Promise<TResponse> {
  return httpClient.delete<TResponse>(url, config).then<TResponse>((response) => response.data);
}

const httpService = {
  get,
  getBlob,
  getBlobPost,
  put,
  post,
  patch,
  delete: _delete,
};

export default httpService;
