import { FC, ReactNode, useState, MouseEvent } from "react";
import { alpha, makeStyles } from "@material-ui/core/styles";
import Autocomplete, {
  AutocompleteCloseReason,
} from "@material-ui/lab/Autocomplete";
import { ArrowDropDown as ArrowDropDownIcon } from "@material-ui/icons";
import {
  Popper,
  PopperPlacementType,
  IconButton,
  InputBase,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  popper: {
    border: "1px solid rgba(27,31,35,.15)",
    boxShadow: "0 3px 12px rgba(27,31,35,.15)",
    borderRadius: 3,
    width: 300,
    zIndex: 1,
    fontSize: 13,
    color: "#586069",
    backgroundColor: theme.palette.background.paper,
  },
  inputBase: {
    padding: 10,
    width: "100%",
    borderBottom: "1px solid #dfe2e5",
    "& input": {
      borderRadius: 4,
      backgroundColor: theme.palette.background.default,
      padding: 8,
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      border: "1px solid #ced4da",
      fontSize: 14,
      "&:focus": {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  paper: {
    boxShadow: "none",
    margin: 0,
    color: "#586069",
    fontSize: 13,
  },
  option: {
    minHeight: "auto",
    padding: 0,
    '&[aria-selected="true"]': {
      backgroundColor: "transparent",
    },
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  popperDisablePortal: {
    position: "relative",
  },
  listBox: {
    padding: 0,
  },
}));

interface CustomPopupAutocompleteProps {
  options: any[];
  renderOption: (option: any) => ReactNode;
  getOptionLabel: (option: any) => string;
  buttonIcon?: ReactNode;
  noOptionsText?: string | ReactNode;
  placement?: PopperPlacementType;
  onChange?: (value: any) => void;
}

const CustomPopupAutocomplete: FC<CustomPopupAutocompleteProps> = ({
  options,
  renderOption,
  noOptionsText,
  placement = "bottom-start",
  buttonIcon,
  getOptionLabel,
  onChange,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState<unknown>();

  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setValue(null);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (_, reason: AutocompleteCloseReason) => {
    if (reason === "toggleInput") {
      return;
    }

    if (anchorEl) {
      anchorEl.focus();
    }

    setAnchorEl(null);
  };

  const handleChange = (_, newValue: unknown) => {
    setValue(newValue);

    if (onChange) onChange(newValue);
  };

  return (
    <>
      <IconButton disableRipple onClick={handleOpen} size="small">
        {buttonIcon ? buttonIcon : <ArrowDropDownIcon />}
      </IconButton>

      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement={placement}
        className={classes.popper}
      >
        <Autocomplete
          open
          onClose={handleClose}
          classes={{
            paper: classes.paper,
            option: classes.option,
            popperDisablePortal: classes.popperDisablePortal,
            listbox: classes.listBox,
          }}
          disablePortal
          value={value}
          onChange={handleChange}
          noOptionsText={noOptionsText}
          renderOption={renderOption}
          options={options}
          getOptionLabel={getOptionLabel}
          renderInput={(params) => (
            <InputBase
              ref={params.InputProps.ref}
              inputProps={params.inputProps}
              className={classes.inputBase}
              placeholder="Барај..."
              autoFocus
            />
          )}
        />
      </Popper>
    </>
  );
};

export default CustomPopupAutocomplete;
