import { FC, Fragment, memo } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";
import DriverCard from "@App/modules/Logistics/components/Drivers/DriverCard";
import {
  selectActiveDrivers,
  selectPausedDrivers,
} from "../../store/features/drivers/driverSelectors";
import DriverSummaryRow from "./DriverSummaryRow";
import Draggable from "../DnD/Draggable";
import Droppable from "../DnD/Droppable";
import Accordion from "../Accordion/Accordion";
import AccordionSummary from "../Accordion/AccordionSummary";
import AccordionDetails from "../Accordion/AccordionDetails";
import { useMemoSelector } from "@shared/hooks";
import { IRoutePointDto } from "@shared/services/orders/dtos/orders/routePointDto";

const useStyles = makeStyles((theme) => ({
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(12rem, 1fr))",
    gap: "8px",
  },
}));

export interface DriversProps {}

const Drivers: FC<DriversProps> = () => {
  const classes = useStyles();

  const activeDrivers = useMemoSelector((state) => selectActiveDrivers(state, true));
  const pausedDrivers = useMemoSelector((state) => selectPausedDrivers(state, true));

  const containers = [
    { id: "activeDrivers", name: "", values: activeDrivers },
    { id: "pausedDrivers", name: "Паузирани", values: pausedDrivers },
  ];

  const filteredContainers = containers.filter((container) => container.values.length > 0);

  return (
    <Accordion square defaultExpanded={true}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <div style={{ display: "flex", flexFlow: "wrap", columnGap: "2rem", alignItems: "center" }}>
          <DriverSummaryRow />
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {filteredContainers.map((container, i) => (
          <Fragment key={container.id}>
            {container.name && <Typography>{container.name}:</Typography>}

            <div className={classes.grid}>
              {container.values.map((driver) => (
                <Draggable key={driver.employeeId} id={`Driver-${driver.employeeId}`}>
                  <Droppable
                    id={`Driver-${driver.employeeId}`}
                    style={{ height: "100%" }}
                    styleDragOver={{
                      backgroundColor: "lightgreen",
                    }}
                  >
                    <DriverCard driver={driver} />
                  </Droppable>
                </Draggable>
              ))}
            </div>

            {i < filteredContainers.length - 1 ? (
              <>
                <br />
                <br />
              </>
            ) : null}
          </Fragment>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default memo(Drivers);
