interface Localization {
  props?: {
    MuiBreadcrumbs?: unknown;
    MuiTablePagination?: unknown;
    MuiRating?: {
      emptyLabelText?: string;
      getLabelText?: (value: number) => string;
    };
    MuiAutocomplete?: {
      clearText?: string;
      closeText?: string;
      loadingText?: React.ReactNode;
      noOptionsText?: React.ReactNode;
      openText?: string;
    };
    MuiAlert?: {
      closeText?: string;
    };
    MuiPagination?: {
      "aria-label"?: string;
      getItemAriaLabel?: (
        type: string,
        page: number,
        selected: boolean
      ) => string | undefined;
    };
  };
}

interface labelDisplayedRowsArgs {
  from: number;
  to: number;
  count: number;
}

export const mkMK: Localization = {
  props: {
    MuiBreadcrumbs: {
      expandText: "Покажи патека",
    },
    MuiTablePagination: {
      backIconButtonText: "Претходна страна",
      labelRowsPerPage: "Редови по страница:",
      labelDisplayedRows: ({ from, to, count }: labelDisplayedRowsArgs) =>
        `${from}-${to} од ${count !== -1 ? count : `повеќе од ${to}`}`,
      nextIconButtonText: "Следна страна",
    },
    MuiRating: {
      getLabelText: (value) => `${value} Ѕвезд${value !== 1 ? "и" : "а"}`,
      emptyLabelText: "Празно",
    },
    MuiAutocomplete: {
      clearText: "Исчисти",
      closeText: "Затвори",
      loadingText: "Се вчитува…",
      noOptionsText: "Нема опции",
      openText: "Отвори",
    },
    MuiAlert: {
      closeText: "Затвори",
    },
    MuiPagination: {
      "aria-label": "Навигација со пагинација",
      getItemAriaLabel: (type, page, selected) => {
        if (type === "page") {
          return `${selected ? "" : "Одете на "}страна ${page}`;
        }
        if (type === "first") {
          return "Одете на првата страница";
        }
        if (type === "last") {
          return "Одете на последната страница";
        }
        if (type === "next") {
          return "Одете на следната страница";
        }
        if (type === "previous") {
          return "Одете на претходната страница";
        }
        return undefined;
      },
    },
  },
};
