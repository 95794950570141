import { Grid, Typography } from "@material-ui/core";
import React, { FC, memo, ReactElement } from "react";

interface TextWithIconProps {
    icon: ReactElement;
    title?: string;
    children: any;
}

const TextWithIcon: FC<TextWithIconProps> = ({ icon, title, children }) => (
    <Grid
        container
        alignItems="center"
        justifyContent="center"
        spacing={1}
        wrap="nowrap"
        title={title}
    >
        <Grid item>{icon}</Grid>
        <Grid xs item>
            <Typography variant="body2" component="span">
                {children}
            </Typography>
        </Grid>
    </Grid>
);

export default memo(TextWithIcon);