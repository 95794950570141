import reverseGeocode from "../../utils/reverseGeocode";
import RoutePointTooltipContent from "./RoutePointTooltipContent";
import RouteTimeTooltipContent from "./RouteTimeTooltipContent";
import { Badge, Divider, Icon, makeStyles } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import { CheckCircle, Flag, Storefront } from "@material-ui/icons";
import { IMapOrder } from "@shared/components/Map/models/models";
import ClickableTooltip from "@shared/components/Tooltip/ClickableTooltip";
import { IOrderTardinessDto } from "@shared/services/orders/dtos/orders/orderTardinessDto";
import { IRoutePointDto } from "@shared/services/orders/dtos/orders/routePointDto";
import { OrderStatus } from "@shared/services/orders/enums/orderStatus";
import { cutString } from "@shared/utils/cutString";
import { getDropOffTime, getPickupTime } from "@shared/utils/orders/orderTime";
import dayjs from "dayjs";
import { FC, memo, useEffect, useRef } from "react";
import { useState } from "react";
import { Subscription, timer } from "rxjs";

export interface Props {
  previousPoint: IRoutePointDto;
  routePoint: IRoutePointDto;
  finish: boolean;
  order: IMapOrder;
  orderTardiness?: IOrderTardinessDto;
}

const useStyles = makeStyles((theme) => ({
  timeLeftContainer: {
    flexGrow: 1,
    alignSelf: "center",
    margin: "0 5px",
    position: "relative",
    minWidth: "75px",
  },
  timeLeftLine: {
    height: "2px",
  },
  timeleftIndicator: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    margin: "auto auto",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    textAlign: "center",
    height: "40px",
    width: "40px",
    borderRadius: "50%",
    backgroundColor: "red",
  },
  routeLocationContainer: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
}));

const RoutePoint: FC<Props> = (props) => {
  const { routePoint, order, previousPoint } = props;

  const classes = useStyles();

  const timeleftSubscription = useRef<Subscription>(null);
  const [timeLeft, setTimeLeft] = useState<string>("-");

  const getIndicatorColor = (): string => {
    if (!routePoint) {
      return "gray";
    }

    if (!order) {
      return "gray";
    }

    if (order.orderStatus === OrderStatus.Delivered) {
      return "gray";
    }

    if (routePoint.isPickup && order.orderStatus === OrderStatus.PickedUp) {
      return "gray";
    }

    if (isNaN(Number(timeLeft))) {
      return "gray";
    } else if (Number(timeLeft) <= 0) {
      return "#ff718a";
    } else {
      return "green";
    }
  };

  const formatInterval = () => {
    if (isNaN(Number(timeLeft))) {
      return timeLeft;
    }
    let interval = [
      Number(timeLeft) < 0
        ? "+" + Math.floor(Math.abs(Number(timeLeft)) / 60).toString()
        : "-" + Math.floor(Math.abs(Number(timeLeft)) / 60).toString(),
      (Math.abs(Number(timeLeft)) % 60).toString(),
    ];
    return interval[0].padStart(2, "00") + ":" + interval[1].padStart(2, "00");
  };

  const calculateOrderTimeLeft = (rp: IRoutePointDto) => {
    let orderTimeLeft = "";

    if (!rp) {
      setTimeLeft("-");
      return;
    }

    if (rp.orderStatus === OrderStatus.Delivered) {
      setTimeLeft(dayjs.utc(rp.onLocationAt).local().format("HH:mm"));
      return;
    }

    if (rp.isPickup && rp.orderStatus === OrderStatus.PickedUp) {
      setTimeLeft(dayjs.utc(rp.onLocationAt).local().format("HH:mm"));
      return;
    }

    if (rp.orderTime) {
      const time = rp.isPickup ? getPickupTime(rp) : getDropOffTime(rp);
      orderTimeLeft = dayjs.utc(time).local().diff(dayjs(), "minutes").toString();
    } else {
      orderTimeLeft = "-";
    }

    setTimeLeft(orderTimeLeft);
  };

  const [addressData, setAddressData] = useState<string>();

  useEffect(() => {
    reverseGeocode(routePoint.latitude, routePoint.longitude)
      .then((address) => setAddressData(address))
      .catch((error) => console.error(error));
  }, [routePoint.latitude, routePoint.longitude]);

  useEffect(() => {
    const timeLeftTimer = timer(0, 1000);

    const unsubscribe = () => {
      if (timeleftSubscription.current) {
        timeleftSubscription.current?.unsubscribe();
        timeleftSubscription.current = null;
      }
    };
    unsubscribe();

    timeleftSubscription.current = timeLeftTimer.subscribe(() => {
      calculateOrderTimeLeft(routePoint);
    });

    return () => unsubscribe();
  }, [routePoint, order]);

  const formatTime = (time: string) => {
    if (!time) return "N/A";
    return dayjs.utc(time, "HH:mm:ss").local().format("LT");
  };

  if (!routePoint) {
    return null;
  }

  return (
    <>
      <ClickableTooltip arrow={true} type="routePoint" placement='right' style={{ display: "flex" }} content={<RouteTimeTooltipContent previousPoint={previousPoint} routePoint={routePoint} />}>
        <div className={classes.timeLeftContainer}>
          <div
            style={{
              backgroundColor: getIndicatorColor(),
            }}
            className={classes.timeleftIndicator}
          >
            <div style={{ fontSize: 11, color: "white" }}>{formatInterval()}</div>
          </div>
          <Divider
            style={{
              backgroundColor: getIndicatorColor(),
            }}
            className={classes.timeLeftLine}
          />
        </div>
      </ClickableTooltip>

      {!routePoint.isPickup ? (
        <div className={classes.routeLocationContainer}>
          <ClickableTooltip arrow={true} type="routePoint" placement='right' content={<RoutePointTooltipContent order={order} routePoint={routePoint} />}>
            <Flag
              style={{
                fontSize: "35px",
                color: getIndicatorColor(),
              }}
            />
          </ClickableTooltip>

          {order.displayOrderNumber ? (
            <span>{order.displayOrderNumber}</span>
            ) : (
              <span>#{cutString(order?.externalOrderNumber, 3)}</span>
              )}

          <span title={routePoint.address}>{addressData}</span>

          {routePoint?.estimatedTimeWindow && (
            <em style={{ whiteSpace: "nowrap" }}>
              {formatTime(routePoint?.estimatedTimeWindow?.from)} -&nbsp;
              {formatTime(routePoint?.estimatedTimeWindow?.till)}
            </em>
          )}

          {props.orderTardiness && (
            <span>{Math.round(props.orderTardiness.tardinessInMinutes)} мин.</span>
          )}
        </div>
      ) : (
        <div className={classes.routeLocationContainer}>
          <ClickableTooltip arrow={true} type="routePoint"  placement='right' content={<RoutePointTooltipContent order={order} routePoint={routePoint} />}>
            <Badge
              badgeContent={
                routePoint.onLocationAt ? (
                  <Icon>
                    <CheckCircle color="primary" style={{ fontSize: 22, color: "#2c8432" }} />
                  </Icon>
                ) : (
                  <></>
                )
              }
              variant="standard"
            >
              <Avatar
                variant="square"
                style={{
                  width: "40px",
                  height: "40px",
                }}
                title={`Нарачката со идентификациски број ${routePoint?.orderId}.`}
                alt={routePoint?.orderId?.toString()}
                src={routePoint?.imgSrc}
              >
                <Storefront />
              </Avatar>
            </Badge>
          </ClickableTooltip>

          {order.displayOrderNumber ? (
            <span>{order.displayOrderNumber}</span>
            ) : (
              <span>#{cutString(order?.externalOrderNumber, 3)}</span>
              )}

          <span title={routePoint.address}>{addressData}</span>

          {routePoint?.estimatedTimeWindow && (
            <em style={{ whiteSpace: "nowrap" }}>
              {formatTime(routePoint?.estimatedTimeWindow?.from)} -&nbsp;
              {formatTime(routePoint?.estimatedTimeWindow?.till)}
            </em>
          )}

          {props.orderTardiness && (
            <span>{Math.round(props.orderTardiness.tardinessInMinutes)} мин.</span>
          )}
        </div>
      )}
    </>
  );
};

export default memo(RoutePoint);
