import ErrorFallback from "../../../../../error";
import useEmployeeSalaryConfigDetailsFormik from "./hooks/useEmployeeSalaryConfigDetailsFormik";
import AddonsForm from "./tabs/AddonsForm";
import BonusesForm from "./tabs/BonusesForm";
import BreaksForm from "./tabs/BreaksForm";
import EmployeeVacationsForm from "./tabs/EmployeeVacationsForm";
import FixedEarningsForm from "./tabs/FixedEarningsForm";
import HolidaysForm from "./tabs/HolidaysForm";
import PeakHoursForm from "./tabs/PeakHoursForm";
import PenaltiesForm from "./tabs/PenaltiesForm";
import PerformanceEarningsForm from "./tabs/PerformanceEarningsForm";
import SickDaysForm from "./tabs/SickDaysForm";
import { Button, Grid, Tab, Tabs, Typography, createStyles, makeStyles } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import SaveIcon from "@material-ui/icons/Save";
import DebounceLoading from "@shared/components/Debounce/DebounceLoading";
import Loading from "@shared/components/Loading/Loading";
import { TabPanel } from "@shared/components/TabPanel/TabPanel";
import { getEmployee } from "@shared/services/employees/employees.service";
import {
  getEmployeeSalaryByEmployeeId,
  updateEmployeeSalary,
} from "@shared/services/salary-configuration/employee-salary-configuration.service";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { FC, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const useStyles = makeStyles((theme) =>
  createStyles({
    content: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
  })
);

enum EmployeeSalaryConfigTabs {
  FixedEarnings = 0,
  PerformanceEarnings = 1,
  Breaks = 2,
  Bonuses = 3,
  Penalties = 4,
  Addons = 5,
  PeakHours = 6,
  EmployeeVacations = 7,
  Holidays = 8,
  SickDays = 9,
}

type IEmployeeSalaryConfigParams = {
  employeeId: string;
};

const EmployeeSalaryConfig: FC = () => {
  const params = useParams<IEmployeeSalaryConfigParams>();
  const employeeId = useMemo(() => Number(params.employeeId), [params]);
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [tab, setTab] = useState<EmployeeSalaryConfigTabs>(EmployeeSalaryConfigTabs.FixedEarnings);

  const employeeQuery = useQuery(["getEmployee", employeeId], () => getEmployee(employeeId));

  const employeeSalaryConfigQuery = useQuery(
    ["getEmployeeSalaryByEmployeeId", employeeId],
    () => getEmployeeSalaryByEmployeeId(employeeId),
    {
      onSuccess: (data) => {
        formik.setValues(data);
      },
    }
  );

  const { mutate: updateEmployeeSalaryMutate, isLoading: isLoadingUpdateEmployeeSalary } =
    useMutation(updateEmployeeSalary, {
      onSuccess: () => {
        enqueueSnackbar("Конфигурацијата е успешно зачувана.");
      },
      onError: () => {
        enqueueSnackbar(
          "Се случи грешка. Ве молиме обидете се повторно или контактирајте го тимот за поддршка."
        );
      },
    });

  const formik = useEmployeeSalaryConfigDetailsFormik({
    onSubmit: (values) => updateEmployeeSalaryMutate(values),
  });

  if (employeeQuery.isLoading || employeeSalaryConfigQuery.isLoading) return <Loading />;
  if (employeeQuery.isError || employeeSalaryConfigQuery.isError)
    return <ErrorFallback error={employeeQuery.error ?? employeeSalaryConfigQuery.error} />;

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={4}>
        <Grid item>
          <Button onClick={() => navigate("/app/employees")}>
            <ArrowBackIosIcon />
          </Button>
        </Grid>
        <Grid item>
          <Typography variant="h5">
            Детална конфигурација на вработен: &nbsp;
            <em>
              {employeeQuery.data.firstName} {employeeQuery.data.lastName}
            </em>
          </Typography>
        </Grid>
        <Grid item>
          <DebounceLoading loading={isLoadingUpdateEmployeeSalary}>
            <Button variant="contained" color="primary" onClick={formik.submitForm}>
              <SaveIcon color="action" /> &nbsp; Зачувај промени
            </Button>
          </DebounceLoading>
        </Grid>
      </Grid>

      <Grid container spacing={2} className={classes.content}>
        <Grid item xs={12}>
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            value={tab}
            onChange={(_, newTab: EmployeeSalaryConfigTabs) => setTab(newTab)}
          >
            <Tab
              value={EmployeeSalaryConfigTabs.FixedEarnings}
              label="Фиксна заработка и одбитоци"
            />
            <Tab
              value={EmployeeSalaryConfigTabs.PerformanceEarnings}
              label="Заработка по перформанси"
            />
            <Tab value={EmployeeSalaryConfigTabs.Breaks} label="Пауза" />
            <Tab value={EmployeeSalaryConfigTabs.Bonuses} label="Бонуси" />
            <Tab value={EmployeeSalaryConfigTabs.Penalties} label="Пенали" />
            <Tab value={EmployeeSalaryConfigTabs.Addons} label="Додатна конфигурација" />
            {/* <Tab value={EmployeeSalaryConfigTabs.PeakHours} label="Peak hours" /> */}
            <Tab value={EmployeeSalaryConfigTabs.EmployeeVacations} label="Одмор за вработени" />
            {/* <Tab value={EmployeeSalaryConfigTabs.Holidays} label="Празници" /> */}
            <Tab value={EmployeeSalaryConfigTabs.SickDays} label="Боледување" />
          </Tabs>
        </Grid>

        <Grid item>
          <TabPanel value={tab} index={EmployeeSalaryConfigTabs.FixedEarnings}>
            <FixedEarningsForm formik={formik} />
          </TabPanel>

          <TabPanel value={tab} index={EmployeeSalaryConfigTabs.PerformanceEarnings}>
            <PerformanceEarningsForm formik={formik} />
          </TabPanel>

          <TabPanel value={tab} index={EmployeeSalaryConfigTabs.Breaks}>
            <BreaksForm formik={formik} />
          </TabPanel>

          <TabPanel value={tab} index={EmployeeSalaryConfigTabs.Bonuses}>
            <BonusesForm formik={formik} />
          </TabPanel>

          <TabPanel value={tab} index={EmployeeSalaryConfigTabs.Penalties}>
            <PenaltiesForm formik={formik} />
          </TabPanel>

          <TabPanel value={tab} index={EmployeeSalaryConfigTabs.Addons}>
            <AddonsForm formik={formik} />
          </TabPanel>

          <TabPanel value={tab} index={EmployeeSalaryConfigTabs.PeakHours}>
            <PeakHoursForm formik={formik} />
          </TabPanel>

          <TabPanel value={tab} index={EmployeeSalaryConfigTabs.EmployeeVacations}>
            <EmployeeVacationsForm formik={formik} />
          </TabPanel>

          <TabPanel value={tab} index={EmployeeSalaryConfigTabs.Holidays}>
            <HolidaysForm formik={formik} />
          </TabPanel>

          <TabPanel value={tab} index={EmployeeSalaryConfigTabs.SickDays}>
            <SickDaysForm formik={formik} />
          </TabPanel>
        </Grid>
      </Grid>
    </form>
  );
};

export default EmployeeSalaryConfig;
