import { FC, memo, MouseEvent, useState } from "react";
import { selectDriverById } from "../../store/features/drivers/driverSelectors";
import Card from "../Card/Card";
import { Check, NavigateNext as NavigateNextIcon } from "@material-ui/icons";
import Draggable from "../DnD/Draggable";
import Droppable from "../DnD/Droppable";
import CompactDriverCard from "./CompactDriverCard";
import DriversRouteDetails from "./DriversRouteDetails";
import RoutePoint from "./RoutePoint";
import { Typography, makeStyles } from "@material-ui/core";
import { useMemoSelector } from "@shared/hooks";
import { IDriverRouteResponseDto } from "@shared/services/orders/dtos/orders/driverRouteResponseDto";
import { IRoutePointDto } from "@shared/services/orders/dtos/orders/routePointDto";
const useStyles = makeStyles((theme) => ({
  container: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
  },
  driverCard: {
    height: theme.spacing(4),
  },
  driversRouteContainer: {
    display: "flex",
    flexFlow: "wrap",
    gap: "0.2rem",
    rowGap: "0.5rem",
    alignItems: "center",
  },
  driversRoutePoint: {
    display: "flex",
    gap: "0.2rem",
    alignItems: "center",
    height: theme.spacing(4),
  },
}));

export interface DriversRouteProps {
  driversRoute: IDriverRouteResponseDto;
}

const isRoutePointCompleted = (routePoint: IRoutePointDto) => {
  return routePoint.onLocationAt !== null;
};

const DriversRoute: FC<DriversRouteProps> = ({ driversRoute }) => {
  const classes = useStyles();

  const [isDetailsPanelOpen, setIsDetailsPanelOpen] = useState(false);

  const driver = useMemoSelector((state) => selectDriverById(state, driversRoute.employeeId));

  const activeRoutePoint = driversRoute.routePoints.find((rp) => rp.onLocationAt === null);

  const handleDriverClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();

    setIsDetailsPanelOpen((state) => !state);
  };



  return (
    <>
      <div className={classes.container} onClick={handleDriverClick}>
        <div className={classes.driverCard}>
          
          <Draggable id={`DriversRoute-${driversRoute.employeeId}`}>
            <Droppable
              id={`DriversRoute-${driversRoute.employeeId}`}
              styleDragOver={{
                backgroundColor: "lightgreen",

              }}
            >
              {driver ? (
                <CompactDriverCard driver={driver} />
              ) : (
                <Card background="danger"
                >
                  <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                    <Typography component="span">
                      {driversRoute.firstName} {driversRoute.lastName?.[0] || ""}.
                    </Typography>
                  </div>
                </Card>
              )}
            </Droppable>
          </Draggable>
        </div>

    <div className={classes.driversRouteContainer}>
      {driversRoute.routePoints.map((routePoint) => (
       <>
        <div key={routePoint.id} className={classes.driversRoutePoint}>
        {routePoint.id === activeRoutePoint?.id ? ( //&& routePoint.isDispatched ? (
                <NavigateNextIcon color="primary" />
              ) : isRoutePointCompleted(routePoint) ? (
                <Check color="secondary" />
              ) : routePoint.isDispatched ? (
                <NavigateNextIcon />
              ) : (
                <NavigateNextIcon color="disabled" />
              )}

              <RoutePoint routePoint={routePoint} />
            </div>
            </>))}
        </div>
      </div>

      <DriversRouteDetails driversRoute={driversRoute} open={isDetailsPanelOpen} />
    </>
  );
};

export default memo(DriversRoute);
