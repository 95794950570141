import { withMapData } from "../Logistics/components/MapProvider";
import { selectNumberOfActiveDrivers } from "../Logistics/store/features/drivers/driverSelectors";
import { selectAllDriversRoutes } from "../Logistics/store/features/driversRoutes/driversRoutesSelectors";
import { orderDeleted } from "../Logistics/store/features/orders/orderActions";
import {
  selectAllOrders,
  selectNumberOfActiveOrders,
} from "../Logistics/store/features/orders/orderSelectors";
import AlgorithmControl from "./components/AlgorithmControl";
import DispatcherShiftControl from "./components/DispatcherShift/DispatcherShiftControl";
import DriversPanel from "./components/DriversPanel";
import DriversMap from "./components/Map/DriversMap";
import FullRoutesDialog from "./components/Map/FullRoutesDialog";
import CreateOrderFormDialog from "./components/Orders/CreateOrderFormDialog";
import OrdersPanel from "./components/OrdersPanel";
import StatsOverview from "./components/StatsOverview/StatsOverview";
import { Button, Divider, Hidden, IconButton, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Theme, withStyles } from "@material-ui/core/styles";
import { DriveEta, Fastfood, LinearScale, Map as MapIcon } from "@material-ui/icons";
import SignalRConnectionChangeListener from "@shared/components/SignalRConnectionChangeListener/SignalRConnectionChangeListener";
import { selectIsLogisticsInitializing } from "@shared/store/commonSelectors";
import { Map } from "leaflet";
import { cloneDeep } from "lodash";
import { ChangeEvent, Component, RefObject, createRef } from "react";
import { ConnectedProps, connect } from "react-redux";

const mapStateToProps = (state: IGlobalState) => {
  return {
    orders: selectAllOrders(state),
    driversRoutes: selectAllDriversRoutes(state),
    numberOfDrivers: selectNumberOfActiveDrivers(state),
    numberOfOrders: selectNumberOfActiveOrders(state),
    isInitializing: selectIsLogisticsInitializing(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onOrderDeleted: (orderId: number) => {
      dispatch(orderDeleted(orderId));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const styles = (theme: Theme) => ({
  paper: {
    width: "100%",
    height: "min(100%, 95vh)",
  },
  container: {
    height: "100%",
  },
  divider: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
});

type MaterialStyle<S> = {
  classes: Record<keyof S, string>;
};

type DriversTrackingPageProps = MaterialStyle<ReturnType<typeof styles>> & PropsFromRedux;

type OrderFilterType = "selectedOrderId" | "unassignedOrders";
type DriverFilterType =
  | "selectedDriverId"
  | "lostDrivers"
  | "lowBatteryDrivers"
  | "pausedDrivers"
  | "busyDrivers"
  | "freeDrivers";
type DriversRoutesFilterType =
  | "completelyLateOrders"
  | "latePickUpOrders"
  | "lateDropOffOrders"
  | "normalOrders";
export type FilterType = OrderFilterType | DriverFilterType | DriversRoutesFilterType;

export type Filter<T> = {
  mode: "disabled" | "groups" | "selectedIds";
  selectedIds?: number[];
  groups: T;
};

export interface IFilterState {
  orders: Filter<{
    unassignedOrders: boolean;
  }>;
  drivers: Filter<{
    lostDrivers: boolean;
    lowBatteryDrivers: boolean;
    pausedDrivers: boolean;
    busyDrivers: boolean;
    freeDrivers: boolean;
  }>;
  driversRoutes: Filter<{
    completelyLateOrders: boolean;
    latePickUpOrders: boolean;
    lateDropOffOrders: boolean;
    normalOrders: boolean;
  }>;
}

export interface DriversTrackingPageState {
  ordersSearchString: string;
  addNewOrderDialogOpened: boolean;

  isOrdersPanelHidden: boolean;
  isMapPanelHidden: boolean;
  isDriversPanelHidden: boolean;
  isRoutesModalOpen: boolean;

  filters: IFilterState;
}

class DriversTrackingPage extends Component<DriversTrackingPageProps, DriversTrackingPageState> {
  state: DriversTrackingPageState = {
    ordersSearchString: "",
    addNewOrderDialogOpened: false,
    isOrdersPanelHidden: false,
    isMapPanelHidden: false,
    isDriversPanelHidden: false,
    isRoutesModalOpen: false,
    filters: {
      orders: {
        mode: "disabled",
        selectedIds: [],
        groups: {
          unassignedOrders: false,
        },
      },
      drivers: {
        mode: "disabled",
        selectedIds: [],
        groups: {
          lostDrivers: false,
          lowBatteryDrivers: false,
          pausedDrivers: false,
          busyDrivers: false,
          freeDrivers: false,
        },
      },
      driversRoutes: {
        mode: "disabled",
        groups: {
          completelyLateOrders: false,
          lateDropOffOrders: false,
          latePickUpOrders: false,
          normalOrders: false,
        },
      },
    },
  };

  mapRef: RefObject<Map>;

  constructor(props: DriversTrackingPageProps) {
    super(props);
    this.mapRef = createRef<Map>();
  }

  handleNewOrderDialogOpen = () => {
    this.setState({ addNewOrderDialogOpened: true });
  };

  handleNewOrderDialogClose = () => {
    this.setState({ addNewOrderDialogOpened: false });
  };

  handleOrderDeleted = (orderId: number) => {
    this.props.onOrderDeleted?.(orderId);

    // Clear filters
    const filters = this.state.filters;
    filters.orders.selectedIds = filters.orders.selectedIds.filter((x) => x !== orderId);

    // Clear all order group filters
    Object.keys(filters.orders.groups).forEach((group) => {
      filters.orders.groups[group] = false;
    });

    const isActiveOrderIds = filters.orders.selectedIds.length > 0;
    filters.orders.mode = isActiveOrderIds ? "selectedIds" : "disabled";

    this.setState({
      filters: filters,
    });
  };

  private handleOrdersSearch = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ ordersSearchString: event.currentTarget.value });
  };

  private filterOrdersSearch = (string: string) => {
    const cleanSearchString = this.state.ordersSearchString.trim().toLowerCase();
    const cleanString = string.trim().toLowerCase();
    return cleanString.includes(cleanSearchString);
  };

  private handleOrdersPanelToggle = () => {
    this.setState(
      (state) => ({ isOrdersPanelHidden: !state.isOrdersPanelHidden }),
      () => {
        this.mapRef.current?.invalidateSize(false);
      }
    );
  };

  private handleMapPanelToggle = () => {
    this.setState(
      (state) => ({ isMapPanelHidden: !state.isMapPanelHidden }),
      () => {
        this.mapRef.current?.invalidateSize(false);
      }
    );
  };

  private handleDriversPanelToggle = () => {
    this.setState(
      (state) => ({ isDriversPanelHidden: !state.isDriversPanelHidden }),
      () => {
        this.mapRef.current?.invalidateSize(false);
      }
    );
  };

  private handleFilterChange = (type: FilterType, isEnabled: boolean, selectedId?: number) => {
    const filters = cloneDeep(this.state.filters);
    switch (type) {
      case "selectedOrderId": {
        const exists = filters.orders.selectedIds.includes(selectedId);

        if (isEnabled && !exists) {
          filters.orders.selectedIds.push(selectedId);
        } else {
          filters.orders.selectedIds = filters.orders.selectedIds.filter(
            (orderId) => orderId !== selectedId
          );
        }

        // Reset group filters
        Object.keys(filters.orders.groups).forEach((key) => (filters.orders.groups[key] = false));

        // Reset drivers routes filters
        Object.keys(filters.driversRoutes.groups).forEach(
          (key) => (filters.driversRoutes.groups[key] = false)
        );

        break;
      }
      case "unassignedOrders": {
        filters.orders.groups[type] = isEnabled;

        // Clear Id filters
        filters.orders.selectedIds = [];

        // Reset drivers routes filters
        Object.keys(filters.driversRoutes.groups).forEach(
          (key) => (filters.driversRoutes.groups[key] = false)
        );

        break;
      }
      case "selectedDriverId": {
        const exists = filters.drivers.selectedIds.includes(selectedId);

        if (isEnabled && !exists) {
          filters.drivers.selectedIds.push(selectedId);
        } else {
          filters.drivers.selectedIds = filters.drivers.selectedIds.filter(
            (driverId) => driverId !== selectedId
          );
        }

        // Reset group filters
        Object.keys(filters.drivers.groups).forEach((key) => (filters.drivers.groups[key] = false));

        // Reset drivers routes filters
        Object.keys(filters.driversRoutes.groups).forEach(
          (key) => (filters.driversRoutes.groups[key] = false)
        );

        break;
      }
      case "lostDrivers":
      case "lowBatteryDrivers":
      case "pausedDrivers":
      case "busyDrivers":
      case "freeDrivers": {
        filters.drivers.groups[type] = isEnabled;

        // Clear Id filters
        filters.drivers.selectedIds = [];

        // Reset drivers routes filters
        Object.keys(filters.driversRoutes.groups).forEach(
          (key) => (filters.driversRoutes.groups[key] = false)
        );

        break;
      }
      case "completelyLateOrders":
      case "latePickUpOrders":
      case "lateDropOffOrders":
      case "normalOrders": {
        // Reset order filters
        Object.keys(filters.orders.groups).forEach((key) => (filters.orders.groups[key] = false));

        filters.orders.selectedIds = [];

        // Reset driver filters
        Object.keys(filters.drivers.groups).forEach((key) => (filters.drivers.groups[key] = false));

        filters.drivers.selectedIds = [];

        // Toggle drivers routes group
        filters.driversRoutes.groups[type] = isEnabled;

        break;
      }
      default:
        console.error(`Unknown filter type: ${type}`);
    }

    // Calculate isActive
    const isActiveOrderGroups = Object.values(filters.orders.groups).some(
      (filter) => filter === true
    );
    const isActiveOrderIds = filters.orders.selectedIds.length > 0;
    filters.orders.mode = isActiveOrderGroups
      ? "groups"
      : isActiveOrderIds
      ? "selectedIds"
      : "disabled";

    const isActiveDriverGroups = Object.values(filters.drivers.groups).some(
      (filter) => filter === true
    );
    const isActiveDriverIds = filters.drivers.selectedIds.length > 0;
    filters.drivers.mode = isActiveDriverGroups
      ? "groups"
      : isActiveDriverIds
      ? "selectedIds"
      : "disabled";

    const isActiveDriverRoutesGroups = Object.values(filters.driversRoutes.groups).some(
      (filter) => filter === true
    );
    filters.driversRoutes.mode = isActiveDriverRoutesGroups ? "groups" : "disabled";

    this.setState({ filters: filters });
  };

  render() {
    const {
      filters,
      isOrdersPanelHidden,
      isMapPanelHidden,
      isDriversPanelHidden,
      ordersSearchString,
    } = this.state;
    const { classes, orders, driversRoutes, numberOfDrivers, numberOfOrders, isInitializing } =
      this.props;

    const mapPanelSize = isOrdersPanelHidden && isDriversPanelHidden ? 12 : true;

    return (
      <>
        <Paper className={classes.paper}>
          <Grid container className={classes.container} direction="column">
            {isInitializing ? (
              <Grid item xs={12}>
                <Typography variant="h6" align="center">
                  Се вчитува
                </Typography>
              </Grid>
            ) : (
              <>
                <Grid item container>
                  <Grid xs item>
                    <StatsOverview
                      numberOfOrders={numberOfOrders}
                      numberOfDrivers={numberOfDrivers}
                    />
                  </Grid>

                  <Grid item>
                    <DispatcherShiftControl />
                  </Grid>

                  <Grid item>
                    <Divider orientation="vertical" className={classes.divider} />
                  </Grid>

                  <Grid item>
                    <Button
                      size="small"
                      variant="outlined"
                      startIcon={<LinearScale color="inherit" />}
                      onClick={() => this.setState({ isRoutesModalOpen: true })}
                    >
                      Рути
                    </Button>
                  </Grid>

                  <Grid item>
                    <Divider orientation="vertical" className={classes.divider} />
                  </Grid>

                  <Grid item>
                    <AlgorithmControl />
                  </Grid>

                  <Grid item>
                    <Divider orientation="vertical" className={classes.divider} />
                  </Grid>

                  <Grid item>
                    <IconButton size="small" onClick={this.handleOrdersPanelToggle}>
                      <Fastfood color={isOrdersPanelHidden ? "inherit" : "secondary"} />
                    </IconButton>
                  </Grid>

                  <Grid item>
                    <IconButton size="small" onClick={this.handleMapPanelToggle}>
                      <MapIcon color={isMapPanelHidden ? "inherit" : "secondary"} />
                    </IconButton>
                  </Grid>

                  <Grid item>
                    <IconButton size="small" onClick={this.handleDriversPanelToggle}>
                      <DriveEta color={isDriversPanelHidden ? "inherit" : "secondary"} />
                    </IconButton>
                  </Grid>
                </Grid>

                <Grid xs item container style={{ overflow: "auto" }}>
                  <Hidden xlDown={isOrdersPanelHidden}>
                    <OrdersPanel
                      orders={orders as any}
                      driversRoutes={driversRoutes}
                      searchValue={ordersSearchString}
                      filters={{
                        orders: this.state.filters.orders,
                        driversRoutes: this.state.filters.driversRoutes,
                      }}
                      isMapPanelHidden={isMapPanelHidden}
                      onFilterChange={this.handleFilterChange}
                      onSearchChange={this.handleOrdersSearch}
                      onOrderDeleted={this.handleOrderDeleted}
                      onNewOrderClick={this.handleNewOrderDialogOpen}
                    />
                  </Hidden>

                  <Hidden xlDown={isMapPanelHidden}>
                    <Grid item xs={12} md={mapPanelSize} className={classes.container}>
                      <DriversMap
                        forwardRef={this.mapRef}
                        filters={filters}
                        orders={orders}
                        driverRoutes={driversRoutes}
                      />
                    </Grid>
                  </Hidden>

                  <Hidden xlDown={isDriversPanelHidden}>
                    <DriversPanel
                      isMapPanelHidden={isMapPanelHidden}
                      filters={this.state.filters.drivers}
                      onFilterChange={this.handleFilterChange}
                    />
                  </Hidden>
                </Grid>
              </>
            )}
          </Grid>
        </Paper>

        {this.state.isRoutesModalOpen ? (
          <FullRoutesDialog onClose={() => this.setState({ isRoutesModalOpen: false })} />
        ) : null}

        {this.state.addNewOrderDialogOpened ? (
          <CreateOrderFormDialog onClose={this.handleNewOrderDialogClose} />
        ) : null}

        <SignalRConnectionChangeListener />
      </>
    );
  }
}

// @ts-ignore
const StyledDriversTrackingPage = withStyles(styles)(DriversTrackingPage);
export default withMapData(connector(StyledDriversTrackingPage));
