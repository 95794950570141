import {
  Button,
  CardActions,
  CardContent,
  TextField,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { ICompanyDto } from "@shared/services/settings/dtos/companies/companyDto";
import { useFormik } from "formik";
import { FC } from "react";
import * as yup from "yup";

const useStyles = makeStyles((theme) =>
  createStyles({
    form: {
      display: "flex",
      flexDirection: "column",
    },
    actions: {
      display: "flex",
      justifyContent: "end",
    },
  })
);

interface CompanyCardContentEditProps {
  company?: ICompanyDto;
  submitLabel?: string;
  onSubmit: (values: ICompanyDto) => void;
}

const CompanyCardContentEdit: FC<CompanyCardContentEditProps> = ({
  company,
  submitLabel = "Измени",
  onSubmit,
}) => {
  const classes = useStyles();
  const formik = useFormik<ICompanyDto>({
    initialValues: {
      id: company?.id ?? null,
      name: company?.name ?? "",
      abbreviation: company?.abbreviation ?? "",
      address: company?.address ?? "",
      email: company?.email ?? "",
      bankAccount: company?.bankAccount ?? "",
      vatId: company?.vatId ?? "",
    },
    validationSchema: yup.object({
      id: yup.number().nullable().notRequired(),
      name: yup.string().required("Задолжително"),
      abbreviation: yup.string().required("Задолжително"),
      address: yup.string().required("Задолжително"),
      email: yup.string().required("Задолжително").email("Невалидна емаил адреса"),
      bankAccount: yup.string().required("Задолжително"),
      vatId: yup.string().required("Задолжително"),
    }),
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  return (
    <>
      <CardContent>
        <form className={classes.form}>
          <TextField
            id="name"
            name="name"
            label="Име"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
          <TextField
            id="abbreviation"
            name="abbreviation"
            label="Кретенка"
            value={formik.values.abbreviation}
            onChange={formik.handleChange}
            error={formik.touched.abbreviation && Boolean(formik.errors.abbreviation)}
            helperText={formik.touched.abbreviation && formik.errors.abbreviation}
          />
          <TextField
            id="address"
            name="address"
            label="Адреса"
            value={formik.values.address}
            onChange={formik.handleChange}
            error={formik.touched.address && Boolean(formik.errors.address)}
            helperText={formik.touched.address && formik.errors.address}
          />
          <TextField
            id="email"
            name="email"
            label="Емаил"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <TextField
            id="bankAccount"
            name="bankAccount"
            label="Банкарска сметка"
            value={formik.values.bankAccount}
            onChange={formik.handleChange}
            error={formik.touched.bankAccount && Boolean(formik.errors.bankAccount)}
            helperText={formik.touched.bankAccount && formik.errors.bankAccount}
          />
          <TextField
            id="vatId"
            name="vatId"
            label="Даночен број"
            value={formik.values.vatId}
            onChange={formik.handleChange}
            error={formik.touched.vatId && Boolean(formik.errors.vatId)}
            helperText={formik.touched.vatId && formik.errors.vatId}
          />
        </form>
      </CardContent>
      <CardActions className={classes.actions}>
        <Button variant="contained" color="primary" onClick={formik.submitForm}>
          {submitLabel}
        </Button>
      </CardActions>
    </>
  );
};

export default CompanyCardContentEdit;
