// Converts from degrees to radians.
const toRadians = (degrees: number) => {
  return (degrees * Math.PI) / 180;
};

// Converts from radians to degrees.
const toDegrees = (radians: number) => {
  return (radians * 180) / Math.PI;
};

/**
 * Calculates the distance and bearing between two points using the Haversine formula.
 * @param start The start geo location object
 * @param end The end geo location object
 * @returns The distance in kilometers and the bearing in degrees
 */
export const calculateDistanceAndBearing = (
  start: { latitude: number; longitude: number },
  end: { latitude: number; longitude: number }
) => {
  const startLatitude = toRadians(start.latitude);
  const startLongitude = toRadians(start.longitude);
  const endLatitude = toRadians(end.latitude);
  const endLongitude = toRadians(end.longitude);

  const deltaLongitude = endLongitude - startLongitude;
  const deltaLatitude = endLatitude - startLatitude;

  const a =
    Math.pow(Math.sin(deltaLatitude / 2), 2) +
    Math.cos(startLatitude) * Math.cos(endLatitude) * Math.pow(Math.sin(deltaLongitude / 2), 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = 6371 * c; // Convert to kilometers.
  const bearing = toDegrees(Math.atan2(deltaLongitude, deltaLatitude));

  return { distance, bearing };
};
