import { FC, ChangeEvent, useMemo, useState, memo } from "react";
import { makeStyles } from "@material-ui/core";
import { Grid, InputAdornment, TextField } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import {
  isDriverFree,
  isDriverBusy,
  isLowBattery,
  isDriverPaused,
} from "@shared/utils/drivers/driverStatus";
import DriversList from "./Drivers/DriversList";
import { IFilterState, FilterType } from "../Map";
import { selectAllDriversWithoutGeolocation, selectLostLocationDrivers } from "@App/modules/Logistics/store/features/drivers/driverSelectors";
import { useMemoSelector } from "@shared/hooks";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    padding: "0.5rem",
  },
}));

export interface DriversPanelProps {
  filters: IFilterState["drivers"];
  isMapPanelHidden?: boolean;
  onFilterChange: (filterType: FilterType, checked: boolean, driverId?: number) => void
}

const DriversPanel: FC<DriversPanelProps> = ({
  filters,
  isMapPanelHidden,
  onFilterChange,
}) => {
  const classes = useStyles();

  const [searchValue, setSearchValue] = useState("");
  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.currentTarget.value);
  };

  const drivers = useMemoSelector((state) => selectAllDriversWithoutGeolocation(state));
  const driverIdsWithLostLocation = useMemoSelector((state) =>
    selectLostLocationDrivers(state).map((driver) => driver.employeeId)
  );

  const filteredDrivers = useMemo(
    () =>
      drivers.filter((driver) => {
        const driverName = `${driver.firstName} ${driver.lastName}`;

        const cleanSearchString = searchValue.trim().toLowerCase();
        const cleanString = driverName.trim().toLowerCase();
        return cleanString.includes(cleanSearchString);
      }),
    [drivers, searchValue]
  );

  const lostDrivers = filteredDrivers.filter((x) => driverIdsWithLostLocation.includes(x.employeeId));
  const lowBatteryDrivers = filteredDrivers.filter((x) => isLowBattery(x));
  const pausedDrivers = filteredDrivers.filter((x) => isDriverPaused(x));
  const busyDrivers = filteredDrivers.filter((x) => isDriverBusy(x));
  const freeDrivers = filteredDrivers.filter((x) => isDriverFree(x));

  const selectedDriverIds = filters.selectedIds;

  return (
    <Grid item xs={12} md={isMapPanelHidden ? 4 : 2} className={classes.container}>
      <TextField
        variant="outlined"
        margin="dense"
        placeholder="Барај..."
        value={searchValue}
        onChange={handleSearchChange}
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
      />

      {lostDrivers.length > 0 && (
        <DriversList
          expanded={true}
          title="Изгубена локација"
          key="lost_drivers_list"
          drivers={lostDrivers}
          emptyStateText="Возачи со изгубена локација"
          selectedDriverIds={selectedDriverIds}
          onDriverSelect={(driverId, isChecked) => onFilterChange("selectedDriverId", isChecked, driverId)}
          isGroupSelected={filters?.groups.lostDrivers}
          onGroupSelect={(isChecked) => onFilterChange("lostDrivers", isChecked)}
        />
      )}

      {lowBatteryDrivers.length > 0 && (
        <DriversList
          expanded={true}
          title="Слаба батерија"
          key="low_battery_drivers_list"
          drivers={lowBatteryDrivers}
          emptyStateText="Возачи со слаба батерија"
          selectedDriverIds={selectedDriverIds}
          onDriverSelect={(driverId, isChecked) => onFilterChange("selectedDriverId", isChecked, driverId)}
          isGroupSelected={filters?.groups.lowBatteryDrivers}
          onGroupSelect={(isChecked) => onFilterChange("lowBatteryDrivers", isChecked)}
        />
      )}

      {pausedDrivers.length > 0 && (
        <DriversList
          expanded={true}
          title="На пауза"
          key="paused_drivers_list"
          drivers={pausedDrivers}
          emptyStateText="Моментално нема возачи на пауза"
          selectedDriverIds={selectedDriverIds}
          onDriverSelect={(driverId, isChecked) => onFilterChange("selectedDriverId", isChecked, driverId)}
          isGroupSelected={filters?.groups.pausedDrivers}
          onGroupSelect={(isChecked) => onFilterChange("pausedDrivers", isChecked)}
        />
      )}

      {busyDrivers.length > 0 && (
        <DriversList
          expanded={true}
          title="Зафатени"
          key="busy_drivers_list"
          emptyStateText="Моментално нема зафатени возачи"
          drivers={busyDrivers}
          selectedDriverIds={selectedDriverIds}
          onDriverSelect={(driverId, isChecked) => onFilterChange("selectedDriverId", isChecked, driverId)}
          isGroupSelected={filters?.groups.busyDrivers}
          onGroupSelect={(isChecked) => onFilterChange("busyDrivers", isChecked)}
        />
      )}

      <DriversList
        expanded={true}
        title="Слободни"
        key="free_drivers_list"
        emptyStateText="Моментално нема слободни возачи"
        drivers={freeDrivers}
        selectedDriverIds={selectedDriverIds}
        onDriverSelect={(driverId, isChecked) => onFilterChange("selectedDriverId", isChecked, driverId)}
        isGroupSelected={filters?.groups.freeDrivers}
        onGroupSelect={(isChecked) => onFilterChange("freeDrivers", isChecked)}
      />
    </Grid>
  );
};

export default memo(DriversPanel);
