export enum OrderStatus {
  WaitingForProcessing = 1,
  Processing = 2,
  WaitingForAccept = 3,
  Assigned = 4,
  PickedUp = 5,
  Delivered = 6,
  HandleByDispatch = 7,
  RejectByDriver = 8,
  ResetByDispatch = 9,
  Paused = 10,
  PreAssigned = 11,
  Removed = 12,
  RejectByCustomer = 13,
  WaitingForAcceptByCustomer = 14,
  AutoRejectedForCustomer = 15,
  UnsuccessfulPayment = 16,
}
