import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { IPayrollDaySummaryDto } from "@shared/services/payroll/dtos/payrollDaySummaryDto";
import { FC } from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      minWidth: theme.spacing(72),
      minHeight: theme.spacing(36),
      margin: theme.spacing(1),
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.grey[100],
    },
    contentItem: {
      display: "flex",
      justifyContent: "space-between",
      paddingTop: theme.spacing(1),
      paddingBotton: theme.spacing(1),
    },
  })
);

interface PayrollDaySummaryCardProps {
  payrollDaySummary: IPayrollDaySummaryDto;
}

const PayrollDaySummaryCard: FC<PayrollDaySummaryCardProps> = ({ payrollDaySummary }) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent>
        <Box>
          <Typography variant="h5">Заработка по перформанси</Typography>

          <Box className={classes.contentItem}>
            <Typography>По час</Typography>
            <Typography>
              {payrollDaySummary.ratePerHour} <em>мкд</em>
            </Typography>
          </Box>

          <Box className={classes.contentItem}>
            <Typography>По пикап</Typography>
            <Typography>
              {payrollDaySummary.ratePerPickUp} <em>мкд</em>
            </Typography>
          </Box>

          <Box className={classes.contentItem}>
            <Typography>По достава</Typography>
            <Typography>
              {payrollDaySummary.ratePerDropOff} <em>мкд</em>
            </Typography>
          </Box>

          <Box className={classes.contentItem}>
            <Typography>КМ (PU)</Typography>
            <Typography>
              {payrollDaySummary.ratePerKmToPickUp} <em>мкд</em>
            </Typography>
          </Box>

          <Box className={classes.contentItem}>
            <Typography>КМ (DO)</Typography>
            <Typography>
              {payrollDaySummary.ratePerKmToDropOff} <em>мкд</em>
            </Typography>
          </Box>

          <Box className={classes.contentItem}>
            <Typography>Стапка по пикап (по минута)</Typography>
            <Typography>
              {payrollDaySummary.ratePerMinuteToPickUp} <em>мкд</em>
            </Typography>
          </Box>

          <Box className={classes.contentItem}>
            <Typography>Стапка по достава (по минута)</Typography>
            <Typography>
              {payrollDaySummary.ratePerMinuteToDropOff} <em>мкд</em>
            </Typography>
          </Box>

          <Box className={classes.contentItem}>
            <Typography>Времетраење на пауза</Typography>
            <Typography>
              {payrollDaySummary.breakDuration} <em>мин</em>
            </Typography>
          </Box>

          <Box className={classes.contentItem}>
            <Typography>Исплата за пауза</Typography>
            <Typography>
              {payrollDaySummary.breakPay} <em>мкд</em>
            </Typography>
          </Box>
        </Box>

        <Divider style={{ marginTop: 24, marginBottom: 24, backgroundColor: "#ffffff" }} />

        <Box>
          <Typography variant="h5">Екстра</Typography>

          <Box className={classes.contentItem}>
            <Typography>Стапка по час (ноќна смена)</Typography>
            <Typography>
              {payrollDaySummary.ratePerHourNightShift} <em>мкд</em>
            </Typography>
          </Box>

          <Box className={classes.contentItem}>
            <Typography>Празник</Typography>
            <Typography>
              {payrollDaySummary.addOnHoliday} <em>мкд</em>
            </Typography>
          </Box>

          <Box className={classes.contentItem}>
            <Typography>Недела</Typography>
            <Typography>
              {payrollDaySummary.addOnSunday} <em>мкд</em>
            </Typography>
          </Box>

          <Box className={classes.contentItem}>
            <Typography>Празник + Недела</Typography>
            <Typography>
              {payrollDaySummary.addOnSundayHoliday} <em>мкд</em>
            </Typography>
          </Box>
        </Box>

        <Divider style={{ marginTop: 24, backgroundColor: "#ffffff" }} />
      </CardContent>
      <CardHeader title={`Вкупна заработка: ${payrollDaySummary.totalEarnings} мкд`} />
    </Card>
  );
};

export default PayrollDaySummaryCard;
