import React, { FC, useState } from "react";
import { AppBar, Tab, Tabs } from "@material-ui/core";
import { TabPanel } from "@shared/components/TabPanel/TabPanel";
import { IEmployeeDto } from "@shared/services/employees/dtos/employeeDto";
import { EmployeeAreasTab } from "./parts/EmployeeAreasTab";
import { EmployeeGeneralInfoTab } from "./parts/EmployeeGeneralInfoTab";
import EmployeeSystemConnectionsTab from "./parts/EmployeeSystemConnectionsTab";
import { EmployeeUserTab } from "./parts/EmployeeUserTab";

interface DetailsPanelProps {
  employee: IEmployeeDto;
  onUpdate: () => void;
}

const EmployeeDetailsPanel: FC<DetailsPanelProps> = ({ employee, onUpdate }) => {
  const [tabState, setTabState] = useState<number>(0);
  const handleTabChange = (event, newValue) => {
    setTabState(newValue);
  };

  const tabProps = (tab: number) => {
    return {
      id: `tab-${tab}`,
      "aria-controls": `tab-${tab}`,
    };
  };

  const [password, setPassword] = useState("");
  const [showPanel, setShowPanel] = useState(false);

  const handlePasswordSubmit = () => {
    if (password === "hardcoded") {
      setShowPanel(true);
    } else {
      alert("Погрешен PIN. Обидете се повторно!");
    }
  };

  return (
    <>
      {!showPanel && (
        <div style={{ 
          display: "flex",
          alignItems: "center", 
          marginBottom: "10px",
          marginTop: "10px",
          marginLeft: "10px"
        }}>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Внесете го вашиот PIN за да ги видите деталите"
            style={{
              padding: "10px",
              borderRadius: "5px",
              border: "1px solid #ccc",
              marginRight: "10px",
              width: "325px",
            }}
          />
          <button onClick={handlePasswordSubmit}
          style={{
            padding: "10px 20px",
            borderRadius: "5px",
            backgroundColor: "#18ba9b",
            color: "#fff",
            border: "none",
            cursor: "pointer",
          }}
          >
            ПОТВРДИ
            </button>
        </div>
      )}

      {showPanel && (
        <>
          <AppBar color="default" position="sticky">
            <Tabs value={tabState} onChange={handleTabChange}>
              <Tab label="Генерални информации" {...tabProps(0)} />
              <Tab label="Зони" {...tabProps(1)} />
              <Tab label="Корисник" {...tabProps(2)} />
              <Tab label="Поврзувања" {...tabProps(3)} />
            </Tabs>
          </AppBar>
          <TabPanel value={tabState} padding="none" index={0}>
            <EmployeeGeneralInfoTab employeeUserUpdated={onUpdate} employee={employee} />
          </TabPanel>
          <TabPanel value={tabState} padding="none" index={1}>
            <EmployeeAreasTab employee={employee} />
          </TabPanel>
          <TabPanel value={tabState} padding="none" index={2}>
            <EmployeeUserTab employeeId={employee.id} employeeUserId={employee.userFK} />
          </TabPanel>
          <TabPanel value={tabState} padding="none" index={3}>
            <EmployeeSystemConnectionsTab employee={employee} />
          </TabPanel>
        </>
      )}
    </>
  );
};

export default EmployeeDetailsPanel;
