import { FC, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
// import { AppBar, Tabs, Tab } from "@material-ui/core";
import { TabPanel } from "@shared/components/TabPanel/TabPanel";
import ActiveOrders from "./components/ActiveOrders";
// import PastOrdersList from "./components/PastOrders/PastOrdersList";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      height: "100%",
      flexDirection: "column",
    },
    toolbar: {
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
  })
);

const Orders: FC = () => {
  const classes = useStyles();

  const [activeTab, setActiveTab] = useState(0);
  const handleTabChange = (event: React.ChangeEvent<{}>, newTab: number) => {
    setActiveTab(newTab);
  };

  return (
    <div className={classes.root}>
      {/* <AppBar position="fixed" color="primary">
        <Tabs variant="fullWidth" value={activeTab} onChange={handleTabChange}>
          <Tab label="Активни" />
          <Tab label="Завршени" />
        </Tabs>
      </AppBar>

      <div className={classes.toolbar} /> */}

      <TabPanel value={activeTab} index={0}>
        <ActiveOrders />
      </TabPanel>

      {/* <TabPanel value={activeTab} index={1}>
        <PastOrdersList />
      </TabPanel> */}
    </div>
  );
};

export default Orders;
