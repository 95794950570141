
import { ResizableBox, ResizableBoxProps } from "react-resizable";
import {useEffect, useState} from "react";

interface ResizableProps {
 direction: 'vertical' | 'horizontal';
}

const Resizable: React.FC<ResizableProps> = ({  direction, children }) => {
let resizableProps: ResizableBoxProps;

const [innerHeight, setInnerHeight] = useState(window.innerHeight)
const [innerWidth, setInnerWidth] = useState(window.innerWidth)

useEffect(() => {
    const listener = () => {
setInnerHeight(window.innerHeight);
setInnerWidth(window.innerWidth);
    };
    window.addEventListener('resize', listener);

    return ()=> {
        window.removeEventListener('resize', listener);
    };
}, []);

if (direction === 'vertical') {
    resizableProps = {
        className: 'resize-vertical',
        minConstraints: [window.innerHeight * 0.01, Infinity],
        maxConstraints: [window.innerHeight * 2.055, Infinity],
        height: Infinity,
        width: window.innerHeight * 0.75,
        resizeHandles: ['e'],
    };
} else {
resizableProps = {
    minConstraints: [Infinity, 300],
    maxConstraints: [Infinity, window.innerHeight * 0.95],
    height: 600,
    width: 600,
    resizeHandles: ['s'],
};
}


return (
 <ResizableBox {...resizableProps}>
    {children}
    <style>{`
        .resize-vertical {
          display: flex;
          flex-direction: row;
        }
        
        .react-resizable-handle {
          display: block;
          background-color: lightgray;
          background-repeat: no-repeat;
          background-position: 50%;
        }

        .react-resizable-handle-e {
          width: 10px;
          min-width: 10px;
          height: 100%;
          cursor: col-resize;
        }
      `}</style>
    </ResizableBox>
);
};


export default Resizable;
