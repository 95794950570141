import DayjsUtils from "@date-io/dayjs";
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { FC, memo } from "react";

export interface Props extends KeyboardDatePickerProps {
  errorMessage?: string;
  touched?: boolean;
}

const CustomDateField: FC<Props> = ({
  format = "DD.MM.YYYY",
  value,
  errorMessage,
  touched,
  ...props
}) => {
  return (
    <MuiPickersUtilsProvider utils={DayjsUtils}>
      <KeyboardDatePicker
        InputLabelProps={{ shrink: !!value }}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
        disableToolbar
        format={format}
        value={value}
        error={touched && Boolean(errorMessage)}
        {...props}
      />
    </MuiPickersUtilsProvider>
  );
};

export default memo(CustomDateField);
