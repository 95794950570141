import { createTheme } from "@material-ui/core/styles";

export const lightTheme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#18ba9b",
    },
    secondary: {
      main: "#337ab7",
    },
    error:{
      main: "#db3131"
    }
  },
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: "#db3131",
        "&$error": {
          color: "#db3131",
        },
      },
    },
    MuiCssBaseline: {
      "@global": {
        html: {
          colorScheme: "light",
        },
      },
    },
  },
});

export const darkTheme = createTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#ed254e",
    },
    secondary: {
      main: "#337ab7",
    },
    error:{
      main: "#db3131"
    },
    background: {
      default: "#2a2d3d",
      paper: "#31354a",
    },
  },
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: "#db3131",
        "&$error": {
          color: "#db3131",
        },
      },
    },
    MuiCssBaseline: {
      "@global": {
        html: {
          colorScheme: "dark",
        },
      },
    },
  },
});
