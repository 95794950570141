import useFormStyles from "../hooks/useFormStyles";
import { Box, FormControlLabel, InputAdornment, Switch, TextField } from "@material-ui/core";
import { IEmployeeSalaryConfigurationDto } from "@shared/services/salary-configuration/dtos/employee-salary-configuration/employeeSalaryConfigurationDto";
import { useFormik } from "formik";
import { FC } from "react";

interface AddonsFormProps {
  formik: ReturnType<typeof useFormik<IEmployeeSalaryConfigurationDto>>;
}

const AddonsForm: FC<AddonsFormProps> = ({ formik }) => {
  const classes = useFormStyles();

  return (
    <Box display="flex" flexDirection="column" className={classes.form}>
      <Box>
        <TextField
          variant="outlined"
          className={classes.formControl}
          id="addOnSunday"
          name="addOnSunday"
          label="Недела"
          InputProps={{
            endAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
          type="number"
          value={formik.values.addOnSunday}
          onChange={formik.handleChange}
          error={formik.touched.addOnSunday && Boolean(formik.errors.addOnSunday)}
          helperText={formik.touched.addOnSunday && formik.errors.addOnSunday}
        />
      </Box>

      <Box>
        <TextField
          variant="outlined"
          className={classes.formControl}
          id="addOnHoliday"
          name="addOnHoliday"
          label="Празник"
          InputProps={{
            endAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
          type="number"
          value={formik.values.addOnHoliday}
          onChange={formik.handleChange}
          error={formik.touched.addOnHoliday && Boolean(formik.errors.addOnHoliday)}
          helperText={formik.touched.addOnHoliday && formik.errors.addOnHoliday}
        />
      </Box>

      <Box>
        <TextField
          variant="outlined"
          className={classes.formControl}
          id="addOnSundayHoliday"
          name="addOnSundayHoliday"
          label="Празник + Недела"
          InputProps={{
            endAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
          type="number"
          value={formik.values.addOnSundayHoliday}
          onChange={formik.handleChange}
          error={formik.touched.addOnSundayHoliday && Boolean(formik.errors.addOnSundayHoliday)}
          helperText={formik.touched.addOnSundayHoliday && formik.errors.addOnSundayHoliday}
        />
      </Box>

      <Box>
        <TextField
          variant="outlined"
          className={classes.formControl}
          id="addOnNight"
          name="addOnNight"
          label="Ноќна смена > 22:00"
          InputProps={{
            endAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
          type="number"
          value={formik.values.addOnNight}
          onChange={formik.handleChange}
          error={formik.touched.addOnNight && Boolean(formik.errors.addOnNight)}
          helperText={formik.touched.addOnNight && formik.errors.addOnNight}
        />
      </Box>

     
    </Box>
  );
};

export default AddonsForm;
