import { Button, makeStyles } from "@material-ui/core";
import { Autorenew, GpsFixed, Pause, Timeline } from "@material-ui/icons";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import useSubscription from "@shared/hooks/useSubscription/useSubscription";
import { AlgorithmMode } from "@shared/services/orders/enums/algorithmMode";
import {
  executeAlgorithmManually,
  getAlgorithmMode,
  setAlgorithmMode,
} from "@shared/services/orders/orders.service";
import { algorithmStatusUpdate } from "@shared/services/signalR.service";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { FC } from "react";

const useStyles = makeStyles((theme) => ({
  algorithmToggleRoot: {
    padding: theme.spacing(0.5),
  },
}));

const AlgorithmControl: FC = () => {
  const classes = useStyles();
  const queryClient = useQueryClient();

  const { data: algorithmMode } = useQuery(["algorithmMode"], getAlgorithmMode, {
    select: (data) => data.algorithmExecutionMode,
  });

  useSubscription(algorithmStatusUpdate, ({ algorithmExecutionMode }) => {
    queryClient.setQueryData(["algorithmMode"], { algorithmExecutionMode });
  });

  const handleAlgorithmToggle = (mode: AlgorithmMode) => {
    if (!mode) mode = algorithmMode;

    setAlgorithmMode(mode)
      .then(() => {
        queryClient.setQueryData(["algorithmMode"], { algorithmExecutionMode: mode });
      })
      .catch((error) => {
        alert("Неуспешно паузирање на алгоритамот: " + error.message);
      });
  };

  const handleExecuteAlgorithm = () => {
    const decision = window.confirm("Изврши алгоритам?");
    if (decision === false) return;

    executeAlgorithmManually()
      .then(() => {})
      .catch((error) => console.error(error));
  };

  return (
    <ToggleButtonGroup
      size="small"
      value={algorithmMode}
      onChange={(_, value) => handleAlgorithmToggle(value)}
      exclusive
    >
      <Button
        variant="outlined"
        startIcon={<Autorenew color="inherit" />}
        onClick={handleExecuteAlgorithm}
      >
        Алгоритам
      </Button>

      <ToggleButton
        value="Disabled"
        title="Исклучен алгоритам"
        classes={{ root: classes.algorithmToggleRoot }}
      >
        <Pause fontSize="small" color={algorithmMode === "Disabled" ? "error" : "inherit"} />
      </ToggleButton>

      <ToggleButton
        value="Basic"
        title="Основен алгоритам"
        classes={{ root: classes.algorithmToggleRoot }}
      >
        <GpsFixed fontSize="small" color={algorithmMode === "Basic" ? "secondary" : "inherit"} />
      </ToggleButton>

      <ToggleButton
        value="Advanced"
        title="Напреден алгоритам"
        classes={{ root: classes.algorithmToggleRoot }}
      >
        <Timeline fontSize="small" color={algorithmMode === "Advanced" ? "secondary" : "inherit"} />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default AlgorithmControl;
