import { Button, CssBaseline, Paper, Typography } from "@material-ui/core";
import { FC, useState } from "react";
import { useNavigate, useRouteError } from "react-router-dom";

export const setupViteErrorOverlay = () => {
  if (process.env.NODE_ENV !== "development") {
    return;
  }

  const showErrorOverlay = (err) => {
    const ErrorOverlay = customElements.get("vite-error-overlay");
    if (!ErrorOverlay) {
      return;
    }

    const overlay = new ErrorOverlay(err);
    document.body.appendChild(overlay);
  };

  window.addEventListener("error", ({ error }) => showErrorOverlay(error));
  window.addEventListener("unhandledrejection", ({ reason }) => showErrorOverlay(reason));
};

interface ErrorFallbackProps {
  error?: unknown;
}

const ErrorFallback: FC<ErrorFallbackProps> = ({ error }) => {
  const [errorProp] = useState<any>(error);
  const routerError: any = useRouteError();
  const navigate = useNavigate();
  return (
    <div
      role="alert"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        boxSizing: "border-box",
        padding: "16px",
        width: "100%",
        height: "100vh",
        gap: "16px",
      }}
    >
      <CssBaseline />

      <img
        src="/images/error.svg"
        alt="Error icon"
        style={{ width: "min(80%, 32em)", marginBottom: "16px" }}
      />

      <Typography variant="body1" align="center">
        Се случи грешка. Ве молиме обидете се повторно или контактирајте го тимот за поддршка
      </Typography>

      <Paper
        variant="outlined"
        style={{
          padding: "16px",
        }}
      >
        <Typography component="pre" color="error" variant="h6" style={{ fontWeight: "bold" }}>
          {errorProp?.message ?? routerError?.message}
        </Typography>

        <Typography component="pre" variant="body2" style={{ fontWeight: "bold" }}>
          {errorProp?.stack ?? routerError?.stack}
        </Typography>
      </Paper>

      <Button variant="outlined" size="small" color="primary" onClick={() => navigate(0)}>
        Обиди се повторно
      </Button>
    </div>
  );
};

export default ErrorFallback;
