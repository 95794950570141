import httpService from "../http.service";
import { IEmployeePositionDto } from "./dtos/employee-positions/employeePositionDto";
import { IEmployeePositionsRequestDto } from "./dtos/employee-positions/employeePositionsRequestDto";

export const getEmployeePositions = () => {
  return httpService.get<IEmployeePositionDto[]>("/api/employee-positions");
};

export const getEmployeePosition = (id: Number) => {
  return httpService.get<IEmployeePositionDto>(`/api/employee-positions/${id}`);
};

export const createEmployeePosition = (request: IEmployeePositionsRequestDto) => {
  return httpService.post<void, IEmployeePositionsRequestDto>("/api/employee-positions", request);
};

export const updateEmployeePosition = (id: number, request: IEmployeePositionsRequestDto) => {
  return httpService.put<void, IEmployeePositionsRequestDto>(
    `/api/employee-positions/${id}`,
    request
  );
};

export const deleteEmployeePosition = (id: number) => {
  return httpService.delete<void>(`/api/employee-positions/${id}`);
};
