import { Marker, Popup } from "react-leaflet"
import { FC, memo, useCallback, useMemo, useRef, useState } from "react"
import { Icon, LatLng, LeafletEventHandlerFnMap } from "leaflet"

interface Props {
    center: LatLng,
    onMarkerPositionChanged: (position: LatLng) => void,
    popupLabel
}

const DraggableMarker: FC<Props> = ({ center, onMarkerPositionChanged, popupLabel }) => {
    const [draggable, setDraggable] = useState(false)
    const [position, setPosition] = useState(center)
    const markerRef = useRef(null)

    const toggleDraggable = useCallback(() => {
        setDraggable((d) => (!d))
    }, [])

    const updateMarkerPosition = useCallback(() => {
        const marker = markerRef.current
        if (marker != null) {
            setPosition(marker.getLatLng())
            onMarkerPositionChanged(marker.getLatLng());
        }
    }, [])


    const eventHandlers: LeafletEventHandlerFnMap = useMemo(
        () => ({
            dragend() {
                updateMarkerPosition();
            }
        }),
        [],
    )

    return (
        <Marker
            draggable={true}
            position={position}
            ref={markerRef}
            eventHandlers={eventHandlers}
            icon={new Icon({
                iconUrl: "/images/icons/marker-icon.png",
                iconSize: [20, 32],

            })}>
            {
                popupLabel != null ?
                    <Popup>
                        <div>{popupLabel}</div>
                        <div>Longitude: {position.lng}</div>
                        <div>Latitude: {position.lat}</div>
                    </Popup> : null

            }
        </Marker>
    )
}

export default memo(DraggableMarker)