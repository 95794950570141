import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { IPayrollEmployeePositionSummaryDto } from "@shared/services/payroll/dtos/payrollEmployeePositionSummaryDto";
import { FC } from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      minWidth: theme.spacing(48),
      minHeight: theme.spacing(36),
      margin: theme.spacing(1),
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.grey[100],
    },
    contentItem: {
      display: "flex",
      justifyContent: "space-between",
      paddingTop: theme.spacing(1),
      paddingBotton: theme.spacing(1),
      cursor: "default",
    },
  })
);

interface EmployeePositionSummaryCardProps {
  employeePositionSummary: IPayrollEmployeePositionSummaryDto;
}

const EmployeePositionSummaryCard: FC<EmployeePositionSummaryCardProps> = ({
  employeePositionSummary,
}) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardHeader title={employeePositionSummary.employeePosition.name} />
      <CardContent>
        <Box className={classes.contentItem}>
          <Typography>Заработка: </Typography>
          <Typography>
            {employeePositionSummary.earnings} <em>мкд</em>
          </Typography>
        </Box>
        <Box className={classes.contentItem}>
          <Typography>Бонус: </Typography>
          <Typography>
            {employeePositionSummary.bonus} <em>мкд</em>
          </Typography>
        </Box>
        <Box className={classes.contentItem}>
          <Typography>Боледување: </Typography>
          <Typography>
            {employeePositionSummary.sick} <em>мкд</em>
          </Typography>
        </Box>
        <Box className={classes.contentItem}>
          <Typography>Одмор: </Typography>
          <Typography>
            {employeePositionSummary.holiday} <em>мкд</em>
          </Typography>
        </Box>
        <Box className={classes.contentItem}>
          <Typography>Пенали: </Typography>
          <Typography>
            {employeePositionSummary.penalties} <em>мкд</em>
          </Typography>
        </Box>
        <Box className={classes.contentItem}>
          <Typography>Заработено: </Typography>
          <Typography>
            {employeePositionSummary.salary} <em>мкд</em>
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default EmployeePositionSummaryCard;
