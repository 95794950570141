import { FC, ReactNode } from "react";
import { createStyles, Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { DialogTitleProps as MuiDialogTitleProps } from "@material-ui/core/DialogTitle";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      display: "flex",
      alignItems: "center",
    },
    icon: {
      display: "flex",
      paddingRight: theme.spacing(1),
    },
    closeButton: {
      marginLeft: "auto",
      color: theme.palette.grey[500],
    },
  })
);

export interface DialogTitleProps extends MuiDialogTitleProps {
  icon?: ReactNode;
  onClose?: () => void;
}

const DialogTitle: FC<DialogTitleProps> = (props) => {
  const { icon, children, onClose, ...rest } = props;
  const classes = useStyles();

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...rest}>
      {icon ? <div className={classes.icon}>{icon}</div> : null}

      <Typography variant="h6">{children}</Typography>

      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

export default DialogTitle;
