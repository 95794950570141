import { debounce } from "lodash";
import { FC, memo, useEffect } from "react";
import { useMap } from "react-leaflet";

interface MapResizeObserverProps {
  debounceWaitTime?: number;
}

/**
 * This component is used to invalidate the size of the map whenever it gets changed
 */
const MapResizeObserver: FC<MapResizeObserverProps> = ({ debounceWaitTime = 10 }) => {
  const map = useMap();

  useEffect(() => {
    const callback = debounce(() => {
      map?.invalidateSize();
    }, debounceWaitTime);

    const observer = new ResizeObserver(callback);

    const mapContainer = map?.getContainer();
    if (mapContainer) observer.observe(mapContainer);

    return () => {
      observer.disconnect();
    };
  }, [debounceWaitTime]);

  return null;
};

export default memo(MapResizeObserver);
