import useRoleSalaryConfigDetailsFormik from "../hooks/useRoleSalaryConfigDetailsFormik";
import {
  Button,
  CardActions,
  CardContent,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { IRoleSalaryConfigurationDto } from "@shared/services/salary-configuration/dtos/role-salary-configurations/roleSalaryConfigurationDto";
import { getAllCompanies } from "@shared/services/settings/companies.service";
import { getEmployeePositions } from "@shared/services/settings/employee-positions.service";
import { useQuery } from "@tanstack/react-query";
import { FC } from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    position: {
      minWidth: theme.spacing(24),
    },
    actions: {
      display: "flex",
      justifyContent: "end",
    },
  })
);

interface AddRoleSalaryConfigFormProps {
  onSubmit: (roleSalaryConfig: IRoleSalaryConfigurationDto) => void;
}

const AddRoleSalaryConfigForm: FC<AddRoleSalaryConfigFormProps> = ({ onSubmit }) => {
  const classes = useStyles();

  const formik = useRoleSalaryConfigDetailsFormik({ onSubmit: (values) => onSubmit(values) });

  const { data: employeePositions, isLoading: isLoadingEmployeePositions } = useQuery(
    ["getEmployeePositions"],
    getEmployeePositions,
    {
      onSuccess: (data) => {
        formik.setValues({
          ...formik.values,
          employeePositionFK: data.find(() => true)?.id ?? -1,
        });
      },
    }
  );

  const { data: companies, isLoading: isLoadingCompanies } = useQuery(
    ["getAllCompanies"],
    getAllCompanies,
    {
      onSuccess: (data) => {
        const firstCompanyId = data.find(() => true)?.id ?? -1;
        formik.setValues({
          ...formik.values,
          fixedSalaryBruttoCompanyFK: firstCompanyId,
          fixedContractCompanyFK: firstCompanyId,
          vernaCompanyFK: firstCompanyId,
          carRentCompanyFK: firstCompanyId,
          contractCompanyFK: firstCompanyId,
        });
      },
    }
  );

  if (isLoadingEmployeePositions || isLoadingCompanies) return <CircularProgress />;

  return (
    <>
      <CardContent>
        <form>
          <FormControl variant="outlined" className={classes.position}>
            <InputLabel id="employeePositionFKLabel">Позиција</InputLabel>
            <Select
              id="employeePositionFK"
              name="employeePositionFK"
              value={formik.values.employeePositionFK}
              labelId="employeePositionFKLabel"
              label="Позиција"
              onChange={formik.handleChange}
              error={formik.touched.employeePositionFK && Boolean(formik.errors.employeePositionFK)}
            >
              {!employeePositions.length && (
                <MenuItem value={-1}>
                  <em>Нема позиции</em>
                </MenuItem>
              )}
              {employeePositions.map((employeePosition) => (
                <MenuItem key={employeePosition.id} value={employeePosition.id}>
                  {employeePosition.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </form>

        {!employeePositions.length && (
          <Alert severity="warning">Прво мора да додадете барем една позиција во системот</Alert>
        )}
        {!companies.length && (
          <Alert severity="warning">Прво мора да додадете барем една компанија во системот</Alert>
        )}
      </CardContent>

      <CardActions className={classes.actions}>
        <Button
          disabled={!employeePositions.length || !companies.length}
          variant="contained"
          color="primary"
          onClick={formik.submitForm}
        >
          Додади
        </Button>
      </CardActions>
    </>
  );
};

export default AddRoleSalaryConfigForm;
