import ErrorFallback from "../../../../error";
import PayrollEmployeeDay from "./PayrollEmployeeDay/PayrollEmployeeDay";
import PayrollEmployeePeriod from "./PayrollEmployeePeriod/PayrollEmployeePeriod";
import PayrollEmployeesOverview from "./PayrollEmployeesOverview/PayrollEmployeesOverview";
import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

const Payroll: FC = () => {
  return (
    <Routes>
      <Route errorElement={<ErrorFallback />}>
        <Route index element={<PayrollEmployeesOverview />} />
        <Route path=":employeeId" element={<PayrollEmployeePeriod />} />
        <Route path=":employeeId/:date" element={<PayrollEmployeeDay />} />

        <Route path="*" element={<PayrollFallback />} />
      </Route>
    </Routes>
  );
};

const PayrollFallback: FC = () => <Navigate to="" />;

export default Payroll;
