import useFormStyles from "../hooks/useFormStyles";
import { Box, InputAdornment, TextField } from "@material-ui/core";
import { IEmployeeSalaryConfigurationDto } from "@shared/services/salary-configuration/dtos/employee-salary-configuration/employeeSalaryConfigurationDto";
import { useFormik } from "formik";
import { FC } from "react";

interface PeakHoursFormProps {
  formik: ReturnType<typeof useFormik<IEmployeeSalaryConfigurationDto>>;
}

const PeakHoursForm: FC<PeakHoursFormProps> = ({ formik }) => {
  const classes = useFormStyles();

  return (
    <Box display="flex" flexDirection="column" className={classes.form}>
      {/* <Box>
        <TextField
          variant="outlined"
          className={classes.formControl}
          id="breakDuration"
          name="breakDuration"
          label="Пауза времетраење"
          InputProps={{
            endAdornment: <InputAdornment position="start">мкд</InputAdornment>,
          }}
          type="number"
          value={formik.values.breakDuration}
          onChange={formik.handleChange}
          error={formik.touched.breakDuration && Boolean(formik.errors.breakDuration)}
          helperText={formik.touched.breakDuration && formik.errors.breakDuration}
        />
      </Box> */}
    </Box>
  );
};

export default PeakHoursForm;
