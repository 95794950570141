import httpService from "../http.service";
import { IRoleTypeDto } from "./dtos/role-types/roleTypeDto";
import { IRoleTypeRequestDto } from "./dtos/role-types/roleTypeRequestDto";

export const getAllRoles = () => {
  return httpService.get<IRoleTypeDto[]>("/api/role-types");
};

export const getRole = (id: string) => {
  return httpService.get<IRoleTypeDto>(`/api/role-types/${id}`);
};

export const addNewRole = (request: IRoleTypeRequestDto) => {
  return httpService.post<IRoleTypeDto, IRoleTypeRequestDto>(`/api/role-types`, request);
};

export const updateRole = (id: string, request: IRoleTypeRequestDto) => {
  return httpService.put<IRoleTypeDto, IRoleTypeRequestDto>(`/api/role-types/${id}`, request);
};

export const removeRole = (id: string) => {
  return httpService.delete<void>(`/api/role-types/${id}`);
};
