import { FC, useEffect, useRef, memo } from "react";
import leaflet, { Control, Layer, LayerGroup, LeafletEvent } from "leaflet";
import {
  LeafletContextInterface,
  useLeafletContext,
} from "@react-leaflet/core";
import "leaflet-draw";

// Vite probably removes this property from the build
// Related: https://github.com/Leaflet/Leaflet.draw/issues/1026#issuecomment-986702652
// @ts-ignore
window.type = true;

const eventHandlers = {
  onEdited: "draw:edited",
  onDrawStart: "draw:drawstart",
  onDrawStop: "draw:drawstop",
  onDrawVertex: "draw:drawvertex",
  onEditStart: "draw:editstart",
  onEditMove: "draw:editmove",
  onEditResize: "draw:editresize",
  onEditVertex: "draw:editvertex",
  onEditStop: "draw:editstop",
  onDeleted: "draw:deleted",
  onDeleteStart: "draw:deletestart",
  onDeleteStop: "draw:deletestop",
};

interface Props extends Control.DrawConstructorOptions {
  geoJSON: string;

  // https://leaflet.github.io/Leaflet.draw/docs/leaflet-draw-latest.html#l-draw-event-draw:created
  onEdited?: (event: LayerGroup) => void;
  onDrawStart?: (event: string) => void;
  onDrawStop?: (event: string) => void;
  onDrawVertex?: (event: LayerGroup) => void;
  onEditStart?: (event: string) => void;
  onEditMove?: (event: leaflet.Layer) => void;
  onEditResize?: (event: leaflet.Layer) => void;
  onEditVertex?: (event: LayerGroup) => void;
  onEditStop?: (event: string) => void;
  onDeleted?: (event: LayerGroup) => void;
  onDeleteStart?: (event: string) => void;
  onDeleteStop?: (event: string) => void;

  onCreated?: (event: LeafletEvent) => void;
  onMounted?: (draw: Control.Draw) => void;
}

const DrawControl: FC<Props> = (props) => {
  const context = useLeafletContext();
  const drawRef = useRef(createDrawElement(props, context));

  const addFeature = (layer: Layer) => {
    const container = context.layerContainer || context.map;

    if (context.map.hasLayer(layer)) return;
    if (isFeatureDrawn()) return alert("Може да нацртате само 1 облик!");

    container.addLayer(layer);

    layer.on("click", () => {
      // _toobars comes from leaflet-draw
      // From what i can see there are no types exported for it
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { edit, remove } = drawRef.current?._toolbars.edit._modes;

      if (!remove.handler.enabled()) edit.handler.enable();
    });
  };

  const isFeatureDrawn = (): boolean => {
    const container = context.layerContainer || context.map;

    if (container) {
      // Typescript warns that _layers doesn't exist on the type
      // Works fine once it's compiled
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const keysLength = Object.keys(container._layers).length;
      return keysLength > 0;
    }
    return false;
  };

  const onDrawCreate = (e: LeafletEvent) => {
    // It says it's deprecated, but propagatedFrom doesn't exist on the event
    // TODO: Probably a library / types version sync issue
    addFeature(e.layer);

    const { onCreated } = props;
    onCreated && onCreated(e);
  };

  const addGeoJSON = (geoJSON: string) => {
    if (!geoJSON) return;

    const style =
      props.draw &&
        Object.prototype.hasOwnProperty.call(props.draw.polygon, "shapeOptions")
        ? // eslint-disable-next-line
        { color: (props?.draw?.polygon as any)?.shapeOptions.color }
        : {};

    const leafletGeoJSON = new leaflet.GeoJSON(JSON.parse(geoJSON), {
      style: style,
    });

    leafletGeoJSON.eachLayer((layer) => {
      addFeature(layer);
    });
  };

  useEffect(() => {
    const { map } = context;
    const { geoJSON, onMounted } = props;

    for (const [, eventName] of Object.entries(eventHandlers)) {
      map.on(eventName, (evt) => {
        const handler = Object.keys(eventHandlers).find(
          (x) => eventHandlers[x] === evt.type
        );

        if (handler && handler in props) {
          props[handler](evt);
        }
      });
    }
    map.on(leaflet.Draw.Event.CREATED, onDrawCreate);

    addGeoJSON(geoJSON);

    drawRef.current.addTo(map);

    onMounted && onMounted(drawRef.current);

    return () => {
      const { map } = context;

      map.off(leaflet.Draw.Event.CREATED, onDrawCreate);

      // Unmount all the event callbacks
      for (const [, eventName] of Object.entries(eventHandlers)) {
        map.off(eventName);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { map } = context;

    map.removeControl(drawRef.current);
    drawRef.current.remove();
    drawRef.current = createDrawElement(props, context);
    drawRef.current.setDrawingOptions(props.draw);
    drawRef.current.addTo(map);

    // TODO: Custom city drawing doesn't work now
    // TODO: Clear the old geoJSON
    // TODO: Searching cities with latin letters doesn't work aswell
    // addGeoJSON(props.geoJSON);

    const { onMounted } = props;
    onMounted && onMounted(drawRef.current);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.draw, props.edit, props.position, props.geoJSON]);

  return null;
};

function createDrawElement(props: Props, context: LeafletContextInterface) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { layerContainer }: typeof context.layerContainer | any = context;
  const { draw, edit, position } = props;

  const options: Control.DrawConstructorOptions = {
    edit: {
      ...edit,
      featureGroup: layerContainer,
    },
    draw: undefined,
  };

  if (draw) options.draw = { ...draw };

  if (position) {
    options.position = position;
  }

  return new Control.Draw(options);
}

export default memo(DrawControl, (prev, next) => {
  return (
    JSON.stringify(next.draw) === JSON.stringify(prev.draw) &&
    JSON.stringify(next.edit) === JSON.stringify(prev.edit) &&
    next.position === prev.position &&
    next.geoJSON === prev.geoJSON
  );
});
