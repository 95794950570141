import { driverEndedShift } from "../drivers/driversSlice";
import { orderUpdated } from "../orders/orderActions";
import { getAllDriversRoutesAsync } from "./driversRoutesThunk";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IBaseType } from "@shared/interfaces/IBaseType";
import { IDriverRouteResponseDto } from "@shared/services/orders/dtos/orders/driverRouteResponseDto";
import { IRoutePointDto } from "@shared/services/orders/dtos/orders/routePointDto";
import { OrderStatus } from "@shared/services/orders/enums/orderStatus";

const initialState: IBaseType<IDriverRouteResponseDto[]> = {
  status: "idle",
  error: "",
  data: [],
};

const driversRoutesSlice = createSlice({
  name: "driversRoutes",
  initialState,
  reducers: {
    driverRouteUpdated: (driversRoutes, action: PayloadAction<IDriverRouteResponseDto>) => {
      const data = action.payload;

      // Get all orderIds in the incoming route
      const routeOrderIds = data.routePoints.map((rp) => rp.orderId);

      // Find the drivers route that has at least one of the incoming route's orderIds
      //  and has the mergeGuid
      const driverRouteIndex = driversRoutes.data.findIndex(
        (x) =>
          x.employeeId === data.employeeId &&
          x.routePoints.some((rp) => routeOrderIds.includes(rp.orderId))
      );

      // If the route is no longer active, remove it
      if (data.isActive === false || data.routePoints.length === 0) {
        driversRoutes.data.splice(driverRouteIndex, 1);
        return;
      }

      if (driverRouteIndex !== -1) {
        driversRoutes.data[driverRouteIndex] = data;
      } else {
        driversRoutes.data.push(data);
      }
    },
    driverRoutePointUpdated: (driversRoutes, action: PayloadAction<IRoutePointDto>) => {
      const data = action.payload;

      const driversRoute = driversRoutes.data.find((dr) =>
        dr.routePoints.some((rp) => rp.orderId === data.orderId && rp.isPickup === data.isPickup)
      );

      if (!driversRoute) {
        return;
      }

      const routePointIndex = driversRoute.routePoints.findIndex(
        (rp) => rp.orderId === data.orderId && rp.isPickup === data.isPickup
      );

      // Update the route point
      if (routePointIndex !== -1) {
        driversRoute.routePoints[routePointIndex] = data;
      }

      if (data.orderStatus === OrderStatus.Delivered) {
        // Cleanup route points if they're delivered
        driversRoute.routePoints = driversRoute.routePoints.filter(
          (routePoint) =>
            routePoint.orderStatus !== OrderStatus.Delivered && routePoint.orderId !== data.orderId
        );

        // If the route is no longer active, remove it
        if (driversRoute.isActive === false || driversRoute.routePoints.length === 0) {
          driversRoutes.data.splice(driversRoutes.data.indexOf(driversRoute), 1);
        }
      }
    },
    cleanupDriversRoutes: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    return builder
      .addCase(getAllDriversRoutesAsync.fulfilled, (_, action) => {
        return { status: "succeeded", error: "", data: action.payload };
      })
      .addCase(getAllDriversRoutesAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllDriversRoutesAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(driverEndedShift, (state, action) => {
        const employeeId = action.payload;

        // Remove the drivers route if the employee id matches and
        //  all the orders assigned to the driver are delivered
        state.data = state.data.filter(
          (driversRoute) =>
            !(
              driversRoute.employeeId === employeeId &&
              driversRoute.routePoints.every((rp) => rp.orderStatus === OrderStatus.Delivered)
            )
        );
      })
      .addCase(orderUpdated, (driversRoutes, { payload: order }) => {
        const driversRouteIndex = driversRoutes.data.findIndex((dr) =>
          dr.routePoints.some((rp) => rp.orderId === order.id)
        );

        if (driversRouteIndex === -1) {
          return;
        }

        const driversRoute = driversRoutes.data[driversRouteIndex];

        // Order was removed from route
        if (order.employeeFk === null) {
          driversRoute.routePoints = driversRoute.routePoints.filter(
            (rp) => rp.orderId !== order.id
          );

          // If the route is no longer active, remove it
          if (driversRoute.routePoints.length === 0) {
            driversRoutes.data.splice(driversRouteIndex, 1);
          }

          return;
        }

        const routePointIndexes = driversRoute.routePoints
          .map((rp, index) => (rp.orderId === order.id ? index : 0))
          .filter((index) => index > 0);

        // Update route point order status
        for (const routePointIndex of routePointIndexes) {
          driversRoute.routePoints[routePointIndex].orderStatus = order.orderStatus;
        }
      });
  },
});

export const { driverRouteUpdated, driverRoutePointUpdated, cleanupDriversRoutes } =
  driversRoutesSlice.actions;

export default driversRoutesSlice.reducer;
