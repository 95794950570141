import CustomSelect from "../../../../../../../shared/components/CustomFormControls/CustomSelect";
import DialogActions from "../../../../../../../shared/components/Dialog/DialogActions";
import DialogContent from "../../../../../../../shared/components/Dialog/DialogContent";
import DialogTitle from "../../../../../../../shared/components/Dialog/DialogTitle";
import ErrorMessage from "../../../../../../../shared/components/ErrorMessage/ErrorMessage";
import {
  addNewPerformanceBonusType,
  updatePerformanceBonusType,
} from "../../../../../../../shared/services/salary-configuration/performance-bonuses.service";
import { IPerformanceBonusType } from "../../pages/PerformanceBonusPage";
import { PerformanceBonusTypeSchema } from "../../schemas/PerformanceBonusTypeSchema";
import { Button, CircularProgress, Dialog, TextField } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { useMemoSelector } from "@shared/hooks";
import { IApiErrorResponseData } from "@shared/services/http.client";
import { AxiosError } from "axios";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import React, { FC, SyntheticEvent, useEffect, useState } from "react";

const useStyles = makeStyles(() =>
  createStyles({
    dialogContent: {
      minWidth: "290px",
    },
    form: {
      width: "100%",
    },
  })
);

const initialValues = {
  numberOfOrders: 0,
  amount: 0,
  cityFk: 0,
};

interface PerformanceBonusFormTypeProps {
  title: string;
  addButtonText: string;
  open: boolean;
  row: IPerformanceBonusType | null;
  onClose: () => void;
  onSuccess: () => void;
}

const PerformanceBonusForm: FC<PerformanceBonusFormTypeProps> = (props) => {
  const classes = useStyles();
  const { title, addButtonText, open, row, onClose, onSuccess } = props;

  const { enqueueSnackbar } = useSnackbar();

  const [error, setError] = useState("");
  const handleSubmit = (values: typeof initialValues) => {
    const APICall =
      row && row.id
        ? updatePerformanceBonusType(row.id, { ...values })
        : addNewPerformanceBonusType(values);

    APICall.then((response) => {
      // Reset formik
      formik.setSubmitting(false);
      formik.resetForm();

      // Show notification
      enqueueSnackbar("Успешно додадено", { variant: "success" });

      // TODO: Send back the data from the response here

      // Refetch data
      onSuccess();

      // Close the dialog
      onClose();
    }).catch((error: AxiosError<IApiErrorResponseData>) => {
      // Reset formik
      formik.setSubmitting(false);

      // Show error message
      setError(error.response?.data?.message);
    });
  };

  const {
    setValues,
    handleChange: handleFormikChange,
    ...formik
  } = useFormik({
    initialValues,
    validationSchema: PerformanceBonusTypeSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
    validateOnChange: false,
  });

  const handleClose = () => {
    formik.resetForm();
    setError("");

    if (onClose) onClose();
  };

  const handleChange = (event: SyntheticEvent) => {
    // Reset errors on input change
    if (error) setError("");

    handleFormikChange(event);
  };

  // Set initial values
  useEffect(() => {
    if (!row) return;

    const newInitialValues = {} as typeof initialValues;
    Object.keys(initialValues).forEach((key: string) => {
      if (key in row) {
        newInitialValues[key] = row[key];
      }
    });

    setValues(newInitialValues);
  }, [row, setValues]);

  const { cityTypes } = useMemoSelector(({ sharedState }) => ({
    cityTypes: sharedState?.cityTypes?.data?.map((x) => ({ text: x.name, value: x.id })),
  }));

  return (
    <Dialog
      maxWidth={false}
      aria-labelledby="new-performance-bonus-type-dialog"
      open={open}
      onClose={handleClose}
    >
      <form className={classes.form} onSubmit={formik.handleSubmit}>
        <DialogTitle onClose={handleClose}>{title}</DialogTitle>

        <DialogContent className={classes.dialogContent} dividers>
          <TextField
            name="numberOfOrders"
            label="Број на нарачки"
            variant="outlined"
            margin="normal"
            value={formik.values.numberOfOrders}
            onChange={handleChange}
            error={formik.touched.numberOfOrders && Boolean(formik.errors.numberOfOrders)}
            helperText={formik.touched.numberOfOrders && formik.errors.numberOfOrders}
            autoFocus
            fullWidth
            required
          />

          <TextField
            name="amount"
            label="Износ"
            variant="outlined"
            margin="normal"
            value={formik.values.amount}
            onChange={handleChange}
            error={formik.touched.amount && Boolean(formik.errors.amount)}
            helperText={formik.touched.amount && formik.errors.amount}
            autoFocus
            fullWidth
            required
          />

          <CustomSelect
            label="Град"
            value={formik.values.cityFk}
            errorMessage={formik.errors.cityFk}
            touched={formik.touched.cityFk}
            onChange={(e) => {
              formik.setFieldValue("cityFk", e);
            }}
            size="medium"
            variant="outlined"
            selectOptions={cityTypes.map((x) => ({ text: x.text, value: x.value }))}
            multiple={false}
            key="city"
            id="city"
          />

          <ErrorMessage error={error} />
        </DialogContent>

        <DialogActions>
          {formik.isSubmitting ? (
            <CircularProgress size={25} />
          ) : (
            <Button type="submit" color="primary">
              {addButtonText}
            </Button>
          )}

          <Button onClick={handleClose}>Откажи</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default PerformanceBonusForm;
