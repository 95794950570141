import axios from "axios";

const baseURL = "https://helper-api.logistics.maksi.mk";

const reverseGeocode = (lat: number, lon: number) => {
  return axios
    .get(`${baseURL}/geocode/reverse?lat=${lat}&lon=${lon}`)
    .then((data) => {
      const addressProperties = data.data?.features?.[0]?.properties;

      const name: string = addressProperties?.suburb ?? "Непознато";

      const bannedWords = ["општина"];
      const cleanName = name
        .split(" ")
        .filter((word) => !bannedWords.includes(word.toLowerCase().trim()))
        .join(" ");

      return capitalize(cleanName);
    });
};

const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export default reverseGeocode;
