import { driverEndedShift } from "../drivers/driversSlice";
import { driverRouteUpdated } from "../driversRoutes/driversRoutesSlice";
import { orderDeleted, orderUpdated } from "../orders/orderActions";
import { Filter } from "@Logistics/interfaces/Filter";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { OrderStatus } from "@shared/services/orders/enums/orderStatus";

export type SortType = "routeGroupAsc" | "routeGroupDesc" | "orderCountAsc" | "orderCountDesc";

export interface IFilterState {
  orders: Filter<{
    unassignedOrders: boolean;
  }>;
  drivers: Filter<{
    availability: {
      free: boolean;
      busy: boolean;
      paused: boolean;
    };
    problem: {
      lowBattery: boolean;
      lostLocation: boolean;
      problem: boolean;
    };
    capabilities: {
      hasFiscalPrinter: boolean;
    };
    city: number[];
    vehicleType: number[];
    deliveryType: number[];
  }>;
  driversRoutes: Filter<{
    completelyLateOrders: boolean;
    latePickUpOrders: boolean;
    lateDropOffOrders: boolean;
    normalOrders: boolean;
  }>;
  global: {
    city: number[];
    deliveryType: number[];
    search: string;
  };
  sort: SortType;
}

const initialState: IFilterState = {
  orders: {
    mode: "disabled",
    selectedIds: [],
    groups: { unassignedOrders: false },
  },
  drivers: {
    mode: "disabled",
    selectedIds: [],
    groups: {
      availability: {
        free: false,
        busy: false,
        paused: false,
      },
      capabilities: {
        hasFiscalPrinter: false,
      },
      problem: {
        lowBattery: false,
        lostLocation: false,
        problem: false,
      },
      city: [],
      vehicleType: [],
      deliveryType: [],
    },
  },
  driversRoutes: {
    mode: "disabled",
    // selectedIds: [],
    groups: {
      completelyLateOrders: false,
      lateDropOffOrders: false,
      latePickUpOrders: false,
      normalOrders: false,
    },
  },
  global: {
    city: [],
    deliveryType: [],
    search: "",
  },
  sort: "routeGroupAsc",
};

const processChange = (filters: IFilterState) => {
  // Calculate isActive
  const isActiveOrderGroups = Object.values(filters.orders.groups).some(
    (filter) => filter === true
  );
  const isActiveOrderIds = filters.orders.selectedIds.length > 0;
  filters.orders.mode = isActiveOrderGroups
    ? "groups"
    : isActiveOrderIds
    ? "selectedIds"
    : "disabled";

  // If the child is an object, check the values if any are true
  // If the child is an array, check the length
  const isActiveDriverGroups = Object.values(filters.drivers.groups).some((group) =>
    Array.isArray(group) ? group.length > 0 : Object.values(group).some((value) => value === true)
  );
  const isActiveDriverIds = filters.drivers.selectedIds.length > 0;
  filters.drivers.mode = isActiveDriverGroups
    ? "groups"
    : isActiveDriverIds
    ? "selectedIds"
    : "disabled";

  const isActiveDriverRoutesGroups = Object.values(filters.driversRoutes.groups).some(
    (filter) => filter === true
  );
  filters.driversRoutes.mode = isActiveDriverRoutesGroups ? "groups" : "disabled";

};

const driversSlice = createSlice({
  name: "driversStore",
  initialState,
  reducers: {
    selectedOrderId: (
      filters,
      action: PayloadAction<{ selectedId: number; isEnabled: boolean }>
    ) => {
      const { selectedId, isEnabled } = action.payload;

      const exists = filters.orders.selectedIds.includes(selectedId);

      if (isEnabled && !exists) {
        filters.orders.selectedIds.push(selectedId);
      } else {
        filters.orders.selectedIds = filters.orders.selectedIds.filter(
          (orderId) => orderId !== selectedId
        );
      }

      // Reset orders group filters
      filters.orders.groups = initialState.orders.groups;

      // Reset drivers routes filters
      filters.drivers.groups = initialState.drivers.groups;

      processChange(filters);
    },
    ordersCategory: (
      filters,
      action: PayloadAction<{ type: keyof IFilterState["orders"]["groups"]; isEnabled: boolean }>
    ) => {
      const { type, isEnabled } = action.payload;

      filters.orders.groups[type] = isEnabled;

      // Clear Id filters
      filters.orders.selectedIds = [];

      // Reset drivers routes filters
      filters.driversRoutes.groups = initialState.driversRoutes.groups;

      processChange(filters);
    },
    selectedDriverId: (
      filters,
      action: PayloadAction<{ selectedId: number; isEnabled: boolean }>
    ) => {
      const { selectedId, isEnabled } = action.payload;

      const exists = filters.drivers.selectedIds.includes(selectedId);

      if (isEnabled && !exists) {
        filters.drivers.selectedIds.push(selectedId);
      } else {
        filters.drivers.selectedIds = filters.drivers.selectedIds.filter(
          (driverId) => driverId !== selectedId
        );
      }

      // Reset drivers group filters
      filters.drivers.groups = initialState.drivers.groups;

      // Reset drivers routes filters
      filters.driversRoutes.groups = initialState.driversRoutes.groups;

      processChange(filters);
    },
    selectedRouteId: (
      filters,
      action: PayloadAction<{ selectedId: number; isEnabled: boolean }>
    ) => {
     
      const { selectedId } = action.payload;


      // const exists = filters.driversRoutes.selectedIds.includes(selectedId);
      
      filters.driversRoutes.selectedIds = filters.driversRoutes.selectedIds.filter(
        (x) => x !== selectedId
      );
      // if (isEnabled && !exists) {
      //   filters.driversRoutes.selectedIds.push(selectedId);
      // } else {
      //   filters.driversRoutes.selectedIds = filters.driversRoutes.selectedIds.filter(
      //     (driverId) => driverId !== selectedId
      //   );
      // }

      // Reset drivers group filters
      filters.drivers.groups = initialState.drivers.groups;

      // Reset drivers routes filters
      filters.driversRoutes.groups = initialState.driversRoutes.groups;

      processChange(filters);
    },
    driversCategory: (
      filters,
      action: PayloadAction<{
        group: keyof IFilterState["drivers"]["groups"];
        type: number | string;
        isEnabled: boolean;
      }>
    ) => {
      const { group, type, isEnabled } = action.payload;

      if (Array.isArray(filters.drivers.groups[group])) {
        if (isEnabled === true) {
          (filters.drivers.groups[group] as any)?.push(type);
        } else {
          filters.drivers.groups[group] = (filters.drivers.groups[group] as any)?.filter(
            (value) => value !== type
          );
        }
      } else {
        filters.drivers.groups[group][type] = isEnabled;
      }
      
      // Clear Id filters
      filters.drivers.selectedIds = [];

      // Reset drivers routes filters
      filters.driversRoutes.groups = initialState.driversRoutes.groups;

      processChange(filters);
    },
    driversRoutesGroup: (
      filters,
      action: PayloadAction<{
        type: keyof IFilterState["driversRoutes"]["groups"];
        isEnabled: boolean;
      }>
    ) => {
      const { type, isEnabled } = action.payload;

      // Reset order filters
      filters.orders.groups = initialState.orders.groups;

      filters.orders.selectedIds = [];

      // Reset driver filters
      filters.drivers.groups = initialState.drivers.groups;

      filters.drivers.selectedIds = [];

      // Toggle drivers routes group
      filters.driversRoutes.groups[type] = isEnabled;

      processChange(filters);
    },
    changeSort: (filters, action: PayloadAction<SortType>) => {
      filters.sort = action.payload;
    },
    changeCityFilter: (filters, action: PayloadAction<number[]>) => {
      filters.global.city = action.payload;
    },
    changeDeliveryTypeFilter: (filters, action: PayloadAction<number[]>) => {
      filters.global.deliveryType = action.payload;
    },
    changeDriversRoutesFilterGroups: (filters, action: PayloadAction<string[]>) => {
      // Reset the values
      filters.driversRoutes.groups = initialState.driversRoutes.groups;

      // Set the new values
      const values = action.payload;
      values?.forEach((value) => {
        filters.driversRoutes.groups[value] = true;
      });

      processChange(filters);
    },
    changeSearchFilter: (filters, action: PayloadAction<string>) => {
      filters.global.search = action.payload;
    },
    cleanupFilters: () => {
      return initialState;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(driverEndedShift, (filters, action) => {
        const employeeId = action.payload;

        // Remove driver from selected driver ids
        filters.drivers.selectedIds = filters.drivers.selectedIds.filter((x) => x !== employeeId);

        // Clear all driver group filters
        filters.drivers.groups = initialState.drivers.groups;

        const isActiveDriverIds = filters.drivers.selectedIds.length > 0;
        filters.drivers.mode = isActiveDriverIds ? "selectedIds" : "disabled";
      })
      .addCase(orderDeleted, (filters, action) => {
        const orderId = action.payload;

        // Clear filters
        filters.orders.selectedIds = filters.orders.selectedIds.filter((x) => x !== orderId);

        // Clear all order group filters
        filters.orders.groups = initialState.orders.groups;

        const isActiveOrderIds = filters.orders.selectedIds.length > 0;
        filters.orders.mode = isActiveOrderIds ? "selectedIds" : "disabled";
      })
      .addCase(driverRouteUpdated, (filters, action) => {
        const driversRoute = action.payload;

        // Clear filters
        const isCompleted = driversRoute.routePoints.every(
          (rp) => rp.orderStatus === OrderStatus.Delivered
        );

        if (isCompleted) {
          filters.driversRoutes.selectedIds = filters.driversRoutes.selectedIds.filter(
            (x) => x !== driversRoute.id
          );

          filters.driversRoutes.groups = initialState.driversRoutes.groups;
        }

        filters.driversRoutes.mode = "disabled";
      })
      .addCase(orderUpdated, (filters, { payload: data }) => {
        if (data.orderStatus === OrderStatus.Delivered) {
          filters.orders.selectedIds = filters.orders.selectedIds.filter((x) => x !== data.id);

          // Clear all order group filters
          filters.orders.groups = initialState.orders.groups;
        }

        const isActiveOrderIds = filters.orders.selectedIds.length > 0;
        filters.orders.mode = isActiveOrderIds ? "selectedIds" : "disabled";
      }),
});

export const {
  changeSearchFilter,
  changeSort,
  changeCityFilter,
  changeDeliveryTypeFilter,
  changeDriversRoutesFilterGroups,
  driversCategory,
  cleanupFilters,
  selectedDriverId,
  selectedOrderId,
  selectedRouteId,
} = driversSlice.actions;

export default driversSlice.reducer;
