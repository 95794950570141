import { IDriverActiveStatusDto } from "./drivers/dtos/driverActiveStatusDto";
import { IDriverDto } from "./drivers/dtos/driverDto";
import { IAlgorithmStatusResponseDto } from "./orders/dtos/orders/algorithmStatusResponseDto";
import { IDriverRouteResponseDto } from "./orders/dtos/orders/driverRouteResponseDto";
import { IFullOrderDto } from "./orders/dtos/orders/fullOrderDto";
import { IRoutePointDto } from "./orders/dtos/orders/routePointDto";
import { connectionBuilder } from "./signalR.client";
import { HubConnection } from "@microsoft/signalr";
import { Subject } from "rxjs";

export interface IReceiveCoordinatesResponse {
  userId: string;
  latitude: number;
  longitude: number;
  batteryPercentage: number;
  recievedOn: string;
}

export const driverStartedOrEndedPause: Subject<IDriverActiveStatusDto> = new Subject();
export const driverStartedWorking: Subject<IDriverDto> = new Subject();
export const driverEndedWorking: Subject<number> = new Subject();
export const orderUpdated: Subject<IFullOrderDto> = new Subject();
export const orderDeleted: Subject<number> = new Subject();
export const coordinatesReceived: Subject<IReceiveCoordinatesResponse> = new Subject();
export const driverRoutePointUpdate: Subject<IRoutePointDto> = new Subject();
export const driverRouteUpdate: Subject<IDriverRouteResponseDto> = new Subject();
export const algorithmStatusUpdate: Subject<IAlgorithmStatusResponseDto> = new Subject();

var driversAndOrdersConnection: HubConnection;
var geolocationsConnection: HubConnection;

export const getBaseURL = (): string => {
  return import.meta.env.VITE_API_BASE_URL?.toString() ?? "";
};

const receiveCoordinatesListener = (data: IReceiveCoordinatesResponse) => {
  coordinatesReceived.next(data);
};

const orderUpdatedListener = (data: IFullOrderDto) => {
  orderUpdated.next(data);
};

const orderDeletedListener = (data: number) => {
  orderDeleted.next(data);
};

const driverStartedOrEndedPauseListener = (data: IDriverActiveStatusDto) => {
  driverStartedOrEndedPause.next(data);
};

const driverStartedWorkingListener = (data: IDriverDto) => {
  driverStartedWorking.next(data);
};

const driverEndedWorkingListener = (data: number) => {
  driverEndedWorking.next(data);
};

const driverRoutePointUpdateListener = (data: IRoutePointDto) => {
  driverRoutePointUpdate.next(data);
};

const driverRouteUpdateListener = (data: IDriverRouteResponseDto) => {
  driverRouteUpdate.next(data);
};

const algorithmStatusUpdateListener = (data: IAlgorithmStatusResponseDto) => {
  algorithmStatusUpdate.next({ algorithmExecutionMode: data.algorithmExecutionMode });
};

export const disconnectGeolocationConnection = (): void => {
  if (geolocationsConnection) {
    geolocationsConnection.off("ReceiveCoordinates", receiveCoordinatesListener);

    geolocationsConnection.stop();
    geolocationsConnection = null;
  }
};

export const disconnectDriversAndOrdersConnection = (): void => {
  if (driversAndOrdersConnection) {
    driversAndOrdersConnection.off("OrderUpdated", orderUpdatedListener);
    driversAndOrdersConnection.off("OrderDeleted", orderDeletedListener);
    driversAndOrdersConnection.off("DriverStartedOrEndedPause", driverStartedOrEndedPauseListener);
    driversAndOrdersConnection.off("DriverStartedWorking", driverStartedWorkingListener);
    driversAndOrdersConnection.off("DriverEndedWorking", driverEndedWorkingListener);
    driversAndOrdersConnection.off("DriverRoutePointUpdate", driverRoutePointUpdateListener);
    driversAndOrdersConnection.off("DriverRoutesUpdate", driverRouteUpdateListener);
    driversAndOrdersConnection.off("AlgorithmStatusUpdate", algorithmStatusUpdateListener);

    driversAndOrdersConnection.stop();
    driversAndOrdersConnection = null;
  }
};

export const establishGeolocationConnection = (): Promise<void> => {
  return new Promise((resolve, reject) => {
    disconnectGeolocationConnection();

    if (!geolocationsConnection) {
      geolocationsConnection = connectionBuilder(getBaseURL(), "geolocations", "геолокација");
    }

    geolocationsConnection.on("ReceiveCoordinates", receiveCoordinatesListener);

    resolve();
  });
};

export const establishDriversAndOrdersConnection = (): Promise<void> => {
  return new Promise((resolve, reject) => {
    disconnectDriversAndOrdersConnection();

    if (!driversAndOrdersConnection) {
      driversAndOrdersConnection = connectionBuilder(getBaseURL(), "orders", "нарачки и возачи");
    }

    driversAndOrdersConnection.on("OrderUpdated", orderUpdatedListener);
    driversAndOrdersConnection.on("OrderDeleted", orderDeletedListener);
    driversAndOrdersConnection.on("DriverStartedOrEndedPause", driverStartedOrEndedPauseListener);
    driversAndOrdersConnection.on("DriverStartedWorking", driverStartedWorkingListener);
    driversAndOrdersConnection.on("DriverEndedWorking", driverEndedWorkingListener);
    driversAndOrdersConnection.on("DriverRoutePointUpdate", driverRoutePointUpdateListener);
    driversAndOrdersConnection.on("DriverRoutesUpdate", driverRouteUpdateListener);
    driversAndOrdersConnection.on("AlgorithmStatusUpdate", algorithmStatusUpdateListener);

    resolve();
  });
};
