import { IconButton, Menu, MenuItem } from "@material-ui/core";
import { MonetizationOnOutlined } from "@material-ui/icons";
import { SelectOption } from "@shared/components/CustomFormControls/CustomMenuSelect";
import { useConfirmationDialog } from "@shared/context/ConfirmationDialogContext";
import { manuallyChargeTaximeter } from "@shared/services/orders/orders.service";
import { useSnackbar } from "notistack";
import { FC, useState } from "react";

type PaymentType = "online" | "cash";

const paymentOptions: SelectOption<PaymentType>[] = [
  {
    id: "online",
    name: "Наплати со картичка",
  },
  {
    id: "cash",
    name: "Наплати во кеш",
  },
];

interface UnsuccessfulPaymentMenuProps {
  orderId: number;
}

const UnsuccessfulPaymentMenu: FC<UnsuccessfulPaymentMenuProps> = ({ orderId }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const confirmationDialog = useConfirmationDialog();
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemClick = (paymentType: PaymentType) => {
    setAnchorEl(null);
    confirmationDialog.setLoading(true);

    manuallyChargeTaximeter(orderId, paymentType)
      .then(() => {
        enqueueSnackbar("Успешно наплатено", {
          variant: "success",
          autoHideDuration: 6000,
        });
      })
      .catch((error) => {
        enqueueSnackbar(`Неуспешно наплатено: ${error.message}`, {
          variant: "error",
          autoHideDuration: 6000,
        });
      })
      .finally(() => {
        confirmationDialog.close();
      });
  };

  return (
    <div>
      <IconButton size="small" aria-haspopup="true" onClick={handleClick}>
        <MonetizationOnOutlined />
      </IconButton>

      <Menu
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {paymentOptions.map((paymentOption) => (
          <MenuItem dense={true} onClick={() => handleItemClick(paymentOption.id)}>
            {paymentOption.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default UnsuccessfulPaymentMenu;
