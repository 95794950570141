import { Box, Button, Dialog, DialogContent, DialogTitle, DialogContentText, DialogActions, Typography, createStyles, makeStyles, Divider } from "@material-ui/core";
import { OpenInNew } from "@material-ui/icons";
import { IBonusPenaltyDto } from "@shared/services/payroll/dtos/payrollPeriodDayOverviewDto";
import { FC, useState } from "react";


const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      minWidth: "100%",
      justifyContent: "space-between",
      textTransform: "lowercase",
    },
    earnings: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  })
);

interface BonusPenaltySummaryItemrops {
  item: IBonusPenaltyDto;
  index: number;
}

const BonusPenaltySummaryItem: FC<BonusPenaltySummaryItemrops> = ({
  item = null,
  index = 0,
}) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();
  const parseLines = (value) => value.replace(/(\\n)/g, "\n");

  return (
    <div>
      <Button size="large" className={classes.root} style={{
        background: item.type === 1 ? "green" : "red",
        color: "white",
      }} onClick={handleClickOpen}>
        <Divider variant="inset" component="button" />
        <Typography>{item.type === 1 ? "Бонус" : "Пенал"}</Typography>
        <Typography variant="subtitle1">{item.amount} ден.</Typography>
        <Typography variant="subtitle1" className={classes.earnings}>
          {/* {item.totalEarnings} ден */}
        </Typography>
        <OpenInNew />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Детали за ${item.type === 1 ? "бонус" : "пенал"}`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography style={{ whiteSpace: 'pre-line' }}>
              Причина: {item.reason}
            </Typography>
            <Typography style={{ whiteSpace: 'pre-line' }}>
              Сума: {item.amount} ден.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Затвори
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default BonusPenaltySummaryItem;
