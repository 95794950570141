import { EmployeeSalaryValidationSchema } from "../../../../Settings//Salaries/schemas/EmployeeSalaryValidationSchema";
import { Button, Icon, IconButton, TableCell, TableRow } from "@material-ui/core";
import { ImageSearchOutlined } from "@material-ui/icons";
import CustomCheckboxField from "@shared/components/CustomFormControls/CustomCheckboxField";
import { IEmployeeAreaDto } from "@shared/services/employees/dtos/employeeAreaDto";
import { updateEmployeeArea } from "@shared/services/employees/employees.service";
import { getVehicleIcon } from "@shared/utils/getVehicleIcon";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import React, { memo, useEffect, useState } from "react";
import { FC } from "react";

export interface Props {
  employeeArea: IEmployeeAreaDto;
  employeeId: number;
  isSelected: boolean;
  onSelect: () => void;
}

const initialValues = {
  isPickUpAllowed: false,
  isDropOffAllowed: false,
};

const EmployeeAreaListItem: FC<Props> = ({ employeeArea, employeeId, isSelected, onSelect }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [initialFormValuesState, setInitialFormValuesState] = useState<{
    initialValuesState: typeof initialValues;
  }>({ initialValuesState: { ...initialValues } });

  const handleSubmit = () => {
    if (!employeeArea || (employeeArea && !employeeArea.id) || !employeeId) {
      return;
    }

    updateEmployeeArea(employeeId, employeeArea.id, { ...formik.values })
      .then(() => {
        enqueueSnackbar("Успешно ги запишавте податоците", {
          variant: "success",
          autoHideDuration: 6000,
        });
      })
      .catch((err) => {
        enqueueSnackbar(`Неуспешно ги запишавте податоците: ${err.message}`, {
          variant: "error",
          persist: true,
          preventDuplicate: true,
          anchorOrigin: { horizontal: "center", vertical: "bottom" },
          action: function onDismiss(key) {
            return <Button onClick={() => closeSnackbar(key)}>OK</Button>;
          },
        });
      });
  };

  const {
    setValues,
    handleChange: handleFormikChange,
    ...formik
  } = useFormik({
    initialValues,
    validationSchema: EmployeeSalaryValidationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
    validateOnChange: false,
  });

  useEffect(() => {
    const newInitialValues = {} as typeof initialValues;
    Object.keys(initialValues).forEach((key) => {
      newInitialValues[key] = employeeArea[key] as any;
    });
    setInitialFormValuesState({ initialValuesState: { ...newInitialValues } });
    setValues(newInitialValues);
  }, [employeeArea]);

  return (
    <React.Fragment>
      <TableRow
        style={{ backgroundColor: isSelected ? "#eee" : "inherit" }}
        id={"pickup-dropoff-area-row-" + employeeArea.id}
        key={"pickup-dropoff-area-row-" + employeeArea.id}
      >
        <TableCell align="left">&nbsp;</TableCell>
        <TableCell
          id={"pickup-dropoff-area-city-" + employeeArea.cityName}
          key={"pickup-dropoff-area-city-" + employeeArea.cityName}
        >
          {employeeArea.cityName}
        </TableCell>
        <TableCell
          id={"pickup-dropoff-area-name-" + employeeArea.areaName}
          key={"pickup-dropoff-area-name-" + employeeArea.areaName}
        >
          {employeeArea.areaName}
        </TableCell>
        <TableCell
          id={"pickup-dropoff-area-vehicle-types-" + employeeArea.id}
          key={"pickup-dropoff-area-vehicle-types-" + employeeArea.id}
        >
          {employeeArea?.vehicleTypes?.map((x) => (
            <Icon title={x.name} style={{ padding: "0 5px", fontSize: "1em" }}>
              {getVehicleIcon(x.name)}
            </Icon>
          ))}
        </TableCell>
        <TableCell align="center" padding="checkbox">
          <CustomCheckboxField
            checked={formik.values.isPickUpAllowed}
            onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              handleFormikChange(event);
              formik.handleSubmit();
            }}
            name="isPickUpAllowed"
          />
        </TableCell>
        <TableCell align="center" padding="checkbox">
          <CustomCheckboxField
            checked={formik.values.isDropOffAllowed}
            onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              handleFormikChange(event);
              formik.handleSubmit();
            }}
            name="isDropOffAllowed"
          />
        </TableCell>
        <TableCell align="center" padding="checkbox">
          <IconButton
            type="button"
            size="small"
            onClick={() => {
              onSelect();
            }}
          >
            <ImageSearchOutlined color="secondary" />
          </IconButton>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default memo(EmployeeAreaListItem);
