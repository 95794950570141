import { FC, useState } from "react";
import { createStyles, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { useMemoSelector } from "@shared/hooks";
import TaximeterRatesConfig from "./components/TaximeterRatesConfig";
import CustomSelect from "@shared/components/CustomFormControls/CustomSelect";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      //   padding: theme.spacing(2),
    },
  })
);

const Taximeter: FC = () => {
  const classes = useStyles();

  const { cities, deliveryTypes } = useMemoSelector((state) => ({
    cities: state.sharedState?.cityTypes?.data?.map((x) => ({ text: x.name, value: x.id })),
    deliveryTypes: state.sharedState?.deliveryTypes?.data?.map((x) => ({
      text: x.name,
      value: x.id,
    })),
  }));

  const [cityId, setCityId] = useState<number>();
  const [deliveryTypeId, setDeliveryTypeId] = useState<number>();

  return (
    <Grid container spacing={4} className={classes.root}>
      <Grid item xs={12} sm={4} md={3}>
        <CustomSelect
          value={cityId}
          errorMessage={null}
          touched={false}
          onChange={setCityId}
          size="small"
          variant="outlined"
          selectOptions={cities}
          label="Град"
          multiple={false}
          id="city"
        />

        <br />
        <br />

        <CustomSelect
          value={deliveryTypeId}
          errorMessage={null}
          touched={false}
          onChange={setDeliveryTypeId}
          size="small"
          variant="outlined"
          selectOptions={deliveryTypes}
          multiple={false}
          label="Тип на испорака"
          id="deliveryType"
        />
      </Grid>

      <Grid item xs={12} sm={8} md={9}>
        {cityId && deliveryTypeId ? (
          <TaximeterRatesConfig
            city={cities.find(({ value }) => value === cityId)}
            deliveryType={deliveryTypes.find(({ value }) => value === deliveryTypeId)}
          />
        ) : (
          <Typography align="center">Избери град и тип на испорака</Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default Taximeter;
