import TableView from "../../../../../shared/components/Table/TableView";
import { ITableHeaderCell } from "../../../../../shared/components/Table/types/ITableHeaderCell";
import {
  getAllDeliveryTypes,
  removeDeliveryType,
} from "../../../../../shared/services/settings/delivery-types.service";
import { IDeliveryTypeDto } from "../../../../../shared/services/settings/dtos/delivery-types/deliveryTypeDto";
import DeliveryTypeForm from "./components/NewDeliveryTypeForm";
import { Button, IconButton, TableCell } from "@material-ui/core";
import { Delete, Edit, Warning } from "@material-ui/icons";
import { useConfirmationDialog } from "@shared/context/ConfirmationDialogContext";
import { IApiErrorResponseData } from "@shared/services/http.client";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import { FC, useCallback, useEffect, useState } from "react";

export interface IDeliveryType {
  id: number;
  name: string;
  createdOn: string;
  onEditClick?: (id: number) => void;
  onDeleteClick?: (id: number) => void;
}

const createDeliveryType = (
  id: number,
  name: string,
  createdOn: string,
  onEditClick?: (id: number) => void,
  onDeleteClick?: (id: number) => void
): IDeliveryType => ({
  id,
  name,
  createdOn,
  onEditClick,
  onDeleteClick,
});

const headerCells: ITableHeaderCell<IDeliveryType>[] = [
  { id: "id", label: "ID" },
  { id: "name", label: "Име" },
  {
    id: "createdOn",
    label: "Датум и време на додавање",
  },
  { id: "editButton", label: "", disableSorting: true },
  { id: "deleteButton", label: "", disableSorting: true },
];

const createRow = (row: IDeliveryType) => (
  <>
    <TableCell>{row.id}</TableCell>
    <TableCell>{row.name}</TableCell>
    <TableCell>{dayjs.utc(row.createdOn).local().format("LLL")}</TableCell>
    <TableCell padding="checkbox">
      <IconButton size="small" onClick={() => (row.onEditClick ? row.onEditClick(row.id) : null)}>
        <Edit />
      </IconButton>
    </TableCell>
    <TableCell padding="checkbox">
      <IconButton
        size="small"
        onClick={() => (row.onDeleteClick ? row.onDeleteClick(row.id) : null)}
      >
        <Delete />
      </IconButton>
    </TableCell>
  </>
);

const DeliveryTypeViewPage: FC = () => {
  const {
    data: deliveryTypes,
    isLoading,
    isFetching,
    error,
    refetch,
  } = useQuery(["deliveryTypes"], getAllDeliveryTypes, {
    refetchInterval: 15000,
  });

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  useEffect(() => {
    if (!error || !(error instanceof Error)) return;

    enqueueSnackbar(`Се случи грешка, обидете се повторно. ${error.message}`, {
      variant: "error",
      persist: true,
      preventDuplicate: true,
      anchorOrigin: { horizontal: "center", vertical: "bottom" },
      action: function onDismiss(key) {
        return <Button onClick={() => closeSnackbar(key)}>OK</Button>;
      },
    });
  }, [error, enqueueSnackbar, closeSnackbar]);

  const confirmationDialog = useConfirmationDialog();

  const handleDeleteClick = (id: number) => {
    const deliveryType = deliveryTypes.find((x: IDeliveryTypeDto) => x.id === id);

    if (deliveryType) {
      confirmationDialog.open({
        icon: <Warning fontSize="large" style={{ color: "orange" }} />,
        title: "Избриши тип",
        body: `Дали сте сигурни дека сакате да го избришете типот ${deliveryType.name} со ID ${deliveryType.id}?`,
        onConfirm: () => handleDelete(deliveryType.id),
        onDeny: () => confirmationDialog.close(),
      });
    }
  };

  const handleDelete = useCallback(
    (id: number) => {
      confirmationDialog.setLoading(true);

      removeDeliveryType(id)
        .then(() => {
          confirmationDialog.close();
          enqueueSnackbar("Успешно го избришавте типот на испорака", {
            variant: "success",
            autoHideDuration: 6000,
          });

          refetch();
        })
        .catch((error: AxiosError<IApiErrorResponseData>) => {
          confirmationDialog.close();
          enqueueSnackbar(
            `Не успеа да се отстрани типот на испорака: ${error.response?.data?.message}`,
            {
              variant: "error",
              persist: true,
              preventDuplicate: true,
              anchorOrigin: { horizontal: "center", vertical: "bottom" },
              action: function onDismiss(key) {
                return <Button onClick={() => closeSnackbar(key)}>OK</Button>;
              },
            }
          );
        });
    },
    [closeSnackbar, enqueueSnackbar, refetch]
  );

  const handleEditClick = (id: number) => {
    const deliveryType = deliveryTypes.find((x: IDeliveryTypeDto) => x.id === id);

    if (deliveryType) setSelectedRow(deliveryType);
  };

  const [selectedRow, setSelectedRow] = useState<IDeliveryType | null>(null);
  const handleAddTypeClick = () => {
    setSelectedRow(createDeliveryType(0, "", ""));
  };

  const handleAddTypeDialogClose = () => {
    setSelectedRow(null);
  };

  // Map data
  const rows: IDeliveryType[] = [];
  if (deliveryTypes) {
    deliveryTypes.forEach((x: IDeliveryTypeDto) => {
      rows.push(createDeliveryType(x.id, x.name, x.createdOn, handleEditClick, handleDeleteClick));
    });
  }

  return (
    <TableView
      title="Тип на испорака"
      addButtonText="Додади тип"
      onAddButtonClick={handleAddTypeClick}
      onRefreshClick={() => refetch()}
      headers={headerCells}
      rows={rows}
      render={createRow}
      isLoading={isLoading || isFetching}
      addDialog={
        <DeliveryTypeForm
          title={selectedRow?.id ? "Промени тип на испорака" : "Додади тип на испорака"}
          addButtonText={selectedRow?.id ? "Зачувај" : "Додади"}
          open={selectedRow ? true : false}
          row={selectedRow?.id ? selectedRow : null}
          onClose={handleAddTypeDialogClose}
          onSuccess={() => refetch()}
        />
      }
    />
  );
};

export default DeliveryTypeViewPage;
