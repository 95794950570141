import httpService from "../http.service";
import { ICompanyDto } from "./dtos/companies/companyDto";
import { ICreateCompanyDto } from "./dtos/companies/createCompanyDto";

const controllerUrl = "/api/companies";

export const getAllCompanies = () => {
  return httpService.get<ICompanyDto[]>(controllerUrl);
};

export const getCompanyById = (id: number) => {
  return httpService.get<ICompanyDto>(controllerUrl + `/${id}`);
};

export const insertNewCompany = (createCompany: ICreateCompanyDto) => {
  return httpService.post<ICompanyDto, ICreateCompanyDto>(controllerUrl, createCompany);
};

export const editCompany = (company: ICompanyDto) => {
  return httpService.put<ICompanyDto, ICompanyDto>(controllerUrl, company);
};

export const deleteCompany = (id: number) => {
  return httpService.delete<ICompanyDto>(controllerUrl + `/${id}`);
};
