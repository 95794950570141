import { NewEmployeeSchema } from "../schemas/NewEmployeeSchema";
import EmployeeDetailsPanel from "./EmployeeDetailsPanel";
import { Column, Localization } from "@material-table/core";
import { Theme, createStyles, makeStyles } from "@material-ui/core";
import { Refresh } from "@material-ui/icons";
import PaymentIcon from "@material-ui/icons/Payment";
import { Pagination } from "@material-ui/lab";
import CustomSelect from "@shared/components/CustomFormControls/CustomSelect";
import ImageUpload from "@shared/components/ImageUpload/ImageUpload";
import MaterialTable from "@shared/components/MaterialTable/MaterialTable";
import { useMemoSelector } from "@shared/hooks";
import { IEmployeeDto } from "@shared/services/employees/dtos/employeeDto";
import { translateSalaryType } from "@shared/services/employees/employees.service";
import { FC, memo, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ObjectSchema } from "yup";

const useRowStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiInput-root .MuiSvgIcon-root": {
        fontSize: "1rem",
      },
      "& .MuiSelect-root": {
        fontSize: "14px",
        width: "100%",
      },
      "& td > .MuiFormControl-root": {
        width: "100%",
      },
      "& th, & td": {
        paddingRight: "5px",
        paddingLeft: "5px",
        fontSize: "14px",
      },
    },
  })
);

const formatForLookup = (arr: { text: string; value: any }[]) =>
  arr?.reduce((obj, cur) => ({ ...obj, [cur.value]: cur.text }), {});

const validator = (schema: ObjectSchema<any>, employeeData: IEmployeeDto, field: string) => {
  try {
    schema.validateSyncAt(field, employeeData, { stripUnknown: true });
    return { isValid: true, helperText: "" };
  } catch (e: any) {
    // Not touched yet
    if (employeeData[field] === null || employeeData[field] === undefined) {
      return { isValid: false, helperText: "" };
    }

    return { isValid: false, helperText: e.message };
  }
};

type SearchColumn = "paymentTypes" | "deliveryTypes";

const filterBaseType = (
  filterTerm: string[],
  rowData: IEmployeeDto,
  column: Column<IEmployeeDto>,
  searchColumn: SearchColumn
) => {
  const filterType = Array.isArray(filterTerm) ? "filter" : "search";

  if (filterType === "search") {
    return Object.values(column.lookup).some((name) => name.includes(filterTerm));
  }

  if (filterTerm?.length === 0) {
    return true;
  }

  return filterTerm.every((filter) => rowData[searchColumn]?.includes(parseInt(filter)));
};

interface EmployeeTableProps {
  employeeData: IEmployeeDto[];
  onAdd?: (employee: IEmployeeDto) => void;
  onDelete?: (employee: IEmployeeDto) => void;
  onUpdate?: (employee: IEmployeeDto) => void;
  onRefresh?: () => void;
}

const EmployeeTable: FC<EmployeeTableProps> = ({
  employeeData,
  onAdd,
  onDelete,
  onUpdate,
  onRefresh,
}) => {
 
  const tableStyles = useRowStyles();
  const navigate = useNavigate();

  const { cityTypes, paymentTypes, deliveryTypes, employeePositionTypes } = useMemoSelector(
    ({ sharedState }) => ({
      cityTypes: sharedState?.cityTypes?.data?.map((x) => ({ text: x.name, value: x.id })),
      paymentTypes: sharedState?.paymentTypes?.data?.map((x) => ({ text: x.name, value: x.id })),
      deliveryTypes: sharedState?.deliveryTypes?.data?.map((x) => ({ text: x.name, value: x.id })),
      employeePositionTypes: sharedState?.employeePositionTypes?.data?.map((x) => ({
        text: x.name,
        value: x.id,
      })),
    })
  );

  const salaryTypeOptions = useMemo(
    () => [
      { value: 1, text: translateSalaryType(1) },
      { value: 2, text: translateSalaryType(2) },
    ],
    []
  );

  const columns: Column<IEmployeeDto>[] = [
    {
      title: "",
      field: "imageSrc",
      sorting: false,
      filtering: false,
      width: "5%",
      cellStyle: { paddingLeft: "1rem", paddingRight: "2rem" },
      editComponent: (row) => (
        <ImageUpload
          mode="upload"
          imageSrc={row.rowData.imageSrc}
          alt={`${row.rowData.firstName} ${row.rowData.lastName}`}
          onChange={(value) => row.onChange(value)}
        />
      ),
      render: (row) => (
        <ImageUpload
          mode="display"
          status={row.status}
          imageSrc={row.imageSrc}
          alt={`${row.firstName} ${row.lastName}`}
        />
      ),
    },
    {
      title: "Име",
      field: "firstName",
      validate: (employeeData) => validator(NewEmployeeSchema, employeeData, "firstName"),
    },
    {
      title: "Презиме",
      field: "lastName",
      validate: (employeeData) => validator(NewEmployeeSchema, employeeData, "lastName"),
    },
    {
      title: "Е-маил",
      field: "email",
      validate: (employeeData) => validator(NewEmployeeSchema, employeeData, "email"),
    },
    {
      title: "Телефон",
      field: "phoneNumber",
      validate: (employeeData) => validator(NewEmployeeSchema, employeeData, "phoneNumber"),
    },
    {
      title: "Позиција",
      field: "employeePositionFk",
      validate: (employeeData) => validator(NewEmployeeSchema, employeeData, "employeePositionFk"),
      lookup: formatForLookup(employeePositionTypes),
    },
    {
      title: "Тип на вработен",
      field: "salaryType",
      validate: (employeeData) => validator(NewEmployeeSchema, employeeData, "salaryType"),
      lookup: formatForLookup(salaryTypeOptions),
    },
    {
      title: "Град",
      field: "cityFk",
      validate: (employeeData) => validator(NewEmployeeSchema, employeeData, "cityFk"),
      lookup: formatForLookup(cityTypes),
    },
    {
      title: "Тип на наплата",
      field: "paymentTypes",
      lookup: formatForLookup(paymentTypes),
      validate: (employeeData) => validator(NewEmployeeSchema, employeeData, "paymentTypes"),
      customFilterAndSearch: (filterTerm, rowData, column) => {
        return filterBaseType(filterTerm, rowData, column, "paymentTypes");
      },
      editComponent: (row) => (
        <CustomSelect
          value={row.value}
          errorMessage={null}
          touched={row.error}
          onChange={(e) => row.onChange(e)}
          size="small"
          variant="standard"
          selectOptions={paymentTypes}
          multiple={true}
          id="paymentTypes"
        />
      ),
      render: (row) =>
        row.paymentTypes
          .sort()
          .map((id) => paymentTypes.filter((x) => x.value === id).map((x) => x.text))
          .join(", "),
    },
    {
      title: "Тип на достава",
      field: "deliveryTypes",
      lookup: formatForLookup(deliveryTypes),
      validate: (employeeData) => validator(NewEmployeeSchema, employeeData, "deliveryTypes"),
      customFilterAndSearch: (filterTerm, rowData, column) => {
        return filterBaseType(filterTerm, rowData, column, "deliveryTypes");
      },
      editComponent: (row) => (
        <CustomSelect
          value={row.value}
          errorMessage={null}
          touched={row.error}
          onChange={(e) => row.onChange(e)}
          size="small"
          variant="standard"
          selectOptions={deliveryTypes}
          multiple={true}
          id="paymentTypes"
        />
      ),
      render: (row) =>
        row?.deliveryTypes
          ?.sort()
          .map((id) => deliveryTypes?.filter((x) => x.value === id).map((x) => x.text))
          .join(", "),
    },
  ];

  const localizationData: Localization = {
    body: {
      emptyDataSourceMessage: "Нема записи за прикажување",
      addTooltip: "Додади",
      deleteTooltip: "Избриши",
      editTooltip: "Уреди",
      filterRow: {
        filterPlaceHolder: "",
        filterTooltip: "Филтер",
      },
      editRow: {
        deleteText: "Дали сте сигурни дека сакате да го избришете овој вработен?",
        cancelTooltip: "Откажи",
        saveTooltip: "Зачувај",
      },
    },
    grouping: {
      placeholder: "Повлечете ја колоната ...",
      groupedBy: "Групирано по:",
    },
    header: {
      actions: "",
    },
    pagination: {
      labelDisplayedRows: "{from}-{to} од {count}",
      labelRowsSelect: "редови",
      labelRowsPerPage: "Редови по страна:",
      firstAriaLabel: "Прва страна",
      firstTooltip: "Прва страна",
      previousAriaLabel: "Претходна страна",
      previousTooltip: "Претходна страна",
      nextAriaLabel: "Следна Страна",
      nextTooltip: "Следна Страна",
      lastAriaLabel: "Последна страна",
      lastTooltip: "Последна страна",
    },
    toolbar: {
      addRemoveColumns: "Додадете или отстранете колони",
      nRowsSelected: "{0} избрани редови",
      showColumnsTitle: "Прикажи колони",
      showColumnsAriaLabel: "Прикажи колони",
      exportTitle: "Зачувај",
      exportAriaLabel: "Зачувај",
      exportPDFName: "Зачувај како PDF",
      exportCSVName: "Зачувај како CSV",
      searchTooltip: "Пребарување",
      searchPlaceholder: "Пребарување",
      searchAriaLabel: "Пребарување",
      clearSearchAriaLabel: "Исчисти",
    },
  };

  return (
    <div className={tableStyles.root}>
      <MaterialTable<IEmployeeDto>
        title="Вработени"
        columns={columns}
        infiniteLoad={false}
        data={employeeData ?? []}
        options={{
          addRowPosition: "first",
          actionsColumnIndex: -1,
          tableLayout: "auto",
          padding: "dense",
          pageSize: 10,
          pageSizeOptions: [10,20,30],
          filtering: true,
          grouping: true,
          search: true,
        }}
        localization={localizationData}
        actions={[
          {
            icon: Refresh,
            tooltip: "Вчитај повторно податоци",
            isFreeAction: true,
            onClick: onRefresh,
          },
          {
            icon: PaymentIcon,
            tooltip: "Конфигурирај плата",
            onClick: (_, data) => {
              
              const employee = data as IEmployeeDto;

              const pin = prompt("Внесете го вашиот PIN:");
              if (pin === "7768") {
                navigate(`/app/employees/${employee.id}/salary-config`);
              } else {
                alert("Погрешен PIN. Обидете се повторно!");
              }            },
          },
        ]}
        editable={{
          isEditable: () => true,
          isDeletable: () => true,
          onRowAdd: async (newData) => await onAdd(newData),
          onRowUpdate: async (newData) => await onUpdate(newData),
          onRowDelete: async (oldData) => await onDelete(oldData),
        }}
        detailPanel={({ rowData: employee }) => (
          <EmployeeDetailsPanel key={employee.id} employee={employee} onUpdate={onRefresh} />
        )}
        
      />
    </div>
  );
};


export default memo(EmployeeTable);