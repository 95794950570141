import { FC, memo } from "react";
import { IMapOrder } from "@shared/components/Map/models/models";
import PackageUnassignedOrderMarker from "./UnassignedOrderMarker";
import { IOrderDto, PackageOrderGuid } from "@shared/services/order.service";

interface UnassignedOrderMarkersProps {
  orders: IOrderDto[];
}

const PackageUnassignedOrderMarkers: FC<UnassignedOrderMarkersProps> = ({
  orders,
}) => (
  <>
    {orders.map((order) => (
      <PackageUnassignedOrderMarker key={order.id} order={order} />
    ))}
  </>
);

export default memo(PackageUnassignedOrderMarkers);
