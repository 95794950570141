import { createSelector } from "@reduxjs/toolkit";
import { OrderStatus } from "@shared/services/orders/enums/orderStatus";

export const selectAllDriversRoutes = createSelector(
  (state: IGlobalState) => state.appState.logistics.driversRoutes,
  (driversRoutes) => {
    return driversRoutes.data.map((dr) => ({
      ...dr,
      routePoints: dr.routePoints.filter((rp) => rp.orderStatus !== OrderStatus.Delivered),
    }));
  }
);
