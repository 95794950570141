import ProfileListItem from "./ProfileListItem";
import Divider from "@material-ui/core/Divider";
import MatDrawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import {
  AccountTree,
  AttachMoney,
  CalendarToday,
  ChangeHistory,
  CreditCard,
  Description,
  DirectionsCar,
  ListAlt,
  LocalShipping,
  LocationCity,
  Map,
  Menu as MenuIcon,
  ReceiptOutlined,
  Schedule,
  Security,
  Settings,
  SupervisorAccount,
  Toc,
  Tune,
} from "@material-ui/icons";
import { Info as InfoIcon, Message as MessageIcon } from "@material-ui/icons";
import BusinessIcon from "@material-ui/icons/Business";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { CalendarMonth, CalendarViewWeek } from "@mui/icons-material";
import DrawerListItem from "@shared/components/Drawer/DrawerListItem";
import DrawerListMenu from "@shared/components/Drawer/DrawerListMenu";
import clsx from "clsx";
import { FC, useState } from "react";

type StyleProps = {
  width: number;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      height: "100%",
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    paper: {
      backgroundColor: theme.palette.background.default,
    },
    drawerOpen: {
      width: ({ width }: StyleProps) => width,
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: theme.spacing(0, 1),
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.text.primary,
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    mainNav: {
      height: "100%",
      overflowX: "hidden",
      overflowY: "auto",
    },
    drawerOpenButton: {
      marginLeft: "auto",
    },
  })
);

const DRAWER_WIDTH = 260;

const Drawer: FC = () => {
  const classes = useStyles({ width: DRAWER_WIDTH });

  const [isOpen, setIsOpen] = useState(false);
  const handleDrawerOpen = () => {
    setIsOpen(true);
  };

  const handleDrawerClose = () => {
    setIsOpen(false);
  };

  return (
    <MatDrawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: isOpen,
        [classes.drawerClose]: !isOpen,
      })}
      classes={{
        paper: clsx(classes.paper, {
          [classes.drawerOpen]: isOpen,
          [classes.drawerClose]: !isOpen,
        }),
      }}
    >
      <div className={clsx(classes.toolbar)}>
        <IconButton
          onClick={isOpen ? handleDrawerClose : handleDrawerOpen}
          className={clsx({
            [classes.drawerOpenButton]: isOpen,
          })}
        >
          {isOpen ? <ChevronLeftIcon /> : <MenuIcon />}
        </IconButton>
      </div>

      <List disablePadding className={classes.mainNav}>
        <DrawerListItem text="Логистика" to="/app/logistics" icon={<Map />} />

        <DrawerListItem text="Вработени" to="/app/employees" icon={<SupervisorAccount />} />

        <DrawerListMenu
          text="Плати"
          icon={<AttachMoney />}
          isDrawerOpen={isOpen}
          onClick={handleDrawerOpen}
        >
          <DrawerListItem text="Заработка" to="/app/payroll" icon={<Toc />} />

          <DrawerListItem text="Дневен промет" to="/app/dailyCash" icon={<AccountTree />} />

          <DrawerListItem text="Креирај исплата" to="/app/payouts/create" icon={<ReceiptOutlined />} />

          <DrawerListItem text="Прикажи исплати" to="/app/payouts/list" icon={<Toc />} />

        </DrawerListMenu>

        <DrawerListMenu
          text="Распоред"
          icon={<Schedule />}
          isDrawerOpen={isOpen}
          onClick={handleDrawerOpen}
        >
          {/* <DrawerListItem text="Месечно" to="/app/schedule/monthly" icon={<CalendarMonth />} /> */}

          <DrawerListItem text="Табеларно" to="/app/schedule/weekly" icon={<CalendarViewWeek />} />

          <DrawerListItem text="Дневно" to="/app/schedule/daily" icon={<CalendarToday />} />
        </DrawerListMenu>

        <DrawerListMenu
          text="Подесувања"
          icon={<Settings />}
          isDrawerOpen={isOpen}
          onClick={handleDrawerOpen}
        >
          <DrawerListItem text="Возила" to="/app/settings/vehicle" icon={<LocalShipping />} />

          <DrawerListItem
            text="Плати"
            to="/app/settings/role-salary-config"
            icon={<AttachMoney />}
          />

          <DrawerListItem text="Наплата" to="/app/settings/payment" icon={<CreditCard />} />

          <DrawerListItem text="Испораки" to="/app/settings/delivery" icon={<DirectionsCar />} />

          <DrawerListItem text="Компании" to="/app/settings/comapnies" icon={<BusinessIcon />} />

          <DrawerListItem text="Градови" to="/app/settings/cities" icon={<LocationCity />} />

          <DrawerListItem text="Зони" to="/app/settings/area" icon={<ChangeHistory />} />

          <DrawerListItem text="Улоги" to="/app/settings/role" icon={<Security />} />

          <DrawerListItem text="Причини" to="/app/settings/order" icon={<Description />} />

          <DrawerListItem
            text="Работна позиција"
            to="/app/settings/employee-positions"
            icon={<Description />}
          />

          <DrawerListItem text="Параметри" to="/app/settings/parameters" icon={<Tune />} />
        </DrawerListMenu>

        <DrawerListItem text="Лог" to="/app/logs" icon={<ListAlt />} />

        <DrawerListItem text="Chat" to="/app/chat" icon={<MessageIcon />} />
      </List>

      <Divider />

      <List disablePadding>
        <ProfileListItem onClick={handleDrawerOpen} />
      </List>
    </MatDrawer>
  );
};

export default Drawer;

