import { CircularProgress, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@material-ui/lab";
import { IOrderTrackChangesResponseDto } from "@shared/services/orders/dtos/orders/orderTrackChangesResponseDto";
import {
  getOrderLog,
  translateOrderChangeType,
  translateOrderStatus,
} from "@shared/services/orders/orders.service";
import dayjs from "dayjs";
import * as React from "react";
import { useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

interface Props {
  orderId: number;
}

const OrderLogDetails: React.FC<Props> = ({ orderId }) => {
  const classes = useStyles();

  const [trackChangesState, setTrackChangesState] = useState<IOrderTrackChangesResponseDto[]>([]);
  const [loadingState, setLoadingState] = useState<boolean>(false);
  const [errorState, setErrorState] = useState<string>("");

  useEffect(() => {
    if (orderId) {
      setLoadingState(true);
      getOrderLog(orderId)
        .then((res) => {
          setTrackChangesState(res);
          setLoadingState(false);
        })
        .catch((e) => {
          if (e.data) setErrorState(e.data.message);
          else setErrorState(e.message);

          setTrackChangesState([]);
          setLoadingState(false);
        });
    } else {
      setTrackChangesState([]);
      setLoadingState(false);
    }
  }, [orderId]);

  return (
    <div
      style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: 400 }}
    >
      {loadingState ? (
        <CircularProgress size={50} />
      ) : errorState ? (
        <Typography variant="h4">{errorState}</Typography>
      ) : trackChangesState.length > 0 ? (
        <Timeline align="left">
          {trackChangesState.map((orderChange, index) => (
            <TimelineItem key={orderChange.id}>
              <TimelineOppositeContent style={{ flex: "0 0 auto" }}>
                <Typography variant="body1" color="textPrimary">
                  {dayjs.utc(orderChange.createdOn).local().format("DD.MM.YYYY")}
                  <br />
                  <strong>{dayjs.utc(orderChange.createdOn).local().format("HH:mm")}</strong>
                </Typography>
              </TimelineOppositeContent>

              <TimelineSeparator>
                <TimelineDot
                  variant="outlined"
                  color={index === trackChangesState.length - 1 ? "secondary" : "inherit"}
                />
                {trackChangesState.length - 1 > index ? <TimelineConnector /> : null}
              </TimelineSeparator>

              <TimelineContent>
                <Paper elevation={5} className={classes.paper}>
                  <Typography variant="body1" color="secondary">
                    {translateOrderStatus(orderChange.orderStatus)}
                  </Typography>

                  {(orderChange.orderUpdatedFromFirstName &&
                    orderChange.orderUpdatedFromLastName) ||
                  orderChange.orderChangeType ? (
                    <Typography variant="body2" color="textSecondary">
                      <strong>Променетa од:</strong>{" "}
                      {[
                        translateOrderChangeType(orderChange.orderChangeType),
                        `${orderChange.orderUpdatedFromFirstName || ""} ${
                          orderChange.orderUpdatedFromLastName || ""
                        }`.trim(),
                      ]
                        .filter(Boolean)
                        .join(" - ")}
                    </Typography>
                  ) : null}

                  {orderChange.orderAssignToFirstName && orderChange.orderAssignToLastName && (
                    <Typography variant="body2" color="textSecondary">
                      <strong>Доделена на:</strong> {orderChange.orderAssignToFirstName}{" "}
                      {orderChange.orderAssignToLastName}
                    </Typography>
                  )}

                  <Typography variant="body2" color="textSecondary">
                    <strong>Опис:</strong> {orderChange.description}
                  </Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      ) : (
        <Typography variant="h4" color="textPrimary">
          Нема податоци
        </Typography>
      )}
    </div>
  );
};
export default OrderLogDetails;
