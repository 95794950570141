import { FC, Fragment, memo } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { useMemoSelector } from "@shared/hooks";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAllDrivers,
  selectDriversByCity,
  selectDriversByDeliveryType,
  selectDriversByVehicleType,
  selectFreeDrivers,
  selectPausedDrivers,
  selectProblemDrivers,
} from "../../store/features/drivers/driverSelectors";
import { IFilterState, driversCategory } from "@Logistics/store/features/filters/filtersSlice";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  filterToggle: {
    paddingTop: theme.spacing(0.25),
    paddingBottom: theme.spacing(0.25),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
    cursor: "pointer",
  },
  filterToggleEnabled: {
    backgroundColor: theme.palette.info.main,
  },
}));

type filterGroup = keyof IFilterState["drivers"]["groups"];
type driverGroup = { id: number | string; name: string; count: number; isFilterActive };

export interface DriverSummaryRowProps {}

const DriverSummaryRow: FC<DriverSummaryRowProps> = () => {
  const classes = useStyles();

  const driversByVehicleType: driverGroup[] = useMemoSelector((state) =>
    state.sharedState.vehicleTypes.data.map(
      (vehicleType): driverGroup => ({
        id: vehicleType.id,
        name: vehicleType.name,
        count: selectDriversByVehicleType(state, vehicleType.id).length,
        isFilterActive: state.appState.logistics.filters.drivers.groups.vehicleType.includes(
          vehicleType.id
        ),
      })
    )
  );

  const driversByCity: driverGroup[] = useMemoSelector((state) =>
    state.sharedState.cityTypes.data.map((city) => ({
      id: city.id,
      name: city.name,
      count: selectDriversByCity(state, city.id).length,
      isFilterActive: state.appState.logistics.filters.drivers.groups.city.includes(city.id),
    }))
  );

  const driversByDeliveryType: driverGroup[] = useMemoSelector((state) =>
    state.sharedState.deliveryTypes.data.map((deliveryType) => ({
      id: deliveryType.id,
      name: deliveryType.name,
      count: selectDriversByDeliveryType(state, deliveryType.id).length,
      isFilterActive: state.appState.logistics.filters.drivers.groups.deliveryType.includes(
        deliveryType.id
      ),
    }))
  );

  const summaries: Record<filterGroup, driverGroup[]> = {
    // Status
    availability: [
      {
        id: "busy",
        name: "Активни",
        count: useSelector((state: IGlobalState) => selectAllDrivers(state, true).length),
        isFilterActive: useSelector(
          ({ appState }: IGlobalState) =>
            appState.logistics.filters.drivers.groups.availability.busy
        ),
      },
      {
        id: "free",
        name: "Слободни",
        count: useSelector((state: IGlobalState) => selectFreeDrivers(state, true).length),
        isFilterActive: useSelector(
          ({ appState }: IGlobalState) =>
            appState.logistics.filters.drivers.groups.availability.free
        ),
      },
      {
        id: "paused",
        name: "Паузирани",
        count: useSelector((state: IGlobalState) => selectPausedDrivers(state, true).length),
        isFilterActive: useSelector(
          ({ appState }: IGlobalState) =>
            appState.logistics.filters.drivers.groups.availability.paused
        ),
      },
      // { name: "Фалат", count: 2 },
    ],

    // Problem (drivers with lost GPS or low battery)
    problem: [
      {
        id: "problem",
        name: "Проблем",
        count: useSelector((state: IGlobalState) => selectProblemDrivers(state).length),
        isFilterActive: useSelector(
          ({ appState }: IGlobalState) =>
            appState.logistics.filters.drivers.groups.problem.lostLocation ||
            appState.logistics.filters.drivers.groups.problem.lowBattery ||
            appState.logistics.filters.drivers.groups.problem.problem
        ),
      },
    ],

    capabilities: [
      {
        id: "hasFiscalPrinter",
        name: "Каса",
        count: useSelector((state: IGlobalState) => selectAllDrivers(state, true)).filter(
          (driver) => driver.hasFiscalPrinter
        ).length,
        isFilterActive: useSelector(
          ({ appState }: IGlobalState) =>
            appState.logistics.filters.drivers.groups.capabilities.hasFiscalPrinter
        ),
      },
    ],

    // City
    city: driversByCity,

    // Vehicle type
    vehicleType: driversByVehicleType,

    // Delivery type
    deliveryType: driversByDeliveryType,
  };

  const dispatch = useDispatch();

  const handleFilterClick = (
    event,
    name: keyof IFilterState["drivers"]["groups"],
    type: number | string,
    isEnabled: boolean
  ) => {
    event.stopPropagation();

    dispatch(driversCategory({ group: name, type, isEnabled: !isEnabled }));
  };

  const renderDriverGroupCounts = (id: filterGroup, driverGroup: driverGroup[]) => {
    const filtered = driverGroup.filter((group) => group.count > 0);

    if (filtered.length === 0) return null;

    return (
      <Typography>
        {filtered.map((group, i) => (
          <Fragment key={group.name}>
            {group.count >= 0 && (
              <>
                <Typography
                  component="span"
                  onClick={(e) => handleFilterClick(e, id, group.id, group.isFilterActive)}
                  className={clsx(classes.filterToggle, {
                    [classes.filterToggleEnabled]: group.isFilterActive,
                  })}
                >
                  {group.name} ({group.count})
                </Typography>
                <Typography component="span" color="secondary">
                  {i < filtered.length - 1 ? " / " : ""}
                </Typography>
              </>
            )}
          </Fragment>
        ))}
      </Typography>
    );
  };

  return (
    <>
      {Object.entries(summaries).map(([id, summary]) => (
        <Fragment key={id}>{renderDriverGroupCounts(id as any, summary)}</Fragment>
      ))}
    </>
  );
};

export default memo(DriverSummaryRow);
