import { FC, Fragment, memo } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Avatar,
  Divider,
  Card,
  Chip,
  Button,
  CardActions,
  CardHeader,
} from "@material-ui/core";
import {
  Schedule,
  CreditCard,
  LocalShipping,
  Info,
  PanTool,
  Storefront,
} from "@material-ui/icons";
import ChipContainer from "@shared/components/ChipContainer/ChipContainer";
import CustomMenuSelect, { SelectOption } from "@shared/components/CustomFormControls/CustomMenuSelect";
import dayjs from "dayjs";
import { IPendingOrderDto } from "@shared/services/orders/dtos/orders/pendingOrderDto";
import { useMemoSelector } from "@shared/hooks";
import { getDisplayValue } from "@shared/utils/getDisplayValue";
import Typography from "@material-ui/core/Typography";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
  card: {
    width: "100%",
  },
  cardHeader: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    padding: theme.spacing(1),
  },
  cardHeaderTitle: {
    textAlign: "center",
  },
  order: {
    textAlign: "left",
  },
  routePointContainer: {
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  addressContainer: {},
  addressText: {
    whiteSpace: "normal",
    fontWeight: "normal",
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.text.primary,
    padding: theme.spacing(1),
  },
  chipIcon: {
    backgroundColor: "transparent !important",
  },
  restaurantContainer: {
    display: "flex",
    gap: theme.spacing(1),
    alignItems: "center",
  },
  restaurantImage: {
    width: "60px",
    height: "60px",
  },
  restaurantNameContainer: {
    overflow: "auto",
  },
}));

interface OrderCardProps {
  ordersPackage: IPendingOrderDto[];

  onConfirmClick: (orderId: number, mergeGuid: string, packageGuid: string) => void;
  onDenyClick: (
    orderId: number,
    mergeGuid: string,
    packageGuid: string,
    rejectReason: SelectOption<number>
  ) => void;
}

const NewOrderCard: FC<OrderCardProps> = ({
  ordersPackage,
  onConfirmClick,
  onDenyClick,
}) => {
  const classes = useStyles();

  const { paymentTypes, deliveryTypes, orderRejectReasons } = useMemoSelector(
    ({ sharedState }) => ({
      paymentTypes: sharedState.paymentTypes.data,
      deliveryTypes: sharedState.deliveryTypes.data,
      orderRejectReasons: sharedState?.orderRejectReasons?.data,
    })
  );

  const getPaymentTypeText = (FK: number) => getDisplayValue(paymentTypes, FK);
  const getDeliveryTypeText = (FK: number) =>
    getDisplayValue(deliveryTypes, FK);

  if (ordersPackage.length === 0) return null;

  const baseOrder = ordersPackage[0];

  return (
    <Card
      data-testid="order-card"
      variant="elevation"
      className={classes.card}
      square
    >
      <CardHeader
        title={ordersPackage.length > 1 ? "НОВИ НАРАЧКИ" : "НОВА НАРАЧКА"}
        titleTypographyProps={{
          variant: "h6",
          className: classes.cardHeaderTitle,
        }}
        classes={{ root: classes.cardHeader }}
      />

      <Divider />

      {ordersPackage.map((order) => (
        <Fragment key={order.orderId}>
          <div className={classes.order}>
            {/* Order descriptors */}
            <ChipContainer>
              {getPaymentTypeText(order.paymentTypeFK) ? (
                <Chip
                  size="small"
                  label={getPaymentTypeText(order.paymentTypeFK)}
                  avatar={<CreditCard />}
                  variant="outlined"
                  component="li"
                  title="Тип на наплата"
                />
              ) : null}

              {getDeliveryTypeText(order.deliveryTypeFK) ? (
                <Chip
                  size="small"
                  label={getDeliveryTypeText(order.deliveryTypeFK)}
                  avatar={<LocalShipping />} // TODO: Switch icon depending on type
                  variant="outlined"
                  component="li"
                  title="Тип на пратка"
                />
              ) : null}

              {baseOrder.isPriority ? (
                <Chip
                  data-testid="priority-indicator"
                  size="small"
                  label="Приоритетна"
                  avatar={<Info />}
                  classes={{
                    avatar: classes.chipIcon,
                  }}
                  variant="default"
                  color="secondary"
                  component="li"
                  title="Приоритетна"
                />
              ) : null}

              {baseOrder.isContactless ? (
                <Chip
                  data-testid="contactless-indicator"
                  size="small"
                  label="Бесконтактна"
                  avatar={<PanTool />}
                  classes={{
                    avatar: classes.chipIcon,
                  }}
                  variant="default"
                  color="secondary"
                  component="li"
                  title="Бесконтактна достава"
                />
              ) : null}
            </ChipContainer>

            <Divider />

            {/* Pick up */}
            <div className={classes.routePointContainer}>
              <Typography variant="caption">ПОДИГНУВАЊЕ</Typography>

              {/* Restaurant information */}
              <div className={classes.restaurantContainer}>
                <Avatar
                  variant="rounded"
                  src={order?.restaurantPhoto}
                  className={classes.restaurantImage}
                >
                  <Storefront />
                </Avatar>

                <div className={classes.restaurantNameContainer}>
                  <Typography variant="h6">
                    {order.restaurantName}
                  </Typography>

                  <ChipContainer
                    disableHorizontalPadding
                    disableVerticalPadding
                  >
                    <Chip
                      size="small"
                      label={dayjs
                        .utc(order.pickUpTime)
                        .local()
                        .format("HH:mm")}
                      variant="outlined"
                      avatar={<Schedule />}
                      component="li"
                      title="Време на подигнување"
                    />
                  </ChipContainer>
                </div>
              </div>

              <Typography variant="h6" className={classes.addressText}>
                {order.restaurantAddress}
              </Typography>
            </div>

        {/* <Divider /> */}

        {/* Drop off */}
        {/* <div className={classes.routePointContainer}>
          <Typography variant="caption">ДОСТАВА</Typography>

              <ChipContainer disableHorizontalPadding disableVerticalPadding>
                <Chip
                  size="small"
                  label={dayjs.utc(order.dropOffTime).local().format("HH:mm")}
                  variant="outlined"
                  avatar={<Schedule />}
                  component="li"
                  title="Време на подигнување"
                />
              </ChipContainer>

          <Typography variant="h6" className={classes.addressText} noWrap>
            {dropOffAddressText}
          </Typography>
        </div> */}
      </div>

          <Divider />
        </Fragment>
      ))}

      {/* Buttons */}
      <CardActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onConfirmClick(baseOrder.orderId, baseOrder.mergeGuid, baseOrder.packageGuid)}
          fullWidth
        >
          Прифати
        </Button>

        <CustomMenuSelect
          onSelected={(reason: SelectOption<number>) =>
            onDenyClick(baseOrder.orderId, baseOrder.mergeGuid, baseOrder.packageGuid, reason)
          }
          children="Одбиј"
          selectoptions={orderRejectReasons}
          color="secondary"
          variant="contained"
          fullWidth
        />
      </CardActions>
    </Card>
  );
};

export default memo(NewOrderCard);