import PaycheckHeader from "./components/PaycheckHeader";
import PayrollDayDetails from "./components/PayrollDayDetails";
import RoutePointSummaryItem from "./components/RoutePointSummaryItem";
import { Button, Grid, Paper, createStyles, makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
// import {
//   getEmployeePayrollsForEmployee,
//   recalculateEmplyoeePayroll,
// } from "@shared/services/payroll/payroll-old.service";
import { getBonusesAndPenaltiesForEmployee, getPayrollDayDriverRoutesForDriver } from "@shared/services/payroll/payroll.service";
import { useMutation, useQuery } from "@tanstack/react-query";
import { FC, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import BonusPenaltySummaryItem from "./components/BonusPenaltySummaryItem";

const useStyles = makeStyles((theme) =>
  createStyles({
    back: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    recalculate: {
      display: "flex",
      justifyContent: "space-around",
      padding: theme.spacing(2),
    },
  })
);

type PaycheckDetailsParams = {
  payrollId?: string;
  day?: string;
};

const PaycheckDetails: FC = () => {
  const classes = useStyles();
  const params = useParams<PaycheckDetailsParams>();
  const employeeId = useMemo(() => Number(params.payrollId), [params]);
  const day = useMemo(() => Number(params.day), [params]);
  const [payrollDay, setPayrollDay] = useState<any>();
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [totalKm, settotalKm] = useState(0);
  const [totalBonuses, setTotalBonuses] = useState(0);
  const [bonuses, setBonuses] = useState([]);
  const [penalties, setPenalties] = useState([]);
  const [totalPenalties, setTotalPenalties] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");



  
  const bonusesAndPenaltiesQuery = useQuery(
    ["getBonusesAndPenaltiesForEmployee", employeeId, params.day],
    () => getBonusesAndPenaltiesForEmployee(employeeId, params.day),
    { keepPreviousData: true }
  );


  useEffect(() => {
    if (bonusesAndPenaltiesQuery.data) {
      const bonuses = bonusesAndPenaltiesQuery.data.filter(item => item.type === 1);
      const penalties = bonusesAndPenaltiesQuery.data.filter(item => item.type === 2);

      setBonuses(bonuses);
      setPenalties(penalties);

      const totalBonuses = bonuses.reduce((sum, bonus) => sum + bonus.amount, 0);
      const totalPenalties = penalties.reduce((sum, penalty) => sum + penalty.amount, 0);

      setTotalBonuses(totalBonuses);
      setTotalPenalties(totalPenalties);
    }
  }, [bonusesAndPenaltiesQuery.data]);

  useEffect(() => {
    getPayrollDayDriverRoutesForDriver(employeeId, params.day)
      .then((payroll) => {
        setPayrollDay(payroll);
        const totalEarnings = payroll.reduce((acc, obj) => acc + obj.totalEarnings, 0);
        setTotalEarnings(totalEarnings);
        const totalKm = payroll.reduce((acc, obj) => acc + obj.totalKm, 0);
        settotalKm(parseFloat(totalKm.toFixed(1)));
      })
      .catch((error: string) => {
        if (error) setErrorMessage(error);
      });
  }, []);





  if (!payrollDay || !bonusesAndPenaltiesQuery) return <Skeleton variant="rect" height={100} />;

  return (
    <Grid container>
      <Grid item xs={12}>
        <PaycheckHeader />
      </Grid>

      <Grid item xs={12}>
        <PayrollDayDetails
          totalEarnings={totalEarnings}
          totalKm={totalKm}
          totalBonuses={totalBonuses}
          totalPenalties={totalPenalties}
        />
      </Grid>

      <Grid item xs={12}>
        {/* <PayrollSummary employeePayroll={payrollDay} /> */}
      </Grid>

      <Grid item xs={12}>
      <h2 style={{textAlign: "center"}}>Рути</h2>
        {payrollDay.map((item, index) => (
          <RoutePointSummaryItem key={index} index={index} item={item} />
        ))}
      </Grid>
      <Grid item xs={12}>
        {bonusesAndPenaltiesQuery.data.length > 0 && (
        <h2 style={{textAlign: "center"}}>Бонуси и пенали</h2>
        )}
        {bonusesAndPenaltiesQuery.data.map((item, index) => (
          <BonusPenaltySummaryItem key={index} index={index} item={item} />
        ))}
      </Grid>

      <Grid item xs={12}>
        <Paper square className={classes.recalculate}>
       {/*   <Button variant="contained" color="secondary" onClick={() => recalculate()}>
            Освежи
        </Button> */}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default PaycheckDetails;

