import { Button, Grid, Paper, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { CancelOutlined, CheckCircleOutlineRounded } from "@material-ui/icons";
import CustomSelect from "@shared/components/CustomFormControls/CustomSelect";
import { useMemoSelector } from "@shared/hooks";
import { getCurrentUser } from "@shared/services/auth/auth.service";
import { IEmployeeDto } from "@shared/services/employees/dtos/employeeDto";
import { getEmployeeByUserId } from "@shared/services/employees/employees.service";
import { IApiErrorResponseData } from "@shared/services/http.client";
import { IWorkShiftRequestDto } from "@shared/services/settings/dtos/workshift/workShiftRequestDto";
import {
  endWorkShiftAsync,
  hasUserStartWorkingAsync,
  insertWorkShiftAsync,
} from "@shared/services/settings/workshift.service";
import { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import { orange, blue } from "@material-ui/core/colors";
import { FC, useState, useEffect } from "react";


const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            height: "100%"
        },
        box: {
            height: "100%",
            padding: "15px",
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            justifyContent: "center"
        },
        header: {
            padding: "15px",
        },
        content: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            flexGrow: 1
        },
        footer: {
            padding: "15px",
        },
        orangeButton: {
            backgroundColor: orange[500],
            color: theme.palette.getContrastText(orange[500]),
            '&:hover': {
                backgroundColor: orange[700],
            },
        },
        blueButton: {
            backgroundColor: blue[500],
            color: theme.palette.getContrastText(blue[500]),
            '&:hover': {
                backgroundColor: blue[700],
            },
        }

    })
);

const Workshift: FC = () => {
  const classes = useStyles();

  const [currentStatusState, setCurrentShiftStatusState] = useState<{ status: boolean }>({
    status: false,
  });
  const [currentEmployeeState, setCurrentEmployeeState] = useState<{ employee: IEmployeeDto }>({
    employee: null,
  });
  const [currentSelectedFormValues, setCurrentSelectedFormValues] = useState<{
    vehicleTypeFk: number;
  }>({ vehicleTypeFk: 0 });
  const [loadingState, setLoadingState] = useState({
    vehicleTypes: true,
    shift: false,
  });
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const vehicleTypesOptions = useMemoSelector(({ sharedState }) =>
    sharedState?.vehicleTypes?.data?.map((x) => ({
      text: x.name,
      value: x.id,
    }))
  );

  useEffect(() => {
    if (!vehicleTypesOptions || vehicleTypesOptions.length <= 0) {
      setLoadingState((state) => ({ ...state, vehicleTypes: true }));
    } else {
      setLoadingState((state) => ({ ...state, vehicleTypes: false }));
    }
  }, [vehicleTypesOptions]);

  useEffect(() => {
    hasUserStartWorkingAsync()
      .then((res) => {
        setCurrentShiftStatusState((state) => ({ ...state, status: res }));
      })
      .catch((error: AxiosError<IApiErrorResponseData>) => {
        enqueueSnackbar(error.response?.data?.message || error.message, {
          variant: "error",
          persist: false,
          autoHideDuration: 6000,
          preventDuplicate: true,
          anchorOrigin: { horizontal: "center", vertical: "bottom" },
          action: (key) => {
            return <Button onClick={() => closeSnackbar(key)}>OK</Button>;
          },
        });
      });
  }, []);

  useEffect(() => {
    setLoadingState((state) => ({ ...state, vehicleTypes: true }));

    const currentId = getCurrentUser()?.id;
    if (currentId) {
      getEmployeeByUserId(currentId).then((res) => {
        setCurrentEmployeeState((state) => ({ ...state, employee: res }));
        setLoadingState((state) => ({ ...state, vehicleTypes: false }));
      });
    }
  }, []);

  const toggleShift = () => {
    setLoadingState((state) => ({ ...state, shift: true }));

    if (currentStatusState.status) {
      endWorkShiftAsync()
        .then(() => {
          setCurrentShiftStatusState((state) => ({ ...state, status: false }));
          setLoadingState((state) => ({ ...state, shift: false }));

          if (window.Android && window.Android.onShiftEnd) {
            window.Android.onShiftEnd();
          }
        })
        .catch((error: AxiosError<any>) => {
          setLoadingState((state) => ({ ...state, shift: false }));
          enqueueSnackbar(
            `Не може да ја завршите смена: ${error.response?.data?.message || error?.message}`,
            {
              variant: "error",
              persist: false,
              autoHideDuration: 6000,
              preventDuplicate: true,
              anchorOrigin: { horizontal: "center", vertical: "bottom" },
              action: function onDissmis(key) {
                return <Button onClick={() => closeSnackbar(key)}>OK</Button>;
              },
            }
          );
        });
    } else {
      let request: IWorkShiftRequestDto = {
        vehicleTypeFk: currentSelectedFormValues.vehicleTypeFk,
      };

      insertWorkShiftAsync(request)
        .then(() => {
          if (window.Android && window.Android.onShiftStart) {
            window.Android.onShiftStart();
          }

          setCurrentShiftStatusState((state) => ({ ...state, status: true }));
          setLoadingState((state) => ({ ...state, shift: false }));
        })
        .catch((error) => {
          setLoadingState((state) => ({ ...state, shift: false }));
          enqueueSnackbar(
            `Не може да се започне смена: ${error.response?.data?.message || error?.message}`,
            {
              variant: "error",
              persist: false,
              autoHideDuration: 6000,
              preventDuplicate: true,
              anchorOrigin: { horizontal: "center", vertical: "bottom" },
              action: (key) => {
                return <Button onClick={() => closeSnackbar(key)}>OK</Button>;
              },
            }
          );
        });
    }
  };

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12} style={{ display: "flex", flexDirection: "column" }}>
                {
                    currentEmployeeState.employee ?
                        <Paper className={classes.box} elevation={0}>
                            <div className={classes.header}>
                                <Typography align="center" variant="h4">
                                    Здраво, {currentEmployeeState.employee.firstName} {currentEmployeeState.employee.lastName}!
                                </Typography>
                            </div>
                            <div className={classes.content}>
                                {

                                    currentStatusState.status ?
                                        <>
                                            <Typography align="center" variant="h6" style={{ marginBottom: "30px" }}>
                                                Вашата смена е започната!
                                            </Typography>
                                            <CheckCircleOutlineRounded style={{ color: "lightgreen", fontSize: "10em" }} />
                                            <Typography align="center" variant="body1" style={{ marginBottom: "30px" }}>
                                                За завршување на смена само кликнете го копчето и со тоа системот ќе ве одјави дека примате нарачки за достава.
                                            </Typography>
                                        </>
                                        :
                                        <>
                                            <Typography align="center" variant="h6" style={{ marginBottom: "30px" }}>
                                                Вашата смена не е започната!
                                            </Typography>
                                            <CancelOutlined style={{ color: "orange", fontSize: "10em" }} />
                                            <Typography align="center" variant="body1" style={{ marginBottom: "30px" }}>
                                                За започнување на смена изберете тип на превозно средство и притиснете го копчето. Со тоа системот ќе почне да ви праќа нарачки кои се за достава.
                                            </Typography>
                                            {
                                                loadingState.vehicleTypes === false ?
                                                    <CustomSelect
                                                        label="Тип на возило"
                                                        value={currentSelectedFormValues.vehicleTypeFk}
                                                        errorMessage={""}
                                                        touched={false}
                                                        onChange={(e) => {
                                                            setCurrentSelectedFormValues((state) => ({ ...state, vehicleTypeFk: e }))
                                                        }}
                                                        size="small"
                                                        variant="outlined"
                                                        selectOptions={vehicleTypesOptions}
                                                        multiple={false}
                                                        key="employeePositionFk"
                                                        id="employeePositionFk"
                                                    /> : null
                                            }
                                        </>

                                }
                            </div>
                            <div className={classes.footer}>
    <Button
        onClick={toggleShift}
        variant="contained"
        className={`${currentStatusState.status ? classes.orangeButton : classes.blueButton}`}
        disabled={loadingState.shift}
    >
        {currentStatusState.status ? "Заврши смена" : "Започни смена"}
    </Button>
</div>
                        </Paper> : null
                }
            </Grid>
        </Grid >
    )
};

export default Workshift;
