import useFormStyles from "../hooks/useFormStyles";
import { Box, InputAdornment, TextField } from "@material-ui/core";
import { IEmployeeSalaryConfigurationDto } from "@shared/services/salary-configuration/dtos/employee-salary-configuration/employeeSalaryConfigurationDto";
import { useFormik } from "formik";
import { FC } from "react";

interface VacationsFormProps {
  formik: ReturnType<typeof useFormik<IEmployeeSalaryConfigurationDto>>;
}

const EmployeeVacationsForm: FC<VacationsFormProps> = ({ formik }) => {
  const classes = useFormStyles();

  return (
    <Box display="flex" flexDirection="column" className={classes.form}>
      <Box>
        <TextField
          variant="outlined"
          className={classes.formControl}
          id="maxHolidays"
          name="maxHolidays"
          label="Максимум празници (денови)"
          InputProps={{
            endAdornment: <InputAdornment position="start">денови</InputAdornment>,
          }}
          type="number"
          value={formik.values.maxHolidays}
          onChange={formik.handleChange}
          error={formik.touched.maxHolidays && Boolean(formik.errors.maxHolidays)}
          helperText={formik.touched.maxHolidays && formik.errors.maxHolidays}
        />
      </Box>

      <Box>
        <TextField
          variant="outlined"
          className={classes.formControl}
          id="holidayTimeEarn"
          name="holidayTimeEarn"
          label="Изработени часови за право на празник"
          InputProps={{
            endAdornment: <InputAdornment position="start">часови</InputAdornment>,
          }}
          type="number"
          value={formik.values.holidayTimeEarn}
          onChange={formik.handleChange}
          error={formik.touched.holidayTimeEarn && Boolean(formik.errors.holidayTimeEarn)}
          helperText={formik.touched.holidayTimeEarn && formik.errors.holidayTimeEarn}
        />
      </Box>

      <Box>
        <TextField
          variant="outlined"
          className={classes.formControl}
          id="holidayPay"
          name="holidayPay"
          label="Исплата за празник (по ден)"
          InputProps={{
            endAdornment: <InputAdornment position="start">мкд</InputAdornment>,
          }}
          type="number"
          value={formik.values.holidayPay}
          onChange={formik.handleChange}
          error={formik.touched.holidayPay && Boolean(formik.errors.holidayPay)}
          helperText={formik.touched.holidayPay && formik.errors.holidayPay}
        />
      </Box>
    </Box>
  );
};

export default EmployeeVacationsForm;
