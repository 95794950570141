import { object, string, date, array, number } from "yup";

export const NewEmployeeSchema = object({
  imageSrc: string().optional(),
  firstName: string().required("Задолжително"),
  lastName: string().required("Задолжително"),
  email: string().email("Не е валиден е-маил").required("Задолжително"),
  phoneNumber: string().required("Задолжително"),
  cityFk: number().min(1, "Задолжително").required("Задолжително"),
  employeePositionFk: number().min(1, "Задолжително").required("Задолжително"),
  salaryType: number().min(1).required("Задолжително"),
  paymentTypes: array().optional(),
  deliveryTypes: array().optional(),
});
