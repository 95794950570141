import dayjs from "dayjs";
import { useMemo } from "react";

export const useDaysInMonthArr = (date: string) => {
  const daysInMonth = useMemo(() => {
    if (!date) return [];
    const days = dayjs(date).daysInMonth();
    return [...Array(days).keys()].map((d) => d + 1);
  }, [date]);

  return daysInMonth;
};
