import { Box } from "@material-ui/core";
import { Pause, PlayArrow, Warning } from "@material-ui/icons";
import ColoredButton from "@shared/components/ColoredButton/ColoredButton";
import { useConfirmationDialog } from "@shared/context/ConfirmationDialogContext";
import { IApiErrorResponseData } from "@shared/services/http.client";
import {
  endDispatcherWorkShiftAsync,
  hasUserStartWorkingAsync,
  startDispatcherWorkShiftAsync,
} from "@shared/services/settings/workshift.service";
import { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import { FC, useEffect, useState } from "react";

const DispatcherShiftControl: FC = () => {
  const [isStarted, setIsStarted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { enqueueSnackbar } = useSnackbar();
  const confirmationDialog = useConfirmationDialog();

  useEffect(() => {
    setIsLoading(true);

    hasUserStartWorkingAsync()
      .then((data) => {
        setIsStarted(data);
      })
      .catch((error: AxiosError<IApiErrorResponseData>) => {
        enqueueSnackbar(`Грешка: ${error?.response?.data?.message}`, {
          variant: "error",
          autoHideDuration: 6000,
          anchorOrigin: { horizontal: "left", vertical: "bottom" },
        });
      })
      .finally(() => setIsLoading(false));
  }, [enqueueSnackbar]);

  const handleStartShift = () => {
    setIsLoading(true);

    startDispatcherWorkShiftAsync()
      .then(() => {
        setIsStarted(true);
      })
      .catch((error) => {
        enqueueSnackbar(`Грешка: ${error?.response?.data?.message}`, {
          variant: "error",
          autoHideDuration: 6000,
          anchorOrigin: { horizontal: "left", vertical: "bottom" },
        });
      })
      .finally(() => setIsLoading(false));
  };

  const handleEndShiftClick = () => {
    confirmationDialog.open({
      icon: <Warning fontSize="large" style={{ color: "orange" }} />,
      title: "Заврши смена?",
      body: `Дали сте сигурни дека сакате да ја завршите смената?`,
      onConfirm: handleEndShift,
      onDeny: () => confirmationDialog.close(),
    });
  };

  const handleEndShift = () => {
    confirmationDialog.setLoading(true);

    endDispatcherWorkShiftAsync()
      .then(() => {
        setIsStarted(false);
      })
      .catch((error) => {
        enqueueSnackbar(`Грешка: ${error?.response?.data?.message || error?.message}`, {
          variant: "error",
          autoHideDuration: 6000,
          anchorOrigin: { horizontal: "left", vertical: "bottom" },
        });
      })
      .finally(() => confirmationDialog.close());
  };

  return (
    <>
      {isLoading ? (
        <Box
          display="flex"
          alignItems="center"
          height="100%"
          sx={{
            marginLeft: "10px",
          }}
        >
          Се вчитува
        </Box>
      ) : isStarted ? (
        <ColoredButton
          size="small"
          variant="outlined"
          color="default"
          startIcon={<Pause color="error" />}
          onClick={handleEndShiftClick}
        >
          Смена
        </ColoredButton>
      ) : (
        <ColoredButton
          size="small"
          variant="outlined"
          color="success"
          startIcon={<PlayArrow color="inherit" />}
          onClick={handleStartShift}
        >
          Смена
        </ColoredButton>
      )}
    </>
  );
};

export default DispatcherShiftControl;

