import NewRoleForm from "./components/NewRoleForm";
import { Button, IconButton, TableCell } from "@material-ui/core";
import { Delete, Edit, Warning } from "@material-ui/icons";
import TableView from "@shared/components/Table/TableView";
import { ITableHeaderCell } from "@shared/components/Table/types/ITableHeaderCell";
import { useConfirmationDialog } from "@shared/context/ConfirmationDialogContext";
import { IApiErrorResponseData } from "@shared/services/http.client";
import { IRoleTypeDto } from "@shared/services/settings/dtos/role-types/roleTypeDto";
import { getAllRoles, removeRole } from "@shared/services/settings/role-types.service";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import { FC, useCallback, useEffect, useState } from "react";

export interface IRole {
  id: string;
  name: string;
  createdOn?: string;
  onEditClick?: (id: string) => void;
  onDeleteClick?: (id: string) => void;
}

const createRole = (
  id: string,
  name: string,
  createdOn?: string,
  onEditClick?: (id: string) => void,
  onDeleteClick?: (id: string) => void
): IRole => ({
  id,
  name,
  createdOn,
  onEditClick,
  onDeleteClick,
});

const headerCells: ITableHeaderCell<IRole>[] = [
  { id: "id", label: "ID" },
  { id: "name", label: "Име" },
  { id: "editButton", label: "", disableSorting: true },
  { id: "deleteButton", label: "", disableSorting: true },
];

const createRow = (row: IRole) => (
  <>
    <TableCell>{row.id}</TableCell>
    <TableCell>{row.name}</TableCell>
    <TableCell>{dayjs.utc(row.createdOn).local().format("LLL")}</TableCell>
    <TableCell padding="checkbox">
      <IconButton size="small" onClick={() => (row.onEditClick ? row.onEditClick(row.id) : null)}>
        <Edit />
      </IconButton>
    </TableCell>
    <TableCell padding="checkbox">
      <IconButton
        size="small"
        onClick={() => (row.onDeleteClick ? row.onDeleteClick(row.id) : null)}
      >
        <Delete />
      </IconButton>
    </TableCell>
  </>
);

const RoleViewPage: FC = () => {
  const {
    data: roles,
    isLoading,
    isFetching,
    error,
    refetch,
  } = useQuery(["roles"], getAllRoles, {
    refetchInterval: 15000,
  });

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  useEffect(() => {
    if (error) {
      enqueueSnackbar(
        `Се случи грешка, обидете се повторно. ${
          (error as AxiosError<IApiErrorResponseData>).response?.data?.message
        }`,
        {
          variant: "error",
          persist: true,
          preventDuplicate: true,
          anchorOrigin: { horizontal: "center", vertical: "bottom" },
          action: function onDismiss(key) {
            return <Button onClick={() => closeSnackbar(key)}>OK</Button>;
          },
        }
      );
    }
  }, [error, enqueueSnackbar, closeSnackbar]);

  const confirmationDialog = useConfirmationDialog();

  const handleDeleteClick = (id: string) => {
    const role = roles.find((x: IRoleTypeDto) => x.id === id);

    if (role) {
      confirmationDialog.open({
        icon: <Warning fontSize="large" style={{ color: "orange" }} />,
        title: "Избриши улога",
        body: `Дали сте сигурни дека сакате да ја избришете улогата ${role.name} со ID ${role.id}?`,
        onConfirm: () => handleDelete(role.id),
        onDeny: () => confirmationDialog.close(),
      });
    }
  };

  const handleDelete = useCallback(
    (id: string) => {
      confirmationDialog.setLoading(true);

      removeRole(id)
        .then(() => {
          confirmationDialog.close();
          enqueueSnackbar("Успешно ја избришавте улогата", {
            variant: "success",
            autoHideDuration: 6000,
          });

          refetch();
        })
        .catch((error: AxiosError<IApiErrorResponseData>) => {
          confirmationDialog.close();
          enqueueSnackbar(`Не успеа да се отстрани улогата: ${error.response?.data?.message}`, {
            variant: "error",
            persist: true,
            preventDuplicate: true,
            anchorOrigin: { horizontal: "center", vertical: "bottom" },
            action: function onDismiss(key) {
              return <Button onClick={() => closeSnackbar(key)}>OK</Button>;
            },
          });
        });
    },
    [closeSnackbar, enqueueSnackbar, refetch]
  );

  const handleEditClick = (id: string) => {
    const role = roles.find((x: IRole) => x.id === id);

    if (role) setSelectedRow(role);
  };

  const [selectedRow, setSelectedRow] = useState<IRole | null>(null);
  const handleAddTypeClick = () => {
    setSelectedRow(createRole("", ""));
  };

  const handleAddTypeDialogClose = () => {
    setSelectedRow(null);
  };

  // Map data
  const rows: IRole[] = [];
  if (roles) {
    roles.forEach((x: IRole) => {
      rows.push(createRole(x.id, x.name, x.createdOn, handleEditClick, handleDeleteClick));
    });
  }

  return (
    <TableView
      title="Улоги"
      addButtonText="Додади улога"
      onAddButtonClick={handleAddTypeClick}
      onRefreshClick={() => refetch()}
      headers={headerCells}
      rows={rows}
      render={createRow}
      isLoading={isLoading || isFetching}
      addDialog={
        <NewRoleForm
          title={selectedRow?.id ? "Промени улога" : "Додади улога"}
          addButtonText={selectedRow?.id ? "Зачувај" : "Додади"}
          open={selectedRow ? true : false}
          row={selectedRow?.id ? selectedRow : null}
          onClose={handleAddTypeDialogClose}
          onSuccess={() => refetch()}
        />
      }
    />
  );
};

export default RoleViewPage;
