import * as themes from "../../../../theme";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface IThemeState {
  isDarkMode: boolean;
  animations: boolean;

  borderRadius: number;
}

export interface IPreferencesState {
  theme: IThemeState;
  showConfirmationDialog: boolean;
}

const PREFERENCES_STORAGE_KEY = "preferences";

const loadPreferences = (): IPreferencesState => {
  const json = localStorage.getItem(PREFERENCES_STORAGE_KEY);

  if (!json) {
    return {
      theme: {
        isDarkMode: false,
        animations: true,
        borderRadius: themes.lightTheme.shape.borderRadius,
      },
      showConfirmationDialog: true,
    };
  }

  const data: IPreferencesState = JSON.parse(json);
  return data;
};

const initialState: IPreferencesState = {
  ...loadPreferences(),
};

const savePreferences = (state: IPreferencesState) => {
  localStorage.setItem(PREFERENCES_STORAGE_KEY, JSON.stringify(state));
};

const preferencesSlice = createSlice({
  name: "preferencesStore",
  initialState,
  reducers: {
    toggleDarkMode: (state) => {
      state.theme.isDarkMode = !state.theme.isDarkMode;
      savePreferences(state);
    },

    toggleAnimations: (state) => {
      state.theme.animations = !state.theme.animations;
      savePreferences(state);
    },

    setBorderRadius: (state, action: PayloadAction<number>) => {
      state.theme.borderRadius = action.payload;
      savePreferences(state);
    },

    toggleShowConfirmationDialog: (state) => {
      state.showConfirmationDialog = !state.showConfirmationDialog;
      savePreferences(state);
    },
  },
});

export const { toggleDarkMode, toggleAnimations, setBorderRadius, toggleShowConfirmationDialog } =
  preferencesSlice.actions;

export default preferencesSlice.reducer;
