import useFormStyles from "../hooks/useFormStyles";
import { Box, Button, InputAdornment, TextField } from "@material-ui/core";
import { IRoleSalaryConfigurationDto } from "@shared/services/salary-configuration/dtos/role-salary-configurations/roleSalaryConfigurationDto";
import { addEmployeeSalaryBonus } from "@shared/services/salary-configuration/employee-salary-configuration.service";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { FC, useState } from "react";

interface BonusesFormProps {
  formik: ReturnType<typeof useFormik<IRoleSalaryConfigurationDto>>;
}

const BonusesForm: FC<BonusesFormProps> = ({ formik }) => {
  const classes = useFormStyles();


    // Function to add a new bonus
    const handleAddBonus = () => {
      const newBonus = { id: 0, condition: '', amount: '', type: 1 }; // Adjust based on your needs
      const bonuses = [...formik.values.roleSalaryBonuses, newBonus];
      formik.setFieldValue('roleSalaryBonuses', bonuses);
      console.log(bonuses);
    };
  
    const handleDeleteBonus = (bonus) => () => {
      formik.setFieldValue('roleSalaryBonuses', formik.values.roleSalaryBonuses.filter(b => b.id !== bonus.id));
      // enqueueSnackbar('Бонусот е успешно избришан', { variant: 'success' });
  
    }

  return (
    <Box display="flex" flexDirection="column" className={classes.form}>
    <h1>Услови за бонус:</h1>
  <Box>
  <TextField
      variant="outlined"
      className={classes.formControl}
      id="scheduleBonus"
      name="scheduleBonus"
      label="Бонус за работни часа над: (h)"
      type="number"
      value={formik.values.scheduleBonus}
      onChange={formik.handleChange}
      error={formik.touched.scheduleBonus && Boolean(formik.errors.scheduleBonus)}
      helperText={formik.touched.scheduleBonus && formik.errors.scheduleBonus}
    />
    </Box><Box>
      <TextField
      variant="outlined"
      className={classes.formControl}
      id="orderDeclineBonus"
      name="orderDeclineBonus"
      label="Максимум одбиени нарачки:"
      type="number"
      value={formik.values.orderDeclineBonus}
      onChange={formik.handleChange}
      error={formik.touched.orderDeclineBonus && Boolean(formik.errors.orderDeclineBonus)}
      helperText={formik.touched.orderDeclineBonus && formik.errors.orderDeclineBonus}
    />
    </Box>
    <Box>
       <TextField
      variant="outlined"
      className={classes.formControl}
      id="penaltiesUnderBonus"
      name="penaltiesUnderBonus"
      label="Максимум пенали (ден):"
      type="number"
      value={formik.values.penaltiesUnderBonus}
      onChange={formik.handleChange}
      error={formik.touched.penaltiesUnderBonus && Boolean(formik.errors.penaltiesUnderBonus)}
      helperText={formik.touched.penaltiesUnderBonus && formik.errors.penaltiesUnderBonus}
    />
    
  </Box>

  {formik.values.roleSalaryBonuses.map((bonus, index) => (
        <Box key={bonus.id}>
          <TextField
            variant="outlined"
            className={classes.formControl}
            id={`roleSalaryBonuses.${index}.condition`}
            name={`roleSalaryBonuses.${index}.condition`}
            label="Нарачки"
            type="number"
            value={bonus.condition}
            onChange={formik.handleChange}
            error={formik.touched.roleSalaryBonuses && Boolean(formik.errors.roleSalaryBonuses)}
            helperText={formik.touched.roleSalaryBonuses && formik.errors.roleSalaryBonuses}
          />
           <TextField
            variant="outlined"
            className={classes.formControl}
            id={`roleSalaryBonuses.${index}.amount`}
            name={`roleSalaryBonuses.${index}.amount`}
            label="Бонус"
            type="number"
            value={bonus.amount}
            onChange={formik.handleChange}
            error={formik.touched.roleSalaryBonuses && Boolean(formik.errors.roleSalaryBonuses)}
            helperText={formik.touched.roleSalaryBonuses && formik.errors.roleSalaryBonuses}
          />
         
          <Button variant="contained" color="secondary" onClick={handleDeleteBonus(bonus)} style={{
            marginRight: 10,
            marginBottom: 10,
          }}>
            Избриши
          </Button>
          </Box>


          
      ))}
          <Button variant="contained" color="primary" onClick={handleAddBonus}>
          Додај нов бонус за нарачки
      </Button>
 
</Box>
  );
};

export default BonusesForm;
