import { Typography, createStyles, makeStyles } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import DebounceLoading from "@shared/components/Debounce/DebounceLoading";
import { getEmployeePositions } from "@shared/services/settings/employee-positions.service";
import { useQuery } from "@tanstack/react-query";
import { FC, useState } from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      margin: theme.spacing(2),
    },
  })
);

interface EmployeePositionsFilterProps {
  onChange: (employeePositionsIds: number[]) => void;
}

const EmployeePositionsFilter: FC<EmployeePositionsFilterProps> = ({ onChange }) => {
  const classes = useStyles();

  const [selectedRoles, setSelectedRoles] = useState<number[]>([]);
  const {
    data: employeePositions,
    isLoading,
    isFetched,
  } = useQuery(["getEmployeePositions"], getEmployeePositions);

  return (
    <div style= {{display: "flex" ,flexDirection: "column", alignItems: "center"}} className={classes.root}>
      <Typography variant="h6">Позиции</Typography>

      <DebounceLoading loading={isLoading} render={isFetched}>
        <ToggleButtonGroup
          value={selectedRoles}
          onChange={(_, values: number[]) => {
            setSelectedRoles(values);
            onChange(values);
          }}
        >
          {employeePositions?.map((employeePosition) => (
            <ToggleButton key={employeePosition.id} value={employeePosition.id}>
              {employeePosition.name}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </DebounceLoading>
    </div>
  );
};

export default EmployeePositionsFilter;
