import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { getAccessToken } from "@shared/services/auth/auth.service";
import { Subject } from "rxjs";

export const connectionUpdate: Subject<{ type: string; msg: string }> = new Subject();

type ConnectionStatus = "CONNECTED" | "NOT_CONNECTED";

const connectionBuilder = (
  hubServer: string,
  endpoint: string,
  serviceName: string
): HubConnection => {
  // minutes * 60 / delay
  const delays = Array((30 * 60) / 15).fill(15 * 1000);

  const token = getAccessToken();
  const connection = new HubConnectionBuilder()
    .withUrl(`${hubServer}/${endpoint}`, {
      accessTokenFactory: () => `${token}`,
    })
    .withAutomaticReconnect([0, 5000, 10000, ...delays])
    .build();

  connection.keepAliveIntervalInMilliseconds = 5000;
  startConnection(connection, serviceName, "NOT_CONNECTED");

  return connection;
};

const startConnection = (
  connection: HubConnection,
  serviceName: string,
  status: ConnectionStatus
) => {
  connection
    .start()
    .then(() => {
      status = "CONNECTED";
      connectionUpdate.next({ type: "success", msg: `Сервисот за ${serviceName} е поврзан.` });
    })
    .catch((err) => {
      if (status === "CONNECTED") {
        connectionUpdate.next({ type: "error", msg: `Сервисот за ${serviceName} не е поврзан.` });
      }
      status = "NOT_CONNECTED";
    });

  connection.onclose((err) => {
    if (status === "CONNECTED") {
      connectionUpdate.next({ type: "error", msg: `Сервисот за ${serviceName} не е поврзан.` });
    }
    status = "NOT_CONNECTED";
  });

  connection.onreconnecting(() => {
    if (status === "CONNECTED") {
      connectionUpdate.next({ type: "error", msg: `Сервисот за ${serviceName} не е поврзан.` });
    }
    status = "NOT_CONNECTED";
  });

  connection.onreconnected(() => {
    status = "CONNECTED";
    connectionUpdate.next({ type: "success", msg: `Сервисот за ${serviceName} е поврзан.` });
  });
};

export { connectionBuilder };
