import type { Table } from "../../../genericTypes";
import type { Daily } from "../types";
import { GridColDef, GridRowsProp } from "@mui/x-data-grid";

function driverWeekToTable(days: Daily[]): Table {
  const columns: GridColDef[] = [
    {
      field: "infoCol",
      headerName: "",
      width: 150,
      cellClassName: "cols-pin shadow driver-data-cell",
    },
  ];
  const rows: GridRowsProp = [
    {
      id: 1,
      infoCol: "Датум",
    },
    {
      id: 2,
      infoCol: "Ден",
    },
    {
      id: 3,
      infoCol: "9:00",
    },
    {
      id: 4,
      infoCol: "10:00",
    },
    {
      id: 5,
      infoCol: "11:00",
    },
    {
      id: 6,
      infoCol: "12:00",
    },
    {
      id: 7,
      infoCol: "13:00",
    },
    {
      id: 8,
      infoCol: "14:00",
    },
    {
      id: 9,
      infoCol: "15:00",
    },
    {
      id: 10,
      infoCol: "16:00",
    },
    {
      id: 11,
      infoCol: "17:00",
    },
    {
      id: 12,
      infoCol: "18:00",
    },
    {
      id: 13,
      infoCol: "19:00",
    },
    {
      id: 14,
      infoCol: "20:00",
    },
    {
      id: 15,
      infoCol: "21:00",
    },
    {
      id: 16,
      infoCol: "22:00",
    },
    {
      id: 17,
      infoCol: "23:00",
    },
  ];

  days.forEach((day) => {
    const key = `${day.day}${day.date}`;
    columns.push({
      field: key,
      headerName: "",
      width: 150,
      cellClassName: (params) => {
        if ((params.id as number) <= 2) {
          return "driver-data-cell";
        }

        const [onDuty, needed] = params.value.split(" / ").map((num: string) => parseInt(num, 10));
        if (needed === onDuty) {
          return "drivers-full driver-data-cell bordered";
        }

        if (needed > onDuty) {
          if (needed - onDuty < 2) {
            return "drivers-ok driver-data-cell bordered";
          } else if (needed - onDuty < 4) {
            return "drivers-missing driver-data-cell bordered";
          }
        }
        return "drivers-empty driver-data-cell bordered";
      },
    });
    rows[0][key] = day.date;
    rows[1][key] = day.day;

    day.timetable.forEach((hour, index) => {
      rows[index + 2][key] = `${hour.driversOnDuty} / ${hour.driversNeeded}`;
    });
  });

  return {
    columns,
    rows,
  };
}

export default driverWeekToTable;

