import useFormStyles from "../hooks/useFormStyles";
import { InputAdornment, TextField } from "@material-ui/core";
import { IRoleSalaryConfigurationDto } from "@shared/services/salary-configuration/dtos/role-salary-configurations/roleSalaryConfigurationDto";
import { useFormik } from "formik";
import { FC } from "react";

interface PenaltiesFormProps {
  formik: ReturnType<typeof useFormik<IRoleSalaryConfigurationDto>>;
}

const PenaltiesForm: FC<PenaltiesFormProps> = ({ formik }) => {
  const classes = useFormStyles();

  return (
    <div style= {{display:"flex", flexDirection:"column"}} className={classes.form}>
      <div>
        <TextField
          variant="outlined"
          className={classes.formControl}
          id="orderDeclinePenalty"
          name="orderDeclinePenalty"
          label="Пенали за одбиена нарачка"
          InputProps={{
            endAdornment: <InputAdornment position="start">мкд</InputAdornment>,
          }}
          type="number"
          value={formik.values.orderDeclinePenalty}
          onChange={formik.handleChange}
          error={formik.touched.orderDeclinePenalty && Boolean(formik.errors.orderDeclinePenalty)}
          helperText={formik.touched.orderDeclinePenalty && formik.errors.orderDeclinePenalty}
        />

      </div>

      <div>
        <TextField
          variant="outlined"
          className={classes.formControl}
          id="lateLess15"
          name="lateLess15"
          label="Пенали за смена (< 15 мин)"
          InputProps={{
            endAdornment: <InputAdornment position="start">мкд</InputAdornment>,
          }}
          type="number"
          value={formik.values.lateLess15}
          onChange={formik.handleChange}
          error={formik.touched.lateLess15 && Boolean(formik.errors.lateLess15)}
          helperText={formik.touched.lateLess15 && formik.errors.lateLess15}
        />
      </div>

      <div>
        <TextField
          variant="outlined"
          className={classes.formControl}
          id="late1530"
          name="late1530"
          label="Пенали за смена (15-30 мин)"
          InputProps={{
            endAdornment: <InputAdornment position="start">мкд</InputAdornment>,
          }}
          type="number"
          value={formik.values.late1530}
          onChange={formik.handleChange}
          error={formik.touched.late1530 && Boolean(formik.errors.late1530)}
          helperText={formik.touched.late1530 && formik.errors.late1530}
        />
      </div>

      <div>
        <TextField
          variant="outlined"
          className={classes.formControl}
          id="late3060"
          name="late3060"
          label="Пенали за смена (30-60 мин)"
          InputProps={{
            endAdornment: <InputAdornment position="start">мкд</InputAdornment>,
          }}
          type="number"
          value={formik.values.late3060}
          onChange={formik.handleChange}
          error={formik.touched.late3060 && Boolean(formik.errors.late3060)}
          helperText={formik.touched.late3060 && formik.errors.late3060}
        />
      </div>

      <div>
        <TextField
          variant="outlined"
          className={classes.formControl}
          id="late60"
          name="late60"
          label="Пенали за смена (> 1ч)"
          InputProps={{
            endAdornment: <InputAdornment position="start">мкд</InputAdornment>,
          }}
          type="number"
          value={formik.values.late60}
          onChange={formik.handleChange}
          error={formik.touched.late60 && Boolean(formik.errors.late60)}
          helperText={formik.touched.late60 && formik.errors.late60}
        />
      </div>
    </div>
  );
};

export default PenaltiesForm;
