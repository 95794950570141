import {
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import Loading from "@shared/components/Loading/Loading";
import dayjs, { Dayjs } from "dayjs";
import { FC, useEffect, useState } from "react";
import DayjsUtils from "@date-io/dayjs";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

export interface IPeriodSingleDayFilter {
  from?: Date;
  to?: Date;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      margin: theme.spacing(2),
    },
    monthPicker: {
      minWidth: theme.spacing(24),
    },
  })
);

interface PeriodSingleDayFilterProps {
  initialValues?: { from?: Date; to?: Date };
  onChange: (from: Date, to: Date) => void;
}

const PeriodSingleDayFilter: FC<PeriodSingleDayFilterProps> = ({ initialValues, onChange }) => {
  const classes = useStyles();

  const [fromDate, setFromDate] = useState<Date>(new Date());
  const [toDate, setToDate] = useState<Date>(new Date());
  const [months, setMonths] = useState<Dayjs[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<Dayjs>();

  const handleChange = (date: Date) => {
    onChange(dayjs(date).utcOffset(0).startOf("day").toDate(), dayjs(date).utcOffset(0).endOf("day").toDate());
  };

  useEffect(() => {
    const currentDate: Dayjs = dayjs();
    const years: number[] = [0, 1, 2];
    const months: number[] = Array.from({ length: 12 }, (_, i) => i);

    const monthsList: Dayjs[] = years.flatMap((year) =>
      months.map((month) => currentDate.subtract(year, "year").subtract(month, "month"))
    );

    setMonths(monthsList);
    setSelectedMonth(monthsList.find(() => true));

  }, []);

  if (!months.length) return <Loading />;

  return (
    <div style= {{display: "flex", flexDirection: "column"}} className={classes.root}>
      <Typography variant="h6">Период</Typography>

      <div style= {{display: "flex", alignItems: "end", height: "100%"}}>

      <MuiPickersUtilsProvider utils={DayjsUtils}>
          <DatePicker onChange={(date) => {
            console.log(date)
            setFromDate(date.toDate());
            setToDate(date.toDate());
            handleChange(date.toDate());
            }}     value={fromDate || new Date()} />
         </MuiPickersUtilsProvider>
        
      </div>
    </div>
  );
};

export default PeriodSingleDayFilter;
