import { FC, memo } from "react";
import { useMapEvents } from "react-leaflet";
import { LeafletEventHandlerFnMap } from "leaflet";

interface LeafletEventsProps {
  onMove?: LeafletEventHandlerFnMap["move"];
  onMoveStart?: LeafletEventHandlerFnMap["movestart"];
  onMoveEnd?: LeafletEventHandlerFnMap["moveend"];
  onZoom?: LeafletEventHandlerFnMap["zoom"];
  onZoomStart?: LeafletEventHandlerFnMap["zoomstart"];
  onZoomEnd?: LeafletEventHandlerFnMap["zoomend"];
  onDrag?: LeafletEventHandlerFnMap["drag"];
  onDragStart?: LeafletEventHandlerFnMap["dragstart"];
  onDragEnd?: LeafletEventHandlerFnMap["dragend"];
}

const LeafletEvents: FC<LeafletEventsProps> = ({
  onMove,
  onMoveStart,
  onMoveEnd,
  onZoom,
  onZoomStart,
  onZoomEnd,
  onDrag,
  onDragStart,
  onDragEnd,
}) => {
  useMapEvents({
    move: (e) => (onMove ? onMove(e) : null),
    movestart: (e) => (onMoveStart ? onMoveStart(e) : null),
    moveend: (e) => (onMoveEnd ? onMoveEnd(e) : null),
    zoom: (e) => (onZoom ? onZoom(e) : null),
    zoomstart: (e) => (onZoomStart ? onZoomStart(e) : null),
    zoomend: (e) => (onZoomEnd ? onZoomEnd(e) : null),
    drag: (e) => (onDrag ? onDrag(e) : null),
    dragstart: (e) => (onDragStart ? onDragStart(e) : null),
    dragend: (e) => (onDragEnd ? onDragEnd(e) : null),
  });

  return null;
};

export default memo(LeafletEvents);
