import httpService from "../http.service";
import { ICreateConnectionRequestDto } from "./dtos/createConnectionRequestDto";
import { IExternalSystemConnectionDto } from "./dtos/externalSystemConnectionDto";
import { ExternalSystem } from "./enums/externalSystem";

export const getAllExternalConnections = () => {
  return httpService.get<IExternalSystemConnectionDto[]>("/api/externalSystemUserConnections");
};

export const getExternalConnectionsByUserId = (userId: number) => {
  return httpService.get<IExternalSystemConnectionDto[]>(
    `/api/externalSystemUserConnections/user/${userId}`
  );
};

export const addExternalConnection = (request: ICreateConnectionRequestDto) => {
  return httpService.post<IExternalSystemConnectionDto, ICreateConnectionRequestDto>(
    `/api/externalSystemUserConnections`,
    request
  );
};

export const updateExternalConnection = (id: number, request: ICreateConnectionRequestDto) => {
  return httpService.put<IExternalSystemConnectionDto, ICreateConnectionRequestDto>(
    `/api/externalSystemUserConnections/${id}`,
    request
  );
};

export const removeExternalConnection = (id: number) => {
  return httpService.delete<void>(`/api/externalSystemUserConnections/${id}`);
};

export const translateExternalSystem = (externalSystem: ExternalSystem): string => {
  const systemsLabelMap = new Map([
    [ExternalSystem.KlikniJadiLegacy, "КликниЈади Legacy"],
    [ExternalSystem.Exelerate, "Exelerate"],
  ]);

  if (systemsLabelMap.has(externalSystem)) {
    return systemsLabelMap.get(externalSystem);
  } else if (externalSystem in ExternalSystem) {
    return ExternalSystem[externalSystem];
  } else {
    return externalSystem.toString();
  }
};
