import { CircularProgress, IconButton, Toolbar, Typography, makeStyles } from "@material-ui/core";
import { Refresh } from "@material-ui/icons";
import { FC, memo } from "react";

const useStyles = makeStyles(() => ({
  title: {
    flex: "1 1 100%",
    textAlign: "left",
  },
}));

interface OrdersToolbarProps {
  title: string;
  onRefreshClick?: () => void;
  isLoading?: boolean;
}

const OrdersToolbar: FC<OrdersToolbarProps> = ({ title, isLoading, onRefreshClick }) => {
  const classes = useStyles();

  return (
    <Toolbar>
      <Typography variant="h6" component="div" className={classes.title} noWrap>
        {title}
      </Typography>

      <IconButton onClick={!isLoading ? onRefreshClick : undefined} disabled={isLoading}>
        {isLoading ? <CircularProgress size={25} /> : <Refresh />}
      </IconButton>
    </Toolbar>
  );
};

export default memo(OrdersToolbar);
