import { IFilterState } from "../../Map";
import DriversMap from "./DriversMap";
import { selectAllDrivers } from "@App/modules/Logistics/store/features/drivers/driverSelectors";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { Storefront } from "@material-ui/icons";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import ColoredButton from "@shared/components/ColoredButton/ColoredButton";
import DialogTitle from "@shared/components/Dialog/DialogTitle";
import { IMapDriver } from "@shared/components/Map/models/models";
import { useMemoSelector } from "@shared/hooks";
import { getDriverActiveRoute, updateDriverRoute } from "@shared/services/drivers/drivers.service";
import { IRoutePointDto } from "@shared/services/orders/dtos/orders/routePointDto";
import {
  getDriverAndOrderRoutePoints,
  modifyDriverRoutePoints,
} from "@shared/services/orders/orders.service";
import { cutString } from "@shared/utils/cutString";
import { getPickupTime } from "@shared/utils/orders/orderTime";
import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import { FC, useState } from "react";
import { DragDropContext, Draggable, DropResult, Droppable } from "react-beautiful-dnd";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: 10,
  },
  container: {
    height: "75vh",
  },
  dialogContent: {
    padding: "18px 24px 8px 24px",
    height: "600px",
  },
  avatar: {
    objectFit: "contain",
  },
  listItem: {
    backgroundColor: theme.palette.background.paper,
    border: `1px dashed ${theme.palette.secondary.main}`,
    marginTop: "-2px",
  },
  disabled: {
    backgroundColor: grey[300],
  },
}));

const draggingCustomStyle = (style: React.CSSProperties | undefined) => {
  if (style.transform) {
    const axisLockY =
      "translate(0px" + style.transform.slice(style.transform.indexOf(","), style.transform.length);

    return {
      ...style,
      transform: axisLockY,
    };
  }
  return style;
};

export interface EditRouteDialogProps {
  selectedDriverId: number;
  selectedOrderId?: number;
  onClose?: () => void;
}

const EditRouteDialog: FC<EditRouteDialogProps> = ({
  selectedDriverId,
  selectedOrderId,
  onClose,
}) => {
  const classes = useStyles();

  const orders = useMemoSelector((state) => state.appState.logistics.orders.data);

  const [driverRoutePoints, setDriverRoutePoints] = useState<IRoutePointDto[]>([]);
  const {
    data: driverRoute,
    isLoading,
    error,
  } = useQuery(
    selectedOrderId !== null
      ? ["driver-route-order", selectedDriverId, selectedOrderId]
      : ["driver-route", selectedDriverId],
    () => {
      return selectedOrderId !== null
        ? getDriverAndOrderRoutePoints(selectedDriverId, selectedOrderId)
        : getDriverActiveRoute(selectedDriverId);
    },
    {
      cacheTime: 0,
      retry: false,
      onSuccess: (data) => {
        setDriverRoutePoints(data.routePoints);
      },
    }
  );

  const handleDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (destination.index === source.index) {
      return;
    }

    const old_index = source.index;
    const new_index = destination.index;

    const rp = driverRoutePoints[old_index];

    // Completed route points can't be reordered and can be moved to the start
    const completedIndexes = driverRoutePoints
      .map((rp, index) => (rp.onLocationAt ? index : undefined))
      .filter((index) => index !== undefined);

    if (completedIndexes.includes(0) && completedIndexes.includes(new_index)) {
      return;
    }

    // Reorder route points
    const routePoints = Array.from(driverRoutePoints);
    const [removed] = routePoints.splice(old_index, 1);
    routePoints.splice(new_index, 0, removed);

    // Pickup for the order must be before the drop-off
    const dropOffRoutePointIndex = routePoints.findIndex(
      (x) => x.orderId === rp.orderId && x.isPickup === false
    );

    if (rp.isPickup && new_index >= dropOffRoutePointIndex) {
      return;
    }

    // Drop-off for the order must be before the pickup
    const pickupRoutePointIndex = routePoints.findIndex(
      (x) => x.orderId === rp.orderId && x.isPickup === true
    );

    if (!rp.isPickup && new_index <= pickupRoutePointIndex) {
      return;
    }
    setDriverRoutePoints(routePoints);
  };

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const handleSubmit = () => {
    const promise =
      selectedOrderId !== null
        ? modifyDriverRoutePoints(selectedDriverId, driverRoute.id, driverRoutePoints)
        : updateDriverRoute(selectedDriverId, { ...driverRoute, routePoints: driverRoutePoints });

    promise
      .then(() => {
        // Show snackbar
        enqueueSnackbar("Рутата е испешно зачувана.", {
          variant: "success",
          autoHideDuration: 6000,
        });

        onClose?.();
      })
      .catch((error) => {
        // TODO: Change this to show an error message
        enqueueSnackbar(`Грешка: ${error.response.data.message}`, {
          variant: "error",
          persist: true,
          preventDuplicate: true,
          anchorOrigin: { horizontal: "left", vertical: "bottom" },
          action: function onDismiss(key) {
            return <Button onClick={() => closeSnackbar(key)}>OK</Button>;
          },
        });

        onClose?.();
      });
  };

  const getExternalOrderId = (orderId: number) => {
    const order = orders.find((x) => x.id === orderId);
    const orderNumber = order?.displayOrderNumber || order?.externalOrderNumber;
    return cutString(orderNumber, order?.displayOrderNumber ? 6 : 3);
  };

  return (
    <Dialog open fullWidth maxWidth="lg" onClose={onClose}>
      <DialogTitle onClose={onClose}>
        {selectedOrderId !== null ? "Додели нарачка" : "Промени рута"}
      </DialogTitle>

      <Divider />

      <DialogContent className={classes.dialogContent}>
        {isLoading ? (
          <Typography align="center" variant="h6">
            Се вчитува
          </Typography>
        ) : error ? (
          <Typography align="center" variant="h6" color="error">
            Грешка: {(error as Error).message || error}
          </Typography>
        ) : (
          <Grid spacing={2} style={{ height: "100%" }} container>
            <Grid xs={8} style={{ height: "100%" }} item>
              <DriversMap
                orders={orders}
                driverRoutes={[
                  {
                    ...driverRoute,
                    routePoints: driverRoutePoints,
                  },
                ]}
                filters={
                  {
                    drivers: {
                      mode: "selectedIds",
                      selectedIds: [selectedDriverId],
                    },
                    orders: {
                      mode: "groups",
                      groups: {
                        unassignedOrders: false,
                      },
                    },
                  } as IFilterState
                }
              />
            </Grid>

            <Grid xs={4} item>
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="driver-route-points">
                  {(provided) => (
                    <List
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      style={{ height: "100%" }}
                    >
                      {driverRoutePoints.map((routePoint, index) => (
                        <Draggable
                          key={routePoint.id}
                          draggableId={routePoint.id?.toString()}
                          index={index}
                          isDragDisabled={Boolean(routePoint.onLocationAt)}
                        >
                          {(provided) => (
                            <ListItem
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className={clsx(classes.listItem, {
                                [classes.disabled]: routePoint.onLocationAt,
                              })}
                              style={draggingCustomStyle(provided.draggableProps.style)}
                            >
                              <ListItemAvatar>
                                <Avatar src={routePoint.imgSrc} classes={{ img: classes.avatar }}>
                                  {routePoint.isPickup ? <Storefront /> : null}
                                </Avatar>
                              </ListItemAvatar>

                              <ListItemText
                                primary={
                                  routePoint.isPickup
                                    ? `PU: #${getExternalOrderId(routePoint.orderId)}`
                                    : `DO: #${getExternalOrderId(routePoint.orderId)}`
                                }
                              />

                              <Typography
                                variant="body1"
                                style={{
                                  marginRight: "1rem",
                                  fontWeight: "bold",
                                }}
                              >
                                {routePoint.isPickup
                                  ? dayjs.utc(getPickupTime(routePoint)).local().format("HH:mm")
                                  : null}
                              </Typography>

                              <ListItemIcon>
                                <DragHandleIcon />
                              </ListItemIcon>
                            </ListItem>
                          )}
                        </Draggable>
                      ))}

                      {provided.placeholder}
                    </List>
                  )}
                </Droppable>
              </DragDropContext>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <ColoredButton color="success" onClick={handleSubmit}>
          Зачувај
        </ColoredButton>

        <ColoredButton color="error" onClick={onClose}>
          Затвори
        </ColoredButton>
      </DialogActions>
    </Dialog>
  );
};

export default EditRouteDialog;
