import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) =>
  createStyles({
    drawerIcon: {
      [theme.breakpoints.up("sm")]: {
        paddingLeft: "24px",
        paddingRight: "24px",
      },
    },
  })
);

export interface DrawerListItemProps {
  text: string;
  to?: string;
  href?: string;
  icon: React.ReactNode;
}

const DrawerListItem: React.FC<DrawerListItemProps> = ({ text, to, href, icon }) => {
  const classes = useStyles();

  const handleClick = () => {
    if (href) {
      window.open(href, "_blank");
    }
  };
``
  if (to) {
    return (
      <ListItem
        title={text}
        component={Link}
        to={to}
        button
        className={classes.drawerIcon}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={text} />
      </ListItem>
    );
  }

  return (
    <ListItem
      title={text}
      button
      className={classes.drawerIcon}
      onClick={handleClick}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={text} />
    </ListItem>
  );
};

export default DrawerListItem;
