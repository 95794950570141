import ErrorFallback from "../../error";
import ApplicationLayout from "./components/Layout/Layout";
import Employees from "./modules/Employees/Employees";
import Logistics from "./modules/Logistics/Logistics";
import LogsView from "./modules/LogsView/LogsView";
import Map from "./modules/Map/Map";
import Payouts from "./modules/Payouts/Payouts";
import Payroll from "./modules/Payroll/Payroll";
import DailyCash from "./modules/DailyCash/DailyCash";

import Settings from "./modules/Settings/Settings";
import { getAccessToken, startTokenChecker } from "@shared/services/auth/auth.service";
import {
  getAllOrderRejectReasonTypesAsyncThunk,
  getCitiesThunkAsync,
  getDeliveryTypesAsyncThunk,
  getEmployeePositionTypesAsyncThunk,
  getPaymentTypesAsyncThunk,
  getVehicleTypesAsyncThunk,
} from "@shared/store/async-thunks";
import LogisticsPage from "./modules/Logistics/Logistics";
import DriverMonthly from "./Scheduler/modules/DriverMonthly/DriverMonthly";
import EmployeeWeekly from "./Scheduler/modules/EmployeeWeekly/EmployeeWeekly";
import EmployeeDaily from "./Scheduler/modules/EmployeeDaily/EmployeeDaily";

import { AppDispatch } from "@store/index";
import { FC } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { Subscription, timer } from "rxjs";
import ChatIframe from "./modules/Chat/Chat";

const t = timer(0, 15 * 60 * 1000); // 15 minutes
var subscription: Subscription;

const App: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const initApplicationStoreLoader = () => {
    if (subscription) {
      subscription.unsubscribe();
      subscription = null;
    }
    subscription = t.subscribe((x) => {
      dispatch(getCitiesThunkAsync());
      dispatch(getEmployeePositionTypesAsyncThunk());
      dispatch(getDeliveryTypesAsyncThunk());
      dispatch(getVehicleTypesAsyncThunk());
      dispatch(getPaymentTypesAsyncThunk());
      dispatch(getAllOrderRejectReasonTypesAsyncThunk());
    });
  };

  const accessToken = getAccessToken();
  if (!accessToken) {
    navigate("auth/login");
  } else {
    initApplicationStoreLoader();
    startTokenChecker();
  }

  return (
    <Routes>
      <Route element={<ApplicationLayout />}>
        <Route errorElement={<ErrorFallback />}>
          <Route path="map" element={<Map />} />
          <Route path="logistics" element={<Logistics />} />
          <Route path="employees/*" element={<Employees />} />
          <Route path="payroll/*" element={<Payroll />} />
          <Route path="dailyCash/*" element={<DailyCash />} />
          <Route path="payouts/*" element={<Payouts />} />
          <Route path="settings/*" element={<Settings />} />
          <Route path="logs/*" element={<LogsView />} />
          <Route path="chat" element={<ChatIframe />} />
          <Route path="schedule/monthly" element={<DriverMonthly />} />
          <Route path="schedule/weekly" element={<EmployeeWeekly />} />
          <Route path="schedule/daily" element={<EmployeeDaily />} />

          <Route path="*" element={<AppFallback />} />
        </Route>
      </Route>
    </Routes>
  );
};

const AppFallback: FC = () => <Navigate to="logistics" />;

export default App;

