import { Avatar, Divider, Grid, Typography, makeStyles } from "@material-ui/core";
import {
  AlarmOn,
  ArrowDownward,
  ArrowUpward,
  DriveEta,
  FiberManualRecord,
  Flag,
  Storefront,
} from "@material-ui/icons";
import { TimelineOppositeContent } from "@material-ui/lab";
import Timeline from "@material-ui/lab/Timeline";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import ColoredButton from "@shared/components/ColoredButton/ColoredButton";
import TextWithIcon from "@shared/components/TextWithIcon/TextWithIcon";
import { useConfirmationDialog } from "@shared/context/ConfirmationDialogContext";
import { useMemoSelector } from "@shared/hooks";
import { IDriverRouteResponseDto } from "@shared/services/orders/dtos/orders/driverRouteResponseDto";
import { IOrderDetailsDto } from "@shared/services/orders/dtos/orders/orderDetailsDto";
import { IOrderItemDto } from "@shared/services/orders/dtos/orders/orderItemDto";
import { OrderStatus } from "@shared/services/orders/enums/orderStatus";
import {
  changeOrderStatus,
  resetOrderManuallyAsync,
  translateOrderStatus,
} from "@shared/services/orders/orders.service";
import { cutString } from "@shared/utils/cutString";
import { getDisplayValue } from "@shared/utils/getDisplayValue";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import { FC, Fragment, memo } from "react";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1),
  },
  orderCard: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));

export interface DriversRouteDetailsProps {
  driversRoute: IDriverRouteResponseDto;
  open?: boolean;
}

const DriversRouteDetails: FC<DriversRouteDetailsProps> = ({ driversRoute, open }) => {
  const classes = useStyles();

  const { paymentTypes, deliveryTypes } = useMemoSelector(({ sharedState }) => ({
    paymentTypes: sharedState?.paymentTypes?.data,
    deliveryTypes: sharedState?.deliveryTypes?.data,
  }));

  const orderIds = [...new Set(driversRoute.routePoints.map((rp) => rp.orderId))];
  const orders = useMemoSelector(({ appState }) =>
    appState.logistics.orders.data.filter((order) => orderIds.includes(order.id))
  );

  const confirmationDialog = useConfirmationDialog();
  const { enqueueSnackbar } = useSnackbar();
  const handleResetClick = (orderId: number, rejectReasonId: number = 1) => {
    const order = orders.find((order) => order.id === orderId);
    const driverId = order.employeeFk;

    if (driverId && orderId && rejectReasonId) {
      confirmationDialog.setLoading(true);

      const request = { driverId, orderRejectReasonFK: rejectReasonId };
      resetOrderManuallyAsync(orderId, request).then(() => {
        enqueueSnackbar("Нарачката е успешно тргната од возачот.", {
          variant: "success",
          autoHideDuration: 6000,
        });
        confirmationDialog.close();
      });
    } else {
      console.error("This should never happen :)");
    }
  };

  const handleStatusChangeClick = (orderId: number, orderStatus: OrderStatus) => {
    if (!orderId) return;

    confirmationDialog.setLoading(true);

    changeOrderStatus(orderId, { status: orderStatus })
      .then(() => {
        enqueueSnackbar(
          `Статусот на нарачката е успешно променет во: ${translateOrderStatus(orderStatus)}`,
          {
            variant: "success",
            autoHideDuration: 6000,
          }
        );

        confirmationDialog.close();
      })
      .catch((e) => {
        enqueueSnackbar(
          `Грешка при промена на статусот на нарачката: ${
            e?.response?.data?.message || e?.message
          }`,
          {
            variant: "error",
            autoHideDuration: 6000,
          }
        );

        confirmationDialog.close();
      });
  };

  const getOrder = (orderId: number) => {
    return orders.find((order) => order.id === orderId);
  };

  if (!open) {
    return null;
  }

  const getOrderTardiness = (orderId: number) => {
    return driversRoute?.ordersTardiness?.[orderId];
  };

  return (
    <Grid container className={classes.container}>
      {/* Order details */}
      <Grid xs item>
        {orders.map((order) => (
          <Grid
            key={order.id}
            md={12}
            lg
            container
            justifyContent="space-between"
            className={classes.orderCard}
          >
            <Grid
              item
              container
              xs
              direction="row"
              style={{
                gap: "1rem",
              }}
            >
              <Grid item md={3} lg={2}>
              {order?.displayOrderNumber ? (
                 <Typography title={order?.displayOrderNumber} color="primary" component="span">
                  {order.displayOrderNumber}
            </Typography>) : (
              <Typography component="span">
                  #
                  <Typography color="primary" component="span">
                    {cutString(order?.externalOrderNumber, 3).padStart(3, "0")}
                  </Typography>
                </Typography>
                )}

                <Typography>{getDisplayValue(deliveryTypes, order.deliveryTypeFK)}</Typography>

                <Typography>{getDisplayValue(paymentTypes, order.paymentTypeFK)}</Typography>

                {getOrderTardiness(order.id) && (
                  <TextWithIcon icon={<AlarmOn color="primary" />}>
                    {Math.round(getOrderTardiness(order.id)?.tardinessInMinutes)} мин.
                  </TextWithIcon>
                )}

                {/* <div style={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
                  <ArrowUpward color="secondary" />
                  <Typography>{dayjs.utc(order.pickUpTime).local().format("HH:mm")}</Typography> /
                  <Typography>
                    {dayjs.utc(order.estimatedPickupTime).local().format("HH:mm")}
                  </Typography>
                </div>

                <div style={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
                  <ArrowDownward color="primary" />
                  <Typography>{dayjs.utc(order.dropOffTime).local().format("HH:mm")}</Typography> /
                  <Typography>
                    {dayjs.utc(order.estimatedDropOffTime).local().format("HH:mm")}
                  </Typography>
                </div> */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "0.5rem",
                    alignItems: "center",
                  }}
                >
                  <FiberManualRecord
                    style={{
                      color: "yellow",
                    }}
                  />
                  {translateOrderStatus(order.orderStatus)}
                </div>
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                container
                spacing={4}
                style={{
                  borderRadius: "4px",
                }}
              >
                <Grid xs item>
                  <OrderContents orderItems={order.orderDetails?.orderItems} />
                </Grid>

                <Grid item style={{ minWidth: "200px" }}>
                  <Receipt orderDetails={order.orderDetails} />
                </Grid>
              </Grid>

              <Grid
                item
                xs={2}
                style={{
                  backgroundColor: "indianred",
                  paddingLeft: "8px",
                  paddingRight: "8px",
                }}
              >
                <Typography variant="caption">КОМЕНТАР</Typography>
                <Typography variant="body2">{order.driverComment}</Typography>
              </Grid>
            </Grid>

            <Grid item style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
              <ColoredButton
                size="small"
                color="primary"
                variant="contained"
                disableElevation
                onClick={() =>
                  handleStatusChangeClick(
                    order.id,
                    order.orderStatus === OrderStatus.PickedUp
                      ? OrderStatus.Delivered
                      : OrderStatus.PickedUp
                  )
                }
              >
                {order.orderStatus === OrderStatus.PickedUp ? "Доставена" : "Подигната"}
              </ColoredButton>

              <ColoredButton
                size="small"
                color="info"
                variant="contained"
                disableElevation
                disabled
              >
                Измени
              </ColoredButton>

              <ColoredButton
                size="small"
                color="warning"
                variant="contained"
                disableElevation
                onClick={() => handleResetClick(order.id, 1)}
              >
                Ресетирај
              </ColoredButton>
            </Grid>
          </Grid>
        ))}
      </Grid>

      {/* Timeline */}
      <Grid item md={12}>
        <Grid container>
          {driversRoute?.totalDistanceInKilometers != null && (
            <Grid sm={3} item>
              <TextWithIcon icon={<DriveEta color="primary" />}>
                {driversRoute.totalDistanceInKilometers} км.
              </TextWithIcon>
            </Grid>
          )}

          {driversRoute?.totalTimeInMinutes != null && (
            <Grid sm={4} item>
              <TextWithIcon icon={<AlarmOn color="primary" />}>
                {Math.round(driversRoute.totalTimeInMinutes)} мин.
              </TextWithIcon>
            </Grid>
          )}
        </Grid>

        <Timeline align="left">
          {driversRoute.routePoints.map((routePoint, i) => {
            const order = getOrder(routePoint.orderId);

            const locationName = routePoint.isPickup ? order?.partnerName : order?.customerName;

            return (
              <TimelineItem key={routePoint.id}>
                <TimelineOppositeContent
                  style={{
                    display: "flex",
                    flex: "0 0 auto",
                    flexDirection: "row",
                    gap: "1rem",
                    paddingLeft: 0,
                    paddingTop: "13px",
                  }}
                >
                  {routePoint.orderTime ? (
                    <Typography>
                      {dayjs.utc(routePoint.orderTime).local().format("HH:mm")}
                    </Typography>
                  ) : (
                    <Typography style={{ paddingRight: "18px" }}>--:--</Typography>
                  )}{" "}
                  /
                  {routePoint.estimatedTime ? (
                    <Typography>
                      {dayjs.utc(routePoint.estimatedTime).local().format("HH:mm")}
                    </Typography>
                  ) : (
                    <Typography style={{ paddingRight: "18px" }}>--:--</Typography>
                  )}
                  {routePoint.isPickup ? (
                    <ArrowUpward color="secondary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </TimelineOppositeContent>

                <TimelineSeparator>
                  <TimelineDot
                    color={routePoint.isPickup ? "secondary" : "primary"}
                    variant="outlined"
                  >
                    {/* <Avatar src={order.partnerImageSrc} alt="Partner avatar or logo"> */}
                    {routePoint.isPickup ? (
                      <>
                        {routePoint.imgSrc ? (
                          <Avatar
                            className={classes.small}
                            src={
                              routePoint.imgSrc ||
                              "https://www.kliknijadi.mk/images/products/1459_151121141250668_400x400.jpg?v=1"
                            }
                            alt="Partner avatar or logo"
                          >
                            <Storefront />
                          </Avatar>
                        ) : (
                          <Storefront />
                        )}
                      </>
                    ) : (
                      <Flag />
                    )}
                  </TimelineDot>

                  {i < driversRoute.routePoints.length - 1 ? <TimelineConnector /> : null}
                </TimelineSeparator>

                <TimelineContent>
                  <div style={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
                    <div>
                    {order?.displayOrderNumber ? (
                        <Typography title={order?.displayOrderNumber}color="primary" component="span">
                          {order.displayOrderNumber}
                         </Typography>) : (
                        <Typography component="span">
                          #
                        <Typography color="primary" component="span">
                          {cutString(getOrder(routePoint.orderId)?.externalOrderNumber, 3).padStart(
                            3,
                            "0"
                          )}
                        </Typography>{" "}
                        - <Typography component="span">{locationName}</Typography>
                      </Typography>)}

                      <Typography variant="body2">{routePoint.address}</Typography>
                    </div>
                  </div>
                </TimelineContent>
              </TimelineItem>
            );
          })}
        </Timeline>
      </Grid>
    </Grid>
  );
};

interface OrderContentsProps {
  orderItems: IOrderItemDto[];
}

const OrderContents: FC<OrderContentsProps> = ({ orderItems }) => {
  return (
    <>
      {orderItems?.map((orderItem, i) => (
        <Fragment key={i}>
          <Grid container spacing={2}>
            <Grid item xs>
              <Typography variant="body2">
                <b>{orderItem.quantity} x</b> {orderItem.foodName?.trim()}
              </Typography>

              {orderItem.foodAdditions && orderItem.foodAdditions !== " - 0 ден." ? (
                <Typography variant="caption">
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <b>+</b> {orderItem.foodAdditions?.replace(/#/g, ", ")}
                </Typography>
              ) : null}
            </Grid>

            <Grid item>
              <Typography variant="body2">{orderItem.price} ден.</Typography>
            </Grid>
          </Grid>

          {i < orderItems.length - 1 ? (
            <Divider style={{ marginTop: "8px", marginBottom: "8px" }} />
          ) : null}
        </Fragment>
      ))}
    </>
  );
};

interface ReceiptProps {
  orderDetails: Omit<IOrderDetailsDto, "orderItems">;
}

const Receipt: FC<ReceiptProps> = ({ orderDetails }) => {
  const price = orderDetails?.price ?? 0;
  const deliveryPrice = orderDetails?.deliveryPrice ?? 0;
  const priorityPrice = orderDetails?.priorityPrice ?? 0;
  const total = price + deliveryPrice + priorityPrice;

  return (
    <>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography variant="body2">Цена:</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2">{price} ден.</Typography>
        </Grid>
      </Grid>

      {deliveryPrice > 0 && (
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="body2">Достава:</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">{deliveryPrice} ден.</Typography>
          </Grid>
        </Grid>
      )}

      {priorityPrice > 0 && (
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="body2">Приоритет:</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">{priorityPrice} ден.</Typography>
          </Grid>
        </Grid>
      )}

      <Divider />

      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography variant="body2">Вкупно:</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2" color="primary">
            <b>{total} ден.</b>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default memo(DriversRouteDetails);

// TODO:
// [ ] On hover replace the dot separator with a phone icon, and on click call that location
// [x] Add Order id next to the partner or customer name
// [ ] When route point is completed, replace the icon with a checkmark
