import httpService from "../http.service";
import { IWorkShiftRequestDto } from "./dtos/workshift/workShiftRequestDto";

export const hasUserStartWorkingAsync = () => {
  return httpService.get<boolean>("/api/workshifts/started");
};

export const insertWorkShiftAsync = (request: IWorkShiftRequestDto) => {
  return httpService.post<void, IWorkShiftRequestDto>(`/api/workshifts/drivers/start`, request);
};

export const endWorkShiftAsync = () => {
  return httpService.put<void>(`/api/workshifts/drivers/end`);
};

export const endDriverWorkShiftAsync = (employeeId: number) => {
  return httpService.put<void>(`/api/workshifts/drivers/${employeeId}/end`);
};

export const startDispatcherWorkShiftAsync = () => {
  return httpService.post<void>(`/api/workshifts/dispatchers/start`);
};

export const endDispatcherWorkShiftAsync = () => {
  return httpService.put<void>(`/api/workshifts/dispatchers/end`);
};

export const insertPauseAsync = (employeeId: number, endTime: string) => {
  return httpService.put<void, { endTime: string }>(`/api/workshifts/${employeeId}/start-pause`, {
    endTime: endTime,
  });
};

export const insertUnlimitedPauseAsync = (employeeId: number) => {
  return httpService.put<void, { endTime: string }>(
    `/api/workshifts/${employeeId}/start-pause?unlimited=true`,
    {
      endTime: null,
    }
  );
};

export const endPauseAsync = (employeeId: number) => {
  return httpService.put<void>(`/api/workshifts/${employeeId}/end-pause`);
};

export const exportWorkShifts = () => {
  return httpService.getBlob(`/api/workshifts/export`,{});
};
