import { FC, memo } from "react";
import { IMapDriver } from "@shared/components/Map/models/models";
import DriverMarker from "./DriverMarker";
import { IFilterState } from "../../Map";

export interface DriverMarkersProps {
  drivers: IMapDriver[];
  driversFilter: IFilterState["drivers"];
  driversRoutesFilter: IFilterState["driversRoutes"];
}

const DriverMarkers: FC<DriverMarkersProps> = ({ drivers, driversFilter, driversRoutesFilter }) => (
  <>
    {drivers?.map((driver) => (
      <DriverMarker
        key={driver.employeeId}
        driver={driver}
        iconMode={
          driversFilter?.mode !== "disabled" || driversRoutesFilter?.mode !== "disabled"
            ? "avatar"
            : "vehicle"
        }
      />
    ))}
  </>
);

export default memo(DriverMarkers);
