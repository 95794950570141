import CustomSelect from "../../../../../../shared/components/CustomFormControls/CustomSelect";
import DialogActions from "../../../../../../shared/components/Dialog/DialogActions";
import DialogContent from "../../../../../../shared/components/Dialog/DialogContent";
import DialogTitle from "../../../../../../shared/components/Dialog/DialogTitle";
import ErrorMessage from "../../../../../../shared/components/ErrorMessage/ErrorMessage";
import DrawControl from "../../../../../../shared/components/Map/DrawControl/DrawControl";
import LeafletMap from "../../../../../../shared/components/Map/LeafletMap/LeafletMap";
import { addNewArea, updateArea } from "../../../../../../shared/services/settings/area.service";
import { IAreaType } from "../Area";
import { NewAreaSchema } from "../schemas/NewAreaSchema";
import EmployeeList from "./Employees/EmployeeList";
import TypeSelect from "./TypeSelect";
import {
  Button,
  CircularProgress,
  Dialog,
  Grid,
  Paper,
  Switch,
  TextField,
  Theme,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import MapController from "@shared/components/Map/LeafletMap/MapController";
import { useMemoSelector } from "@shared/hooks";
import { IApiErrorResponseData } from "@shared/services/http.client";
import { AxiosError } from "axios";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import React, { FC, SyntheticEvent, memo, useEffect, useState } from "react";
import { TwitterPicker } from "react-color";
import { FeatureGroup } from "react-leaflet";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      minWidth: "80vw",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    dialogContent: {
      display: "flex",
      flexDirection: "column",
      minWidth: "290px",
      overflow: "auto",
      gap: theme.spacing(2),
    },
    mainContainer: {
      // This has to be used in combination with the map
      [theme.breakpoints.up("sm")]: {
        height: "70vh",
      },
    },
    mapContainer: {
      // This has to be used in combination with the main container
      height: "60vh",
      [theme.breakpoints.up("sm")]: {
        height: "100%",
      },
    },
    leftContainer: {
      display: "flex",
      flexDirection: "column",
      overflow: "auto",
      gap: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        height: "100%",
      },
    },
    rightContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      gap: "1em",
      minHeight: "100%",
    },
    form: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      overflowY: "auto",
    },
    paper: {
      padding: theme.spacing(1.5),
    },
  })
);

const defaultCity = {
  value: 0,
  text: "Ниеден", // Macedonia
  latitude: 41.6137143,
  longitude: 21.743258,
  zoom: 9,
};

const initialValues = {
  name: "",
  polygonData: "",
  cityFK: 0,
  color: "#ff0000",
  vehicleTypesIds: [],
  isForAllEmployees: true,
  employeesIds: [],
};

interface NewAreaTypeFormProps {
  title: string;
  addButtonText: string;
  open: boolean;
  row: IAreaType | null;
  onClose: () => void;
  onSuccess: () => void;
}

const NewAreaTypeForm: FC<NewAreaTypeFormProps> = (props) => {
  const classes = useStyles();
  const { title, addButtonText, open, row, onClose, onSuccess } = props;

  const { enqueueSnackbar } = useSnackbar();

  const [error, setError] = useState("");
  const handleSubmit = (values: typeof initialValues) => {
    const APICall = row && row.id ? updateArea(row.id, { ...values }) : addNewArea({ ...values });

    APICall.then((response) => {
      // Reset formik
      formik.setSubmitting(false);
      formik.resetForm();

      // Show notification
      enqueueSnackbar("Успешно додадено", { variant: "success" });

      // TODO: Send back the data from the response here

      // Refetch data
      onSuccess();

      // Close the dialog
      onClose();
    }).catch((error: AxiosError<IApiErrorResponseData>) => {
      // Reset formik
      formik.setSubmitting(false);

      // Show error message
      setError(error.response?.data?.message || "Unexpected error occured");
    });
  };

  const {
    setValues,
    handleChange: handleFormikChange,
    ...formik
  } = useFormik({
    initialValues,
    validationSchema: NewAreaSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
    validateOnChange: false,
  });

  const handleClose = () => {
    formik.resetForm();
    setError("");

    if (onClose) onClose();
  };

  const handleChange = (event: SyntheticEvent) => {
    // Reset errors on input change
    if (error) setError("");

    handleFormikChange(event);
  };

  // Set initial values
  useEffect(() => {
    if (!row) return;

    const newInitialValues = {} as typeof initialValues;

    Object.keys(initialValues).forEach((key: string) => {
      if (key in row) newInitialValues[key] = row[key];
    });

    setValues(newInitialValues);
  }, [row, setValues]);

  const { cityTypes, vehicleTypes } = useMemoSelector(({ sharedState }) => ({
    cityTypes: sharedState.cityTypes,
    vehicleTypes: sharedState.vehicleTypes,
  }));

  const getSelectedCity = () => {
    const city = cityTypes.data.find((x) => x.id === formik.values.cityFK);
    return city || defaultCity;
  };
  const selectedCity = getSelectedCity();

  const handleTypeChange = (name: string, data: number[]) => {
    formik.setFieldValue(name, data);
  };

  return (
    <Dialog
      maxWidth={false}
      aria-labelledby="new-area-type-dialog"
      classes={{ paper: classes.dialog }}
      open={open}
      onClose={handleClose}
    >
      <form className={classes.form} onSubmit={formik.handleSubmit}>
        <DialogTitle onClose={handleClose}>{title}</DialogTitle>

        <DialogContent className={classes.dialogContent} dividers>
          <Grid container spacing={2} className={classes.mainContainer}>
            <Grid xs={12} sm={5} md={3} item className={classes.leftContainer}>
              <TextField
                name="name"
                label="Име"
                variant="outlined"
                value={formik.values.name}
                onChange={handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                autoFocus
                fullWidth
                required
              />
              <CustomSelect
                label="Град"
                value={formik.values.cityFK}
                errorMessage={formik.errors.cityFK}
                touched={formik.touched.cityFK}
                onChange={(e) => {
                  formik.setFieldValue("cityFK", e);
                }}
                size="medium"
                variant="outlined"
                selectOptions={cityTypes.data.map((x) => ({
                  value: x.id,
                  text: x.name,
                }))}
                multiple={false}
                key="cityFK"
                id="cityFK"
              />
              <TypeSelect
                name="vehicleTypesIds"
                title="Возила"
                value={formik.values.vehicleTypesIds}
                errorText={vehicleTypes.error || formik.errors.vehicleTypesIds}
                onChange={handleTypeChange}
                options={vehicleTypes.data.map((x) => ({
                  id: x.id,
                  name: x.name,
                }))}
                isLoading={vehicleTypes.status === "loading"}
              />
              <Paper variant="outlined" className={classes.paper}>
                Селекција
                <Switch
                  name="isForAllEmployees"
                  checked={formik.values.isForAllEmployees}
                  onChange={handleChange}
                />
                Сите возачи
              </Paper>

              {/* <CustomSelect
                label="Возачи"
                value={formik.values.employeesInfos}
                errorMessage={formik.errors.employeesInfos}
                touched={formik.touched.employeesInfos}
                onChange={(e) => {
                  formik.setFieldValue("employeesInfos", e);
                }}
                size="medium"
                variant="outlined"
                selectoptions={employeesState.employees.map((x) => ({
                  ...x,
                  text: `${x.firstName} ${x.lastName}`,
                  value: x.id,
                }))}
                multiple={true}
                key="employeesInfos"
                id="employeesInfos"
                disabled={formik.values.isForAllEmployees}
              /> */}

              {!formik.values.isForAllEmployees ? (
                <EmployeeList
                  values={formik.values.employeesIds}
                  onChange={(values) => {
                    formik.setFieldValue("employeesIds", values);
                  }}
                />
              ) : null}
            </Grid>

            <Grid xs={12} sm={7} md={9} item className={classes.rightContainer}>
              <TwitterPicker
                width="100%"
                triangle="hide"
                color={formik.values.color}
                colors={[
                  "#FF6900",
                  "#FCB900",
                  "#78DC85",
                  "#00D084",
                  "#8ED1FC",
                  "#0693E3",
                  "#ABB8C3",
                  "#EB144C",
                  "#F78da7",
                  "#9900EF",
                  "#000000",
                ]}
                onChangeComplete={(color) => formik.setFieldValue("color", color.hex)}
              />

              <div className={classes.mapContainer}>
                <LeafletMap height="100%">
                  <MapController
                    geolocation={{
                      latitude: selectedCity.latitude,
                      longitude: selectedCity.longitude,
                    }}
                    zoom={selectedCity.zoom}
                  />

                  <FeatureGroup>
                    <DrawControl
                      geoJSON={formik.values.polygonData}
                      draw={{
                        polygon: {
                          showArea: true,
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          metric: ["m", "km"],
                          allowIntersection: false,
                          shapeOptions: {
                            stroke: true,
                            color: formik.values.color,
                            weight: 4,
                            opacity: 0.5,
                            fill: true,
                            fillColor: null,
                            fillOpacity: 0.2,
                            clickable: true,
                          },
                        },
                        rectangle: false,
                        circle: false,
                        marker: false,
                        circlemarker: false,
                        polyline: false,
                      }}
                      onEdited={(event) => {
                        if (!(event as any).layers) return;

                        (event as any).layers.eachLayer((layer: any) => {
                          if (layer.toGeoJSON() !== formik.values.polygonData)
                            formik.setFieldValue("polygonData", JSON.stringify(layer.toGeoJSON()));
                        });
                      }}
                      onCreated={(data) => {
                        formik.setFieldError("polygonData", "");

                        formik.setFieldValue("polygonData", JSON.stringify(data.layer.toGeoJSON()));
                      }}
                      onDeleted={(event) => {
                        if (!(event as any).layers) return;

                        // Check if there are any deleted layers
                        let isDeleted = false;
                        (event as any).layers.eachLayer((layer: any) => {
                          if (layer) isDeleted = true;
                        });

                        if (isDeleted) {
                          formik.setFieldValue("polygonData", "");
                          formik.validateField("polygonData");
                        }
                      }}
                    />
                  </FeatureGroup>
                </LeafletMap>
              </div>

              {formik.errors.polygonData ? (
                <ErrorMessage error={formik.errors.polygonData} />
              ) : null}
            </Grid>
          </Grid>

          <ErrorMessage error={error} />
        </DialogContent>

        <DialogActions>
          {formik.isSubmitting ? (
            <CircularProgress size={25} />
          ) : (
            <Button type="submit" color="primary">
              {addButtonText}
            </Button>
          )}

          <Button onClick={handleClose}>Откажи</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default memo(NewAreaTypeForm);
