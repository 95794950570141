import { orderDeleted, orderUpdated } from "../orders/orderActions";
import { getAllDriversThunk } from "./driversThunk";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IMapDriver } from "@shared/components/Map/models/models";
import { IBaseType } from "@shared/interfaces/IBaseType";
import { IDriverActiveStatusDto } from "@shared/services/drivers/dtos/driverActiveStatusDto";
import { IDriverDto } from "@shared/services/drivers/dtos/driverDto";
import { OrderStatus } from "@shared/services/orders/enums/orderStatus";
import { IReceiveCoordinatesResponse } from "@shared/services/signalR.service";

const initialState: IBaseType<IMapDriver[]> = {
  status: "idle",
  error: "",
  data: [],
};

const driversSlice = createSlice({
  name: "driversStore",
  initialState,
  reducers: {
    driverStartedShift: (state, action: PayloadAction<IDriverDto>) => {
      const drivers = state.data;
      const data = action.payload;
      const driverIndex = drivers.findIndex((x) => x.employeeId === data.employeeId);

      if (driverIndex <= -1) {
        drivers.push({
          ...data,
          geolocation: { latitude: data.latitude, longitude: data.longitude },
        });
      } else {
        drivers[driverIndex] = {
          ...data,
          geolocation: drivers[driverIndex].geolocation,
        };
      }
    },
    driverEndedShift: (state, action: PayloadAction<number>) => {
      const employeeId = action.payload;

      state.data = state.data.filter((x) => x.employeeId !== employeeId);
    },
    driverCoordinatesUpdated: (state, action: PayloadAction<IReceiveCoordinatesResponse>) => {
      const drivers = state.data;
      const data = action.payload;

      const driverIndex = drivers.findIndex((x) => x.userId === data.userId);
      if (driverIndex <= -1) {
        return;
      }

      drivers[driverIndex].batteryPercentage = data.batteryPercentage;
      drivers[driverIndex].recievedOn = data.recievedOn;
      drivers[driverIndex].geolocation = { latitude: data.latitude, longitude: data.longitude };
    },

    driverPauseStatusUpdated: (state, action: PayloadAction<IDriverActiveStatusDto>) => {
      const drivers = state.data;
      const data = action.payload;

      const driverIndex = drivers.findIndex((x) => x.employeeId === data.employeeId);
      if (driverIndex <= -1) {
        return;
      }

      drivers[driverIndex].pauseStatus = data;
    },
    cleanupDrivers: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    return builder
      .addCase(getAllDriversThunk.fulfilled, (_, action) => {
        return { status: "succeeded", error: "", data: action.payload };
      })
      .addCase(getAllDriversThunk.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllDriversThunk.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(orderUpdated, (drivers, { payload: data }) => {
        const driver = drivers.data.find(
          (driver) =>
            driver.employeeId === data.employeeFk ||
            driver.orders.some((order) => order.id === data.id)
        );

        if (!driver) {
          return;
        }

        const driverIndex = drivers.data.indexOf(driver);
        const driverOrderIndex = drivers.data[driverIndex].orders.findIndex(
          (order) => order.id === data.id
        );

        // New order
        if (driverOrderIndex === -1) {
          drivers.data[driverIndex].orders.push(data);
        }

        if (
          data.orderStatus === OrderStatus.Delivered ||
          data.orderStatus === OrderStatus.ResetByDispatch ||
          data.orderStatus === OrderStatus.RejectByDriver ||
          data.orderStatus === OrderStatus.RejectByCustomer ||
          data.orderStatus === OrderStatus.AutoRejectedForCustomer ||
          data.orderStatus === OrderStatus.Paused
        ) {
          // Cleanup order
          drivers.data[driverIndex].orders.splice(driverOrderIndex, 1);
        } else {
          // Update order
          drivers.data[driverIndex].orders[driverOrderIndex] = data;
        }
      })
      .addCase(orderDeleted, (state, action) => {
        const orderId = action.payload;

        const driverIndex = state.data.findIndex((x) => !!x.orders.find((r) => r.id === orderId));
        if (driverIndex === -1) {
          return;
        }

        state.data[driverIndex].orders = state.data[driverIndex].orders.filter(
          (x) => x.id === orderId
        );
      });
  },
});
export const selectDriverByOrderId = (state, orderId) => {
  return state.drivers.data.find(driver =>
    driver.orders.some(order => order.id === orderId)
  );
};
export const {
  driverStartedShift,
  driverEndedShift,
  driverCoordinatesUpdated,
  driverPauseStatusUpdated,
  cleanupDrivers,
} = driversSlice.actions;

export default driversSlice.reducer;
