import { orderDeleted, orderUpdated } from "./orderActions";
import { getActiveOrdersAsyncThunk } from "./ordersThunk";
import { createSlice } from "@reduxjs/toolkit";
import { IBaseType } from "@shared/interfaces/IBaseType";
import { IFullOrderDto } from "@shared/services/orders/dtos/orders/fullOrderDto";
import { OrderStatus } from "@shared/services/orders/enums/orderStatus";

const initialState: IBaseType<IFullOrderDto[]> = {
  status: "idle",
  error: "",
  data: [],
};

const ordersSlice = createSlice({
  name: "ordersStore",
  initialState,
  reducers: {
    cleanupOrders: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    return builder
      .addCase(getActiveOrdersAsyncThunk.fulfilled, (_, action) => {
        return { status: "succeeded", error: "", data: action.payload };
      })
      .addCase(getActiveOrdersAsyncThunk.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getActiveOrdersAsyncThunk.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(orderUpdated, (orders, { payload: data }) => {
        if (!data) return;

        // Find order in orders state and update accordingly
        const orderIndex = orders.data.findIndex((x) => x.id === data.id);

        let action: "add" | "remove" | "update" | "noAction" | "group";

        if (data.orderStatus === OrderStatus.Delivered && orderIndex > -1) {
          action = "remove";
        } else if (data.orderStatus === OrderStatus.Delivered && orderIndex <= -1) {
          action = "noAction";
        } else if (data.orderStatus !== OrderStatus.Delivered && orderIndex > -1) {
          action = "update";
        } else if (orderIndex <= -1) {
          action = "add";
        } else if (data.packageGuid !== orders.data[orderIndex].packageGuid) {
          action = "group";
        }
        switch (action) {
          case "add":
            orders.data.push(data);
            break;

          case "update":
          case "group":
            orders.data[orderIndex] = data;
            break;

          case "remove":
            orders.data.splice(orderIndex, 1);
            break;

          case "noAction":
          default:
            break;
        }
      })
      .addCase(orderDeleted, (state, action) => {
        const orderId = action.payload;
        const index = state.data.findIndex((x) => x.id === orderId);
        if (index === -1) {
          return;
        }

        state.data.splice(index, 1);
      });
  },
});

export const { cleanupOrders } = ordersSlice.actions;

export default ordersSlice.reducer;
