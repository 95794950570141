import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Check, Close, Warning } from "@material-ui/icons";
import CustomMenuSelect from "@shared/components/CustomFormControls/CustomMenuSelect";
import ErrorMessage from "@shared/components/ErrorMessage/ErrorMessage";
import LeafletMap from "@shared/components/Map/LeafletMap/LeafletMap";
import { useConfirmationDialog } from "@shared/context/ConfirmationDialogContext";
import { useMemoSelector } from "@shared/hooks";
import { getCurrentUser } from "@shared/services/auth/auth.service";
import { IEmployeeBaseDto } from "@shared/services/employees/dtos/employeeBaseDto";
import { getEmployeeByUserId } from "@shared/services/employees/employees.service";
import { IApiErrorResponseData } from "@shared/services/http.client";
import { IOrderRejectReasonDto } from "@shared/services/orders/dtos/order-reject-reason/orderRejectReasonDto";
import { IDriverOrderStatusResponseDto } from "@shared/services/orders/dtos/orders/driverOrderStatusResponseDto";
import { IOrderDto } from "@shared/services/orders/dtos/orders/orderDto";
import { OrderStatus } from "@shared/services/orders/enums/orderStatus";
import {
  driverAcceptsOrderAsync,
  driverConfirmsOrderDropOffAsync,
  driverConfirmsOrderPickUpAsync,
  driverRejectsOrderAsync,
  getDriverOrder,
  translateOrderStatus,
} from "@shared/services/orders/orders.service";
import { AxiosError } from "axios";
import dayjs from "dayjs";
import { Icon } from "leaflet";
import { FC, ReactNode, useCallback, useEffect, useState } from "react";
import { Marker, Popup } from "react-leaflet";
import { useNavigate, useParams } from "react-router-dom";

const PickUpMarkerIcon = new Icon({
  iconUrl: "/images/icons/marker-icon.png",
  iconSize: [20, 35],
});

const DropOffMarkerIcon = new Icon({
  iconUrl: "/images/icons/finish_flag.png",
  iconSize: [25, 35],
});

const useStyles = makeStyles(() =>
  createStyles({
    div: {
      height: "100%",
      padding: "10px",
      display: "flex",
      flexDirection: "column",
      textAlign: "center",
      justifyContent: "center",
    },
    header: {
      marginBottom: "10px",
    },
    content: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      flexGrow: 1,
    },
    footer: {
      display: "flex",
      justifyContent: "center",
      padding: "10px",
    },
    loadingErrorContainer: {
      margin: "auto",
      textAlign: "center",
    },
    button: {
      margin: "5px",
      cursor: "pointer",
      width: "100%",
    },
    buttonIcon: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  })
);

type OrderDetailsParams = {
  orderId: string;
};

interface IConfirmationDialog {
  status: "open" | "closed";
  title?: string;
  body?: string | ReactNode;
  icon?: ReactNode;
  action?: "accept" | "reject" | "pick-up" | "drop-off";
  
}

const OrderDetails: FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const { orderId } = useParams<OrderDetailsParams>();

  const [currentEmployee, setCurrentEmployee] = useState<IEmployeeBaseDto>(null);
  const [orderRejectReason, setOrderRejectReason] = useState<IOrderRejectReasonDto>(null);
  const [order, setOrder] = useState<IOrderDto>(null);
  const [errorMessage, setErrorMessage] = useState("");

  const { deliveryTypes, orderRejectReasons, paymentTypes } = useMemoSelector(
    ({ sharedState }) => ({
      deliveryTypes: sharedState?.deliveryTypes?.data,
      orderRejectReasons: sharedState?.orderRejectReasons?.data,
      paymentTypes: sharedState?.paymentTypes?.data,
    })
  );

  useEffect(() => {
    const currentUser = getCurrentUser();

    if (!currentUser || !currentUser.id) return;

    setErrorMessage("");

    getEmployeeByUserId(currentUser.id)
      .then((employee) => {
        setCurrentEmployee(employee);
      })
      .catch((error: string) => {
        if (error) setErrorMessage(error);
      });
  }, []);

  useEffect(() => {
    // Wait for the employee info to load before loading the order
    if (!currentEmployee || !currentEmployee.id || !orderId) return;

    setErrorMessage("");

    getDriverOrder(currentEmployee.id, orderId)
      .then((res) => {
        setOrder(res);
      })
      .catch((error: string) => {
        if (error) setErrorMessage(error);
      });
  }, [currentEmployee, orderId]);

  const confirmationDialog = useConfirmationDialog();

  const handleOrderRejectSelection = (option: IOrderRejectReasonDto) => {
    setOrderRejectReason(option);

    confirmationDialog.open({
      icon: <Warning fontSize="large" style={{ color: "orange" }} />,
      title: "Одбиј нарачка?",
      body: `Дали сте сигурни дека сакате да ја одбиете нарачката со број ${order.displayOrderNumber} поради ${option.name}?`,
      onConfirm: () => handleConfirmationDialogAction(true, "reject", order, option),
      onDeny: () => handleConfirmationDialogAction(false),
    });
  };

  const handleOrderAccept = () => {
    setOrderRejectReason(null);

    confirmationDialog.open({
      icon: <Warning fontSize="large" style={{ color: "orange" }} />,
      title: "Прифати нарачка?",
      body: `Дали сте сигурни дека сакате да ја прифатите нарачката со број ${order.displayOrderNumber}?`,
      onConfirm: () => handleConfirmationDialogAction(true, "accept", order),
      onDeny: () => handleConfirmationDialogAction(false),
    });
  };

  const handlePickUpConfirm = () => {
    confirmationDialog.open({
      icon: <Warning fontSize="large" style={{ color: "orange" }} />,
      title: "Потврди подигнување?",
      body: `Дали сте сигурни дека сакате да го потврдите подигнувањето на нарачката со број ${order.displayOrderNumber}?`,
      onConfirm: () => handleConfirmationDialogAction(true, "pick-up", order),
      onDeny: () => handleConfirmationDialogAction(false),
    });
  };

  const handleDropOffConfirm = () => {
    confirmationDialog.open({
      icon: <Warning fontSize="large" style={{ color: "orange" }} />,
      title: "Потврди достава?",
      body: `Дали сте сигурни дека сакате да ја потврдите доставата на нарачката со број ${order.displayOrderNumber}?`,
      onConfirm: () => handleConfirmationDialogAction(true, "drop-off", order),
      onDeny: () => handleConfirmationDialogAction(false),
    });
  };

  const handleConfirmationDialogAction = useCallback(
    (
      accept: boolean,
      action?: IConfirmationDialog["action"],
      order?: IOrderDto,
      option?: IOrderRejectReasonDto
    ) => {
      if (accept === false) {
        return confirmationDialog.close();
      }

      if (!currentEmployee || !currentEmployee.id || !order || !order.id) {
        return setErrorMessage("There has been a problem, please try again");
      }

      setErrorMessage("");

      let APICall: Promise<IDriverOrderStatusResponseDto> = null;

      if (action === "reject") {
        if (!option || !option.id) {
          return setErrorMessage("There has been a problem, please try again");
        }

        APICall = driverRejectsOrderAsync(currentEmployee.id, order.id, {
          orderRejectReasonFK: option.id,
        });
      } else if (action === "accept") {
        APICall = driverAcceptsOrderAsync(currentEmployee.id, order.id);
      } else if (action === "pick-up") {
        APICall = driverConfirmsOrderPickUpAsync(currentEmployee.id, order.id);
      } else if (action === "drop-off") {
        APICall = driverConfirmsOrderDropOffAsync(currentEmployee.id, order.id);
      }

      if (!APICall) return;

      confirmationDialog.setLoading(true);

      APICall.then((response) => {
        if (response?.status) {
          if (response.status === OrderStatus.Delivered) return navigate("/drivers/orders");

          setOrder({ ...order, orderStatus: response.status });
        }

        setErrorMessage("");
        setOrderRejectReason(null);
        confirmationDialog.close();
      }).catch((error: AxiosError<IApiErrorResponseData>) => {
        confirmationDialog.close();
        if (error.response) {
          setErrorMessage(error.response?.data?.message);
        }
      });
    },
    [currentEmployee]
  );

  const getDisplayValue = (data: unknown[], id: number) => {
    if (!data || !Array.isArray(data)) return id;

    const item = data.find((x) => x.id === id);
    if (item) return item.name || item.id;

    return id;
  };

  // TODO: Refactor this
  const getButtons = (orderStatus: OrderStatus) => {
    switch (orderStatus) {
      case OrderStatus.WaitingForAccept: {
        return (
          <>
            <Button
              color="primary"
              variant="contained"
              className={classes.button}
              classes={{ label: classes.buttonIcon }}
              onClick={handleOrderAccept}
            >
              <Check />
              Прифати нарачка
            </Button>

            <CustomMenuSelect
              onSelected={(reason: any) => handleOrderRejectSelection(reason)}
              children={
                <div className={classes.buttonIcon}>
                  <Close fontSize="large" />
                  Одбиј нарачка
                </div>
              }
              className={classes.button}
              selectoptions={orderRejectReasons.map((orderRejectReason) => ({
                id: orderRejectReason.id,
                name: orderRejectReason.name,
              }))}
              color="secondary"
              variant="contained"
            />
          </>
        );
      }
      case OrderStatus.Assigned: {
        return (
          <Button
            color="primary"
            variant="contained"
            className={classes.button}
            classes={{ label: classes.buttonIcon }}
            onClick={handlePickUpConfirm}
          >
            <Check fontSize="large" />
            Потврди подигнување
          </Button>
        );
      }
      case OrderStatus.PickedUp: {
        return (
          <Button
            color="primary"
            variant="contained"
            className={classes.button}
            classes={{ label: classes.buttonIcon }}
            onClick={handleDropOffConfirm}
          >
            <Check fontSize="large" />
            Потврди достава
          </Button>
        );
      }
      case OrderStatus.Delivered: {
        return <Typography>Успешно ја завршивте доставата</Typography>;
      }
      default:
        return null;
    }
  };

  return (
    <>
      {order ? (
        <div className={classes.div}>
          <div className={classes.content}>
            <LeafletMap
              height={300}
              defaultZoom={11}
              defaultLocation={{
                latitude: order.pickUpLatitude,
                longitude: order.pickUpLongitude,
              }}
            >
              <Marker
                icon={PickUpMarkerIcon}
                title={order.pickUpAddress}
                key="pickupMarker"
                position={{
                  lat: order.pickUpLatitude,
                  lng: order.pickUpLongitude,
                }}
              >
                <Popup>
                  <Grid container>
                    <span>
                      <b>Податоци за подигање</b>
                    </span>
                    <Grid item xs={12}>
                      {order.pickUpAddress}
                    </Grid>
                    <Grid item xs={12}>
                      {order.pickUpTime
                        ? dayjs.utc(order.pickUpTime).local().format("DD.MM.YYYY")
                        : ""}{" "}
                      {order.pickUpTime ? dayjs.utc(order.pickUpTime).local().format("HH:mm") : ""}
                    </Grid>
                  </Grid>
                </Popup>
              </Marker>
              <Marker
                icon={DropOffMarkerIcon}
                title={order.dropOffAddress}
                key="dropoffMarker"
                position={{
                  lat: order.dropOffLatitude,
                  lng: order.dropOffLongitude,
                }}
              >
                <Popup>
                  <Grid container>
                    <span>
                      <b>Податоци за достава</b>
                    </span>
                    <Grid item xs={12}>
                      {order.dropOffAddress}
                    </Grid>
                    <Grid item xs={12}>
                      {order.dropOffTime
                        ? dayjs.utc(order.dropOffTime).local().format("DD.MM.YYYY")
                        : ""}{" "}
                      {order.dropOffTime
                        ? dayjs.utc(order.dropOffTime).local().format("HH:mm")
                        : ""}
                    </Grid>
                  </Grid>
                </Popup>
              </Marker>
            </LeafletMap>

            <TableContainer style={{ marginTop: "10px" }}>
              <Table size="small" aria-label="collapsible table">
                <TableBody>
                  <TableRow>
                    <TableCell>Број на нарачка</TableCell>
                    <TableCell align="left">{order.id}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Начин на плаќање</TableCell>
                    <TableCell align="left">
                      {getDisplayValue(paymentTypes, order.paymentTypeFK)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Тип на пратка</TableCell>
                    <TableCell align="left">
                      {getDisplayValue(deliveryTypes, order.deliveryTypeFK)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Приоритетна</TableCell>
                    <TableCell align="left">{order.isPriority ? "Да" : "Не"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Статус</TableCell>
                    <TableCell align="left">{translateOrderStatus(order.orderStatus)}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          {errorMessage ? <ErrorMessage error={errorMessage} /> : null}

          <div className={classes.footer}>{getButtons(order.orderStatus)}</div>
        </div>
      ) : errorMessage ? (
        <div className={classes.loadingErrorContainer}>
          {/* Error message for the loading stage */}
          <ErrorMessage error={errorMessage} />
        </div>
      ) : (
        <div className={classes.loadingErrorContainer}>
          <CircularProgress />
        </div>
      )}
    </>
  );
};

export default OrderDetails;
