import { Column } from "@material-table/core";
import { IEmployeePayoutHistoryDto } from "@shared/services/payout/dtos/employeePayoutHistoryDto";
import dayjs from "dayjs";

const useEmployeePayoutsHistoryColumns = (): Column<IEmployeePayoutHistoryDto>[] => {
  return [
    {
      title: "Последна исплата",
      field: "lastPayout",
      render: (data) => dayjs(data.lastPayout).format("DD.MM.YYYY"),
    },
    {
      title: "Од",
      field: "from",
      render: (data) => dayjs(data.from).format("DD.MM.YYYY"),
    },
    {
      title: "До",
      field: "to",
      render: (data) => dayjs(data.to).format("DD.MM.YYYY"),
    },
    {
      title: "Заработка",
      field: "totalEarnings",
    },
    {
      title: "Фиксна заработка",
      field: "fixedSalary",
    },
   
    {
      title: "Верна",
      field: "verna",
    },
   
    {
      title: "Закуп на возило",
      field: "carRent",
    },
   
    {
      title: "Договор на дело",
      field: "contract",
    },
    
    {
      title: "Вкупно",
      field: "total",
    },
    {
      title: "Датум на исплата",
      field: "payoutDate",
      render: (data) => dayjs(data.payoutDate).format("DD.MM.YYYY"),
    },
  ];
};

export default useEmployeePayoutsHistoryColumns;
