import React, { FC, memo } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";
import Accordion from "../Accordion/Accordion";
import AccordionSummary from "../Accordion/AccordionSummary";
import AccordionDetails from "../Accordion/AccordionDetails";
import NotActiveDriversCard from "./NotActiveDriverCard";


const useStyles = makeStyles((theme) => ({}));

export interface EmployeeScheduleResponseProps {}

const NotActiveDrivers: FC<EmployeeScheduleResponseProps> = () => {
  const classes = useStyles();

  return (
    <Accordion square defaultExpanded={true}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="body1">Неактивни шофери</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div>
          <NotActiveDriversCard />
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default memo(NotActiveDrivers);