import React, { ChangeEvent, FC, memo } from "react";
import {
  Checkbox,
} from "@material-ui/core";

export interface Props {
    checked: boolean;
    name: string;
    disabled?: boolean;
    onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const CustomCheckboxField: FC<Props> = ({
  checked,
  name,
  onChange,
  disabled
}) => {
  return (
    <Checkbox disabled={disabled} checked={checked} onChange={onChange} name={name} />
  );
};

export default memo(CustomCheckboxField);
