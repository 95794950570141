import { IEmployeeSalaryConfigurationDto } from "@shared/services/salary-configuration/dtos/employee-salary-configuration/employeeSalaryConfigurationDto";
import { useFormik } from "formik";
import * as yup from "yup";

export const initialValues: IEmployeeSalaryConfigurationDto = {
  id: -1,
  employeeFK: -1,
  ratePerHour: 0,
  ratePerHourNightShift: 0,
  ratePerPickUp: 0,
  ratePerDropOff: 0,
  ratePerKmToPickUp: 0,
  ratePerKmToDropOff: 0,
  ratePerMinuteToPickUp: 0,
  ratePerMinuteToDropOff: 0,
  ratePerWaitingMinute: 0,
  validFrom: undefined,
  validTo: undefined,
  rateSick: 0,
  applyPeakHours: false,
  breakDuration: 0,
  breakPay: 0,
  orderDeclinePenalty: 0,
  orderDeclineTimePenalty: 0,
  lateLess15: 0,
  late1530: 0,
  late3060: 0,
  late60: 0,
  addOnSunday: 0,
  addOnHoliday: 0,
  addOnSundayHoliday: 0,
  addOnNight: 0,
  maxHolidays: 0,
  holidayTimeEarn: 0,
  holidayPay: 0,
  fixedSalaryBrutto: 0,
  fixedSalaryBruttoCompanyFK: -1,
  fixedContractCompanyFK: -1,
  fixedContract: 0,
  vernaCompanyFK: -1,
  verna: 0,
  carRentCompanyFK: -1,
  carRent: 0,
  contractCompanyFK: -1,
  contract: 0,
  scheduleBonus: 0,
  orderDeclineBonus: 0,
  penaltiesUnderBonus: 0,
  employeeSalaryBonuses: [],
};

const validationSchema = yup
  .object()
  .shape<Record<keyof IEmployeeSalaryConfigurationDto, yup.Schema>>({
    id: yup.number().min(-1).required(),
    employeeFK: yup.number().positive().required(),
    ratePerHour: yup.number().min(0).required(),
    ratePerHourNightShift: yup.number().min(0).required(),
    ratePerPickUp: yup.number().min(0).required(),
    ratePerDropOff: yup.number().min(0).required(),
    ratePerKmToPickUp: yup.number().min(0).required(),
    ratePerKmToDropOff: yup.number().min(0).required(),
    ratePerMinuteToPickUp: yup.number().min(0).required(),
    ratePerMinuteToDropOff: yup.number().min(0).required(),
    ratePerWaitingMinute: yup.number().min(0).required(),
    validFrom: yup.date().notRequired(),
    validTo: yup.date().notRequired(),
    rateSick: yup.number().min(0).required(),
    applyPeakHours: yup.boolean().required(),
    breakDuration: yup.number().min(0).required(),
    breakPay: yup.number().min(0).required(),
    orderDeclinePenalty: yup.number().min(0).required(),
    orderDeclineTimePenalty: yup.number().min(0).required(),
    lateLess15: yup.number().min(0).required(),
    late1530: yup.number().min(0).required(),
    late3060: yup.number().min(0).required(),
    late60: yup.number().min(0).required(),
    addOnSunday: yup.number().min(0).required(),
    addOnHoliday: yup.number().min(0).required(),
    addOnSundayHoliday: yup.number().min(0).required(),
    addOnNight: yup.number().min(0).required(),
    maxHolidays: yup.number().min(0).required(),
    holidayTimeEarn: yup.number().min(0).required(),
    holidayPay: yup.number().min(0).required(),
    fixedSalaryBrutto: yup.number().min(0).required(),
    fixedSalaryBruttoCompanyFK: yup.number().positive().required(),
    fixedContractCompanyFK: yup.number().positive().required(),
    fixedContract: yup.number().min(0).required(),
    vernaCompanyFK: yup.number().positive().required(),
    verna: yup.number().min(0).required(),
    carRentCompanyFK: yup.number().positive().required(),
    carRent: yup.number().min(0).required(),
    contractCompanyFK: yup.number().positive().required(),
    contract: yup.number().min(0).required(),
    scheduleBonus: yup.number().min(0).required(),
    orderDeclineBonus: yup.number().min(0).required(),
    penaltiesUnderBonus: yup.number().min(0).required(),
    employeeSalaryBonuses: yup.array().of(
      yup.object().shape({
        id: yup.number().min(-1).optional(),
        type: yup.number().min(0).optional(),
        condition: yup.number().min(0).optional(),
        amount: yup.number().min(0).optional(),
        employeeSalaryFk: yup.number().min(0).optional(),
      })
    ),
  });

interface UseEmployeeSalaryConfigFormikProps {
  onSubmit: (values: IEmployeeSalaryConfigurationDto) => void;
}

const useEmployeeSalaryConfigDetailsFormik = ({
  onSubmit,
}: UseEmployeeSalaryConfigFormikProps): ReturnType<
  typeof useFormik<IEmployeeSalaryConfigurationDto>
> => {
  const formik = useFormik<IEmployeeSalaryConfigurationDto>({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  return formik;
};

export default useEmployeeSalaryConfigDetailsFormik;
