import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import mk from "dayjs/locale/mk";
import utc from 'dayjs/plugin/utc';
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";

const locales = { mk };

export const setupLocalization = (defaultLocale: keyof typeof locales) => {
  dayjs.extend(LocalizedFormat);
  dayjs.extend(utc);
  dayjs.extend(duration);
  dayjs.extend(relativeTime);
  dayjs.locale(locales[defaultLocale]);
};
