import {
  setBorderRadius,
  toggleAnimations,
  toggleDarkMode,
  toggleShowConfirmationDialog,
} from "@App/store/slices/preferences.slice";
import {
  Avatar,
  Chip,
  Dialog,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  TextFieldProps,
  Typography,
  debounce,
} from "@material-ui/core";
import { Theme, makeStyles } from "@material-ui/core/styles";
import { VerifiedUser } from "@material-ui/icons";
import DialogContent from "@shared/components/Dialog/DialogContent";
import DialogTitle from "@shared/components/Dialog/DialogTitle";
import { useMemoSelector } from "@shared/hooks";
import { getCurrentUser } from "@shared/services/auth/auth.service";
import { ChangeEvent, FC } from "react";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  dialogContent: {
    minWidth: "500px",
    overflow: "auto",
  },
  largeAvatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

const OutlinedDiv: FC<TextFieldProps> = ({ children, ...props }) => (
  <TextField
    {...props}
    variant="outlined"
    InputLabelProps={{ shrink: true }}
    InputProps={{
      inputComponent: (props: any) => <div {...props} />,
    }}
    inputProps={{
      children: children,
    }}
    multiline
  />
);

export interface UserPreferencesDialogProps {
  open: boolean;
  onClose: () => void;
}

const UserPreferencesDialog: FC<UserPreferencesDialogProps> = ({ open, onClose }) => {
  const classes = useStyles();

  const user = getCurrentUser();

  const dispatch = useDispatch();
  const preferences = useMemoSelector(({ appState }) => appState.preferences);

  const handleThemeToggle = () => dispatch(toggleDarkMode());
  const handleAnimationsToggle = () => dispatch(toggleAnimations());
  const handleBorderRadiusChange = debounce((event: ChangeEvent<HTMLInputElement>) => {
    var value = parseInt(event.target.value, 10);
    dispatch(setBorderRadius(value));
  }, 500);

  const handleShowConfirmationDialogToggle = () => dispatch(toggleShowConfirmationDialog());

  return (
    <Dialog maxWidth={false} classes={{ paper: classes.dialog }} open={open} onClose={onClose}>
      <DialogTitle onClose={onClose}>Кориснички подесувања</DialogTitle>

      <DialogContent className={classes.dialogContent} dividers>
        <Grid container spacing={2}>
          <Grid item>
            <Avatar className={classes.largeAvatar} />
          </Grid>

          <Grid item>
            <Typography variant="h6">Име Презиме</Typography>
            <Chip icon={<VerifiedUser />} label="Диспечер" size="small" color="secondary" />
          </Grid>
        </Grid>

        <br />

        <OutlinedDiv label="Корисничко име" size="small" fullWidth>
          <Typography>{user.email}</Typography>
        </OutlinedDiv>

        <br />
        <br />

        <OutlinedDiv label="Тема" fullWidth>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={preferences.theme.isDarkMode}
                onChange={handleThemeToggle}
              />
            }
            label="Dark theme"
          />

          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={preferences.theme.animations}
                onChange={handleAnimationsToggle}
              />
            }
            label="Анимации"
          />

          <br />
          <br />

          <TextField
            fullWidth
            variant="filled"
            color="primary"
            label="Заоблување"
            type="number"
            inputProps={{
              min: 0,
              max: 100,
            }}
            defaultValue={preferences.theme.borderRadius}
            onChange={handleBorderRadiusChange}
          />
        </OutlinedDiv>

        <br />
        <br />

        <OutlinedDiv label="" fullWidth>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={preferences.showConfirmationDialog}
                onChange={handleShowConfirmationDialogToggle}
              />
            }
            label="Потврдување на акција"
          />
        </OutlinedDiv>
      </DialogContent>
    </Dialog>
  );
};

export default UserPreferencesDialog;
