import httpService from "../http.service";
import { ITaxSettingsDto } from "./dtos/tax-settings/taxSettingsDto";
import { ITaxSettingsRequestDto } from "./dtos/tax-settings/taxSettingsRequestDto";

export const getTaxSettings = () => {
  return httpService.get<ITaxSettingsDto>(`/api/tax-settings`);
};

export const updateTaxSettings = (request: ITaxSettingsRequestDto) => {
  return httpService.put<void, ITaxSettingsRequestDto>(`/api/tax-settings`, request);
};
