import { Grid, Typography, makeStyles } from "@material-ui/core";
import { IMapDriver, IMapOrder } from "@shared/components/Map/models/models";
import { IRoutePointDto } from "@shared/services/orders/dtos/orders/routePointDto";
import dayjs from "dayjs";
import React, { FC, memo } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "50%",
  },
  image: {
    height: "10rem",
    width: "10rem",
    margin: "auto",
  },
  sectionTitle: {
    marginBottom: theme.spacing(2),
    display: "block",
  },
  container: {
    display: "block",
  },
}));

export interface RouteTimeTooltipContentProps {
  previousPoint: IRoutePointDto;
  routePoint: IRoutePointDto;
}

const RouteTimeTooltipContent: FC<RouteTimeTooltipContentProps> = ({
  routePoint,
  previousPoint,
}) => {
  const classes = useStyles();
  return (
    <>
      {routePoint ? (
        <>
          <Grid spacing={2} className={classes.container} container>
            <Grid md={12} item>
              <Typography className={classes.sectionTitle} variant="body1" component="div">
                {routePoint.isPickup
                  ? "Време за подигање на нарачка "
                  : "Време за испорака на нарачка "}
                со идентификациски број {routePoint.orderId}
              </Typography>
            </Grid>
            <Grid md={12} item>
              <Typography variant="body1" component="span">
                {routePoint.isPickup ? "Подигната: " : "Испорачана: "}
              </Typography>
              <Typography variant="body1" component="span">
                {routePoint.onLocationAt
                  ? `ДА ${dayjs.utc(routePoint.onLocationAt).local().format("DD.MM.YYYY HH:mm")}`
                  : "НЕ"}
              </Typography>
            </Grid>
            <Grid md={12} item>
              <Typography variant="body1" component="span">
                Max време за {routePoint.isPickup ? "подигање " : "испорака "} на нарачка:{" "}
              </Typography>
              <Typography variant="body1" component="span">
                {routePoint.orderTime
                  ? dayjs.utc(routePoint.orderTime).local().format("DD.MM.YYYY HH:mm")
                  : "N/A"}
              </Typography>
            </Grid>
            <Grid md={12} item>
              <Typography variant="body1" component="span">
                Max време за {routePoint.isPickup ? "подигање " : "испорака "} на нарачка од
                алгоритмот:{" "}
              </Typography>
              <Typography variant="body1" component="span">
                {routePoint.estimatedTime
                  ? dayjs.utc(routePoint.estimatedTime).local().format("DD.MM.YYYY HH:mm")
                  : "N/A"}
              </Typography>
            </Grid>
            <Grid md={12} item>
              <Typography variant="body1" component="span">
                {previousPoint ? "Изминато време од подигање: " : "Изминато време од нарачка: "}
              </Typography>
              <Typography variant="body1" component="span">
                {/* {previousPoint.onLocationAt ? dayjs.utc(routePoint.onLocationAt).local().diff(dayjs.utc(previousPoint.orderTime).local(), "minutes") : "N/A"} */}
                {previousPoint &&
                previousPoint.onLocationAt &&
                routePoint &&
                routePoint.onLocationAt
                  ? dayjs
                      .utc(routePoint.onLocationAt)
                      .local()
                      .diff(dayjs.utc(previousPoint.onLocationAt).local(), "minutes") + " минути"
                  : routePoint && routePoint.onLocationAt
                  ? dayjs
                      .utc(routePoint.onLocationAt)
                      .local()
                      .diff(dayjs.utc(routePoint.orderTime).local(), "minutes") + " минути"
                  : "N/A"}
              </Typography>
            </Grid>
          </Grid>
        </>
      ) : null}
    </>
  );
};

export default memo(RouteTimeTooltipContent);
