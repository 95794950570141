import DayjsUtils from "@date-io/dayjs";
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { memo } from "react";

const CustomDateTimePicker = ({ label, fullWidth = true, style = {}, format, value, handleDateChange, required = false }) => {
    return (
        <>
            <MuiPickersUtilsProvider utils={DayjsUtils}>
                <KeyboardDateTimePicker
                    variant="inline"
                    style={style}
                    fullWidth={fullWidth}
                    ampm={false}
                    label={label}
                    value={value}
                    onChange={handleDateChange}
                    disablePast
                    format={format}
                    required={required}
                />
            </MuiPickersUtilsProvider>
        </>
    );
}

export default memo(CustomDateTimePicker);