import { FC, memo, useMemo, useState } from "react";
// import { useDispatch } from "react-redux";
import { useMemoSelector } from "@shared/hooks";
import { makeStyles, Typography } from "@material-ui/core";
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";
import { selectAllDriversRoutes } from "../../store/features/driversRoutes/driversRoutesSelectors";
import DriversRoute from "./DriversRoute";
import Accordion from "../Accordion/Accordion";
import AccordionSummary from "../Accordion/AccordionSummary";
import AccordionDetails from "../Accordion/AccordionDetails";
import EstimatedRoutesButton from "./EstimatedRoutesButton";
import {IRoutePoint } from "@shared/services/order.service";
import dayjs from "dayjs";
import { getDropOffTime, getPickupTime } from "@shared/utils/orders/orderTime";
import { sum } from "lodash";
// import { selectedRouteId } from "../../store/features/filters/filtersSlice";

const useStyles = makeStyles((theme) => ({
  accordionSummary: {
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

export interface DriversRoutesProps {}

const DriversRoutes: FC<DriversRoutesProps> = () => {
  const classes = useStyles();

  const driversRoutes = useMemoSelector(selectAllDriversRoutes);

  return (
    <Accordion square defaultExpanded={true}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        classes={{ content: classes.accordionSummary }}
      >
        <div>
          <Typography variant="body1">Рути на возачи ({driversRoutes.length})</Typography>
        </div>

        <div>
          <EstimatedRoutesButton />
        </div>
      </AccordionSummary>

      <AccordionDetails style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        {driversRoutes.map((driversRoute) => (
          <DriversRoute key={driversRoute.id} driversRoute={driversRoute} />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default memo(DriversRoutes);