import { Paper, Typography, createStyles, makeStyles } from "@material-ui/core";
import { FC } from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    header: {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(4),
    },
  })
);

const PaycheckHeader: FC = () => {
  const classes = useStyles();
  return (
    <Paper square className={classes.header}>
      <Typography align="center" variant="h4">
        Заработка
      </Typography>
    </Paper>
  );
};

export default PaycheckHeader;
