import httpService from "../http.service";
import { IVehicleTypeDto } from "./dtos/vehicle-types/vehicleTypeDto";
import { IVehicleTypeRequestDto } from "./dtos/vehicle-types/vehicleTypeRequestDto";

export const getAllVehicleTypes = () => {
  return httpService.get<IVehicleTypeDto[]>("/api/vehicle-types");
};

export const getVehicleType = (id: number) => {
  return httpService.get<IVehicleTypeDto>(`/api/vehicle-types/${id}`);
};

export const addNewVehicleType = (request: IVehicleTypeRequestDto) => {
  return httpService.post<IVehicleTypeDto, IVehicleTypeRequestDto>(`/api/vehicle-types`, request);
};

export const updateVehicleType = (id: number, request: IVehicleTypeRequestDto) => {
  return httpService.put<IVehicleTypeDto, IVehicleTypeRequestDto>(
    `/api/vehicle-types/${id}`,
    request
  );
};

export const removeVehicleType = (id: number) => {
  return httpService.delete<void>(`/api/vehicle-types/${id}`);
};
