import React, { FC, useEffect, useState } from 'react';
import { Table, TableBody, TableContainer, TableRow, Paper, TableCell } from '@mui/material';
import CustomDateField from '@shared/components/CustomFormControls/CustomDateField';
import { getDriverTurnover } from '@shared/services/drivers/drivers.service';
import { IDriverRevenueDetails } from '@shared/services/drivers/dtos/driverRevenueDetailsDto';
import { getCurrentUser } from '@shared/services/auth/auth.service';
import dayjs from 'dayjs';

const RevenueDetails: FC = () => {
    const [turnoverData, setTurnoverData] = useState<IDriverRevenueDetails | null>(null);
    const [selectedDate, setSelectedDate] = useState(dayjs().format('MM.DD.YYYY'));

    const turnoverLabels = {
        name: 'Име',
        cash: 'Вкупно промет',
        cashFood: 'Промет храна',
        cashDelivery: 'Промет достава',
    };

    useEffect(() => {
        const currentUser = getCurrentUser();
        getDriverTurnover(currentUser.employeeId, selectedDate)
            .then((data) => setTurnoverData(data))
            .catch((error) => console.error('Error fetching turnover:', error));
    }, [selectedDate]);

    return (
        <div style={{ textAlign: 'center', padding: '20px' }}>
            <h1 style={{ color: '#18ba9b', marginBottom: '10px' }}>ПРОМЕТ</h1>
            <p>Тука може да го проверите прометот за ден</p>
            <CustomDateField
              value={selectedDate}
              onChange={(newDate) => setSelectedDate(newDate.format('MM.DD.YYYY'))}
              style={{ marginBottom: '20px' }}
/>
           
            {turnoverData && (
                <TableContainer component={Paper}>
                    <Table>
                        <TableBody>
                            {Object.keys(turnoverLabels).map((property, index) => (
                                <TableRow key={index}>
                                    <TableCell>{turnoverLabels[property]}</TableCell>
                                    <TableCell>{turnoverData[property]}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </div>
    );
}

export default RevenueDetails;
