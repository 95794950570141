import { Grid, Typography, makeStyles } from "@material-ui/core";
import ColoredButton from "@shared/components/ColoredButton/ColoredButton";
import { IMapDriver, IMapOrder } from "@shared/components/Map/models/models";
import { ICustomerDto } from "@shared/services/orders/dtos/orders/customerDto";
import { IPartnerDto } from "@shared/services/orders/dtos/orders/partnerDto";
import { IRoutePointDto } from "@shared/services/orders/dtos/orders/routePointDto";
import {
  deleteRoutePoint,
  getOrderCustomerDetailsAsync,
  getOrderPartnerDetailsAsync,
} from "@shared/services/orders/orders.service";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import React, { FC, memo, useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "50%",
  },
  image: {
    height: "10rem",
    width: "10rem",
    margin: "auto",
  },
  sectionTitle: {
    marginBottom: theme.spacing(2),
    display: "block",
  },
  container: {
    display: "block",
  },
}));

export interface RoutePointTooltipContentProps {
  routePoint: IRoutePointDto;
  order: IMapOrder;
}

const RoutePointTooltipContent: FC<RoutePointTooltipContentProps> = ({ routePoint, order }) => {
  const classes = useStyles();

  const [customerInfoState, setCustomerInfoState] = useState<ICustomerDto>(null);
  const [partnerInfoState, setPartnerInfoState] = useState<IPartnerDto>(null);

  useEffect(() => {
    if (routePoint.isPickup === true) {
      loadPartnerData(routePoint.orderId);
    } else if (routePoint.isPickup === false) {
      loadCustomerData(routePoint.orderId);
    }
  }, [routePoint.isPickup, routePoint.orderId]);

  const loadPartnerData = (orderId: number) => {
    getOrderPartnerDetailsAsync(orderId).then((res) => {
      setPartnerInfoState(res);
    });
  };

  const loadCustomerData = (orderId: number) => {
    getOrderCustomerDetailsAsync(orderId).then((res) => {
      setCustomerInfoState(res);
    });
  };

  const { enqueueSnackbar } = useSnackbar();
  const handleRoutePointDelete = (routePointId: number) => {
    deleteRoutePoint(order.driver.employeeId, routePointId)
      .then(() => {
        enqueueSnackbar("Успешно ја избришавте дестинацијата.", {
          variant: "success",
          autoHideDuration: 6000,
        });
      })
      .catch((e) => {
        enqueueSnackbar(
          `Неуспешно ја избришавте дестинацијата: ${e?.response?.data?.message || e.message}`,
          {
            variant: "error",
            autoHideDuration: 6000,
          }
        );
      });
  };

  return (
    <>
      {order &&
      routePoint &&
      ((routePoint.isPickup && partnerInfoState) || (!routePoint.isPickup && customerInfoState)) ? (
        <>
          <Grid spacing={2} className={classes.container} container>
            <Grid md={12} item>
              <Typography className={classes.sectionTitle} variant="body1" component="span">
                {routePoint.isPickup ? "Податоци за партнер " : "Податоци за корисник "}
                за нарачка со идентификациски број {routePoint.orderId}
              </Typography>
            </Grid>
            <Grid md={12} item>
              <Typography variant="body1" component="span">
                {routePoint.isPickup ? "Партнер: " : "Корисник: "}
              </Typography>
              <Typography variant="body1" component="span">
                {routePoint.isPickup
                  ? order.partnerID + " " + partnerInfoState.name
                  : order.customerNumber + " " + customerInfoState.name}
              </Typography>
            </Grid>
            {routePoint.isPickup ? (
              <>
                <Grid md={12} item>
                  <Typography variant="body1" component="span">
                    Надворешен број на нарачка:
                  </Typography>
                  <Typography variant="body1" component="span">
                    {partnerInfoState.externalNumber}
                  </Typography>
                </Grid>
                <Grid md={12} item>
                  <Typography variant="body1" component="span">
                    Адреса:
                  </Typography>
                  <Typography variant="body1" component="span">
                    {partnerInfoState.address}
                  </Typography>
                </Grid>
                <Grid md={12} item>
                  <Typography variant="body1" component="span">
                    Телефон:
                  </Typography>
                  <Typography variant="body1" component="span">
                    {partnerInfoState.phoneNumbers}
                  </Typography>
                </Grid>
              </>
            ) : (
              <>
                <Grid md={12} item>
                  <Typography variant="body1" component="span">
                    Надворешен број на нарачка:
                  </Typography>
                  <Typography variant="body1" component="span">
                    {customerInfoState.externalNumber}
                  </Typography>
                </Grid>
                <Grid md={12} item>
                  <Typography variant="body1" component="span">
                    Телефон:
                  </Typography>
                  <Typography variant="body1" component="span">
                    {customerInfoState.phoneNumber}
                  </Typography>
                </Grid>

                <Grid md={12} item>
                  <Typography variant="body1" component="span">
                    Адреса:
                  </Typography>
                  <Typography variant="body1" component="span">
                    {customerInfoState.street} {customerInfoState.streetNumber}{" "}
                    {customerInfoState.apartment} {customerInfoState.entranceCode}
                  </Typography>
                </Grid>
              </>
            )}

            <ColoredButton
              color="error"
              variant="text"
              size="small"
              onClick={() => handleRoutePointDelete(routePoint.id)}
            >
              Избриши
            </ColoredButton>
          </Grid>
        </>
      ) : null}
    </>
  );
};

export default memo(RoutePointTooltipContent);
