import logisticsReducer from "../modules/Logistics/store";
import preferencesReducer from "./slices/preferences.slice";
import { combineReducers } from "@reduxjs/toolkit";

const appReducer = combineReducers({
  preferences: preferencesReducer,
  logistics: logisticsReducer,
});

export default appReducer;
export type IApplicationState = ReturnType<typeof appReducer>;
