import httpService from "../http.service";
import { IAreaDto } from "./dtos/area/areaDto";
import { IAreaRequestDto } from "./dtos/area/areaRequestDto";

export const getAllAreas = () => {
  return httpService.get<IAreaDto[]>("/api/areas");
};

export const getArea = (id: number) => {
  return httpService.get<IAreaDto>(`/api/areas/${id}`);
};

export const addNewArea = (request: IAreaRequestDto) => {
  return httpService.post<IAreaDto, IAreaRequestDto>(`/api/areas`, request);
};

export const updateArea = (id: number, request: IAreaRequestDto) => {
  return httpService.put<IAreaDto, IAreaRequestDto>(`/api/areas/${id}`, request);
};

export const removeArea = (id: number) => {
  return httpService.delete<void>(`/api/areas/${id}`);
};
