import CompanyCardContentDisplay from "./CompanyCardContentDisplay";
import CompanyCardContentEdit from "./CompanyCardContentEdit";
import { Button, Card, CardHeader, createStyles, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import { editCompany } from "@shared/services/settings/companies.service";
import { ICompanyDto } from "@shared/services/settings/dtos/companies/companyDto";
import { useMutation } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { FC, useState } from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      minWidth: theme.spacing(48),
      minHeight: theme.spacing(36),
      margin: theme.spacing(1),
    },
  })
);

interface CompanyCardProps {
  company: ICompanyDto;
  onEdit: (company: ICompanyDto) => void;
}

const CompanyCard: FC<CompanyCardProps> = ({ company, onEdit }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [editMode, setEditMode] = useState(false);

  const { mutate: editCompanyMutate } = useMutation(editCompany, {
    onSuccess: (company) => {
      setEditMode(false);
      onEdit(company);
    },
    onError: () => {
      enqueueSnackbar(
        "Се случи грешка. Ве молиме обидете се повторно или контактирајте го тимот за поддршка."
      );
    },
  });

  return (
    <Card className={classes.root}>
      <CardHeader
        title={!editMode ? company.name : ""}
        action={
          <Button onClick={() => setEditMode(!editMode)}>
            {!editMode && <EditIcon />}
            {editMode && <CloseIcon />}
          </Button>
        }
      />
      {!editMode && <CompanyCardContentDisplay company={company} />}
      {editMode && <CompanyCardContentEdit company={company} onSubmit={editCompanyMutate} />}
    </Card>
  );
};

export default CompanyCard;
