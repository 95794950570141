import DriverTooltipContent from "./DriverTooltipContent";
import { Avatar, Badge, BadgeProps } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Receipt } from "@material-ui/icons";
import ClickableTooltip from "@shared/components/Tooltip/ClickableTooltip";
import { IDriverDto } from "@shared/services/drivers/dtos/driverDto";
import { getDisplayValue } from "@shared/utils/getDisplayValue";
import { getVehicleIcon } from "@shared/utils/getVehicleIcon";
import { FC, memo } from "react";
import { useSelector } from "react-redux";

type DriverStatus = "free" | "busy" | "paused";

interface IStyledBadgeProps extends BadgeProps {
  status: DriverStatus;
}

const StyledBadge = withStyles({
  badge: {
    padding: "3px",
    borderRadius: "5px",
    transform: "translateY(40%)",
    top: "auto",
    right: "auto",
    backgroundColor: ({ status }: IStyledBadgeProps) =>
      status === "paused" ? "red" : status === "busy" ? "orange" : "green",
  },
  root: {
    justifyContent: "center",
    alignItems: "flex-end",
  },
})(Badge);

export interface DriverCardProps {
  driver: IDriverDto;
  title: string;
}

const DriverCard: FC<DriverCardProps> = (props) => {
  const { driver, title } = props;

  const vehicleTypes = useSelector((x: any) => x.sharedState.vehicleTypes.data);
  const vehicleTypeName = getDisplayValue(vehicleTypes, driver.vehicleTypeFk);

  const isPaused = driver.pauseStatus && driver.pauseStatus.startTime;
  const isBusy = driver.orders && driver.orders.length > 0;

  return (
    <ClickableTooltip
      arrow={true}
      type="driver"
      content={<DriverTooltipContent driver={driver} />}
      placement="right"
    >
      <StyledBadge
        status={isPaused ? "paused" : isBusy ? "busy" : "free"}
        badgeContent={
          <>
            {getVehicleIcon(vehicleTypeName, "small")}
            {driver.hasFiscalPrinter && (
              <>
                <span style={{ marginLeft: "3px", marginRight: "3px" }}>|</span>
                <Receipt fontSize="small" />
              </>
            )}
          </>
        }
      >
        <Avatar
          style={{
            width: "60px",
            height: "60px",
            borderBottom: `4px solid`,
            borderBottomColor: isPaused ? "red" : isBusy ? "orange" : "green",
          }}
          title={title}
          variant="square"
          alt={`${driver.firstName} ${driver.lastName}`}
          src={driver.imageSrc}
        >{`${driver.firstName[0]}${driver.lastName[0]}`}</Avatar>{" "}
      </StyledBadge>
    </ClickableTooltip>
  );
};

export default memo(DriverCard);
