import { forwardRef, useImperativeHandle } from "react";
import { useMap } from "react-leaflet";
import { Map } from "leaflet";

const LeafletMapRefBinder = forwardRef<Map>((_, ref) => {
  const map = useMap();

  useImperativeHandle(ref, () => map);

  return null;
});

export default LeafletMapRefBinder;
