import httpService from "../http.service";
import { IOrderRejectReasonDto } from "./dtos/order-reject-reason/orderRejectReasonDto";
import { IOrderRejectReasonRequestDto } from "./dtos/order-reject-reason/orderRejectReasonRequestDto";

export const getAllOrderRejectReasonTypes = () => {
  return httpService.get<IOrderRejectReasonDto[]>("/api/order-reject-reason");
};

export const getOrderRejectReasonType = (id: Number) => {
  return httpService.get<IOrderRejectReasonDto>(`/api/order-reject-reason/${id}`);
};

export const addNewOrderRejectReasonType = (request: IOrderRejectReasonRequestDto) => {
  return httpService.post<IOrderRejectReasonDto, IOrderRejectReasonRequestDto>(
    "/api/order-reject-reason",
    request
  );
};

export const updateOrderRejectReasonType = (id: number, request: IOrderRejectReasonRequestDto) => {
  return httpService.put<IOrderRejectReasonDto, IOrderRejectReasonRequestDto>(
    `/api/order-reject-reason/${id}`,
    request
  );
};

export const removeOrderRejectReasonType = (id: number) => {
  return httpService.delete<void>(`/api/order-reject-reason/${id}`);
};
