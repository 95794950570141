import ErrorFallback from "../../../../../error";
import useRoleSalaryConfigDetailsFormik from "./hooks/useRoleSalaryConfigDetailsFormik";
import AddonsForm from "./tabs/AddonsForm";
import BonusesForm from "./tabs/BonusesForm";
import BreaksForm from "./tabs/BreaksForm";
import EmployeeVacationsForm from "./tabs/EmployeeVacationsForm";
import FixedEarningsForm from "./tabs/FixedEarningsForm";
import HolidaysForm from "./tabs/HolidaysForm";
import PeakHoursForm from "./tabs/PeakHoursForm";
import PenaltiesForm from "./tabs/PenaltiesForm";
import PerformanceEarningsForm from "./tabs/PerformanceEarningsForm";
import SickDaysForm from "./tabs/SickDaysForm";
import { Button, Grid, Tab, Tabs, Typography, createStyles, makeStyles } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import SaveIcon from "@material-ui/icons/Save";
import DebounceLoading from "@shared/components/Debounce/DebounceLoading";
import Loading from "@shared/components/Loading/Loading";
import { TabPanel } from "@shared/components/TabPanel/TabPanel";
import {
  getRoleSalaryById,
  propagateRoleSalaryConfiguration,
  updateRoleSalary,
} from "@shared/services/salary-configuration/role-salary-configuration.service";
import { getEmployeePosition } from "@shared/services/settings/employee-positions.service";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { FC, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const useStyles = makeStyles((theme) =>
  createStyles({
    content: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
  })
);

enum RoleSalaryConfigTabs {
  FixedEarnings = 0,
  PerformanceEarnings = 1,
  Breaks = 2,
  Bonuses = 3,
  Penalties = 4,
  Addons = 5,
  EmployeeVacations = 7,
  SickDays = 9,
}

type RoleSalaryDetailsParams = {
  id: string;
};

const RoleSalaryConfigDetails: FC = () => {
  const params = useParams<RoleSalaryDetailsParams>();
  const roleSalaryConfigId = useMemo(() => Number(params.id), [params]);
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [tab, setTab] = useState<RoleSalaryConfigTabs>(RoleSalaryConfigTabs.FixedEarnings);

  const { mutate: updateRoleSalaryMutate, isLoading: isLoadingUpdateRoleSalary } = useMutation(
    updateRoleSalary,
    {
      onSuccess: () => {
        enqueueSnackbar("Конфигурацијата е успешно зачувана.");
      },
      onError: () => {
        enqueueSnackbar(
          "Се случи грешка. Ве молиме обидете се повторно или контактирајте го тимот за поддршка."
        );
      },
    }
  );

  const propagateChanges = () => () => {
    // Prop
     propagateRoleSalaryConfiguration(roleSalaryConfigId);
  }

  const formik = useRoleSalaryConfigDetailsFormik({
    onSubmit: (values) => updateRoleSalaryMutate(values),
  });

  const roleSalaryConfigQuery = useQuery(
    ["getRoleSalaryById", roleSalaryConfigId],
    () => getRoleSalaryById(roleSalaryConfigId),
    {
      onSuccess: (data) => {
        formik.setValues(data);
      },
    }
  );

  const employeePositionQuery = useQuery(
    ["getEmployeePosition", roleSalaryConfigQuery.data?.employeePositionFK],
    () => getEmployeePosition(roleSalaryConfigQuery.data.employeePositionFK),
    {
      enabled: !!roleSalaryConfigQuery.data,
    }
  );

  if (roleSalaryConfigQuery.isLoading || employeePositionQuery.isLoading) return <Loading />;
  if (roleSalaryConfigQuery.isError || employeePositionQuery.isError)
    return <ErrorFallback error={roleSalaryConfigQuery.error ?? employeePositionQuery.error} />;

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={4}>
        <Grid item>
          <Button onClick={() => navigate("/app/settings/role-salary-config")}>
            <ArrowBackIosIcon />
          </Button>
        </Grid>
        <Grid item>
          <Typography variant="h5">
            Конфигурациза на плата за: <em>{employeePositionQuery.data.name || ""}</em>
          </Typography>
        </Grid>
        <Grid item>
          <DebounceLoading loading={isLoadingUpdateRoleSalary}>
            <Button variant="contained" color="primary" onClick={formik.submitForm}>
              <SaveIcon color="action" /> &nbsp; Зачувај промени
            </Button>
            <Button variant="contained" style={{marginLeft: 10}} onClick={propagateChanges()}>
              Проследи промени
            </Button>
          </DebounceLoading>
        </Grid>
      </Grid>

      <Grid container spacing={2} className={classes.content}>
        <Grid item xs={12}>
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            value={tab}
            onChange={(_, newTab: RoleSalaryConfigTabs) => setTab(newTab)}
          >
            <Tab value={RoleSalaryConfigTabs.FixedEarnings} label="Фиксна заработка и одбитоци" />
            <Tab
              value={RoleSalaryConfigTabs.PerformanceEarnings}
              label="Заработка по перформанси"
            />
            <Tab value={RoleSalaryConfigTabs.Breaks} label="Пауза" />
            <Tab value={RoleSalaryConfigTabs.Bonuses} label="Бонуси" />
            <Tab value={RoleSalaryConfigTabs.Penalties} label="Пенали" />
            <Tab value={RoleSalaryConfigTabs.Addons} label="Додатна конфигурација" />
            <Tab value={RoleSalaryConfigTabs.EmployeeVacations} label="Одмор за вработени" />
            <Tab value={RoleSalaryConfigTabs.SickDays} label="Боледување" />
          </Tabs>
        </Grid>

        <Grid item>
          <TabPanel value={tab} index={RoleSalaryConfigTabs.FixedEarnings}>
            <FixedEarningsForm formik={formik} />
          </TabPanel>

          <TabPanel value={tab} index={RoleSalaryConfigTabs.PerformanceEarnings}>
            <PerformanceEarningsForm formik={formik} />
          </TabPanel>

          <TabPanel value={tab} index={RoleSalaryConfigTabs.Breaks}>
            <BreaksForm formik={formik} />
          </TabPanel>

          <TabPanel value={tab} index={RoleSalaryConfigTabs.Bonuses}>
            <BonusesForm formik={formik} />
          </TabPanel>

          <TabPanel value={tab} index={RoleSalaryConfigTabs.Penalties}>
            <PenaltiesForm formik={formik} />
          </TabPanel>

          <TabPanel value={tab} index={RoleSalaryConfigTabs.Addons}>
            <AddonsForm formik={formik} />
          </TabPanel>

       
          <TabPanel value={tab} index={RoleSalaryConfigTabs.EmployeeVacations}>
            <EmployeeVacationsForm formik={formik} />
          </TabPanel>

          <TabPanel value={tab} index={RoleSalaryConfigTabs.SickDays}>
            <SickDaysForm formik={formik} />
          </TabPanel>
        </Grid>
      </Grid>
    </form>
  );
};

export default RoleSalaryConfigDetails;
