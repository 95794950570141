import { createAsyncThunk } from "@reduxjs/toolkit";
import { OrderStatus } from "@shared/services/orders/enums/orderStatus";
import { getDriversRoutesAsync } from "@shared/services/orders/orders.service";

export const getAllDriversRoutesAsync = createAsyncThunk("getAllDriversRoutesRoutes", async () => {
  let driversRoutes = await getDriversRoutesAsync();

  // Filter out all the routePoint that are already delivered
  driversRoutes.map((driversRoute) => {
    driversRoute.routePoints = driversRoute.routePoints.filter(
      (routePoint) => routePoint.orderStatus !== OrderStatus.Delivered
    );

    return driversRoute;
  });

  // Filter out all the drivers routes that are no longer active
  driversRoutes = driversRoutes.filter((driversRoute) => driversRoute.routePoints.length > 0);

  return driversRoutes;
});
