import { useEffect, useRef } from "react";
import { Subject, Subscription } from "rxjs";

const useSubscription = <T>(
  subject: Subject<T>,
  next: (value: T) => void,
  error?: (error: any) => void,
  complete?: () => void
) => {
  const subscriptionRef = useRef<Subscription>();

  useEffect(() => {
    subscriptionRef.current = subject.subscribe(next, error, complete);

    return () => {
      subscriptionRef.current?.unsubscribe();
      subscriptionRef.current = null;
    };
  }, [subject, next, error, complete]);
};

export default useSubscription;
