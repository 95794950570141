export const getNextPageParam = (
  lastPage: number,
  itemsPerPage: number,
  totalItemsCount: number
) => {
  const lastCount = lastPage * itemsPerPage;
  const maxPage = Math.ceil((totalItemsCount - lastCount) / itemsPerPage);

  if (lastPage < maxPage) return lastPage + 1;
  return undefined;
};
