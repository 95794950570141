import {Button, Typography, createStyles, makeStyles } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { FC } from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      minWidth: "75%",
      justifyContent: "space-between",
      textTransform: "lowercase",
    },
    earnings: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  })
);

interface PayrollDaysSummaryItemProps {
  date: string;
  earnings: number;
  onClick: () => void;
}

const PayrollDaysSummaryItem: FC<PayrollDaysSummaryItemProps> = ({
  date = "",
  earnings = 0,
  onClick,
}) => {
  const classes = useStyles();

  return (
    <Button size="large" className={classes.root} onClick={onClick}>
      <Typography variant="subtitle1">{date}</Typography>

      <div style= {{display:"flex"}}>
        <Typography variant="subtitle1" className={classes.earnings}>
          {earnings} ден
        </Typography>
        <ArrowForwardIosIcon />
      </div>
    </Button>
  );
};

export default PayrollDaysSummaryItem;
