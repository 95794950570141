import { CSSProperties, FC } from "react";
type PaddingType = "normal" | "none";

const paddingStyle: { [key in PaddingType]: CSSProperties } = {
  normal: {
    width: "100%",
    paddingBottom: "10px",
  },
  none: {
    width: "100%",
  },
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: unknown;
  value: unknown;
  padding?: PaddingType;
}

export const TabPanel: FC<TabPanelProps> = (props) => {
  const { children, value, index, padding = "normal", ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tab-${index}`}
      aria-labelledby={`tab-${index}`}
      style={paddingStyle[padding]}
      {...other}
    >
      {value === index && (
        <div style={{ padding: 0 }}>
          {children}
        </div>
      )}
    </div>
  );
}