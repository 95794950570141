import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { getCurrentUser } from "@shared/services/auth/auth.service";
import {
  BonusPenaltyType,
  IBonusPenaltyDto,
} from "@shared/services/payroll/dtos/payrollPeriodDayOverviewDto";
import { addBonusPenalty, deleteBonusPenalty } from "@shared/services/payroll/payroll.service";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import * as yup from "yup";

type AddForm = { type: BonusPenaltyType; reason: string; amount: number };
const addValidationSchema = yup.object().shape<Record<keyof AddForm, yup.Schema>>({
  type: yup.number().oneOf([BonusPenaltyType.Bonus, BonusPenaltyType.Penalty]).required(),
  reason: yup.string().required(),
  amount: yup.number().positive().required(),
});
const addInitialValues: AddForm = {
  type: BonusPenaltyType.Bonus,
  reason: "",
  amount: "" as any,
};

type DeleteForm = { reason: string };
const deleteValidationSchema = yup.object().shape<Record<keyof DeleteForm, yup.Schema>>({
  reason: yup.string().required(),
});
const deleteInitialValues: DeleteForm = {
  reason: "",
};

const BonusesAndPenalties = ({
  date,
  bonusesAndPenalties,
  onBonusPenaltyUpdate,
}: {
  date: string;
  bonusesAndPenalties: IBonusPenaltyDto[];
  onBonusPenaltyUpdate: () => void;
}) => {
  const params = useParams();
  const employeeId = useMemo(() => Number(params.employeeId), [params]);
  const theme = useTheme();
  const user = getCurrentUser();
  const { enqueueSnackbar } = useSnackbar();
  const [addMode, setAddMode] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const [deleteModeBonusPenaltyId, setDeleteModeBonusPenaltyId] = useState(null);

  const result = useMemo(() => {
    return bonusesAndPenalties
      .filter((bonusPenalty) => !bonusPenalty.deleted)
      .reduce(
        (result, bonusPenalty) =>
          result +
          (bonusPenalty.type === BonusPenaltyType.Bonus
            ? bonusPenalty.amount
            : Number(`-${bonusPenalty.amount}`)),
        0
      );
  }, [bonusesAndPenalties]);

  const addFormik = useFormik<AddForm>({
    initialValues: addInitialValues,
    validationSchema: addValidationSchema,
    onSubmit: (values) => {
      addBonusPenalty(employeeId, {
        date: date,
        type: values.type,
        reason: values.reason,
        amount: values.amount,
        userId: Number(user.employeeId)
      })
        .then(() => {
          addFormik.resetForm();
          setAddMode(false);
          onBonusPenaltyUpdate();
          enqueueSnackbar("Успешно се додаде бонус/пенал.");
        })
        .catch(() => {
          enqueueSnackbar("Настана грешка при креирање на бонус/пенал.");
        });
    },
  });

  const deleteFormik = useFormik<DeleteForm>({
    initialValues: deleteInitialValues,
    validationSchema: deleteValidationSchema,
    onSubmit: (values) => {
      deleteBonusPenalty(employeeId, {
        id: deleteModeBonusPenaltyId,
        reason: values.reason,
      })
        .then(() => {
          deleteFormik.resetForm();
          setDeleteMode(false);
          onBonusPenaltyUpdate();
          enqueueSnackbar("Успешно се избриша бонус/пенал.");
        })
        .catch(() => {
          enqueueSnackbar("Настана грешка при бришење на бонус/пенал.");
        });
    },
  });

  if (!bonusesAndPenalties?.length) {
    return <div style={{ color: theme.palette.success.light }}>0</div>;
  }

  return (
    <>
      <Tooltip
        placement="top-start"
        interactive={true}
        PopperProps={{
          onClick: (e) => e.stopPropagation(),
        }}
        title={
          <Card style={{ minWidth: 275 }}>
            <CardContent>
              {bonusesAndPenalties.map((bonusPenalty) => (
                <Box key={bonusPenalty.id} mb={2}>
                  <Typography
                    variant="body1"
                    style={{
                      color:
                        bonusPenalty.type === BonusPenaltyType.Bonus
                          ? theme.palette.success.light
                          : theme.palette.error.light,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <strong>
                      {bonusPenalty.type === BonusPenaltyType.Bonus ? "Бонус" : "Пенал"}
                      {bonusPenalty.deleted && (
                        <span style={{ color: theme.palette.text.secondary }}> (Избришан)</span>
                      )}
                    </strong>
                    {!bonusPenalty.deleted && (
                      <DeleteIcon
                        onClick={() => {
                          setDeleteModeBonusPenaltyId(bonusPenalty.id);
                          setDeleteMode(true);
                        }}
                        style={{ color: theme.palette.error.dark, cursor: "pointer" }}
                      />
                    )}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Време:</strong> {dayjs(bonusPenalty.date).format("DD.MM.YYYY HH:mm")}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Од:</strong> {bonusPenalty.author}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Причина:</strong> {bonusPenalty.reason}
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ textDecoration: bonusPenalty.deleted ? "line-through" : "" }}
                  >
                    <strong>Вредност:</strong> {bonusPenalty.amount} ден.
                  </Typography>
                </Box>
              ))}
            </CardContent>
            <CardActions>
              <Button variant="contained" color="primary" onClick={() => setAddMode(true)}>
                Нов бонус/пенал
              </Button>
            </CardActions>
          </Card>
        }
      >
        <div
          style={{
            color: result >= 0 ? theme.palette.success.light : theme.palette.error.light,
          }}
        >
          {result}
        </div>
      </Tooltip>

      <Dialog
        open={addMode}
        onClose={() => {
          addFormik.resetForm();
          setAddMode(false);
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <DialogTitle>Нов бонус/пенал</DialogTitle>
        <DialogContent>
          <form style={{ display: "flex" }} onSubmit={addFormik.handleSubmit}>
            <FormControl component="fieldset" style={{ margin: 4 }}>
              <FormLabel component="legend">Тип</FormLabel>
              <RadioGroup
                aria-label="type"
                id="type"
                name="type"
                value={addFormik.values.type}
                onChange={(e) => addFormik.setFieldValue("type", Number(e.target.value))}
              >
                <FormControlLabel
                  value={BonusPenaltyType.Bonus}
                  control={<Radio />}
                  label="Бонус"
                />
                <FormControlLabel
                  value={BonusPenaltyType.Penalty}
                  control={<Radio />}
                  label="Пенал"
                />
              </RadioGroup>
            </FormControl>

            <Box display="flex" flexDirection="column">
              <TextField
                variant="outlined"
                style={{ margin: 4 }}
                id="reason"
                name="reason"
                label="Причина"
                type="text"
                value={addFormik.values.reason}
                onChange={addFormik.handleChange}
                error={addFormik.touched.reason && Boolean(addFormik.errors.reason)}
                helperText={addFormik.touched.reason && addFormik.errors.reason}
              />

              <TextField
                variant="outlined"
                style={{ margin: 4 }}
                id="amount"
                name="amount"
                label="Вредност"
                InputProps={{
                  endAdornment: <InputAdornment position="start">мкд</InputAdornment>,
                }}
                type="number"
                value={addFormik.values.amount}
                onChange={addFormik.handleChange}
                error={addFormik.touched.amount && Boolean(addFormik.errors.amount)}
                helperText={addFormik.touched.amount && addFormik.errors.amount}
              />
            </Box>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              addFormik.resetForm();
              setAddMode(false);
            }}
            color="default"
          >
            Откажи
          </Button>
          <Button variant="contained" color="primary" onClick={() => addFormik.submitForm()}>
            Додади
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteMode}
        onClose={() => {
          deleteFormik.resetForm();
          setDeleteModeBonusPenaltyId(null);
          setDeleteMode(false);
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <DialogTitle>Избриши бонус/пенал</DialogTitle>
        <DialogContent>
          <form style={{ display: "flex" }} onSubmit={deleteFormik.handleSubmit}>
            <Box display="flex" flexDirection="column">
              {/* <TextField
                variant="outlined"
                style={{ margin: 4 }}
                id="reason"
                name="reason"
                label="Причина"
                type="text"
                value={deleteFormik.values.reason}
                onChange={deleteFormik.handleChange}
                error={deleteFormik.touched.reason && Boolean(deleteFormik.errors.reason)}
                helperText={deleteFormik.touched.reason && deleteFormik.errors.reason}
              /> */}
            </Box>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              deleteFormik.resetForm();
              setDeleteModeBonusPenaltyId(null);
              setDeleteMode(false);
            }}
            color="default"
          >
            Откажи
          </Button>
          <Button variant="contained" color="primary" onClick={() => deleteFormik.submitForm()}>
            Избриши
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BonusesAndPenalties;
