import { useDaysInMonthArr } from "../utils/useDaysInMonthArr";
import PayrollDaysSummaryItem from "./PayrollDaysSummaryItem";
import { Paper, createStyles, makeStyles } from "@material-ui/core";
import dayjs from "dayjs";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
    },
  })
);

interface PayrollSummaryProps {
  employeeId: number;
  employeePayroll: any;
}

const PayrollDaysSummary: FC<PayrollSummaryProps> = ({ employeePayroll, employeeId }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const daysInMonth = useDaysInMonthArr(employeePayroll?.filters?.from);

  return (
    <Paper square className={classes.root}>
      <div style= {{display:"flex", flexDirection:"column", alignItems:"center"}}>
        {employeePayroll.items.map((day, index) => (
          <PayrollDaysSummaryItem
            key={index}
            date={dayjs(day.date).format("DD.MM.YYYY")}
            earnings={day.total !== 0 ? day.total : day.earnings}
            onClick={() => navigate(`/drivers/paycheck/${employeeId}/${day.date}`)}
          />
        ))}
      </div>
    </Paper>
  );
};

export default PayrollDaysSummary;
