import ErrorFallback from "../../../../../error";
import { IPeriodTimeFilter } from "../../shared/components/PeriodTimeFilter";
import PayrollDaySummaryCard from "./components/PayrollDaySummaryCard";
import { usePayrollDayDriverRoutePointColumns } from "./hooks/usePayrollEmployeeDayColumns";
import { Button, Grid, Typography, createStyles, makeStyles } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Loading from "@shared/components/Loading/Loading";
import MaterialTable, { ISort, sortHandler } from "@shared/components/MaterialTable/MaterialTable";
import { getEmployee } from "@shared/services/employees/employees.service";
import { IPayrollDayDriverRoutePointDto } from "@shared/services/payroll/dtos/payrollDayDriverRoutePointDto";
import {
  UpdateDropOffKm,
  getPayrollDayDriverRoutePoints,
  getPayrollDaySummary,
} from "@shared/services/payroll/payroll.service";
import { getNextPageParam } from "@shared/utils/infiniteQuery";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { FC, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const useStyles = makeStyles((theme) =>
  createStyles({
    heading: {
      marginBottom: theme.spacing(4),
    },
    driverRoutePointsTable: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  })
);

type PayrollEmployeeDayParams = {
  employeeId: string;
  date: string;
};

const PayrollEmployeeDay: FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const params = useParams<PayrollEmployeeDayParams>();
  const employeeId = useMemo(() => Number(params.employeeId), [params]);
  const date = useMemo(() => dayjs(params.date + "T00:00Z").toDate(), [params]);
  const endDate = useMemo(() => dayjs(params.date + "T23:59Z").toDate(), [params]);
  const [periodFilter] = useState<IPeriodTimeFilter>({ from: null, to: null });
  const [sort, setSort] = useState<ISort>({ by: null, direction: null });

  const employeeQuery = useQuery(["getEmployee", employeeId], () => getEmployee(employeeId), {
    keepPreviousData: true,
  });

  const summaryQuery = useQuery(
    ["getPayrollDaySummary", employeeId, date, periodFilter.from, periodFilter.to],
    () => getPayrollDaySummary(employeeId, { from: dayjs(date).utc().add(1,"day").toDate(), to: endDate }),
    { keepPreviousData: true }
  );

  const driverRoutePointsQuery = useInfiniteQuery(
    ["getPayrollDayDriverRoutePoints", employeeId, date, periodFilter.from, periodFilter.to],
    ({ pageParam = 0 }) =>
      getPayrollDayDriverRoutePoints(employeeId, {
        currentPage: pageParam,
        day: dayjs(date).utc().add(1,"day").toDate(),
        itemsPerPage: 10,
        filters: {
          from: dayjs(date).utc().add(1,"day").toDate(),
          to: endDate,
        },
        sortBy: sort.by,
        sortDirection: sort.direction,
        items: [],
        totalItemsCount: 0,
      }),
    {
      keepPreviousData: true,
      getNextPageParam: (lastPage) =>
        getNextPageParam(lastPage.currentPage, lastPage.itemsPerPage, lastPage.totalItemsCount),
    }
  );
  const updateRoutePoint = async (
    newData: IPayrollDayDriverRoutePointDto,
    oldData: IPayrollDayDriverRoutePointDto
  ) => {
    if (newData.id !== oldData.id) {
      try {
        const { id, km } = newData;

        const updateDropOffKmDto = {
          DropOffRoutePointId: id,
          TotalKm: km,
        };
        const response = await UpdateDropOffKm(employeeId, updateDropOffKmDto);
        console.log("api", response);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const driverRoutePointsColumns = usePayrollDayDriverRoutePointColumns();
  const driverRoutePointsData = useMemo(
    () => driverRoutePointsQuery.data?.pages?.flatMap((pageData) => pageData.items) ?? [],
    [driverRoutePointsQuery.data]
  );

  if (employeeQuery.isError || summaryQuery.isError || driverRoutePointsQuery.isError)
    return (
      <ErrorFallback
        error={employeeQuery.error ?? summaryQuery.error ?? driverRoutePointsQuery.error}
      />
    );
  if (employeeQuery.isLoading || summaryQuery.isLoading || driverRoutePointsQuery.isLoading)
    return <Loading />;

  return (
    <Grid container>
      <Grid container className={classes.heading}>
        <Grid item>
          <Button onClick={() => navigate(`/app/payroll/${employeeId}`)}>
            <ArrowBackIosIcon />
          </Button>
        </Grid>
        <Grid item>
          <Typography variant="h5">Платен список за вработен:</Typography>
          <Typography variant="h6">
            {employeeQuery.data.firstName} {employeeQuery.data.lastName}
          </Typography>
          <Typography>на ден: {dayjs(date).format("DD.MM.YYYY")}</Typography>
        </Grid>
      </Grid>
      {/* 
      <Grid container>
        <Grid container direction="row">
          <PeriodTimeFilter date={date} onChange={(from, to) => setPeriodFilter({ from, to })} />
        </Grid>
      </Grid> */}

      <Grid container>
        <Grid item>
          <PayrollDaySummaryCard payrollDaySummary={summaryQuery.data} />
        </Grid>

        <MaterialTable<IPayrollDayDriverRoutePointDto>
          title="Испораки"
          className={classes.driverRoutePointsTable}
          columns={driverRoutePointsColumns.map((column) => ({
            ...column,
            editable: column.field === "km" ? "onUpdate" : "never",
          }))}
          data={driverRoutePointsData}
          options={{
            search: false,
            paging: false,
            maxColumnSort: 1,
          }}
          isLoading={driverRoutePointsQuery.isFetching}
          infiniteLoad={true}
          hasNextPage={driverRoutePointsQuery.hasNextPage}
          fetchNextPage={driverRoutePointsQuery.fetchNextPage}
          onOrderCollectionChange={(oc) => sortHandler(oc, driverRoutePointsColumns, setSort)}
          editable={{
            onRowUpdate: (
              newData: IPayrollDayDriverRoutePointDto,
              oldData: IPayrollDayDriverRoutePointDto
            ) => updateRoutePoint(newData, oldData),
          }}
        />
      </Grid>
    </Grid>
  );
};

export default PayrollEmployeeDay;

