import { Typography, createStyles, makeStyles } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import DebounceLoading from "@shared/components/Debounce/DebounceLoading";
import { getAllCompanies } from "@shared/services/settings/companies.service";
import { useQuery } from "@tanstack/react-query";
import { FC, useState } from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      margin: theme.spacing(2),
    },
  })
);

interface CompanyFilterProps {
  onChange: (companyIds: number[]) => void;
}

const CompanyFilter: FC<CompanyFilterProps> = ({ onChange }) => {
  const classes = useStyles();

  const [selectedCompanies, setSelectedCompanies] = useState<number[]>([]);
  const { data: companies, isLoading, isFetched } = useQuery(["getAllCompanies"], getAllCompanies);

  return (
    <div style= {{display: "flex" ,flexDirection: "column", alignItems: "center"}} className={classes.root}>
      <Typography variant="h6">Компании</Typography>

      <DebounceLoading loading={isLoading} render={isFetched}>
        <ToggleButtonGroup
          value={selectedCompanies}
          onChange={(_, values: number[]) => {
            setSelectedCompanies(values);
            onChange(values);
          }}
        >
          {companies?.map((company) => (
            <ToggleButton
              key={company.id}
              value={company.id}
            >{`${company.abbreviation} - ${company.name}`}</ToggleButton>
          ))}
        </ToggleButtonGroup>
      </DebounceLoading>
    </div>
  );
};

export default CompanyFilter;
