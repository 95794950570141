import OrderCard from "./OrderCard";
import { makeStyles } from "@material-ui/core";
import { IOrderDto } from "@shared/services/orders/dtos/orders/orderDto";
import { CSSProperties, FC, memo } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    color: "black",
    alignItems: "center",
  },
  avatar: {
    width: theme.spacing(3.5),
    height: theme.spacing(3.5),
  },
  packageContainer: {
    padding: "10px",
    borderRadius: "8px",
    background: "#ffffff",
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
  },
}));

interface PackageCardProps {
  packageGroup: IOrderDto[];
  style?: CSSProperties;
}

const PackageCard: FC<PackageCardProps> = ({ packageGroup, style }) => {
  const classes = useStyles();

  return (
    <div className={classes.packageContainer} style={style}>
      {packageGroup.map((order) => (
        <OrderCard key={order.id} order={order} style={{ width: "100%" }} />
      ))}
    </div>
  );
};

export default memo(PackageCard);

