import {Typography, createStyles, makeStyles } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import DebounceLoading from "@shared/components/Debounce/DebounceLoading";
import { getCities } from "@shared/services/settings/cities.service";
import { useQuery } from "@tanstack/react-query";
import { FC, useState } from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      margin: theme.spacing(2),
    },
  })
);

interface CitiesFilterProps {
  onChange: (citiesIds: number[]) => void;
}

const CitiesFilter: FC<CitiesFilterProps> = ({ onChange }) => {
  const classes = useStyles();

  const [selectedCities, setSelectedCities] = useState<number[]>([]);
  const { data: cities, isLoading, isFetched } = useQuery(["getCities"], getCities);

  return (
    <div style ={{display:"flex", flexDirection: "column", alignItems: "center"}} className={classes.root}>
      <Typography variant="h6">Градови</Typography>

      <DebounceLoading loading={isLoading} render={isFetched}>
        <ToggleButtonGroup
          value={selectedCities}
          onChange={(_, values: number[]) => {
            setSelectedCities(values);
            onChange(values);
          }}
        >
          {cities?.map((city) => (
            <ToggleButton key={city.id} value={city.id}>
              {city.name}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </DebounceLoading>
    </div>
  );
};

export default CitiesFilter;
