import ErrorFallback from "../../../../error";
import PayoutsCreate from "./PayoutsCreate/PayoutsCreate";
import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import PayoutsList from "./PayoutsList/PayoutsList";

const Payouts: FC = () => {
  return (
    <Routes>
      <Route errorElement={<ErrorFallback />}>
        <Route path="create" element={<PayoutsCreate />} />
        <Route path="list" element={<PayoutsList />} />

        <Route path="*" element={<PayoutsFallback />} />
      </Route>
    </Routes>
  );
};

const PayoutsFallback = () => <Navigate to="" />;

export default Payouts;
